import {
  Button,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Loading,
  TableContainer,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TextInput,
  Modal,
  Grid,
  Column,
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import { Add, Edit, CheckmarkOutline } from '@carbon/icons-react';
import React from 'react';
import Axios from 'axios';
import './edit-program.scss';
import utilService from '../../../services/util-service';

const adminProgramEmailRegex = RegExp(
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
);
const adminProgramUrlRegex = RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
);

export default class EditProgram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: utilService.hostname,
      emailId: localStorage.getItem('adminEmail'),
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      isEditOverlayVisible: false,
      isAddOverlayVisible: false,
      editDataset: {},
      rowData: [],
      headerData: [
        { header: 'Program Name', key: 'portalName' },
        { header: 'Support Email', key: 'emailId' },
        { header: 'Base URL', key: 'baseURL' },
      ],
      selectedRowData: null,
      invalidPortalName: false,
      invalidBaseUrl: false,
      invalidEmailID: false,
      adminProgramName: null,
      adminProgramUrl: null,
      adminProgramEmail: null,
    };
    console.log('program configuration page loaded');
  }

  componentDidMount() {
    this.getAllEditProgramList();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  getAllEditProgramList() {
    this.setState({ showLoader: true });
    utilService
      .getData('/a2mt/services/user-details/getConfigDetails')
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        if (res.data != null) {
          this.setState({ rowData: res.data });
        } else {
          this.setState({ rowData: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  editSelectedRow = event => {
    let sel = this.state.selectedRowData;
    console.log(sel);
    if (sel !== null) {
      let record = this.state.rowData.filter(e => e.id === sel.id);
      if (record.length > 0) {
        this.setState({ editDataset: record[0], isEditOverlayVisible: true });
        console.log('record', record[0]);
      } else {
        this.setState({ editDataset: {} });
      }
    } else {
      this.setState({
        alertMsg: 'Please select a row to edit!',
        alertType: 'Warning',
        showAlert: true,
      });
    }
  };

  addNewRow = event => {
    this.setState({ isAddOverlayVisible: true });
  };

  onTextChange = event => {
    let id = event.target.id,
      vl = event.target.value;
    console.log('onTextChange', id, vl);
    if (id == 'addProgram-1' && vl.trim() !== '') {
      this.setState({ adminProgramName: vl.trim(), invalidPortalName: false });
    }
    if (id == 'addProgram-2' && vl.trim() !== '') {
      adminProgramUrlRegex.test(vl)
        ? this.setState({ adminProgramUrl: vl.trim(), invalidBaseUrl: false })
        : this.setState({ adminProgramUrl: null, invalidBaseUrl: true });
    }
    if (id == 'addProgram-3' && vl.trim() !== '') {
      adminProgramEmailRegex.test(vl)
        ? this.setState({ adminProgramEmail: vl.trim(), invalidEmailID: false })
        : this.setState({ adminProgramEmail: null, invalidEmailID: true });
    }

    if (id == 'editProgram-1' && vl.trim() !== '') {
      this.setState(prevState => ({
        editDataset: { ...prevState.editDataset, portalName: vl.trim() },
      }));
      this.setState({ invalidPortalName: false });
    } else if (id == 'editProgram-1' && vl.trim() == '') {
      this.setState({ invalidPortalName: true });
    }
    if (id == 'editProgram-2' && vl.trim() !== '') {
      if (adminProgramUrlRegex.test(vl.trim())) {
        this.setState(prevState => ({
          editDataset: { ...prevState.editDataset, baseURL: vl.trim() },
        }));
        this.setState({ invalidBaseUrl: false });
      } else {
        this.setState({ invalidBaseUrl: true });
      }
    } else if (id == 'editProgram-2' && vl.trim() == '') {
      this.setState({ invalidBaseUrl: true });
    }
    if (id == 'editProgram-3' && vl.trim() !== '') {
      if (adminProgramEmailRegex.test(vl.trim())) {
        this.setState(prevState => ({
          editDataset: { ...prevState.editDataset, emailId: vl.trim() },
        }));
        this.setState({ invalidEmailID: false });
      } else {
        this.setState({ invalidEmailID: true });
      }
    } else if (id == 'editProgram-3' && vl.trim() == '') {
      this.setState({ invalidEmailID: true });
    }
  };

  addProgramSubmit = event => {
    let addFormValid = true;
    if (this.state.adminProgramName == null) {
      this.setState({ invalidPortalName: true });
      addFormValid = false;
    }
    if (this.state.adminProgramUrl == null) {
      this.setState({ invalidBaseUrl: true });
      addFormValid = false;
    }
    if (this.state.adminProgramEmail == null) {
      this.setState({ invalidEmailID: true });
      addFormValid = false;
    }
    if (addFormValid) {
      console.log('valid form');
      let data = {
        emailId: this.state.adminProgramEmail,
        portalName: this.state.adminProgramName,
        createdBy: this.state.emailId,
        baseURL: this.state.adminProgramUrl,
      };
      this.setState({ showLoader: true });
      this.closeAllOverlays();
      utilService
        .postData('/a2mt/services/user-details/addConfig', data)
        .then(rs => {
          console.log(rs.data);
          this.setState({ showLoader: false });
          this.resetForm();
          this.getAllEditProgramList();
          if (rs.data.success === true) {
            this.setState({
              alertMsg: 'Program is successfully added.',
              alertType: 'Success',
              showAlert: true,
            });
          } else if (rs.data.success === false) {
            this.setState({
              alertMsg: 'Program already exists.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
        })
        .catch(error => {
          this.setState({ showLoader: false });
          this.resetForm();
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  };

  editProgramSubmit = event => {
    if (
      !this.state.invalidPortalName &&
      !this.state.invalidBaseUrl &&
      !this.state.invalidEmailID
    ) {
      console.log('valid form');
      let data = this.state.editDataset;
      this.setState({ showLoader: true });
      this.closeAllOverlays();
      utilService
        .postData('/a2mt/services/user-details/updateConfigDet', data)
        .then(rs => {
          console.log(rs.data);
          this.setState({ showLoader: false });
          this.resetForm();
          this.getAllEditProgramList();
          if (rs.data.success === true) {
            this.setState({
              alertMsg: 'Program is successfully updated.',
              alertType: 'Success',
              showAlert: true,
            });
          } else {
            this.setState({
              alertMsg: 'Something went wrong. Please try again.',
              alertType: 'Error',
              showAlert: true,
            });
          }
        })
        .catch(error => {
          this.setState({ showLoader: false });
          this.resetForm();
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  };

  closeAllOverlays = event => {
    this.setState({ isAddOverlayVisible: false, isEditOverlayVisible: false });
  };

  resetForm() {
    document.getElementById('addProgram-1').value = '';
    document.getElementById('addProgram-2').value = '';
    document.getElementById('addProgram-3').value = '';
    this.setState({
      invalidPortalName: false,
      invalidBaseUrl: false,
      invalidEmailID: false,
      adminProgramName: null,
      adminProgramUrl: null,
      adminProgramEmail: null,
      editDataset: {},
    });
  }

  render() {
    return (
      <div className="body-height">
        <Grid className="admin-sub-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <div style={{ paddingBottom: '32px' }}>
              <h3>List of All Editable Program</h3>
            </div>
            <section>
              <DataTable
                rows={this.state.rowData}
                headers={this.state.headerData}
                isSortable
                radio>
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getSelectionProps,
                  getTableProps,
                  getTableContainerProps,
                  onInputChange,
                }) => (
                  <TableContainer {...getTableContainerProps()}>
                    <TableToolbar>
                      <TableToolbarContent>
                        <TableToolbarSearch onChange={onInputChange} />
                        <Button
                          hasIconOnly
                          renderIcon={Edit}
                          iconDescription="Edit Program"
                          kind="ghost"
                          onClick={this.editSelectedRow}
                          tooltipAlignment="center"
                          tooltipPosition="bottom"
                          id="edt-btn"
                        />
                        <Button
                          renderIcon={Add}
                          onClick={this.addNewRow}
                          id="add-btn">
                          Add Program
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <th scope="col" />
                          {headers.map((header, i) => (
                            <TableHeader
                              key={i}
                              {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i) => (
                          <TableRow key={i} {...getRowProps({ row })}>
                            <TableSelectRow
                              {...getSelectionProps({
                                row,
                                onClick: () =>
                                  this.setState({ selectedRowData: row }),
                              })}
                            />
                            {row.cells.map(cell => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DataTable>
            </section>

            <Loading
              withOverlay={true}
              description="Active loading indicator"
              active={this.state.showLoader}
            />

            <Modal
              passiveModal={true}
              preventCloseOnClickOutside={false}
              open={this.state.showAlert}
              size="sm"
              onRequestClose={() => {
                this.setState({ showAlert: false });
              }}>
              <div style={{ padding: '1em 0' }}>
                {this.state.alertType == 'Success' && (
                  <div>
                    <CheckmarkOutline
                      style={{ width: '7em', height: '7em', color: 'green' }}
                    />
                  </div>
                )}
                {this.state.alertType == 'Warning' && (
                  <div>
                    <WarningAlt
                      style={{ width: '7em', height: '7em', color: 'orange' }}
                    />
                  </div>
                )}
                {this.state.alertType == 'Error' && (
                  <div>
                    <MisuseOutline
                      style={{ width: '7em', height: '7em', color: 'red' }}
                    />
                  </div>
                )}
                <div style={{ paddingLeft: '1em' }}>{this.state.alertMsg}</div>
              </div>
            </Modal>

            <Modal
              hasForm
              open={this.state.isAddOverlayVisible}
              primaryButtonText="Add"
              secondaryButtonText="Cancel"
              modalHeading="Add program"
              onRequestClose={() => {
                this.closeAllOverlays();
                this.resetForm();
              }}
              onRequestSubmit={this.addProgramSubmit}>
              <div style={{ margin: '1em 0' }}>
                <TextInput
                  id="addProgram-1"
                  labelText="Enter program name"
                  onChange={this.onTextChange}
                  invalid={this.state.invalidPortalName}
                  invalidText="Please enter valid program name"
                />
              </div>
              <div style={{ marginBottom: '1em' }}>
                <TextInput
                  id="addProgram-2"
                  labelText="Enter base url"
                  onChange={this.onTextChange}
                  invalid={this.state.invalidBaseUrl}
                  invalidText="Please enter valid base url"
                />
              </div>
              <div style={{ marginBottom: '1em' }}>
                <TextInput
                  id="addProgram-3"
                  labelText="Enter support email ID"
                  onChange={this.onTextChange}
                  invalid={this.state.invalidEmailID}
                  invalidText="Please enter valid support email"
                />
              </div>
            </Modal>

            <Modal
              hasForm
              open={this.state.isEditOverlayVisible}
              primaryButtonText="Update"
              secondaryButtonText="Cancel"
              modalHeading="Edit program"
              onRequestClose={() => {
                this.closeAllOverlays();
                this.resetForm();
              }}
              onRequestSubmit={this.editProgramSubmit}>
              <div style={{ margin: '1em 0' }}>
                <TextInput
                  id="editProgram-1"
                  labelText="Enter program name"
                  defaultValue={this.state.editDataset.portalName}
                  onChange={this.onTextChange}
                  invalid={this.state.invalidPortalName}
                  invalidText="Please enter valid program name"
                />
              </div>
              <div style={{ marginBottom: '1em' }}>
                <TextInput
                  id="editProgram-2"
                  labelText="Enter base url"
                  defaultValue={this.state.editDataset.baseURL}
                  onChange={this.onTextChange}
                  invalid={this.state.invalidBaseUrl}
                  invalidText="Please enter valid base url"
                />
              </div>
              <div style={{ marginBottom: '1em' }}>
                <TextInput
                  id="editProgram-3"
                  labelText="Enter support email ID"
                  defaultValue={this.state.editDataset.emailId}
                  onChange={this.onTextChange}
                  invalid={this.state.invalidEmailID}
                  invalidText="Please enter valid support email"
                />
              </div>
            </Modal>
          </Column>
        </Grid>
      </div>
    );
  }
}
