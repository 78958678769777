import { Grid, Column } from '@carbon/react';
import React from 'react';
import './_sbsd-privacy.scss';
import NormalHeader from '../../components/NormalHeader/NormalHeader';

export default class SBSDPrivacy extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <NormalHeader />
        <Grid condensed>
          <Column lg={5} md={8} sm={4}>
            <div width="100%" className="bgImg1" />
          </Column>
          <Column
            lg={{ span: 8, offset: 6 }}
            md={{ span: 6, offset: 1 }}
            sm={4}>
            <span className="textContent">
              <br /> <br />
              <br /> <br />
              <h2 class="contentTitle">
                IBM SkillsBuild Software Downloads Privacy Consent
              </h2>
              <div />
              <div>
                <div>
                  <div>
                    <div
                      data-wch-inline-edit="elements.desc.value"
                      class="content_descp">
                      <p>
                        The IBM SkillsBuild Software Downloads Privacy Statement
                        applies to use of the IBM SkillsBuild Software Downloads
                        website.&nbsp; The below statements should be considered
                        in addition to the{' '}
                        <a
                          data-key="undefined"
                          href="https://www.ibm.com/privacy/us/en/?lnk=flg-priv-usen"
                          rel="noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer"
                          target="_blank">
                          IBM Online Privacy Statement
                        </a>
                        .
                      </p>
                      <p>&nbsp;</p>
                      <h4>Personal Information</h4>
                      <p>&nbsp;</p>
                      <p>
                        We collect your personal information for the purpose of
                        granting access, processing or communications as it
                        pertains to the IBM Academic Initiative.&nbsp;
                      </p>
                      <p>&nbsp;</p>
                      <ol>
                        <li>
                          Participants will be contacted to revalidate
                          their&nbsp;access to the program every six (6) months
                        </li>
                        <li>
                          Participants may also be contacted with reminders of
                          when services they have signed up for are about to
                          expire.&nbsp;
                        </li>
                      </ol>
                      <p>&nbsp;</p>
                      <h4>Uses</h4>
                      <ol>
                        <li>
                          To evaluate, review and process your request for
                          access to the program
                        </li>
                        <li>
                          To contact you in connection with revalidating your
                          access to the program
                        </li>
                        <li>
                          To contact you to promote projects and services
                          related to those you have previously subscribed to
                        </li>
                        <li>To remind you of expiring services</li>
                        <li>
                          To connect you with other IBM academic offerings,
                          projects, and services related to those you have
                          previously subscribed to
                        </li>
                      </ol>
                      <p>&nbsp;</p>
                      <h4>Retention</h4>
                      <ol>
                        <li>
                          Data is retained for as long as the user remains
                          active and reauthorizes.&nbsp; Once the user no longer
                          reauthorizes, we retain their data for only 6 months
                        </li>
                      </ol>
                      ,<h4>Your Choices</h4>
                      <ol>
                        <li>
                          When we collect information from you, you may tell us
                          that you do not want it used for further contact.
                        </li>
                        <li>
                          You may also request that your nomination be
                          withdrawn.
                        </li>
                      </ol><br />
                      <p>IBM may share the personal information collected with IBM subsidiaries and third parties globally.</p><br />
                      <p>
                        To find out more about IBM privacy practices, see the
                        <a
                          data-key="undefined"
                          href="https://www.ibm.com/privacy"
                          rel="noopener noreferrer"
                          target="_blank">
                          &nbsp;IBM Online Privacy Statement
                        </a>
                        .
                      </p><br />
                    </div>
                  </div>
                </div>
              </div>
              <br /> <br />
              <br /> <br />
            </span>
          </Column>
        </Grid>
      </div>
    );
  }
}
