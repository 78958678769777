import React from 'react';
import {
  Button, DataTable, Table, TableHead, TableRow, TableHeader, TableBody, TableCell,
  Loading, TableContainer, TableSelectRow, TableToolbar, TableToolbarContent, TableToolbarSearch,
  Pagination, Modal, Tooltip, Grid, Column, Section
} from '@carbon/react';
import './create-workshop.scss';
import utilService from '../../../services/util-service';
import { Edit, Add, Download, Copy } from '@carbon/react/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CSVLink, CSVDownload } from "react-csv";
// import {
//   EditorState,
//   ContentState,
//   convertToRaw,
//   convertFromHTML,
// } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
// import draftToHtml from "draftjs-to-html";
// import htmlToDraft from "html-to-draftjs";
const validDomainRegex = RegExp('([\\da-z.-]+)\\.([a-z.]{2,6})[\\w.-]*?');


export default class WorkshopEvent extends React.Component {
  csvLink = React.createRef()
  constructor(props) {
    super(props);
    utilService.setBaseURL();
    this.state = {
      redirect: false,
      baseURL: utilService.hostname,
      //  emailId: localStorage.getItem('adminEmail'),
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      editDataset: {},
      showComponent: false,
      page: 1,
      pageSize: 10,
      rowData: [],
      originalRowData: [],
      headerData: [
        { header: 'ID', key: 'id' },
        { header: 'Workshop title', key: 'TITLE' },
        { header: 'Topic', key: 'TOPIC' },
        { header: 'Start date', key: 'START_DATE' },
        { header: 'End date', key: 'END_DATE' },
        { header: 'Capacity', key: 'CAPACITY' },
        { header: 'Status', key: 'STATUS' },
        { header: 'Copy link', key: 'COPY_LINK' },
        { header: 'CSV download', key: 'CREATOR_EMAIL' },
        //  { header: 'Description', key: 'DESCRIPTION' }
      ],
      copyLinkUrl: "",
      tooltip_notification: {
        recert_email: <Tooltip
          direction="bottom"
          tabIndex={0}
        // triggerText="{triggerEmail}"
        >
          <p>
            Set number of days for sending out recertification email to the users.
          </p>
        </Tooltip>
      },
      selectedRowData: null,
      downloadCsvRowData: [],
      downloadCsvId: '',
      invalidDplType: false, invalidEmailDomain: false, invalidInstitution: false,
    };
  }

  componentDidMount() {
    this.initGrid();
    utilService.setFrontEndLink();
    this.setState({ copyLinkUrl: utilService.copyLINK });
    // const org = window.location.origin;
    // console.log('org', org);
    // if (org.indexOf('prod') !== -1) {

    //   this.state.copyLinkUrl = "https://www.ibm.com/academic/events/skills-academy?ws=";

    // } else if (org.indexOf('uat') !== -1) {
    //   this.state.copyLinkUrl = "https://wwwpoc.ibm.com/academic/events/skills-academy?ws=";

    // } else {

    //   this.state.copyLinkUrl = "https://wwwpoc.ibm.com/academic/events/skills-academy?ws=";
    // }
  }



  initGrid() {

    this.setState({
      showLoader: true
    });
    utilService.getData('/a2mt/services/user-details/getWorkshop').then(res => {
      this.setState({
        showLoader: false
      });
      if (res.data != null) {
        this.setState({
          originalRowData: res.data,
        });
        // console.log("originalRowData==", this.state.originalRowData);
        let dataArray = res.data


        dataArray.map((data, i) => {
          dataArray[i].START_DATE = this.changeDateFormat(data.START_DATE)
          dataArray[i].END_DATE = this.changeDateFormat(data.END_DATE)

          //  let contentBlock = htmlToDraft(data.DESCRIPTION);
          //  let contState = ContentState.createFromBlockArray(
          //    contentBlock.contentBlocks
          //  );
          //  dataArray[i].DESCRIPTION = EditorState.createWithContent(
          //    ContentState.createFromBlockArray(
          //      convertFromHTML(data.DESCRIPTION)
          //    )
          //  );

          //  console.log("dataArray[i].DESCRIPTION===",dataArray[i].DESCRIPTION);

          // dataArray[i].DESCRIPTION = draftToHtml(
          //   convertToRaw(data.DESCRIPTION.getCurrentContent())
          // );
          // dataArray[i].DESCRIPTION_new = data.DESCRIPTION;
          // dataArray[i].DESCRIPTION = data.DESCRIPTION.replace(
          //   /(<([^>]+)>)/gi,
          //   ""
          // );

          // console.log("DESCRIPTION===", data.DESCRIPTION.innerText);
          // console.log(data.DESCRIPTION.replace(/(<([^>]+)>)/gi, ""));


        });
        this.setState({
          rowData: dataArray
        });
        // setTimeout({},)
        // setTimeout(function () {
        // console.log("originalRowData==", this.state.originalRowData);
        // }, 10000);

      } else {
        this.setState({
          rowData: []
        })
      }
    }).catch((error) => {
      this.setState({
        showLoader: false
      });
      this.setState({
        alertMsg: 'Something went wrong. Please try again.',
        alertType: 'Error',
        showAlert: true
      });
    });

  }

  handlePagination(event) {
    // console.log("event=", event)
    const {
      page,
      pageSize
    } = event;

    if (page && pageSize) {
      this.setState({
        page,
        pageSize
      });
    }
  }

  changeDateFormat(a) {

    let str = a.substring(0, a.length - 12);
    var date = new Date(str);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return month + "/" + day + "/" + year;
  }

  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  _onButtonClick() {
    utilService.setOtherPageAccessible(true);
    window.location = "/a2mt/admin-dashboard/workshop-event-form";
  }

  getSelectedRow = (event) => {
    // console.log("originalRowData=", this.state.originalRowData);
    let sel = this.state.selectedRowData;
    // console.log("sel=", sel);
    if (sel !== null) {
      let record = this.state.rowData.filter((e) => e.id === sel.id);


      //   console.log("record=", record[0].id);
      // this.state.originalRowData.filter((e) => {

      //   if (e.id === record[0].id) {
      //     record[0].DESCRIPTION_new = e.DESCRIPTION;
      //   }


      // })
      //  console.log("record=", record);

      if (record.length > 0) {
        sessionStorage.setItem("workshopEventEditDataset", JSON.stringify(record[0]));
        utilService.setOtherPageAccessible(true);
        window.location = "/a2mt/admin-dashboard/workshop-event-form";
      } else {
        this.setState({
          editDataset: {}
        })
      }
    } else {
      this.setState({
        alertMsg: 'Please select a row to review!',
        alertType: 'Warning',
        showAlert: true
      });
    }
  }

  downloadCSV = (id, value) => {
    this.setState({
      showLoader: true,
      downloadCsvRowData: [],
      downloadCsvId: '',
    });
    utilService.getData('/a2mt/services/user-details/showWorkshopRegisterCsv/' + id).then(res => {
      this.setState({
        showLoader: false
      });
      let csvDataArray = [];
      if (res.data.length > 0 && res.data != null) {
        for (var i = 0; i < res.data.length; i++) {
          csvDataArray.push({
            'Country': res.data[i].country,
            'Name': res.data[i].firstName + ' ' + res.data[i].lastName,
            'Email': res.data[i].userEmail,
            'Institution Name': res.data[i].institutionName,
            'Host Email': value
          })
        }
        this.setState({ downloadCsvRowData: csvDataArray, downloadCsvId: id }, () => {
          this.csvLink.current.link.click()
        });
      } else if (res.data.length == 0 || res.data == []) {
        this.setState({
          alertMsg: 'There are currently no approved participants for this event',
          showAlert: true
        });
      } else {
        this.setState({
          alertMsg: 'There are currently no approved participants for this event',
          showAlert: true
        });
      }
    }).catch((error) => {
      this.setState({
        showLoader: false
      });
      this.setState({
        alertMsg: 'Something went wrong. Please try again.',
        alertType: 'Error',
        showAlert: true
      });
    });
  }

  closeAlert = (event) => {
    this.setState({
      showAlert: false
    })
  }

  render() {
    const { page, pageSize } = this.state;
    return (
      <Grid>
        <Column lg={{ span: 12, offset: 4 }} md={8} sm={4} style={{padding: "3em 0"}}>
          <p>
            <div style={{ paddingBottom: '32px' }}>
              <h3>Create Workshop</h3>
            </div>
          </p>
          {/* <p style={{ fontSize: ".75rem",fontWeight: "350"}}>Session on Tuesday, March 16, 2021 8:30am - Friday March 26, 2021 8:30am 9 courses</p> */}
          <Section>
            <DataTable
              rows={this.state.rowData}
              headers={this.state.headerData}
              radio
              isSortable
            >
              {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getSelectionProps,
                getTableProps,
                getTableContainerProps,
                onInputChange,
              }) => (
                <TableContainer {...getTableContainerProps()}>
                  <TableToolbar>
                    <TableToolbarContent>
                      <TableToolbarSearch onChange={onInputChange} />
                      <Edit
                        style={{
                          margin: "15px 25px 0 25px",
                          cursor: "pointer",
                        }}
                        onClick={this.getSelectedRow}
                      />
                      {/* <Settings16 style={{margin:"15px 25px 0 0"}} /> */}
                      <Button onClick={this._onButtonClick}>
                        Add new
                        <Add />
                      </Button>
                    </TableToolbarContent>
                  </TableToolbar>
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        <th scope="col" />
                        {headers.map((header, i) => (
                          <TableHeader
                            key={i}
                            {...getHeaderProps({ header })}
                          >
                            {header.header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .splice((page - 1) * pageSize, pageSize)
                        .map((row, i) => (
                          <TableRow key={i} {...getRowProps({ row })}>
                            <TableSelectRow
                              {...getSelectionProps({
                                row,
                                onClick: () =>
                                  this.setState({ selectedRowData: row }),
                              })}
                            />
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>
                                {cell.id.indexOf("STATUS") !== -1 ? (
                                  <span>
                                    <span className={cell.value}></span>
                                    <span>{cell.value}</span>
                                  </span>
                                ) :
                                  <>
                                    {cell.id.indexOf("COPY_LINK") !== -1 ? (
                                      <div>
                                        <CopyToClipboard
                                          text={this.state.copyLinkUrl + cell.id.split(":")[0]}
                                          onCopy={() => {
                                            // this.state.tooltip_notification.recert_email}
                                            // alert("h"+cell.id.split(":")[0]+"kk")
                                            document.getElementById(
                                              cell.id + 'copyAlert'
                                            ).style.display = 'inline-block';
                                            setTimeout(() => {
                                              document.getElementById(
                                                cell.id + 'copyAlert'
                                              ).style.display = 'none';
                                            }, 2000);
                                          }}>
                                          <Copy
                                            style={{ marginLeft: '1rem', cursor: 'pointer' }}
                                            alt="copy icon"
                                            aria-label="Copy"
                                            tabIndex="2"
                                            id={cell.id}
                                          />
                                        </CopyToClipboard>
                                        <span
                                          id={cell.id + 'copyAlert'}
                                          style={{
                                            position: 'relative',
                                            left: "0.5rem",
                                            bottom: "0.5rem",
                                            display: 'none',
                                          }}
                                        >
                                          Copied!
                                        </span>
                                      </div>)
                                      :
                                      (cell.id.indexOf("CREATOR_EMAIL") !==
                                        -1 ?
                                        (<div>
                                          <Download
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              this.downloadCSV(row.id, cell.value);
                                            }}
                                          >
                                          </Download>
                                          {this.state.downloadCsvRowData.length > 0 &&
                                            <CSVLink
                                              data={this.state.downloadCsvRowData}
                                              filename={this.state.downloadCsvId}
                                              className="hidden"
                                              ref={this.csvLink}
                                              target="_blank"
                                            />
                                          }
                                          {/* {this.state.downloadCsvRowData.length > 0 &&
                                                <CSVDownload data={this.state.downloadCsvRowData} target="_blank" />
                                              } */}
                                        </div>)
                                        :
                                        cell.value)
                                    }
                                  </>
                                }
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DataTable>
            <Pagination
              backwardText="Previous page"
              disabled={false}
              forwardText="Next page"
              isLastPage={false}
              itemsPerPageText="Items per page:"
              onChange={(e) => this.handlePagination(e)}
              pageInputDisabled={false}
              pageNumberText="Page Number"
              pageSizes={[10, 15, 20, 25]}
              pagesUnknown={false}
              totalItems={this.state.rowData.length}
            />
          </Section>
        </Column>
        <Loading
          withOverlay={true}
          description="Active loading indicator"
          active={this.state.showLoader}
        ></Loading>
        <Modal
          passiveModal={true}
          preventCloseOnClickOutside={false}
          open={this.state.showAlert}
          size="sm"
          onRequestClose={this.closeAlert}
        >
          <div style={{ padding: "1em 0" }}>
            <div style={{ paddingLeft: "1em" }}>
              {this.state.alertMsg}
            </div>
          </div>
        </Modal>
      </Grid>
    );
  }
}

