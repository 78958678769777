import {
  Theme,
  Grid,
  Column,
  Section,
  Button,
  Select, SelectItem, RadioButtonGroup, RadioButton, FormGroup, Checkbox, Modal, Link, Loading, TextInput
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/react/icons';
import { Help, CheckmarkOutline, UserAvatar, Search } from '@carbon/react/icons';
import React from 'react';
import './register.scss';
import TermsModal from '../../modals/termsModal';
import PrivacyModal from '../../modals/privacyModal';
import SuccessModal from '../../modals/successModal';
import utilService from "../../services/util-service";
import Axios from 'axios';
import RecertifyModal from '../../modals/recertify';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import factoryService from '../../services/factory-service';

let arayMonth = [];
let arayPopulateMonth = [];
let yearArray = [];
let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
let lsEditValue = JSON.parse(localStorage.getItem('ConfigUserDet'));
let arrMonthYearSplit = [];
let afterRegisterUrl = ""

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   email: null
    // };
    utilService.setBaseURL();
    let localStorageValue = JSON.parse(localStorage.getItem('ConfigMasterDetails'));
    if(!localStorageValue){
      window.location = "/a2mt/email-auth";
    }
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    for (let year = currentYear; year < currentYear + 10; year++) {
      yearArray.push(year);
    }

    arayMonth = [
      {
        name: 'Jan',
        index: '0'
      },
      {
        name: 'Feb',
        index: '1'
      },
      {
        name: 'Mar',
        index: '2'
      },
      {
        name: 'Apr',
        index: '3'
      },
      {
        name: 'May',
        index: '4'
      },
      {
        name: 'Jun',
        index: '5'
      },
      {
        name: 'Jul',
        index: '6'
      },
      {
        name: 'Aug',
        index: '7'
      },
      {
        name: 'Sep',
        index: '8'
      },
      {
        name: 'Oct',
        index: '9'
      },
      {
        name: 'Nov',
        index: '10'
      },
      {
        name: 'Dec',
        index: '11'
      },
    ];
    arayPopulateMonth = arayMonth;

    this.state = {
      baseURL: utilService.hostname,
      showLoader: false,
      showAlert: false,
      showRegisterAlert: false,
      portalName: localStorage.getItem('ConfigPortalName'),
      afterRegisterUrl: null,
      alertMsg: 'Test',
      alertType: 'Success',
      termsModalShow: false,
      privacyModalShow: false,
      successModalShow: false,
      recertifyModalShow: false,
      labelTerms: <span>I agree to the  <Link href="#" onClick={this.termsModalShowClick}>Software Downloads terms of service</Link></span>,
      labelPrivacy: <span>I agree to the  <Link href="#" onClick={this.privacyModalShowClick}>privacy consent</Link></span>,
      loader: false,
      checkTerms: false,
      checkPrivacy: false,
      termsofservice: null,
      privacyconsent: null,
      invalidfirstName: false,
      invalidtermsofservice: false,
      invalidprivacyconsent: false,
      selectRole: false,
      datFaq: [],
      dataRole: [],
      dataDegree: localStorageValue.CurrentDegree,
      dataDepartment: localStorageValue.UniversityDepartment,
      dataSoughtDegree: localStorageValue.SoughtDegree,
      dataYear: yearArray,
      dataMonth: arayMonth,
      role: null,
      student_faculty: null,
      degree: null,
      sought_degree: null,
      graduation_date: null,
      graduation_month: null,
      department: null,
      badgeProgramEnrolled: true,
      invalidrole: false,
      invalidstudent_faculty: false,
      invaliddegree: false,
      invalidsought_degree: false,
      invalidgraduation_date: false,
      invalidgraduation_month: false,
      invaliddepartment: false,
      universityEmail: localStorageValue.UniversityEmail,
      universityName: localStorageValue.UniversityName,
      lableBadge: <span>Enroll me in the IBM badge program. <Link href="https://ibm.com/badging" target="_blank" >What is this?</Link></span>,
      btnDisabled: false,
      userTitle: "",
      accessTokn: factoryService.Xaccess,//process.env.REACT_APP_X_ACCESS
    };
  }

  onChangeHandlerRadio = (event) => {
    //console.log("event----->", event);

    this.setState({ badgeProgramEnrolled: event });
  }
  onChangeHandlerSelect = (event) => {
    const { options, selectedIndex } = event.target;

    //console.log(options[selectedIndex].innerHTML);
    if (event.target.name == "role") {
      this.setState({ role: options[selectedIndex].innerHTML });
      this.setState({ student_faculty: event.target.value });
      this.setState({ invalidrole: false });

      if (options[selectedIndex].innerHTML == "Student") {
        this.setState({ selectRole: true });

      }
      else {
        this.setState({ selectRole: false });
      }
    }
    else if (event.target.name == "graduation_month") {
      this.setState({ graduation_month: event.target.value });
      this.setState({ invalidgraduation_month: false });
    }
  }


  onChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    let namconcat = "invalid" + nam;
    this.setState({ [namconcat]: false });
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();
    if (nam == "graduation_date") {
      if (currentYear == event.target.value) {
        arayMonth = [];
        let count = 0;
        for (let i = currentMonth; i < 12; i++) {
          let obj = {
            name: months[i],
            index: i,
            countIndex: count
          }
          arayMonth.push(obj);
          count++;
        }
        this.setState({ dataMonth: arayMonth });
        let e = document.getElementById("select-graduation-month");
        let selectedMonth = e.options[e.selectedIndex].text;
        let obj = arayMonth.find(o => o.name === selectedMonth);
        if (obj != undefined) {
          document.getElementById('select-graduation-month').value = obj.countIndex;
        }
        else {
          document.getElementById('select-graduation-month').value = "placeholder-item";
        }
      }
      else {
        arayMonth = [];
        for (let i = 0; i < 12; i++) {
          let obj = {
            name: months[i],
            index: i
          }
          arayMonth.push(obj);
        }
        this.setState({ dataMonth: arayMonth });
        let e = document.getElementById("select-graduation-month");
        let selectedMonth = e.options[e.selectedIndex].text;
        if (selectedMonth != 'Choose graduation month') {
          let obj = arayMonth.find(o => o.name === selectedMonth);
          if (obj != undefined) {
            document.getElementById('select-graduation-month').value = obj.index;
          }
        }
        else {
          document.getElementById('select-graduation-month').value = "placeholder-item";
        }
      }
    }

  }

  handleClick = (event) => {
    event.preventDefault();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let valid = true;

    if (document.getElementById('select-role').value == "placeholder-item") {
      this.setState({ invalidrole: true });
      valid = false;
    }

    if (document.getElementById('select-degree').value == "placeholder-item") {
      this.setState({ invaliddegree: true });
      valid = false;
    }
    if (this.state.role == "Student") {
      if (document.getElementById('select-soughtdegree').value == "placeholder-item") {
        this.setState({ invalidsought_degree: true });
        valid = false;
      }
      if (document.getElementById('select-graduation-date').value == "placeholder-item") {
        this.setState({ invalidgraduation_date: true });
        valid = false;
      }
      if (document.getElementById('select-graduation-month').value == "placeholder-item") {
        this.setState({ invalidgraduation_month: true });
        valid = false;
      }
    }
    if (document.getElementById('select-department').value == "placeholder-item") {
      this.setState({ invaliddepartment: true });
      valid = false;
    }

    if (!this.state.checkTerms) {
      this.setState({ invalidtermsofservice: true });
      valid = false;
    }
    if (!this.state.checkPrivacy) {
      this.setState({ invalidprivacyconsent: true });
      valid = false;
    }

    if (valid) {
      this.setState({ recertifyModalShow: true });
      this.setState({ btnDisabled: true });
    }

  }
  modalCloseSuccessRecertify = event => {
    event.preventDefault();
    this.setState({ successRecertifyModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  successRecertifyModalClick = event => {
    event.preventDefault();
    this.setState({ successRecertifyModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  modalCloseRecertify = event => {
    event.preventDefault();
    this.setState({ recertifyModalShow: false });
    this.setState({ btnDisabled: false });
  };

  recertifyModalClick = event => {
    event.preventDefault();
    this.setState({ recertifyModalShow: false });
    this.recertifyDataSave();
    this.setState({ btnDisabled: false });
  };

  recertifyDataSave() {
    let inputData = {}
    let saveData = {
      universityEmail: this.state.universityEmail,
      universityName: this.state.universityName,
      // graduationDate: "2022-3",
      currentDegree: document.getElementById('select-degree').value,
      department: document.getElementById('select-department').value,
      badgeProgramEnrolled: this.state.badgeProgramEnrolled,
      student_Faculty: parseInt(this.state.student_faculty),
      termsAndCondition: this.state.checkTerms,
      privacyConsent: this.state.checkPrivacy,
      id: 0,
      regWhiteListRedID: localStorage.getItem('ConfigInst_id'),
      insttnID: localStorage.getItem('ConfigInst_id')
    };
    if (this.state.role == "Student") {
      let graduation_date = document.getElementById('select-graduation-date').value;
      let graduation_month = (parseInt(document.getElementById('select-graduation-month').value) + 1).toString();
      saveData["graduationDate"] = graduation_date + "-" + graduation_month;
      saveData["soughtDegree"] = document.getElementById('select-soughtdegree').value;
      inputData = { "userData": saveData, "title": "" };
    }
    else {
      saveData["graduationDate"] = "";
      saveData["soughtDegree"] = "";
      inputData = { "userData": saveData, "title": this.state.userTitle }
    }
    console.log("postData", saveData);
    //return false;
    this.setState({ showLoader: true });
    Axios.post(this.state.baseURL + '/a2mt/services/user-details/registerUser', inputData, {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(rs => {
      console.log(rs.data); this.setState({ showLoader: false });
      if (rs.data.success === true) {
        afterRegisterUrl = "";
        afterRegisterUrl = rs.data.redirectURL;
        this.setState({ showRegisterAlert: true });
        setTimeout(function () {
          window.location = afterRegisterUrl;
        }, 6000);
      }
      else { this.setState({ alertMsg: 'Save failed. Please try again later.', alertType: 'Warning', showAlert: true }); }
    })
      .catch((error) => {
        this.setState({ showLoader: false });
        this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
      });
  }

  redirectAfterRegister = (event) => {
    this.setState({ showRegisterAlert: false });
    window.location = afterRegisterUrl;
  }

  modalCloseSuccess = event => {
    event.preventDefault();
    this.setState({ successModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  successModalClick = event => {
    event.preventDefault();
    this.setState({ successModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  checkTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: true });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: false })
  };
  uncheckTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: false });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: true })
  };


  termsModalShowClick = event => {
    event.preventDefault();
    this.setState({ termsModalShow: true })
  }
  modalCloseTerms = event => {
    event.preventDefault();
    this.setState({ termsModalShow: false })
  };
  privacyModalShowClick = event => {
    event.preventDefault();
    this.setState({ privacyModalShow: true })
  }
  modalClosePrivacy = event => {
    event.preventDefault();
    this.setState({ privacyModalShow: false })
  };

  checkPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: true });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: false })
  };
  uncheckPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: false });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: true });
  };

  componentDidMount() {
    this.getUserRole();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  getFAQ() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getFAQ', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(res => {
      this.setState({ showLoader: false });
      console.log(res.data);
      if (res.data != null) {
        this.setState({ dataFaq: res.data.faq });
      } else {
        this.setState({ dataFaq: [] })
      }
    }).catch((error) => {
      this.setState({ showLoader: false });
      this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
    });
  }

  getUserRole() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getUserRole', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(res => {
      this.setState({ showLoader: false }); console.log(res.data);
      this.getFAQ();
      if (res.data != null) {
        this.setState({ dataRole: res.data });
      } else {
        this.setState({ dataRole: [] })
      }
    }).catch((error) => {
      this.setState({ showLoader: false });
      this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
    });
  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    return (
      <Section>
        <NormalHeader></NormalHeader>
        
        <Theme theme="g10">
        <Grid className="register-page" >
          <Column lg={{ span: 9, offset: 3 }} md={8} sm={4}>
                <div className="textContentforInvalidRegis">
                  <p className="defaultPara customText">Find answers in our <a href={this.state.dataFaq} target="_blank" className="bx--link" rel="noopener noreferrer">frequently asked questions</a></p>
                  <p className="defaultPara customHeader">Register below</p><br></br>
                  <p className="defaultPara customText">Complete the information below to register. In addition to the forms below your will need to register for an IBM ID to enroll in the program.</p>
                  <br></br> <br></br>
                  <p className="defaultPara customText">Academic institution issued email: <Link href="#" onClick={this.handleClick}>{this.state.universityEmail}</Link></p>
                  <br></br> <br></br>
                  <p className="defaultPara customText">Academic institution name: {this.state.universityName}</p>
                  <br></br> <br></br>
                  <form onSubmit={this.handleSubmit} noValidate>
                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        defaultValue="placeholder-item"
                        id="select-role"
                        invalidText="Please select a Role"
                        name='role'
                        invalid={this.state.invalidrole}
                        labelText="Role"
                        onChange={this.onChangeHandlerSelect}
                      >
                        <SelectItem
                          disabled
                          hidden
                          value="placeholder-item"
                          text="Choose role"
                        />
                        {this.state.dataRole.map((value) => <SelectItem text={value.roleName} value={value.id}>{value.roleName}</SelectItem>)}
                      </Select>
                    </div>
                    {!this.state.selectRole && (
                      <div style={{ marginBottom: '2rem' }}>
                        <TextInput
                          type="text"
                          name="userTitle"
                          labelText="Title"
                          aria-label="Title"
                          value={this.state.userTitle}
                          onChange={this.onChangeInput}
                          required
                        />
                      </div>
                    )}
                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        defaultValue="placeholder-item"
                        id="select-degree"
                        invalidText="Please select a Degree"
                        name='degree'
                        invalid={this.state.invaliddegree}
                        labelText="Current Degree"
                        onChange={this.onChangeHandler}
                      >

                        <SelectItem
                          value="placeholder-item"
                          text="Choose degree"
                        />
                        {this.state.dataDegree.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}

                      </Select>
                    </div>
                    {this.state.selectRole && (
                      <div>
                        <div style={{ marginBottom: '2rem' }}>
                          <Select
                            defaultValue="placeholder-item"
                            id="select-soughtdegree"
                            invalidText="Please select a Sought Degree"
                            name='sought_degree'
                            invalid={this.state.invalidsought_degree}
                            labelText="Sought Degree"
                            onChange={this.onChangeHandler}
                          >

                            <SelectItem
                              value="placeholder-item"
                              text="Choose sought degree"
                            />
                            {this.state.dataSoughtDegree.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}
                          </Select>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <div className="bx--grid--full-width">
                            <div className="bx--row">
                              <div className="bx--col" style={{ marginBottom: '2rem' }}>
                                <Select
                                  defaultValue="placeholder-item"
                                  id="select-graduation-date"
                                  invalidText="Please select a Graduation Date"
                                  name='graduation_date'
                                  invalid={this.state.invalidgraduation_date}
                                  labelText="Expected graduation Date"
                                  onChange={this.onChangeHandler}
                                >

                                  <SelectItem
                                    value="placeholder-item"
                                    text="Choose graduation date"
                                  />
                                  {this.state.dataYear.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}
                                </Select>
                              </div>
                              <div className="bx--col">
                                <Select
                                  defaultValue="placeholder-item"
                                  id="select-graduation-month"
                                  invalidText="Please select a Month"
                                  name='graduation_month'
                                  invalid={this.state.invalidgraduation_month}
                                  labelText="Month"
                                  onChange={this.onChangeHandlerSelect}
                                >

                                  <SelectItem
                                    value="placeholder-item"
                                    text="Choose graduation month"
                                  />
                                  {this.state.dataMonth.map((value) => <SelectItem text={value.name} value={value.index}>{value.name}</SelectItem>)}
                                </Select>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    )}


                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        defaultValue="placeholder-item"
                        id="select-department"
                        invalidText="Please select a Department"
                        name='department'
                        invalid={this.state.invaliddepartment}
                        labelText="Department"
                        onChange={this.onChangeHandler}
                      >

                        <SelectItem
                          value="placeholder-item"
                          text="Choose department"
                        />
                        {this.state.dataDepartment.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}
                      </Select>
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <FormGroup
                        legendText={this.state.lableBadge}
                      >
                        <RadioButtonGroup orientation='vertical'
                          defaultSelected="default-selected"
                          legend="Group Legend"
                          role="radiogroup"
                          aria-label="Enrollment in IBM badge program"
                          name="badgeProgramEnrolled"
                          valueSelected="true"
                          onChange={this.onChangeHandlerRadio}
                        >
                          <RadioButton
                            id="radio-1"
                            labelText="Yes"
                            value="true"
                          />
                          <RadioButton
                            id="radio-2"
                            labelText="No"
                            value="false"
                          />

                        </RadioButtonGroup>
                      </FormGroup>
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <fieldset className="bx--fieldset">
                        <legend className="bx--label">Please click on the links below to read and accept the program agreement and privacy statement</legend>
                        <br/>
                        <Checkbox labelText={this.state.labelTerms} id="checked-2" disabled checked={this.state.checkTerms} />{this.state.termsModalShow && (
                          <div>
                            <TermsModal hidden={this.modalCloseTerms} check={this.checkTermsModalClick} uncheck={this.uncheckTermsModalClick} />
                          </div>
                        )}

                        <Checkbox labelText={this.state.labelPrivacy} id="checked-3" disabled checked={this.state.checkPrivacy} />{this.state.privacyModalShow && (
                          <div>
                            <PrivacyModal hidden={this.modalClosePrivacy} check={this.checkPrivacyModalClick} uncheck={this.uncheckPrivacyModalClick} />
                          </div>
                        )}
                        {(this.state.invalidtermsofservice ||
                          this.state.invalidprivacyconsent) &&
                          <span className='error' style={{ color: '#da1e28' }}>Please agree on Terms of Service and Privacy Consent</span>}

                      </fieldset>
                      {this.state.successModalShow && (
                        <div>
                          <SuccessModal hidden={this.modalCloseSuccess} successok={this.successModalClick} />
                        </div>
                      )}
                      {this.state.recertifyModalShow && (
                        <div>
                          <RecertifyModal hidden={this.modalCloseRecertify} successok={this.recertifyModalClick}
                            recertify={this.recertifyModalClick} unrecertify={this.unrecertifyModalClick} />
                        </div>
                      )}

                      {this.state.loader && (
                        <div>
                          <Loading
                            description="Active loading indicator" withOverlay={true} active={true}
                          />
                        </div>
                      )}
                       <br/>
                      <Button
                        kind="primary"
                        tabIndex={0}
                        type="submit"
                        disabled={this.state.btnDisabled}
                      >
                        Register
                      </Button>

                    </div>
                  </form>
                </div>
              </Column>
            </Grid>

            <Loading withOverlay={true} description="Active loading indicator" active={this.state.showLoader}></Loading>
            <Modal passiveModal={true} preventCloseOnClickOutside={false} open={this.state.showAlert} size="sm" onRequestClose={() => { this.setState({ showAlert: false }) }}>
              <div style={{ padding: "1em 0" }}>
                {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
                {this.state.alertType == 'Warning' && (<div><WarningAlt style={{ width: "7em", height: "7em", color: "orange" }}></WarningAlt></div>)}
                {this.state.alertType == 'Error' && (<div><MisuseOutline style={{ width: "7em", height: "7em", color: "red" }}></MisuseOutline></div>)}
                <div style={{ paddingLeft: "1em" }}>{this.state.alertMsg}</div>
              </div>
            </Modal>
            <Modal passiveModal={true} preventCloseOnClickOutside={false} open={this.state.showRegisterAlert} size="md" onRequestClose={this.redirectAfterRegister}>
              <div style={{ padding: "1em 0" }}>
                <div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>
                <div style={{ paddingLeft: "1em" }}><p className="defaultPara customHeader">Thank you for registering.</p><br></br>
                  <p className="defaultPara customText">You are now being redirected to create an IBM ID. The IBM ID will be used to sign in to the {this.state.portalName}</p>
                  <br></br>
                  <p className="defaultPara customText">If you are not redirected in 15 seconds
                    <Link href="#" style={{ marginLeft: "0.5rem" }} onClick={this.redirectAfterRegister}>click here.</Link></p>
                </div>
              </div>
            </Modal>
          </Theme>
       
      </Section>
    )
  }
}

