import React from 'react';
import {
  Theme,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavLink,
  SideNavItems,
  Loading,
} from '@carbon/react';
import { Search, Help, UserAvatar } from '@carbon/react/icons';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import utilService from '../../../services/util-service';

const SbsdHeader = cardData => {
  const [path, setPath] = useState('');
  const [showLoader, setLoader] = useState(false);
  useEffect(() => {
    setPath(window.location.pathname);
  }, []);

  const handleRedirect = (event, data) => {
    utilService.setOtherPageAccessible(true);
    window.location =
      '/a2mt/admin-dashboard/' +
      data
        .split(' ')
        .join('-')
        .toLowerCase();
  };
  const redirectToLanding = event => {
    utilService.setOtherPageAccessible(true);
    window.location = '/a2mt/admin-dashboard';
  };

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Theme theme="g100">
          <Header aria-label="IBM SkillsBuild Software Downloads">
            <SkipToContent />
            <HeaderMenuButton
              aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'}
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
              aria-expanded={isSideNavExpanded}
            />
            <HeaderName as={Link} to="/a2mt/downloads" prefix="IBM">
              SkillsBuild Software Downloads
            </HeaderName>
            <HeaderGlobalBar>
              <HeaderGlobalAction aria-label="Search">
                <Search size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction aria-label="Help">
                <Help size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="User Profile"
                tooltipAlignment="end">
                <UserAvatar size={20} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>

            <Theme theme="white">
              <SideNav
                aria-label="Side navigation"
                expanded={isSideNavExpanded}
                onSideNavBlur={onClickSideNavExpand}
                href="#main-content">
                <SideNavItems>
                  <SideNavLink
                    as={Link}
                    onClick={e => redirectToLanding(e)}
                    isActive={path === '/a2mt/admin-dashboard' ? true : false}>
                    <label style={{ fontSize: 16 + 'px' }}>Admin Home</label>
                  </SideNavLink>
                  {cardData.cardData !== null &&
                    cardData.cardData.map(v => {
                      return (
                        <SideNavLink
                          as={Link}
                          onClick={e => handleRedirect(e, v.LNAME)}
                          isActive={
                            path ===
                            '/a2mt/admin-dashboard/' +
                              v.LNAME.split(' ')
                                .join('-')
                                .toLowerCase()
                              ? true
                              : false
                          }>
                          {v.NAME}
                        </SideNavLink>
                      );
                    })}
                </SideNavItems>
              </SideNav>
            </Theme>
          </Header>
          <Loading
            withOverlay={true}
            description="Active loading indicator"
            active={showLoader}
          />
        </Theme>
      )}
    />
  );
};

export default SbsdHeader;
