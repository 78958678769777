import React from 'react';
import {
  Button, DataTable, Table, TableHead, TableRow, TableHeader, TableBody, TableCell, Grid, Column,
  Loading, TableContainer, TableSelectRow, TableToolbar, TableToolbarContent, TableToolbarSearch,
  Modal, TextArea, TextInput, Select, SelectItem, ComposedModal, ModalFooter, ModalBody, ModalHeader, 
  Pagination, Tabs, TabList, Tab, TabPanels, TabPanel
} from '@carbon/react';
import { Warning_02, DoNot } from '@carbon/pictograms-react';
import { CheckmarkOutline } from "@carbon/react/icons";
import Axios from 'axios';
import './manage-workshop.scss';
import utilService from '../../../services/util-service';

export default class ManageWorkshop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: utilService.hostname,
      activeTab: "Pending",
      Rejected: 0,
      Cancelled: 0,
      Approved: 0,
      Pending: 0,
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      isEditOverlayVisible: false,
      isViewOverlayVisible: false,
      editDataset: {},
      resendDisabled: false,
      statusUpdated: false,
      page: 1,
      pageSize: 10,
      rowData: [],
      participationStatus: ['Rejected', 'Pending', "Cancelled", "Approved"],
      dplStatusValues: ['Pass', 'Fail', 'Passed by Admin'],
      headerData: [
        // { header: 'Id', key: 'id' },
        { header: 'Workshop Id', key: 'workshopId' },
        { header: 'Institution Name', key: 'institutionName' },
        { header: 'DPL Status', key: 'dplStatus' },
        { header: 'DPL Denial Code', key: 'dplCode' },
        { header: 'Email', key: 'userEmail' },
        { header: 'First Name', key: 'firstName' },
        { header: 'Last Name', key: 'lastName' },
        { header: 'Registraion Date', key: 'registeredDate' }
      ],
      submitDisabled: false,
      selectedRowData: null,
      invalidDplType: false,
      invalidEmailDomain: false,
      invalidInstitution: false,
      accessTokn: process.env.REACT_APP_X_ACCESS,
      userTitle: '',
    }
  }

  componentDidMount() {
    this.initGrid();
  }

  handleSelect = (label) => {
    // this is how you update your state
    this.setState({
      activeTab: label
    });
    this.setState({
      page: 1,
      pageSize: 10,
    });
    this.initGrid();
  }

  initGrid() {
    this.setState({
      showLoader: true
    });
    utilService.getData('/a2mt/services/user-details/getUserWorkshop').then(res => {
      this.setState({
        showLoader: false
      });
      console.log("res.data=", res.data);
      const dataArray = res.data; // dynamic

      if (dataArray != null) {
        let finalDataArray = [];
        dataArray.map((data, i) => {
          dataArray[i].registeredDate = new Date(data.registeredDate).toISOString().split('T')[0];
          let tab = this.state.activeTab.search(dataArray[i].participationStatus);
          if (tab != -1) {
            finalDataArray.push(dataArray[i]);
          }
        });
        this.setState({
          rowData: finalDataArray
        });
        this.setState({
          selectedRowData: null,
        });
        this.setState({
          page: 1,
          pageSize: 10,
        });
        this.getWorkshopcount();
      } else {
        this.setState({
          rowData: []
        })
      }
    }).catch((error) => {
      // this.setState({ showLoader:false }); this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType:'Error', showAlert:true });
    });
  }

  initDPLstatus() {
    this.setState({
      showLoader: true
    });
    utilService.getData('/a2mt/services/user-details/getDplStatus').then(res => {
      this.setState({
        showLoader: false
      });
      if (res.data != null && res.data.length > 0) {
        let tempData = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].isEnable == true) {
            tempData.push(res.data[i].statusName);
          }
        }
        this.setState({
          dplStatusValues: tempData
        });
        console.log(tempData);
      }
    }).catch((error) => {
      this.setState({
        showLoader: false
      });
      this.setState({
        alertMsg: 'Something went wrong. Please try again.',
        alertType: 'Error',
        showAlert: true
      });
    });
  }

  getSelectedRow = (event) => {
    console.log('event >>>>>>', event);
    let sel = this.state.selectedRowData;
    console.log('Selected row data', sel);
    if (sel !== null) {
      let record = this.state.rowData.filter((e) => e.id === sel.id);
      console.log("record[0]", record[0])
      this.getUserTitle(record[0]);
      if (record.length > 0) {
        this.setState({
          editDataset: record[0]
        });
        this.setState({
          isViewOverlayVisible: true
        });
      } else {
        this.setState({
          editDataset: {}
        })
      }
    } else {
      this.setState({
        alertMsg: 'Please select a row to review!',
        alertType: 'Warning',
        showAlert: true
      });
    }
  }

  onChangeInput = (event) => {
    this.setState({
      editDataset: Object.assign({},
        this.state.editDataset, {
        [event.target.name]: event.target.value
      }
      )
    });
    setTimeout(() => {
      console.log(this.state.editDataset)
    }, 1000);
  }

  onSelectTypeChange = (event) => {
    const {
      options,
      selectedIndex
    } = event.target;
    if (event.target.name == 'dplStatus') {
      console.log("prevState===", this.state.prevState);
      console.log("editDataset===", this.state.editDataset);
      this.setState(prevState => ({
        editDataset: {
          ...prevState.editDataset,
          dplStatus: options[selectedIndex].innerHTML
        }
      }));
      this.setState({
        invalidDplType: false
      });
    }

    if (event.target.name == 'participationStatus') {
      console.log("prevState >>>> ", this.prevState);
      this.setState(prevState => ({
        editDataset: {
          ...prevState.editDataset,
          participationStatus: options[selectedIndex].innerHTML
        }
      }));
      this.setState({
        invalidDplType: false
      });
    }

    setTimeout(() => {
      console.log(this.state.editDataset)
    },
      1000);
  }



  handlePagination(event) {
    const {
      page,
      pageSize
    } = event;
    if (page && pageSize) {
      this.setState({
        page,
        pageSize
      });
    }
  }

  onSubmit = (event) => {
    console.log('form valid', this.validateForm());
    if (this.validateForm()) {
      this.submitStatusData();
    } else {
      return false;
    }
  }

  capacityCheck(Id, formData) {
    utilService.getData('/a2mt/services/user-details/getcountStatus/' + Id).then(res => {
      if (Number(res.data.Capacity) <= Number(res.data.Approved)) {
        this.closeOverlay();
        this.setState({
          alertMsg: 'You have approved more participants than capacity!',
          alertType: 'Warning',
          showAlert: true
        });
      }
      setTimeout(() => {
      }, 10000, this.updateStatus(formData));
    })
  }

  submitStatusData() {
    this.setState({
      showLoader: true,
      submitDisabled: true
    });
    let formData = {
      "id": Number(this.state.editDataset.id),
      "dplStatus": this.state.editDataset.dplStatus,
      "dplCode": this.state.editDataset.dplCode,
      "participationStatus": this.state.editDataset.participationStatus,
      "reason": this.state.editDataset.reason
    }
    console.log('formData', formData);
    if (this.state.editDataset.participationStatus === "Approved") {
      this.capacityCheck(this.state.editDataset.workshopId, formData);
    }
    else {
      this.updateStatus(formData);
    }
  }

  updateStatus(formData) {
    //   if(this.state.showAlert === false && this.state.statusUpdated === false){
    // this.state.statusUpdated = true;
    utilService.postData('/a2mt/services/user-details/updateUserWorkshop', formData)
      .then(res => {
        this.setState({
          showLoader: false
        });
        // console.log("res,res===",res)
        if (typeof (res.data) !== 'undefined' && res.data !== null) {

          if (res.status == 200) {
            this.setState({
              alertMsg: 'Updates saved successfully.',
              alertType: 'Success',
              showAlert: true
            });
          } else {
            this.setState({
              alertMsg: 'Save updates failed. Please try again later.',
              alertType: 'Warning',
              showAlert: true
            });
          }
          this.closeOverlay();
          this.getWorkshopcount();
          this.initGrid();
          this.setState({
            submitDisabled: false,
          });

        }
      })
      .catch((error) => {
        this.setState({
          showLoader: false
        });
        this.closeOverlay();
        // this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType:'Error', showAlert:true });
      });
    //   }
  }

  closeOverlay = (event) => {
    console.log("editDataset==", this.state.editDataset)
    this.setState({
      isEditOverlayVisible: false,
      isViewOverlayVisible: false,
    });
    this.setState({
      editDataset: [{
        ...this.state.editDataset,
        dplCode: "",
        reason: ""
      }]
    })
    // this.initGrid();
    // this.initDPLstatus();
  }

  closeAlert = (event) => {
    this.setState({
      showAlert: false
    })
  }

  validateForm() {
    if (!this.state.invalidDplType) {
      return true;
    } else {
      return false;
    }
  }

  getWorkshopcount() {
    this.setState({
      showLoader: true
    });
    utilService.getData('/a2mt/services/user-details/getcountStatus').then(res => {
      this.setState({
        showLoader: false
      });
      this.setState({
        Pending: res.data.Pending
      });
      this.setState({
        Approved: res.data.Approved
      });
      this.setState({
        Cancelled: res.data.Cancelled
      });
      this.setState({
        Rejected: res.data.Rejected
      });
    }).catch((error) => {
      // this.setState({ showLoader:false }); this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType:'Error', showAlert:true }); 
    });
  }

  getUserTitle(selRecord) {
    let usrTitle = '';
    // console.log("selRecord", selRecord)
    let data = { "userId": selRecord.userId, "emailId": selRecord.userEmail };
    // let data = {"userId":6555,"emailId":"a2mtfaculty10@mailinator.com"}
    Axios.post(this.state.baseURL + '/a2mt/services/user-details/getUserTitle', data, {
      headers: { "X-access": this.state.accessTokn }
    }).then(res => {
      if (res.data != null) {
        usrTitle = res.data.title;
        this.setState({ userTitle: usrTitle });
      } else {
        usrTitle = '';
        this.setState({ userTitle: usrTitle });
      }
    })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({ customMsg: 'Something went wrong. Please try again.', alertType: 'Error', showModal: true });
      });
  }

  render() {
    const { page, pageSize } = this.state;
    console.log(this.state.rowData.length + "render=" + pageSize);
    // console.log("this.state=",this.state);
    //  this.setState({
    //    page: 1,
    //    pageSize: 10,
    //  });
    return (
      <Grid>
        <Column lg={{ span: 12, offset: 4 }} md={8} sm={4} style={{ padding: "3em 0" }}>
          <p>
            <div style={{ paddingBottom: '32px' }}>
              <h3>Manage Workshop Registrations</h3>
            </div>
          </p>
          <header className="header">
            <Tabs className="tabs" style={{ marginLeft: "2rem" }}>
              <TabList>
                {[
                  "Pending" + " (" + this.state.Pending + ")",
                  "Approved" + " (" + this.state.Approved + ")",
                  "Rejected" + " (" + this.state.Rejected + ")",
                  "Cancelled" + " (" + this.state.Cancelled + ")",
                ].map((label, index) => (
                  <Tab
                    key={label}
                    onClick={() => this.handleSelect(label)}
                  >{label}</Tab>
                ))}
              </TabList>
              <TabPanels>
                {[
                  "Pending" + " (" + this.state.Pending + ")",
                  "Approved" + " (" + this.state.Approved + ")",
                  "Rejected" + " (" + this.state.Rejected + ")",
                  "Cancelled" + " (" + this.state.Cancelled + ")",
                ].map((label, index) => (
                  <TabPanel
                    label={label}
                    key={label + index}
                  >
                    <div>
                      <section>
                        <DataTable
                          rows={this.state.rowData}
                          headers={this.state.headerData}
                          radio
                          isSortable
                        >
                          {({
                            rows,
                            headers,
                            getHeaderProps,
                            getRowProps,
                            getSelectionProps,
                            getTableProps,
                            getTableContainerProps,
                            onInputChange,
                          }) => (
                            <TableContainer {...getTableContainerProps()}>
                              <TableToolbar>
                                <TableToolbarContent>
                                  <TableToolbarSearch
                                    onChange={onInputChange}
                                  />
                                  <Button onClick={this.getSelectedRow}>
                                    Review
                                  </Button>
                                </TableToolbarContent>
                              </TableToolbar>
                              <Table {...getTableProps()}>
                                <TableHead>
                                  <TableRow>
                                    <th scope="col" />
                                    {headers.map((header, i) => (
                                      <TableHeader
                                        key={i}
                                        {...getHeaderProps({ header })}
                                      >
                                        {header.header}
                                      </TableHeader>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows
                                    .splice((page - 1) * pageSize, pageSize)
                                    .map((row, i) => (
                                      <TableRow
                                        key={i}
                                        {...getRowProps({ row })}
                                      >
                                        <TableSelectRow
                                          {...getSelectionProps({
                                            row,
                                            onClick: () =>
                                              this.setState({
                                                selectedRowData: row,
                                              }),
                                          })}
                                        />
                                        {row.cells.map((cell) => (
                                          <TableCell key={cell.id}>
                                            {cell.value}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </DataTable>
                        <Pagination
                          page={page}
                          backwardText="Previous page"
                          disabled={false}
                          forwardText="Next page"
                          isLastPage={false}
                          itemsPerPageText="Items per page:"
                          onChange={(e) => this.handlePagination(e)}
                          pageNumberText="Page Number"
                          pageSizes={[10, 15, 20, 25]}
                          pagesUnknown={false}
                          totalItems={this.state.rowData.length}
                        />
                      </section>
                      <Loading
                        withOverlay={true}
                        description="Active loading indicator"
                        active={this.state.showLoader}
                      ></Loading>
                      <Modal
                        passiveModal={true}
                        preventCloseOnClickOutside={false}
                        open={this.state.showAlert}
                        size="sm"
                        onRequestClose={this.closeAlert}
                      >
                        <div style={{ padding: "1em 0" }}>
                          {this.state.alertType == "Success" && (
                            <div>
                              <CheckmarkOutline
                                style={{
                                  width: "7em",
                                  height: "7em",
                                  color: "green",
                                }}
                              ></CheckmarkOutline>
                            </div>
                          )}
                          {this.state.alertType == "Warning" && (
                            <div>
                              <Warning_02
                                style={{
                                  width: "7em",
                                  height: "7em",
                                  color: "orange",
                                }}
                              ></Warning_02>
                            </div>
                          )}
                          {this.state.alertType == "Error" && (
                            <div>
                              <DoNot
                                style={{
                                  width: "7em",
                                  height: "7em",
                                  color: "red",
                                }}
                              ></DoNot>
                            </div>
                          )}
                          <div style={{ paddingLeft: "1em" }}>
                            {this.state.alertMsg}
                          </div>
                        </div>
                      </Modal>
                      <ComposedModal
                        size="lg"
                        selectorPrimaryFocus="#text-input-1"
                        hasform
                        open={this.state.isViewOverlayVisible}
                        hasScrollingContent
                        onRequestClose={this.props.hidden}
                        icondescription="Close the modal"
                        onClose={this.closeOverlay}
                      >
                        <ModalHeader>
                          <h3 className="bx--modal-header__heading">
                            Review Workshop Request
                          </h3>
                        </ModalHeader>
                        <ModalBody className="removePadding">
                          <div className="bx--grid">
                            <div
                              className="bx--row"
                              style={{ paddingBottom: "1em" }}
                            >
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                              >
                                <span>ID</span>
                              </div>
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                {this.state.editDataset.workshopId}
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{ padding: "1em 0" }}
                            >
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                              >
                                <span>Institution Name</span>
                              </div>
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                {this.state.editDataset.institutionName}
                              </div>
                            </div>
                            {(this.state.editDataset.participationStatus == "Approved" || this.state.editDataset.participationStatus == "Rejected") && (
                              <div
                                className="bx--row"
                                style={{
                                  paddingBottom: "1em",
                                }}
                              >
                                <div
                                  className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                  style={{ padding: "1em 0" }}
                                >
                                  {" "}
                                  <span>Institution Country</span>
                                </div>

                                <div
                                  className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                  style={{ margin: "15px 0" }}
                                >
                                  {this.state.editDataset.country}
                                </div>
                                <div
                                  className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                  style={{ padding: "1em 0 0 0" }}
                                >
                                  {" "}
                                  <span>Title</span>
                                </div>

                                <div
                                  className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                  style={{ margin: "15px 0" }}
                                >
                                  {this.state.userTitle}
                                </div>
                              </div>
                            )}
                            <div
                              className="bx--row"
                              style={{ paddingBottom: "1em" }}
                            >
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ padding: "1em 0 0 0" }}
                              >
                                <span>DPL Status</span>{" "}
                              </div>
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                <Select
                                  id="select-option-1"
                                  defaultValue="placeholder-item"
                                  noLabel
                                  invalidText="Please choose an option"
                                  name="dplStatus"
                                  invalid={this.state.invalidDplType}
                                  onChange={this.onSelectTypeChange}
                                  value={this.state.editDataset.dplStatus}
                                >
                                  <SelectItem
                                    disabled
                                    text="Choose an option"
                                  />
                                  {this.state.dplStatusValues.map((val) => (
                                    <SelectItem value={val} text={val} />
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{ padding: "1em 0 1em 0" }}
                            >
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                <span>DPL Denial Code</span>{" "}
                              </div>
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                <TextInput
                                  type="text"
                                  name="dplCode"
                                  value={this.state.editDataset.dplCode}
                                  onChange={this.onChangeInput}
                                  required
                                />
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{ padding: "1em 0 1em 0" }}
                            >
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                <span>Participation Status</span>{" "}
                              </div>
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                <Select
                                  id="select-option-1"
                                  defaultValue="placeholder-item"
                                  noLabel
                                  invalidText="Please choose an option"
                                  name="participationStatus"
                                  onChange={this.onSelectTypeChange}
                                  value={
                                    this.state.editDataset.participationStatus
                                  }
                                >
                                  <SelectItem
                                    disabled
                                    text="Choose an option"
                                  />
                                  {this.state.participationStatus.map((val) => (
                                    <SelectItem value={val} text={val} />
                                  ))}
                                </Select>
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{
                                borderBottom: "1px solid #c6c6c6",
                                padding: "1em 0 1em 0",
                              }}
                            >
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                <span>Reason</span>{" "}
                              </div>
                              <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                <TextArea
                                  cols={20}
                                  name="reason"
                                  value={this.state.editDataset.reason}
                                  onChange={this.onChangeInput}
                                  rows={4}
                                  maxLength="100"
                                  required
                                  type="text"
                                  invalid={this.state.invalidDplType}
                                />
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{
                                borderBottom: "1px solid #c6c6c6",
                                paddingBottom: "1em",
                              }}
                            >
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ padding: "1em 0 0 1em" }}
                              >
                                <span>Email</span>{" "}
                              </div>
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ margin: "15px 0" }}
                              >
                                {this.state.editDataset.userEmail}
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{
                                borderBottom: "1px solid #c6c6c6",
                                paddingBottom: "1em",
                              }}
                            >
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ padding: "1em 0 0 1em" }}
                              >
                                <span>First Name</span>{" "}
                              </div>
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ margin: "15px 0" }}
                              >
                                {this.state.editDataset.firstName}
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{
                                borderBottom: "1px solid #c6c6c6",
                                paddingBottom: "1em",
                              }}
                            >
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ padding: "1em 0 0 1em" }}
                              >
                                <span>Last Name</span>{" "}
                              </div>
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ margin: "15px 0" }}
                              >
                                {this.state.editDataset.lastName}
                              </div>
                            </div>
                            <div
                              className="bx--row"
                              style={{ paddingBottom: "1em" }}
                            >
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ padding: "1em 0 0 1em" }}
                              >
                                {" "}
                                <span>Registraion Date</span>
                              </div>
                              <div
                                className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                style={{ margin: "15px 0" }}
                              >
                                {this.state.editDataset.registeredDate}
                              </div>
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button kind="secondary" onClick={this.closeOverlay}>
                            Close
                          </Button>
                          <Button
                            kind="primary"
                            disabled={this.state.submitDisabled}
                            onClick={this.onSubmit}
                          >
                            Submit
                          </Button>
                        </ModalFooter>
                      </ComposedModal>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </header>
        </Column>
      </Grid>
    );
  }
}