//import './cssfile.scss';
import React, { Component } from 'react';
import './modals.scss';
import { Button, ComposedModal, ModalHeader, ModalFooter } from '@carbon/react';
class SuccessRecertifyModal extends Component {
    constructor(props) {
        super(props);
        let lsEditValue = JSON.parse(localStorage.getItem('ConfigUserDet'));
        this.state = {
            emailRecertify: lsEditValue.universityEmail
        }
    }
    render() {
        return (
            <React.Fragment>
                <ComposedModal
                    className='some-class'
                    hasScrollingContent={false}
                    iconDescription='Close the modal'
                    modalAriaLabel=''
                    modalLabel=''
                    onRequestClose={this.props.hidden}
                    open
                    passiveModal={true}
                    primaryButtonDisabled={false}
                    primaryButtonText='Submit'
                    secondaryButtonText=''
                    alert={true}
                    hasForm={true}
                    selectorPrimaryFocus='[data-modal-primary-focus]'
                    size='sm'
                    onClose={this.props.hidden}>
                    <ModalHeader>
                    <div style={{ marginTop: '1rem' }}>
                    <p>An email has been sent to <strong>{this.state.emailRecertify}</strong>. Please click on the validation link in that email to complete the recertification process.</p><br></br>
                    </div>
                    </ModalHeader>
                     <ModalFooter>
                        <Button
                            kind="primary"
                            type="button"
                            onClick={this.props.successok}
                        >
                            OK
  </Button>

                    </ModalFooter>
                </ComposedModal>
            </React.Fragment>
        )
    }
}
export default SuccessRecertifyModal;