import React from 'react';
import {
  Grid,
  Column,
  Theme,
  Link,
  Section,
  Button,
  Modal,
  Accordion,
  AccordionItem,
  ClickableTile,
  Loading,
} from '@carbon/react';
import { ArrowRight, CopyLink } from '@carbon/react/icons';
import SbsdHeader from '../../components/SBSDHeader/SBSDHeader';
import { useEffect } from 'react';
import factoryService from '../../services/factory-service';
import { useState } from 'react';
import utilService from '../../services/util-service';
import { CheckmarkOutline } from '@carbon/react/icons';
import { Warning_02, DoNot } from '@carbon/pictograms-react';
import queryString from 'query-string';

const RepoPage = props => {
  let pageName =
    typeof props.match.params.id === 'undefined' ? '' : props.match.params.id;
  let cardSelected = queryString.parse(props.location.search);
  const [pageDetail, setPageDetail] = useState(null);
  const [cardDetail, setCardDetail] = useState(null);
  const [SoftwareCount, setSoftwareCount] = useState([]); //Array.from({ length: 6 }, (_, index) => index + 1);
  const [CoursewareCount, setCoursewareCount] = useState([]); //Array.from({ length: 9 }, (_, index) => index + 1);
  const [ResourceCount, setResourceCount] = useState([]); //Array.from({ length: 6 }, (_, index) => index + 1);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalID, setModalID] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [links, setLinks] = useState(null);
  const [showLoader, setLoader] = useState(false);
  const [promoTemplates, setPromoTemplates] = useState([]);
  const [showMsg, setShowMsg] = useState(false);
  const [showPromo, setShowPromo] = useState(false);
  const [msgType, setMsgType] = useState('Success');
  const [msgText, setMsgText] = useState('Test');
  const [promoText, setPromoText] = useState(false);
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  const [responseMsg, setResponseMsg] = useState(null);

  useEffect(() => {
    console.log('pageName', pageName);
    console.log('cardSelected', cardSelected);
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);

    const getDetail = async id => {
      const val = await factoryService.getTechnologyById(id);
      if (typeof val.data != 'undefined') setPageDetail(val.data[0]);
    };

    const getCardDetails = async id => {
      setLoader(true);
      const v = await factoryService.getTechnologyCards(id);
      if (typeof v.data !== 'undefined'  && v.data !== null) { console.log('cd raw', v.data); let crd = v.data.filter(c => c.VISIBILITY === true); setCardDetail(crd); }
    };

    if (
      sessionStorage.getItem('techList') !== 'undefined' &&
      sessionStorage.getItem('techList') !== null
    ) {
      let techList = JSON.parse(sessionStorage.getItem('techList'));
      console.log('techList', techList);
      let pageRequested = techList.find(
        item =>
          item.NAME.split(' ')
            .join('_')
            .toLowerCase() === pageName
      );
      if (typeof pageRequested !== 'undefined') {
        let pageID = pageRequested.ID;
        getDetail(pageID);
        getCardDetails(pageID);
        factoryService.getPromoCodeTemplate().then(vl => {
          setPromoTemplates(vl.data);
        });
        factoryService.getPageMessages().then(v=>{
          if(typeof v.data !== 'undefined') {
            console.log('getPageMessages', v.data);
            setResponseMsg(v.data);
          }
        })
      }
    } else {
      utilService.setOtherPageAccessible(false);
      if (typeof cardSelected.cardID !== 'undefined') sessionStorage.setItem('cardRedirect', window.location.pathname + '?cardID=' + cardSelected.cardID);
      window.location.href = '/a2mt/email-auth';
    }
  }, [pageName]);

  useEffect(() => {
    console.log('pd', pageDetail);
  }, [pageDetail]);
  useEffect(() => {
    console.log('cd', cardDetail);
    sortCards();
  }, [cardDetail]);
  useEffect(() => {
    console.log('lnk', links);
  }, [links]);
  useEffect(() => {
    console.log('template', promoTemplates);
  }, [promoTemplates]);

  const sortCards = () => {
    let a1 = []; let filteredA1 = [];
    let a2 = []; let filteredA2 = [];
    let a3 = []; let filteredA3 = [];
    if (cardDetail != null) {
      cardDetail.map(vl => {
        if (vl.COURSE_TYPE === 'Software') a1.push(vl);
        else if (vl.COURSE_TYPE === 'Courseware') a2.push(vl);
        else if (vl.COURSE_TYPE === 'Resources') a3.push(vl);
      });
      console.log(a1, a2, a3);
      if (
        sessionStorage.getItem('userRo') !== 'undefined' &&
        sessionStorage.getItem('userRo') !== null
      ) {
        let ro = sessionStorage.getItem('userRo');
        filteredA1 = a1.filter(val=>{ if ( val.USER_ROLE == ro || val.USER_ROLE == 0 ) { return val; } });
        filteredA2 = a2.filter(val=>{ if ( val.USER_ROLE == ro || val.USER_ROLE == 0 ) { return val; } });
        filteredA3 = a3.filter(val=>{ if ( val.USER_ROLE == ro || val.USER_ROLE == 0 ) { return val; } });
        setSoftwareCount(filteredA1);
        setCoursewareCount(filteredA2);
        setResourceCount(filteredA3);
      } else {
        setSoftwareCount(a1);
        setCoursewareCount(a2);
        setResourceCount(a3);
      }
      setLoader(false);
      if (typeof cardSelected.cardID !== 'undefined') checkDeepLink();
    }
  };

  const checkDeepLink = () => {
    const cardDesc = cardDetail.filter(item => item.ID == cardSelected.cardID);
    if (cardDesc.length > 0) {
      setModalTitle(cardDesc[0].COURSE_TITLE);
      setModalDesc(cardDesc[0].COURSE_DESCRIPTION);
      setModalID(cardDesc[0].ID);
      getLink(cardDesc[0].ID);
      setModalOpen(true);
    } else {
      setMsgType('Error');
      setMsgText('Something went wrong, please try again with valid card ID');
      setShowMsg(true);
    }
  };

  const openTile = (event, data) => {
    console.log('data', event, data);
    setModalTitle(data.COURSE_TITLE);
    setModalDesc(data.COURSE_DESCRIPTION);
    setModalID(data.ID);
    getLink(data.ID);
    setModalOpen(true);
  };

  const getLink = async id => {
    setLoader(true);
    const lnkRaw = await factoryService.getTechnologyLinks();
    const lnk = lnkRaw.data.filter(ln => ln.VISIBILITY === true);
    let newlnk = lnk.filter(l => l.COURSE_ID === id);
    setLinks(newlnk);
    setLoader(false);
  };

  const openLink = async (event, data) => {
    setLoader(true);
    console.log('link', event, data, modalTitle);
    const currentUser =
      factoryService.getSession() != false ? factoryService.getSession() : null;
    if (data.LINK_TYPE.toLowerCase() === 'dsw') {
      let result = await factoryService.callDSW(
        currentUser,
        data.LINK_TARGET,
        data.LINK_NAME,
        modalTitle
      );
      if (result !== false) { setLoader(false); } else {
        setLoader(false);
        setMsgType('Error');
        setMsgText('The services are down, please try again later');
      }
    } else if (data.LINK_TARGET.startsWith('LIC')) {
      getPromo(
        currentUser,
        data.LINK_TARGET,
        data.LINK_TYPE,
        promoTemplates,
        data.LINK_NAME,
        modalTitle
      );
    } else if (data.LINK_TYPE.toLowerCase() === 'request') {
      sendPromoEmail(
        currentUser,
        data.LINK_TARGET,
        promoTemplates,
        data.LINK_NAME,
        modalTitle
      );
    } else {
      const isRoute = data.LINK_TARGET.indexOf('https:');
      if (isRoute > -1) {
        window.open(data.LINK_TARGET, '_blank');
      }
      setLoader(false);
    }
  };

  const getPromo = async (
    data,
    url,
    matchcase,
    templates,
    linkName,
    assetName
  ) => {
    let flag = false,
      promoCodeType = '',
      templateID = '', 
      elemType='';
    const temporaryPromo = String(url)
      .split(':')
      .pop()
      .trim();
    if (templates.length > 0) {
      console.log('Matching user promocode type');
      templates.forEach(element => {
        if (element.TYPE === matchcase) {
          console.log('User matched promo:', element);
          flag = true;
          promoCodeType = temporaryPromo;
          templateID = element.TEMPLATE_ID;
          elemType = element.TYPE;
        }
      });
    }
    if (flag) {
      await factoryService
        .getPromoCode(
          data,
          promoCodeType,
          templateID,
          sessionStorage.getItem('jwtAuth_ID')
        )
        .then(result => {
          setLoader(false);
          console.log('Promo code ok: ', result);
          if (result != null && result != '') {
            console.log('promo pass', result);
            if(responseMsg !== null) {
              let msgTemp = responseMsg.filter(val=>{return val.TYPE == elemType});
              setPromoText(msgTemp[0].MESSAGE + ' \n' + result);
            } else {
              setPromoText('Your feature-code/promo-code is' + ' \n' + result);
            }            
            setShowPromo(true);
            factoryService.saveActivity(data, assetName, linkName);
          } else {
            console.log('promo fail', result);
            setMsgType('Warning');
            setMsgText('The services are down, please try again later');
            setShowMsg(true);
          }
        })
        .catch(error => {
          console.log('Promo code Error: ', JSON.stringify(error));
          setLoader(false);
          setMsgType('Error');
          setMsgText('The services are down, please try again later');
          setShowMsg(true);
        });
    } else {
      setLoader(false);
      setMsgType('Error');
      setMsgText('Something went wrong, please try again later');
      setShowMsg(true);
    }
  };

  const sendPromoEmail = async (data, url, templates, linkName, assetName) => {
    const emailType = String(url)
      .split(':')[0]
      .trim();
    let flag = false,
      emailId = '',
      templateID = '',
      elemType = '';
    if (templates.length > 0) {
      console.log('Matching user promocode type');
      templates.forEach(element => {
        if (element.TYPE === emailType) {
          console.log('User matched promo:', element);
          flag = true;
          emailId = String(url)
            .split(':')
            .pop()
            .trim();
          templateID = element.TEMPLATE_ID;
          elemType = element.TYPE;
        }
      });
    }
    if (flag) {
      await factoryService
        .sendPromoCodeRequestEmail(
          data,
          emailId,
          templateID,
          sessionStorage.getItem('jwtAuth_ID')
        )
        .then(result => {
          setLoader(false);
          console.log('Promo code email result: ', result);
          if (result.trim() === 'success') {
            setMsgType('Success');
            if (responseMsg !== null) {
              let msgTemp = responseMsg.filter(val => { return val.TYPE == elemType });
              setMsgText(msgTemp[0].MESSAGE);
            } else {
              setMsgText('The email was sent successfully!');
            }
            setShowMsg(true);
            factoryService.saveActivity(data, assetName, linkName);
          } else {
            console.log('email send fail', result);
            setMsgType('Warning');
            setMsgText('The services are down, please try again later');
            setShowMsg(true);
          }
        });
    } else {
      setLoader(false);
      setMsgType('Error');
      setMsgText('Something went wrong, please try again later');
      setShowMsg(true);
    }
  };

  const showCopy = () => {
    setShowCopyMsg(true);
    setTimeout(() => {
      setShowCopyMsg(false);
    }, 3000);
  };

  return (
    <Section>
      <SbsdHeader />
      <Grid className="repo-page__r1 repo-page__purple-section">
        {pageDetail != null && (
          <Column lg={{ span: 8, offset: 4 }} md={8} sm={4}>
            <h1 className="repo-page__heading">{pageDetail.NAME}</h1>
            <p className="repo-page__p" dangerouslySetInnerHTML={{ __html: pageDetail.DESCRIPTION }}></p>
          </Column>
        )}
        {pageDetail != null && (
          <Column lg={{ span: 4, offset: 12 }} md={8} sm={4}>
            <img
              className="repo-page__headImg"
              src={pageDetail.IMG_URL}
              alt={pageDetail.NAME}
            />
          </Column>
        )}
      </Grid>
      <Theme theme="g10">
        <Grid narrow className="repo-page">
          {SoftwareCount.length>0 && <Column
            lg={{ span: 12, offset: 4 }}
            md={8}
            sm={4}
            className="repo-page__technology-section">
            <Accordion>
              <AccordionItem title="Software" open>
                <Grid>
                  {SoftwareCount.map((vl, index) => (
                    <Column sm={4} md={2} lg={4} className="repo-page__gapping">
                      <ClickableTile
                        id={'SoftwareTile_' + index}
                        role='button'
                        onClick={e => openTile(e, vl)}>
                        <h4 className="repo-page__tiletitle">
                          {vl.COURSE_TITLE}
                        </h4>
                        <p
                          className="repo-page__subtext"
                          style={{ float: 'left' }}
                          >
                          {vl.COURSE_INFO}
                        </p>
                        <Link
                          style={{ float: 'right' }}
                          renderIcon={() => <ArrowRight aria-label="Link" />}
                        />
                        <br />
                      </ClickableTile>
                    </Column>
                  ))}
                </Grid>
              </AccordionItem>
            </Accordion>
          </Column>}

          {CoursewareCount.length>0 && <Column
            lg={{ span: 12, offset: 4 }}
            md={8}
            sm={4}
            className="repo-page__technology-section">
            <Accordion>
              <AccordionItem title="Courseware" open>
                <Grid>
                  {CoursewareCount.map((vl, index) => (
                    <Column sm={4} md={2} lg={4} className="repo-page__gapping">
                      <ClickableTile
                        id={'CoursewareTile_' + index}
                        role='button'
                        onClick={e => openTile(e, vl)}>
                        <h4 className="repo-page__tiletitle">
                          {vl.COURSE_TITLE}
                        </h4>
                        <p
                          className="repo-page__subtext"
                          style={{ float: 'left' }}>
                          {vl.COURSE_INFO}
                        </p>
                        <Link
                          style={{ float: 'right' }}
                          renderIcon={() => <ArrowRight aria-label="Link" />}
                        />
                        <br />
                      </ClickableTile>
                    </Column>
                  ))}
                </Grid>
              </AccordionItem>
            </Accordion>
          </Column>}

          {ResourceCount.length > 0 && <Column
            lg={{ span: 12, offset: 4 }}
            md={8}
            sm={4}
            className="repo-page__technology-section-last">
            <Accordion>
              <AccordionItem title="Resources" open>
                <Grid>
                  {ResourceCount.map((vl, index) => (
                    <Column sm={4} md={2} lg={4} className="repo-page__gapping">
                      <ClickableTile
                        id={'ResourceTile_' + index}
                        role='button'
                        onClick={e => openTile(e, vl)}>
                        <h4 className="repo-page__tiletitle">
                          {vl.COURSE_TITLE}
                        </h4>
                        <p
                          className="repo-page__subtext"
                          style={{ float: 'left' }}>
                          {vl.COURSE_INFO}
                        </p>
                        <Link
                          style={{ float: 'right' }}
                          renderIcon={() => <ArrowRight aria-label="Link" />}
                        />
                        <br />
                      </ClickableTile>
                    </Column>
                  ))}
                </Grid>
              </AccordionItem>
            </Accordion>
          </Column>}
        </Grid>
      </Theme>

      <Grid className="repo-page__r1 repo-page__blue-section">
        <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
          <h1 className="repo-page__subheading">Have any questions?</h1>
          <span style={{ padding: '0 1em' }}>
            <Button kind="tertiary" href="/a2mt/agreement" target="_blank">
              Terms of use
            </Button>
          </span>
          <span style={{ padding: '0 1em' }}>
            <Button kind="tertiary" href="/a2mt/guidelines" target="_blank">
              Usage guidelines
            </Button>
          </span>
          <span style={{ padding: '0 1em' }}>
            <Button kind="tertiary" href="/a2mt/privacy" target="_blank">
              Privacy statement
            </Button>
          </span>
          <Button href="/a2mt/faq" target="_blank">
            FAQs
          </Button>
        </Column>
      </Grid>

      <Modal
        open={modalOpen}
        passiveModal
        aria-label={modalTitle}
        onRequestClose={() => setModalOpen(false)}>
        <div>
          <h1 className="repo-page__modalheading">
            {modalTitle} <CopyLink size="20" style={{display:"inline"}}
              onClick={() => {
                navigator.clipboard.writeText(
                  window.location.origin +
                  window.location.pathname +
                  '?cardID=' +
                  modalID
                );
                showCopy();
              }}
            />
            {showCopyMsg && (
              <div className="repo-page__modalCopySuccess">
                Link copied successfully!
              </div>
            )}
          </h1>
        </div>
        <p className="repo-page__modaltext" dangerouslySetInnerHTML={{ __html: modalDesc }}></p>
        <Theme theme="g10">
          <Grid condensed className="repo-page__modalGrid">
            {links != null &&
              links.map(lk => {
                return (
                  <Column
                    sm={4}
                    md={4}
                    lg={5}
                    style={{ padding: '0 0.5em 0.5em 0' }}>
                    <ClickableTile
                      onClick={e => openLink(e, lk)}
                      role='button'
                      style={{ minHeight: '5em' }}>
                      <p style={{ minHeight: '4em' }}>{lk.LINK_NAME}</p>
                      <ArrowRight />
                    </ClickableTile>
                  </Column>
                );
              })}
          </Grid>
        </Theme>
      </Modal>

      <Modal
        primaryButtonText="Ok"
        preventCloseOnClickOutside={false}
        open={showMsg}
        size="sm"
        onRequestSubmit={() => setShowMsg(false)}
        onRequestClose={() => setShowMsg(false)}>
        <div style={{ padding: '1em 0' }}>
          {msgType == 'Success' && (
            <div>
              {' '}
              <CheckmarkOutline
                style={{ width: '7em', height: '7em', color: 'green' }}
              />{' '}
            </div>
          )}
          {msgType == 'Warning' && (
            <div>
              {' '}
              <Warning_02
                style={{ width: '7em', height: '7em', color: 'orange' }}
              />{' '}
            </div>
          )}
          {msgType == 'Error' && (
            <div>
              {' '}
              <DoNot
                style={{ width: '7em', height: '7em', color: 'red' }}
              />{' '}
            </div>
          )}
          <div style={{ paddingLeft: '1em' }}> {msgText} </div>{' '}
        </div>
      </Modal>

      <Modal
        preventCloseOnClickOutside={false}
        open={showPromo}
        size="sm"
        primaryButtonText="Copy Code"
        secondaryButtonText="Ok"
        onRequestSubmit={() =>
          navigator.clipboard.writeText(promoText.split('\n')[1])
        }
        onSecondarySubmit={() => setShowPromo(false)}
        onRequestClose={() => setShowPromo(false)}>
        <div style={{ padding: '1em 0' }}>
          <div>
            {' '}
            <CheckmarkOutline
              style={{ width: '7em', height: '7em', color: 'green' }}
            />{' '}
          </div>
          <div style={{ paddingLeft: '1em' }}> {promoText} </div>{' '}
        </div>
      </Modal>

      <Loading
        withOverlay={true}
        description="Active loading indicator"
        active={showLoader}
      />
    </Section>
  );
};

export default RepoPage;
