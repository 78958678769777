import React from "react";
import {
  Button,
  TimePicker,
  TimePickerSelect,
  DatePickerInput,
  Modal,
  DatePicker,
  TextInput,
  NumberInput,
  Select,
  SelectItem,
  Grid,
  Column
} from "@carbon/react";
import "./workshop-event-form.scss";
import WorkshopEvent from "../create-workshop/create-workshop";
import utilService from "../../../services/util-service";
import { Warning_02, DoNot } from '@carbon/pictograms-react';
import { CheckmarkOutline } from "@carbon/react/icons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

//const content = "<ul>\n<li>naanu neenu</li>\n<li>neenu naanu</li>\n</ul>\n<p>Shambulinga, <strong>Satish</strong></p>\n";
const content = "";
const MAX_LENGTH = 2500;
export default class EventForm extends React.Component {
                 constructor(props) {
                   super(props);
                   utilService.setBaseURL();
                   this.state = {
                     redirect: false,
                     baseURL: utilService.hostname,
                     creatorEmailId: localStorage.getItem("userEmail"),
                     creatorName: localStorage.getItem("userName"),
                     editDataset: {},
                     showLoader: false,
                     showAlert: false,
                     alertMsg: "Test",
                     alertType: "Success",
                     rowData: [],
                     obj_chars_left: 0,
                     des_chars_left: 0,
                     max_char: 1000,
                     topic: "",
                     title: "",
                     endDate: "",
                     startDate: "",
                     dat: "",
                     status: "",
                     objective: "",
                     region: "",
                     meeting: "",
                     description: "",
                     attachment: "",
                     contactId: "",
                     fromEmailId:0,
                    //  fromEmail:"",
                     starttimeconv: "AM",
                     endtimeconv: "AM",
                     editDataset: {},
                     editDatasetId: 0,
                     editDatasetCreatedDat: "",
                     capacity: 1,
                     statusValues: ["Open", "Closed"],
                     regionsList: [],
                     badgeImageList: [],
                     emailIdList:[],
                     endTime: "",
                     startTime: "",
                     maxDat: "mm/dd/yyyy",
                     minDat: "mm/dd/yyyy",
                     minEndDat: "mm/dd/yyyy",
                     heading: "Add or Modify Workshop",
                     descWarning: false,
                     objWarning: false,
                     invalidStatus:false,
                     invalidCapacity:false,
                     invalidTimezone:false,
                     invalidEndTime:false,
                     invalidStartTime:false,
                     invalidEndDate:false,
                     invalidTopic:false,
                     invalidWStitle:false,
                     invalidWStitle:false,
                     invalidFromEmailId:false,
                     invalidStartDate:false,
                     invalidEndDate:false,
                     invalidMeetingLink:false,
                     invalidBadgeUrl:false,
                     invalidDesc:false,
                     invalidObj:false,
                     invalidContactId:false,
                     formValidationMessage:true,
                     objEditorState: EditorState.createWithContent(
                       ContentState.createFromBlockArray(
                         convertFromHTML(content)
                       )
                     ),
                     descEditorState: EditorState.createWithContent(
                       ContentState.createFromBlockArray(
                         convertFromHTML(content)
                       )
                     ),
                   };
                 
                   this.handleChange = this.handleChange.bind(this);
                   this.handleSubmit = this.handleSubmit.bind(this);
                 }

                 componentDidUpdate() {
                
                   sessionStorage.removeItem("isOtherPageAccessible");
                   utilService.setOtherPageAccessible(false);
                 }

                 componentDidMount() {


                   let currDate = new Date();
                   let currYear = currDate.getFullYear();
                   let currDat = currDate.getDate();
                   let currMonth = currDate.getMonth() + 1;

                   let currMinDate = `${currMonth}/${currDat}/${currYear}`;
                   let currMaxDate = "12/31/" + currYear;

                   this.setState({
                     maxDat: currMaxDate,
                   });

                   this.setState({
                     minDat: currMinDate,
                   });
                   this.getAllTimeZoneList();

                   if (sessionStorage.getItem("workshopEventEditDataset")) {
                     this.state.editDataset = JSON.parse(
                       sessionStorage.workshopEventEditDataset
                     );
                     this.state.status = this.state.editDataset.STATUS;
                     this.state.title = this.state.editDataset.TITLE;
                     this.state.capacity = this.state.editDataset.CAPACITY;
                     this.state.description = this.state.editDataset.DESCRIPTION.split(
                       "<br/><br/>"
                     ).join("<br/>");
                     const contentBlock = htmlToDraft(this.state.description);
                     const contState = ContentState.createFromBlockArray(
                       contentBlock.contentBlocks
                     );
                     this.state.descEditorState = EditorState.createWithContent(
                       contState
                     );

                     let descTextContentBlock = convertToRaw(
                       this.state.descEditorState.getCurrentContent()
                     ).blocks;
                     let descTextContent = "";
                     descTextContentBlock.forEach((content) => {
                       if (content.text != "");
                       descTextContent += content.text;
                     });
                     const desCharCount = descTextContent.length;
                     this.setState({
                       des_chars_left: desCharCount,
                     });
                    
                     this.state.meeting = this.state.editDataset.MEETING_LOCATION;
                     this.state.attachment = this.state.editDataset.PHOTO_LINK;
                    //  this.state.attachment = this.state.editDataset.PHOTO_LINK;
                     this.state.fromEmailId  = this.state.editDataset.fromEmailId;
                    
                    
                    //  emailId
                     this.state.endDate = this.state.editDataset.END_DATE;
                     this.state.topic = this.state.editDataset.TOPIC;
                     this.state.startDate = this.state.editDataset.START_DATE;
                     this.state.objective = this.state.editDataset.OBJECTIVE.split(
                       "<br/><br/>"
                     ).join("<br/>");
                     const objBlock = htmlToDraft(this.state.objective);
                     const objState = ContentState.createFromBlockArray(
                       objBlock.contentBlocks
                     );
                     this.state.objEditorState = EditorState.createWithContent(
                       objState
                     );
                     let objTextContentBlock = convertToRaw(
                       this.state.objEditorState.getCurrentContent()
                     ).blocks;
                     let objTextContent = "";
                     objTextContentBlock.forEach((content) => {
                       if (content.text != "");
                       objTextContent += content.text;
                     });
                     const objCharCount = objTextContent.length;
                     this.setState({
                       obj_chars_left: objCharCount,
                     });
                    

                     this.state.region = this.state.editDataset.TIME_ZONE;
                     this.state.contactId = this.state.editDataset.contactId;

                     let strTime = this.state.editDataset.START_TIME.split(" ");
                     let endTime = this.state.editDataset.END_TIME.split(" ");

                     this.state.startTime = strTime[0];
                     this.state.endTime = endTime[0];
                     this.state.starttimeconv = strTime[1];
                     this.state.endtimeconv = endTime[1];

                     this.state.editDatasetId = this.state.editDataset.id;
                     this.state.editDatasetCreatedDat = this.state.editDataset.CREATE_DATE;
                     this.setState({
                       heading: "Edit Workshop",
                     });
                   }
                 }

                 handleChangeDate(value) {
                   
                   let startDat = document.getElementById(
                     "start-date-picker-single"
                   ).value;

                  
                    this.setState({  invalidStartDate : false});
                  
                   this.setState({
                     minEndDat: startDat,
                   });
                 }
                 handleChangeEndDate(){
                  this.setState({  invalidEndDate : false});
                 }

                 handleChange(event) {
                   this.setState({
                     [event.target.name]: event.target.value,
                   });

                   if(event.target.name == 'topic') {
                    this.setState({  invalidTopic : false});
                  } else  if(event.target.name == 'title') {
                    this.setState({  invalidWStitle : false});
                  } else  if(event.target.name == 'status') {
                    this.setState({  invalidStatus : false});
                  } else  if(event.target.name == 'capacity') {
                    this.setState({  invalidCapacity : false});

                  } else  if(event.target.name == 'objective') {
                    this.setState({  invalidObj : false});
                  } else  if(event.target.name == 'description') {
                    this.setState({  invalidDesc : false});

                  } else  if(event.target.name == 'region') {
                    this.setState({  invalidTimezone : false});
                  } else  if(event.target.name == 'contactId') {
                    this.setState({  invalidContactId : false});
                  
                  } else  if(event.target.name == 'attachment') {
                    this.setState({  invalidBadgeUrl : false});
                   } 
                   else  if(event.target.name == 'startTime') {
                    this.setState({  invalidStartTime : false});
                  } else  if(event.target.name == 'endTime') {
                    this.setState({  invalidEndTime : false});
                  }else  if(event.target.name == 'contactId') {
                    this.setState({  invalidContactId : false});
                  }
                  else  if(event.target.name == 'meeting') {
                    this.setState({  invalidMeetingLink : false});
                  } else  if(event.target.name == 'fromEmailId') {
                  
                    this.setState({  invalidFromEmailId : false});
                  }

                
                }

                 onChangeHandler = (event) => {
                   let nam = event.target.name;
                   
                   let val = document.getElementById("Capacity-carbon-number")
                     .value;
                   console.log("val  ", val);
                   this.setState({
                     capacity: val,
                   });
                   event.preventDefault();
                 };

                 onObjEditorStateChange = (objEditorState) => {
                 
                    this.setState({  invalidObj : false});
                  
                

                   let textContentBlock = convertToRaw(
                     objEditorState.getCurrentContent()
                   ).blocks;
                   let textContent = "";
                   textContentBlock.forEach((content) => {
                     if (content.text != "");
                     textContent += content.text;
                   });
                   const objCharCount = textContent.length;
                   this.setState({
                     obj_chars_left: objCharCount,
                   });

                   this.state.objective = draftToHtml(
                     convertToRaw(objEditorState.getCurrentContent())
                   );
                   this.setState({
                     objEditorState,
                   });

                   console.log("objCharCount ", objCharCount);
                   if (objCharCount > MAX_LENGTH) {
                     console.log("you can type max 2500 characters");
                     this.setState({ objWarning: true });
                     //return false;
                   } else {
                     this.setState({ objWarning: false });
                   }
                 };

                 onDescEditorStateChange = (descEditorState) => {
                  //  alert("hi")

                 
                    this.setState({  invalidDesc : false});
                  
                  
                   let textContentBlock = convertToRaw(
                     descEditorState.getCurrentContent()
                   ).blocks;
                   let textContent = "";
                   textContentBlock.forEach((content) => {
                     if (content.text != "");
                     textContent += content.text;
                   });
                   const desCharCount = textContent.length;
                   this.setState({
                     des_chars_left: desCharCount,
                   });

                   this.state.description = draftToHtml(
                     convertToRaw(descEditorState.getCurrentContent())
                   );
                   this.setState({
                     descEditorState,
                   });

                   console.log("desCharCount ", desCharCount);
                   if (desCharCount > MAX_LENGTH) {
                     console.log("you can type max 2500 characters");
                     this.setState({ descWarning: true });
                     //return false;
                   } else {
                     this.setState({ descWarning: false });
                   }
                 };
                 async getAllBadgeImageList() {
                   await utilService.getData("/a2mt/services/user-details/getBadgeUrl")
                     .then((res) => {
                      
                        console.log("getBadgeUrl==",res.data);
                      
                       if (res.data != null) {
                         this.setState({
                           badgeImageList: res.data,
                         });
                         this.getAllEmailIdList();
                       } else {
                         this.setState({
                           badgeImageList: [],
                         });
                       }
                     })
                     .catch((error) => {
                       this.setState({
                         showLoader: false,
                       });
                       this.setState({
                         alertMsg: "Something went wrong. Please try again.",
                         alertType: "Error",
                         showAlert: true,
                       });
                     });
                 }

                 async getAllEmailIdList() {
                  await utilService.getData("/a2mt/services/user-details/getEmailsRepo")
                    .then((res) => {
                     
                       console.log("getEmailsRepo==",res.data);
                     
                      if (res.data != null) {
                        this.setState({
                          emailIdList: res.data,
                        });
                       
                      } else {
                        this.setState({
                          emailIdList: [],
                        });
                      }
                    })
                    .catch((error) => {
                      this.setState({
                        showLoader: false,
                      });
                      this.setState({
                        alertMsg: "Something went wrong. Please try again.",
                        alertType: "Error",
                        showAlert: true,
                      });
                    });
                }

                 getAllTimeZoneList() {
                   this.setState({
                     showLoader: true,
                   });
                   utilService.getData("/a2mt/services/user-details/getTimezones")
                     .then((res) => {
                       this.setState({
                         showLoader: false,
                       });
                       // console.log(res.data);
                       if (res.data != null) {
                         this.setState({
                           regionsList: res.data,
                         });
                          this.getAllBadgeImageList();
                       } else {
                         this.setState({
                           regionsList: [],
                         });
                       }
                     })
                     .catch((error) => {
                       this.setState({
                         showLoader: false,
                       });
                       this.setState({
                         alertMsg: "Something went wrong. Please try again.",
                         alertType: "Error",
                         showAlert: true,
                       });
                     });
                 }

                 closeOverlay = (event) => {
                   this.setState({
                     isEditOverlayVisible: false,
                     isViewOverlayVisible: false,
                   });
                 };

                 closeAlert = (event) => {
                   if(!this.state.formValidationMessage){
                    this.setState({
                      showAlert: false,
                    });

                   }else{
                   this.setState({
                     showAlert: false,
                   });
                   utilService.setOtherPageAccessible(true);
                   window.location = "/a2mt/admin-dashboard/create-workshop";
                 };

                }

                 handleSubmit(event) {
                   event.preventDefault();
                   let isFormValid = true;
                    
                
                   
                   if (this.state.title == "") { this.setState({ invalidWStitle: true}); isFormValid = false ;} 
                   if (this.state.topic == "") { this.setState({ invalidTopic: true }); isFormValid = false ;} 
                   if (this.state.startTime == "") { this.setState({ invalidStartTime: true }); isFormValid = false ;}
                   if (this.state.endTime == "") { this.setState({ invalidEndTime: true}); isFormValid = false }
                   if (this.state.status == "") { this.setState({ invalidStatus: true }); isFormValid = false ;}

                   if (this.state.fromEmailId == "") { this.setState({ invalidFromEmailId: true }); isFormValid = false ;}
                   if (this.state.obj_chars_left <= 1) { this.setState({ invalidObj: true }); isFormValid = false ;}
                   if (this.state.des_chars_left <= 1) { this.setState({ invalidDesc: true }); isFormValid = false; }

                   if (document.getElementById(
                    "start-date-picker-single"
                  ).value == "") { this.setState({ invalidStartDate: true }); isFormValid = false ;}
                   if (document.getElementById(
                    "end-date-picker-single"
                  ).value == "") { this.setState({ invalidEndDate: true }); isFormValid = false ;}

                  if (this.state.attachment == "") { this.setState({ invalidBadgeUrl: true }); isFormValid = false ;}
                  if (this.state.meeting == "") { this.setState({ invalidMeetingLink: true }); isFormValid = false ;}

                  if (this.state.region == "") { this.setState({ invalidTimezone: true }); isFormValid = false ;}
                  if (this.state.contactId == "") { this.setState({ invalidContactId: true }); isFormValid = false ;}

                  





                  if(isFormValid === true){

                    
                   let startDate = document.getElementById(
                     "start-date-picker-single"
                   ).value;
                   let endDate = document.getElementById(
                     "end-date-picker-single"
                   ).value;

                   let Sdat = startDate.split("/");
                   let Edat = endDate.split("/");

                   startDate = Sdat[2] + "-" + Sdat[0] + "-" + Sdat[1];
                   endDate = Edat[2] + "-" + Edat[0] + "-" + Edat[1];

                  

                   if (sessionStorage.getItem("workshopEventEditDataset")) {

                       
                        let fromEmail = this.state.emailIdList.find(o => o.id == this.state.fromEmailId);
                       
                     let data = {
                       id: this.state.editDatasetId,
                       CREATOR_NAME: localStorage.getItem("userName"),
                       CREATOR_EMAIL: localStorage.getItem("adminEmail"),
                      //  CREATOR_NAME: "Satish Ambig",
                      //  CREATOR_EMAIL: "sambig1@in.ibm.com",
                       CREATE_DATE: this.state.editDatasetCreatedDat,
                       TITLE: this.state.title,
                       TOPIC: this.state.topic,
                       START_DATE: startDate,
                       END_DATE: endDate,
                       START_TIME:
                         this.state.startTime + " " + this.state.starttimeconv,
                       END_TIME:
                         this.state.endTime + " " + this.state.endtimeconv,
                       TIME_ZONE: this.state.region,
                       STATUS: this.state.status,
                       CAPACITY: this.state.capacity,
                       DESCRIPTION: this.state.description
                         .split("<p></p>")
                         .join("<br/><br/>")
                         .split("<br></p>")
                         .join("<br/><br/></p>")
                         .split("<br></li>")
                         .join("<br/><br/></li>"),
                       PHOTO_LINK: this.state.attachment,
                       MEETING_LOCATION: this.state.meeting,
                       IS_ENABLE: true,
                       OBJECTIVE: this.state.objective
                         .split("<p></p>")
                         .join("<br/><br/>")
                         .split("<br></p>")
                         .join("<br/><br/></p>")
                         .split("<br></li>")
                         .join("<br/><br/></li>"),
                       contactId: this.state.contactId,
                       fromEmailId : this.state.fromEmailId,
                      fromEmail : fromEmail.emailId
                     };

                     utilService.postData("/a2mt/services/user-details/updateWorkshop", data)
                       .then((rs) => {
                         this.setState({
                           showLoader: false,
                         });
                        
                         if (rs.status === 200) {
                          
                           // console.log(rs);
                           this.setState({
                             alertMsg: "Updates saved successfully.",
                             alertType: "Success",
                             showAlert: true,
                           });

                           sessionStorage.removeItem(
                             "workshopEventEditDataset"
                           );
                         } else {
                           this.setState({
                             alertMsg: "Save failed. Please try again later.",
                             alertType: "Warning",
                             showAlert: true,
                           });
                         }
                         utilService.setOtherPageAccessible(true);
                         window.location =
                           "/a2mt/admin-dashboard/create-workshop";
                         return true;
                       })
                       .catch((error) => {
                         this.setState({
                           showLoader: false,
                         });
                         this.setState({
                           alertMsg: "Something went wrong. Please try again.",
                           alertType: "Error",
                           showAlert: true,
                         });
                         utilService.setOtherPageAccessible(true);
                         window.location =
                           "/a2mt/admin-dashboard/create-workshop";
                         return true;
                       });
                   } else {


                    let fromEmail = this.state.emailIdList.find(o => o.id == this.state.fromEmailId);

                     let data = {
                       CREATOR_NAME: localStorage.getItem("userName"),
                       CREATOR_EMAIL: localStorage.getItem("adminEmail"),
                      //  CREATOR_NAME: "Satish Ambig",
                      //  CREATOR_EMAIL: "saambig1@in.ibm.com",
                       TITLE: this.state.title,
                       TOPIC: this.state.topic,
                       START_DATE: startDate,
                       END_DATE: endDate,
                       START_TIME:
                         this.state.startTime + " " + this.state.starttimeconv,
                       END_TIME:
                         this.state.endTime + " " + this.state.endtimeconv,
                       TIME_ZONE: this.state.region,
                       STATUS: this.state.status,
                       CAPACITY: this.state.capacity,
                       DESCRIPTION: this.state.description
                         .split("<p></p>")
                         .join("<br/><br/>")
                         .split("<br></p>")
                         .join("<br/><br/></p>")
                         .split("<br></li>")
                         .join("<br/><br/></li>"),
                       PHOTO_LINK: this.state.attachment,
                       MEETING_LOCATION: this.state.meeting,
                       OBJECTIVE: this.state.objective
                         .split("<p></p>")
                         .join("<br/><br/>")
                         .split("<br></p>")
                         .join("<br/><br/></p>")
                         .split("<br></li>")
                         .join("<br/><br/></li>"),
                       contactId: this.state.contactId,
                       fromEmailId : this.state.fromEmailId,
                        fromEmail : fromEmail.emailId
                     };

                     utilService.postData("/a2mt/services/user-details/addWorkshop", data)
                       .then((rs) => {
                         this.setState({
                           showLoader: false,
                         });
                          
                         if (rs.status === 200) {

                           this.setState({
                             alertMsg: "Workshop is successfully added.",
                             alertType: "Success",
                             showAlert: true,
                           });
                         } else {
                           this.setState({
                             alertMsg: "Save failed. Please try again later.",
                             alertType: "Warning",
                             showAlert: true,
                           });
                         }
                         utilService.setOtherPageAccessible(true);
                         window.location =
                           "/a2mt/admin-dashboard/create-workshop";
                         return true;
                       })
                       .catch((error) => {
                         this.setState({
                           showLoader: false,
                         });
                         this.setState({
                           alertMsg: "Something went wrong. Please try again.",
                           alertType: "Error",
                           showAlert: true,
                         });
                         utilService.setOtherPageAccessible(true);
                         window.location =
                           "/a2mt/admin-dashboard/create-workshop";
                         return true;
                       });
                   }
                 }else{
                  this.setState({ formValidationMessage : false});
                  this.setState({
                    alertMsg: "Please fill in all required fields.",
                    alertType: "Warning",
                    showAlert: true,
                  });

                 }
                }

                 onCancel = (event) => {
                   event.preventDefault();
                   sessionStorage.removeItem("workshopEventEditDataset");
                   utilService.setOtherPageAccessible(true);
                   window.location = "/a2mt/admin-dashboard/create-workshop";
                   return true;
                 };

                 render() {
                   const { objEditorState } = this.state;
                   const { descEditorState } = this.state;

                   return (
                     <form onSubmit={this.handleSubmit}>
                       <Grid>
                         <Column lg={{ span: 12, offset: 4 }} md={8} sm={4} style={{padding: "3em 0"}}>
                           <div style={{ paddingBottom: '32px' }}>
                             <h3>{this.state.heading}</h3>
                           </div>
                           <div className="bx--row" style={{ marginLeft: "0", position: "relative", bottom: "16px" }}>
                             <p style={{ fontSize: ".8rem" }}><span style={{ color: "red" }}>*</span>All fields provided below are mandatory.</p>
                           </div>
                           <div className="bx--row">
                             <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-6">
                               <Select
                                 id="email-id"
                                 value={this.state.fromEmailId}
                                 labelText="Event email"
                                 invalidText="Please choose an option"
                                 onChange={this.handleChange}
                                 name="fromEmailId"
                                 invalid={this.state.invalidFromEmailId}

                               >
                                 <SelectItem text="Select Email Id" />
                                 {this.state.emailIdList.map((val) => (
                                   <SelectItem
                                     key={val.id}
                                     value={val.id}
                                     text={`${val.programName} - ${val.emailId}`}
                                   />
                                 ))}
                               </Select>
                             </div>
                           </div>
                           <div className="bx--row">
                             <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
                               <TextInput
                                 id="title-text"
                                 type="text"
                                 labelText="Workshop title"
                                 name="title"
                                 onChange={this.handleChange}
                                 value={this.state.title}
                                 invalid={this.state.invalidWStitle}
                                 invalidText="Please enter title"
                               />
                             </div>
                             <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
                               <TextInput
                                 id="topic-text"
                                 type="text"
                                 labelText="Topic"
                                 name="topic"
                                 onChange={this.handleChange}
                                 value={this.state.topic}
                                 invalidText="Please enter topic"
                                 invalid={this.state.invalidTopic}
                               />
                             </div>
                           </div>
                           <div className="bx--row">
                             {/* <div className="bx--row"> */}
                             <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
                               {/* <div className="bx--col-lg-4 bx--col-md-4 bx--col-sm-2"> */}
                               <DatePicker
                                 value={this.state.startDate}
                                 datePickerType="single"
                                 //  maxDate={this.state.maxDat}
                                 //  minDate={this.state.minDat}
                                 onChange={this.handleChangeDate.bind(this)}

                               >
                                 <DatePickerInput
                                   placeholder="mm/dd/yyyy"
                                   labelText="Start Date"
                                   id="start-date-picker-single"
                                   name="startDate"
                                   invalid={this.state.invalidStartDate}
                                   invalidText="Please enter start date"
                                 />
                               </DatePicker>
                             </div>
                             <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
                               <DatePicker
                                 value={this.state.endDate}
                                 name="endDate"
                                 datePickerType="single"
                                 //  maxDate={this.state.maxDat}
                                 minDate={this.state.minEndDat}
                                 onChange={this.handleChangeEndDate.bind(this)}

                               >
                                 <DatePickerInput
                                   placeholder="mm/dd/yyyy"
                                   labelText="End Date"
                                   id="end-date-picker-single"
                                   name="endDate"
                                   invalid={this.state.invalidEndDate}
                                   invalidText="Please enter end date"
                                 />
                               </DatePicker>
                             </div>
                           </div>
                           {/* </div> */}
                           <div className="bx--row">
                             <div className="bx--col-lg-4 bx--col-md-4">
                               <TimePicker
                                 id="start-time-picker"
                                 hideLabel=""
                                 value={this.state.startTime}
                                 name="startTime"
                                 onChange={this.handleChange}
                                 labelText="Start Time"
                                 pattern={"(1[0-2]|[1-9]:[1-5]?[0-9](\\s)?"}
                                 invalid={this.state.invalidStartTime}
                               >
                                 <TimePickerSelect
                                   id="start-time-picker-select"
                                   value={this.state.starttimeconv}
                                   onChange={this.handleChange}
                                   labelText="Start Time"
                                   name="starttimeconv"
                                 >
                                   <SelectItem value="AM" text="AM" />
                                   <SelectItem value="PM" text="PM" />
                                 </TimePickerSelect>
                               </TimePicker>
                             </div>
                             <div className="bx--col-lg-4 bx--col-md-4">
                               <TimePicker
                                 id="end-time-picker"
                                 hideLabel=""
                                 value={this.state.endTime}
                                 name="endTime"
                                 labelText="End Time"
                                 onChange={this.handleChange}
                                 pattern={"(1[0-2]|[1-9]:[1-5]?[0-9](\\s)?"}
                                 invalid={this.state.invalidEndTime}


                               >
                                 <TimePickerSelect
                                   id="end-time-picker-select"
                                   value={this.state.endtimeconv}
                                   onChange={this.handleChange}
                                   labelText="End Time"
                                   name="endtimeconv"
                                 >
                                   <SelectItem value="AM" text="AM" />
                                   <SelectItem value="PM" text="PM" />
                                 </TimePickerSelect>
                               </TimePicker>
                             </div>
                             <div className="bx--col-lg-8 bx--col-md-8">
                               <Select
                                 id="time-zone-select"
                                 value={this.state.region}
                                 labelText="Time Zone"
                                 invalidText="Please choose an option"
                                 onChange={this.handleChange}
                                 name="region"
                                 invalid={this.state.invalidTimezone}

                               >
                                 <SelectItem text="Select time zone" />
                                 {this.state.regionsList.map((val) => (
                                   <SelectItem
                                     key={val.name}
                                     value={val.name}
                                     text={
                                       val.description + " (" + val.name + ")"
                                     }
                                   />
                                 ))}
                               </Select>
                             </div>
                           </div>
                           <div className="bx--row">
                             <div className="bx--col-lg-8 bx--col-md-8">
                               <Select

                                 id="status-select"
                                 value={this.state.status}
                                 labelText="Status"
                                 invalidText="Please choose an option"
                                 onChange={this.handleChange}
                                 name="status"
                                 invalid={this.state.invalidStatus}

                               >

                                 <SelectItem text="Select status" />
                                 {this.state.statusValues.map((val) => (
                                   <SelectItem key={val} value={val} text={val} />
                                 ))}
                               </Select>
                             </div>
                             <div className="bx--col-lg-4 bx--col-md-4">
                               <NumberInput

                                 id="Capacity-carbon-number"
                                 min={1}
                                 max={500}
                                 value={this.state.capacity}
                                 label="Capacity"
                                 name="capacity"
                                 invalidText="Number is not valid"
                                 onChange={this.onChangeHandler}
                                 invalid={this.state.invalidCapacity}

                               />
                             </div>

                           </div>
                           <div className="bx--row">
                             <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
                               <Select
                                 id="attachment-topic-text"
                                 value={this.state.attachment}
                                 labelText="Badge Image Url"
                                 invalidText="Please choose an option"
                                 onChange={this.handleChange}
                                 name="attachment"
                                 invalid={this.state.invalidBadgeUrl}
                               >
                                 <SelectItem text="Select badge" />
                                 {this.state.badgeImageList.map((val) => (
                                   <SelectItem
                                     key={val.id}
                                     value={val.url}
                                     text={val.badge}
                                   />
                                 ))}
                               </Select>

                             </div>
                             <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-2">
                               <TextInput
                                 id="meeting-topic-text"
                                 type="text"
                                 labelText="Meeting Link"
                                 name="meeting"
                                 onChange={this.handleChange}
                                 value={this.state.meeting}
                                 invalid={this.state.invalidMeetingLink}
                                 invalidText="Please enter Meeting Link"
                               />
                             </div>
                           </div>
                           <div className="bx--row">
                             <div className="bx--col-lg-16">
                               <span className="bx--label char-count">
                                 {this.state.des_chars_left}/2500
                               </span>
                               <label
                                 className="bx--label"
                                 style={{
                                   left: -3 + "rem",
                                   position: "relative",
                                 }}
                               >
                                 Description
                               </label>

                               <Editor

                                 editorState={descEditorState}
                                 wrapperClassName="demo-wrapper"
                                 editorClassName="demo-editor"

                                 editorStyle={{
                                   border: this.state.invalidDesc ? '2px solid red' : '1px solid grey',

                                   height: "250px",
                                   backgroundColor: "#ffffff",
                                 }}
                                 onEditorStateChange={
                                   this.onDescEditorStateChange
                                 }

                                 toolbar={{
                                   options: [
                                     "inline",
                                     "blockType",
                                     "fontSize",
                                     "list",
                                     "textAlign",
                                     "link",
                                   ],
                                   inline: { inDropdown: true },
                                   list: { inDropdown: true },
                                   textAlign: { inDropdown: true },
                                   link: { inDropdown: true },
                                   history: { inDropdown: true },


                                 }}
                               />
                               {this.state.invalidDesc && (
                                 <p style={{ color: "#da1e28", fontSize: "0.75rem" }}>Please enter description</p>
                               )}
                               {this.state.descWarning && (
                                 <span id="descAlert" style={{ color: "red" }}>
                                   Maximum characters exceeded
                                 </span>
                               )}
                             </div>
                           </div>
                           <div className="bx--row">
                             <div className="bx--col-lg-16">
                               <span className="bx--label char-count">
                                 {this.state.obj_chars_left}/2500
                               </span>

                               <label
                                 className="bx--label"
                                 style={{
                                   left: -3 + "rem",
                                   position: "relative",
                                 }}
                               >
                                 Objective
                               </label>
                               <Editor
                                 editorState={objEditorState}
                                 wrapperClassName="demo-wrapper"
                                 editorClassName="demo-editor"
                                 editorStyle={{
                                   border: this.state.invalidDesc ? '2px solid red' : '1px solid grey',
                                   height: "250px",
                                   backgroundColor: "#ffffff",
                                 }}
                                 onEditorStateChange={this.onObjEditorStateChange}
                                 toolbar={{
                                   options: [
                                     "inline",
                                     "blockType",
                                     "fontSize",
                                     "list",
                                     "textAlign",
                                     "link",
                                   ],
                                   inline: { inDropdown: true },
                                   list: { inDropdown: true },
                                   textAlign: { inDropdown: true },
                                   link: { inDropdown: true },
                                   history: { inDropdown: true },
                                 }}
                               />
                               {this.state.invalidObj && (
                                 <p style={{ color: "#da1e28", fontSize: "0.75rem" }}>Please enter Objective</p>
                               )}
                               {this.state.objWarning && (
                                 <span id="objAlert" style={{ color: "red" }}>
                                   Maximum characters exceeded
                                 </span>
                               )}
                             </div>
                           </div>
                           <div className="bx--row">
                             <div className="bx--col-lg-4 bx--col-md-2">
                               <TextInput
                                 id="contact-id"
                                 type="email"
                                 labelText="Contact Id (For assistance)"
                                 name="contactId"
                                 onChange={this.handleChange}
                                 value={this.state.contactId}
                                 invalid={this.state.invalidContactId}
                                 invalidText="Please enter contact Id"
                               />
                             </div>
                           </div>

                           <div className="bx--row" style={{ marginTop: "2rem" }}>
                             <div className="bx--col-lg-2 bx--col-md-2 bx--col-sm-2">
                               <Button
                                 kind="primary"
                                 type="submit"
                                 disabled={
                                   this.state.des_chars_left > MAX_LENGTH ||
                                   this.state.obj_chars_left > MAX_LENGTH
                                 }
                               >
                                 Save
                               </Button>
                             </div>
                             <div className="bx--col-lg-2 bx--col-md-2 bx--col-sm-2">
                               <Button kind="danger" onClick={this.onCancel}>
                                 Cancel
                               </Button>
                             </div>
                           </div>
                         </Column>
                       </Grid>
                       <Modal
                         passiveModal={true}
                         preventCloseOnClickOutside={false}
                         open={this.state.showAlert}
                         size="sm"
                         onRequestClose={this.closeAlert}
                       >
                         <div style={{ padding: "1em 0" }}>
                           {this.state.alertType == "Success" && (
                             <div>
                               <CheckmarkOutline
                                 style={{
                                   width: "7em",
                                   height: "7em",
                                   color: "green",
                                 }}
                               ></CheckmarkOutline>
                             </div>
                           )}
                           {this.state.alertType == "Warning" && (
                             <div>
                               <Warning_02
                                 style={{
                                   width: "7em",
                                   height: "7em",
                                   color: "orange",
                                 }}
                               ></Warning_02>
                             </div>
                           )}
                           {this.state.alertType == "Error" && (
                             <div>
                               <DoNot
                                 style={{
                                   width: "7em",
                                   height: "7em",
                                   color: "red",
                                 }}
                               ></DoNot>
                             </div>
                           )}
                           <div style={{ paddingLeft: "1em" }}>
                             {this.state.alertMsg}
                           </div>
                         </div>
                       </Modal>
                     </form>
                   );
                 }
               }
