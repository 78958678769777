import {
  Button, TextInput, 
  Theme,
  Grid,
  Column,
  TextArea, Select, SelectItem, RadioButtonGroup, RadioButton, FormGroup, Checkbox, Modal, Link, Loading
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/react/icons';
import { Help, CheckmarkOutline } from '@carbon/react/icons';
import React from 'react';
import './register.scss';
import AgreementModal from '../../modals/agreementModal';
import TermsModal from '../../modals/termsModal';
import PrivacyModal from '../../modals/privacyModal';
import SuccessModal from '../../modals/successModal';
import utilService from "../../services/util-service";
import Axios from 'axios';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import factoryService from '../../services/factory-service';

export default class DeniedIBMer extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   email: null
    // };
    utilService.setBaseURL();
    this.state = {
      baseURL: utilService.hostname,
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      agreementModalShow: false,
      termsModalShow: false,
      privacyModalShow: false,
      successModalShow: false,
      labelAgreement: <span>I agree to the  <Link href="#" onClick={this.agreementModalShowClick}>additional download statement</Link></span>,
      labelTerms: <span>I agree to the  <Link href="#" onClick={this.termsModalShowClick}>Software Downloads terms of service</Link></span>,
      labelPrivacy: <span>I agree to the  <Link href="#" onClick={this.privacyModalShowClick}>privacy consent</Link></span>,
      loader: false,
      checkAgreement: false,
      checkTerms: false,
      checkPrivacy: false,
      firstName: null,
      lastName: null,
      email: localStorage.getItem("userEmail"),
      confirmEmail: null,
      country: null,
      countryID: null,
      countryGeo: null,
      businessJustification: null,
      academicAmbassdor: "No",
      downloadAgreement: null,
      termsofservice: null,
      privacyconsent: null,
      invalidfirstName: false,
      invalidlastName: false,
      invalidemail: false,
      invalidconfirmEmail: false,
      invalidcountry: false,
      invalidbusinessJustification: false,
      invalidacademicAmbassdor: false,
      invaliddownloadAgreement: false,
      invalidtermsofservice: false,
      invalidprivacyconsent: false,
      confirm_email_msg: "Please re-enter your Email",
      data: [],
      dataFaq: localStorage.getItem('dataFAQ') != undefined ? localStorage.getItem('dataFAQ') : "https://www.ibm.com/academic/faqs/faqs",
      platfrmName: localStorage.getItem("ConfigPortalName") != undefined ? localStorage.getItem("ConfigPortalName") : "IBM SkillsBuild Software Downloads",
      accessTokn: factoryService.Xaccess,//process.env.REACT_APP_X_ACCESS,
    };
  }
  onChangeHandlerRadio = (event) => {
    this.setState({ academicAmbassdor: event });
  }
  onChangeHandlerSelect = (event) => {
    const { options, selectedIndex } = event.target;
    this.setState({ country: options[selectedIndex].label });
    this.setState({ countryID: event.target.value, countryGeo: options[selectedIndex].innerHTML });
    this.setState({ invalidcountry: false });
  }

  onChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    let namconcat = "invalid" + nam;
    this.setState({ [namconcat]: false });
  }
  handleSubmit = (event) => {
    event.preventDefault();
    let valid = true;
    if (!this.state.firstName) {
      this.setState({ invalidfirstName: true });
      valid = false;
    }
    if (!this.state.lastName) {
      this.setState({ invalidlastName: true });
      valid = false;
    }
    if (!this.state.email) {
      this.setState({ invalidemail: true });
      valid = false;
    }
    if (!this.state.confirmEmail) {
      this.setState({ confirm_email_msg: 'Please re-enter your Email' });
      this.setState({ invalidconfirmEmail: true });
      valid = false;
    }
    if (this.state.confirmEmail) {
      if (this.state.email !== this.state.confirmEmail) {
        this.setState({ confirm_email_msg: 'Entered email does not match' });
        this.setState({ invalidconfirmEmail: true });
        valid = false;
      }
    }

    if (!this.state.country) {
      this.setState({ invalidcountry: true });
      valid = false;
    }
    if (!this.state.businessJustification) {
      this.setState({ invalidbusinessJustification: true });
      valid = false;
    }

    if (!this.state.checkAgreement) {
      this.setState({ invaliddownloadAgreement: true });
      valid = false;
    }
    if (!this.state.checkTerms) {
      this.setState({ invalidtermsofservice: true });
      valid = false;
    }
    if (!this.state.checkPrivacy) {
      this.setState({ invalidprivacyconsent: true });
      valid = false;
    }
    if (valid) {
      let saveData = {
        academicAmbassador: this.state.academicAmbassdor,
        businessJustification: this.state.businessJustification,
        countryName: this.state.country,
        createdBy: this.state.email,
        downloadAgreement: this.state.checkAgreement,
        emailId: this.state.email,
        firstName: this.state.firstName,
        geoName: this.state.countryGeo,
        geoRepApprovalStatus: "In Progress",
        isoCtryCd: this.state.countryID,
        lastName: this.state.lastName,
        modifiedBy: this.state.email,

      }
      console.log("postData", saveData);
      this.setState({ showLoader: true });
      Axios.post(this.state.baseURL + '/a2mt/services/user-details/saveIbmerRequest', saveData, {
        headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
      }).then(rs => {
        console.log(rs.data); this.setState({ showLoader: false });
        if (rs.data.isIbmerFormDataSaved === true) { this.setState({ successModalShow: true }); }
        else { this.setState({ alertMsg: 'Save failed. Please try again later.', alertType: 'Warning', showAlert: true }); }
      })
        .catch((error) => {
          this.setState({ showLoader: false });
          this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
        });
    }

  }
  agreementModalShowClick = event => {
    event.preventDefault();
    this.setState({ agreementModalShow: true })
  }
  modalCloseAgreement = event => {
    event.preventDefault();
    this.setState({ agreementModalShow: false })
  };
  modalCloseSuccess = event => {
    event.preventDefault();
    this.setState({ successModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  successModalClick = event => {
    event.preventDefault();
    this.setState({ successModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  checkAgreementModalClick = event => {
    event.preventDefault();
    this.setState({ checkAgreement: true });
    this.setState({ agreementModalShow: false });
    this.setState({ invaliddownloadAgreement: false })
  };
  uncheckAgreementModalClick = event => {
    event.preventDefault();
    this.setState({ checkAgreement: false });
    this.setState({ agreementModalShow: false });
    this.setState({ invaliddownloadAgreement: true })
  };

  checkTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: true });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: false })
  };
  uncheckTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: false });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: true })
  };


  termsModalShowClick = event => {
    event.preventDefault();
    this.setState({ termsModalShow: true })
  }
  modalCloseTerms = event => {
    event.preventDefault();
    this.setState({ termsModalShow: false })
  };
  privacyModalShowClick = event => {
    event.preventDefault();
    this.setState({ privacyModalShow: true })
  }
  modalClosePrivacy = event => {
    event.preventDefault();
    this.setState({ privacyModalShow: false })
  };

  checkPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: true });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: false })
  };
  uncheckPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: false });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: true });
  };


  componentDidMount() {
    this.getCountry();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }
  getCountry() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getCountry', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(res => {
      this.setState({ showLoader: false }); console.log(res.data);
      if (res.data != null) { this.setState({ data: res.data }); } else { this.setState({ data: [] }) }
    }).catch((error) => { this.setState({ showLoader: false }); this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true }); });
  }

  render() {
    return (
      <div>
        <NormalHeader></NormalHeader>
        <section>
        <Theme theme="g10" >
          <Grid className="invalid-university-page" style={{minHeight:600+'px'}} >
            <Column lg={{ span: 9, offset: 3 }} md={8} sm={4}>
                <div className="textContentforInvalidRegis" style={{ marginBottom: '2rem' }}>

                  <p className="defaultPara customText">Find answers in our <a href={this.state.dataFaq} target="_blank" className="bx--link" rel="noopener noreferrer">frequently asked questions</a></p>
                  <p className="defaultPara customHeader">Thank you for your interest in {this.state.platfrmName}</p><br></br>
                  <p className="defaultPara customText">IBM Academic Ambassadors are allowed access to {this.state.platfrmName} with a valid business justification and exclusively to support educational initiatives at participating degree-granting accredited academic institutions. To request access, please complete and submit the form below and we will evaluate your request. You will be notified via email once your request is processed.</p>
                  <br></br> <br></br>
                  <p className="defaultPara customText">While you are waiting for access you will not be able to download software or courses due to licensing restrictions, but you are welcome other resources available on the site.</p>
                  <br></br> <br></br>
                  <p className="defaultPara customText"><span style={{ color: "red" }}>*</span>All fields provided below are mandatory</p>
                  <br></br> <br></br>
                  <form onSubmit={this.handleSubmit} noValidate>
                    <div style={{ marginBottom: '2rem' }}>
                      <div className="bx--grid--full-width">
                        <div className="bx--row">
                          <div className="bx--col" style={{ marginBottom: '2rem' }}><TextInput
                            helperText=""
                            id="firstname"
                            invalidText="Please enter your First Name"
                            invalid={this.state.invalidfirstName}
                            labelText="First Name"
                            placeholder="Enter first name"
                            name='firstName'
                            onChange={this.onChangeHandler}
                          /></div>
                          <div className="bx--col"><TextInput
                            helperText=""
                            id="test2"
                            name='lastName'
                            invalidText="Please enter your Last Name"
                            invalid={this.state.invalidlastName}
                            labelText="Last Name"
                            placeholder="Enter last name"
                            onChange={this.onChangeHandler}
                          /></div>

                        </div>
                      </div>
                    </div>

                    <div style={{ marginBottom: '2rem' }}>
                      <TextInput
                        helperText=""
                        id="test25"
                        invalidText="Please enter your Email"
                        labelText="Email"
                        name='email'
                        invalid={this.state.invalidemail}
                        defaultValue={this.state.email}
                        disabled="true"
                        placeholder="Placeholder text"
                        onChange={this.onChangeHandler}
                      />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <TextInput
                        helperText=""
                        id="test28"
                        invalidText={this.state.confirm_email_msg}
                        invalid={this.state.invalidconfirmEmail}
                        labelText="Confirm email"
                        name='confirmEmail'
                        placeholder="Re-enter email ID"
                        onChange={this.onChangeHandler}
                      />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        defaultValue="placeholder-item"
                        id="select-1"
                        invalidText="Please select a Country"
                        name='country'
                        invalid={this.state.invalidcountry}
                        labelText="Country"
                        onChange={this.onChangeHandlerSelect}
                      >

                        <SelectItem
                          disabled
                          hidden
                          value="placeholder-item"
                          text="Choose country"
                        />
                        {this.state.data.map((value) => <SelectItem text={value[2]} value={value[0]} label={value[1]}>{value[1]}</SelectItem>)}
                      </Select>
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <TextArea
                        cols={50}
                        helperText=""
                        id="test5"
                        name='businessJustification'
                        invalidText="Enter business justification"
                        invalid={this.state.invalidbusinessJustification}
                        labelText="Enter Business Justification"
                        placeholder="Enter Business Justification"
                        onChange={this.onChangeHandler}
                        rows={4}
                      />
                    </div>
                    <FormGroup
                      legendText="Are you an IBM SkillsBuild ambassador?"
                    >
                      <RadioButtonGroup orientation='vertical'
                        role="radiogroup"
                        defaultSelected="default-selected"
                        legend="Group Legend"
                        name="academicAmbassdor"
                        aria-label="Are you IBM SkillsBuild ambassador"
                        valueSelected="No"
                        onChange={this.onChangeHandlerRadio}
                      >
                        <RadioButton
                          id="radio-1"
                          labelText="Yes"
                          value="Yes"
                        />
                        <RadioButton
                          id="radio-2"
                          labelText="No"
                          value="No"
                        />

                      </RadioButtonGroup>
                    </FormGroup>
                    <fieldset className="bx--fieldset">
                      <legend className="bx--label">Please click on the links below to read and accept the program agreement and privacy statement</legend>
                      <Checkbox labelText={this.state.labelAgreement} id="checked" disabled checked={this.state.checkAgreement} /> {this.state.agreementModalShow && (
                        <div>
                          <AgreementModal hidden={this.modalCloseAgreement} check={this.checkAgreementModalClick} uncheck={this.uncheckAgreementModalClick} />
                        </div>
                      )}

                      <Checkbox labelText={this.state.labelTerms} id="checked-2" disabled checked={this.state.checkTerms} />{this.state.termsModalShow && (
                        <div>
                          <TermsModal hidden={this.modalCloseTerms} check={this.checkTermsModalClick} uncheck={this.uncheckTermsModalClick} />
                        </div>
                      )}

                      <Checkbox labelText={this.state.labelPrivacy} id="checked-3" disabled checked={this.state.checkPrivacy} />{this.state.privacyModalShow && (
                        <div>
                          <PrivacyModal hidden={this.modalClosePrivacy} check={this.checkPrivacyModalClick} uncheck={this.uncheckPrivacyModalClick} />
                        </div>
                      )}
                      {(this.state.invaliddownloadAgreement || this.state.invalidtermsofservice ||
                        this.state.invalidprivacyconsent) &&
                        <span className='error' style={{ color: '#da1e28' }}>Please agree on Terms of Service and Privacy Consent</span>}

                    </fieldset>
                    {this.state.successModalShow && (
                      <div>
                        <SuccessModal hidden={this.modalCloseSuccess} successok={this.successModalClick} />
                      </div>
                    )}

                    {this.state.loader && (
                      <div>
                        <Loading
                          description="Active loading indicator" withOverlay={true} active={true}
                        />
                      </div>
                    )}
                    <Button
                      kind="primary"
                      tabIndex={0}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </form>
                </div>
              </Column>
            </Grid>
            <br/><br/><br/>
            <Loading withOverlay={true} description="Active loading indicator" active={this.state.showLoader}></Loading>
            <Modal passiveModal={true} preventCloseOnClickOutside={false} open={this.state.showAlert} size="sm" onRequestClose={() => { this.setState({ showAlert: false }) }}>
              <div style={{ padding: "1em 0" }}>
                {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
                {this.state.alertType == 'Warning' && (<div><WarningAlt style={{ width: "7em", height: "7em", color: "orange" }}></WarningAlt></div>)}
                {this.state.alertType == 'Error' && (<div><MisuseOutline style={{ width: "7em", height: "7em", color: "red" }}></MisuseOutline></div>)}
                <div style={{ paddingLeft: "1em" }}>{this.state.alertMsg}</div>
              </div>
            </Modal>
          </Theme>
        </section>
      </div>
    )
  }
}

