import React, { Component } from 'react';
import {
  Grid,
  Column,
  Button,
  TableSelectRow,
  DataTable,
  TableSelectAll,
  TableToolbarSearch,
  TableToolbarContent,
  TableBatchAction,
  TableToolbar,
  TableBatchActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Modal,
  TextInput,
  Loading,
} from '@carbon/react';
import { Add, Delete, CheckmarkOutline } from '@carbon/icons-react';
import './admin-list.scss';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import utilService from '../../../services/util-service';

const adminProgramEmailRegex = RegExp(
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
);
let selectedRow = [];

export default class AdminList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseURL: utilService.hostname,
      emailId: localStorage.getItem('adminEmail'),
      showLoader: true,
      addAdminEmail: '',
      invalidEmailID: false,
      isSubmitDisabled: true,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      rowData: [],
      currentRole: '',
      activeActionBtn: false,
    };

    this.handleEvent = this.handleEvent.bind(this);
  }

  componentDidMount() {
    this.checkUserRole();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
    if (prevState.name !== this.state.name) {
      this.handler();
    }
  }
  checkUserRole() {
    setTimeout(() => {
      if (this.props.userRole != '') {
        this.setState(
          {
            currentRole: this.props.userRole,
          },
          () => {
            if (this.state.currentRole === 'SA') {
              this.setState({
                activeActionBtn: false,
              });
            } else {
              this.setState({
                activeActionBtn: true,
              });
            }
            this.getAdminList();
          }
        );
      } else {
        this.setState({
          activeActionBtn: true,
        });
        this.getAdminList();
      }
    }, 2000);
  }

  getAdminList = () => {
    this.setState({ showLoader: true });
    let url = this.state.baseURL + '/a2mt/services/user-details/admins';
    utilService
      .getData('/a2mt/services/user-details/admins')
      .then(res => {
        this.setState({ showLoader: false });
        console.log('Base-data>>>', res);
        if (res.data != null && typeof res.data != 'string') {
          this.setState({ rowData: res.data });
        } else if (typeof res.data == 'string') {
          this.setState({ rowData: [] });
          this.setState({
            alertMsg: res.data,
            alertType: 'Error',
            showAlert: true,
          });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  };
  addPopup = event => {
    this.setState({ isAddAdminOverlayVisible: true });
  };
  resetForm() {
    //console.log(this.state.addAdminEmail)
    this.setState({
      addAdminEmail: '',
      invalidEmailID: false,
      isSubmitDisabled: true,
    });
  }
  addAdminSubmit = event => {
    let addFormValid = true;
    let postData = {
      adminEmail: this.state.addAdminEmail,
      createdBy: this.state.emailId,
    };
    this.setState({ showLoader: true });
    if (this.state.addAdminEmail == null) {
      this.setState({ invalidEmailID: true });
      addFormValid = false;
    }
    if (addFormValid) {
      utilService
        .postData('/a2mt/services/user-details/addAdmin', postData)
        .then(rs => {
          console.log(rs.data);
          this.setState({ showLoader: false });
          if (rs.data.success === true) {
            this.setState({
              alertMsg: 'Admin is successfully added.',
              alertType: 'Success',
              showAlert: true,
              isAddAdminOverlayVisible: false,
            });
            this.getAdminList();
            this.resetForm();
          } else if (rs.data.success === false) {
            this.setState({
              alertMsg: 'Admin already exists.',
              alertType: 'Warning',
              showAlert: true,
            });
            this.resetForm();
          }
        })
        .catch(error => {
          this.setState({ showLoader: false });
          this.resetForm();
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  };
  onTextChange = event => {
    let id = event.target.id,
      vl = event.target.value; //console.log('onTextChange', id, vl);
    if (id == 'addAdmin' && vl.trim() !== '') {
      adminProgramEmailRegex.test(vl)
        ? this.setState({
            addAdminEmail: vl.trim(),
            invalidEmailID: false,
            isSubmitDisabled: false,
          })
        : this.setState({
            addAdminEmail: null,
            invalidEmailID: true,
            isSubmitDisabled: true,
          });
    }
  };
  confirmAdminListDelete = event => {
    this.setState({ isDeleteAdminOverlay: true });
  };
  closeAllOverlays = event => {
    this.setState({
      isAddAdminOverlayVisible: false,
      isDeleteAdminOverlay: false,
    });
  };
  deleteAdminList = event => {
    this.setState({ isDeleteAdminOverlay: false });
    this.setState({ showLoader: true });
    let postData = { ids: selectedRow };

    if (selectedRow.length > 0) {
      utilService
        .postData('/a2mt/services/user-details/deleteAdmin', postData)
        .then(rs => {
          //console.log(rs.data);
          this.setState({ showLoader: false });
          if (rs.data.success === true) {
            this.setState({
              alertMsg: 'Admin is successfully deleted.',
              alertType: 'Success',
              showAlert: true,
              isAddAdminOverlayVisible: false,
            });
            selectedRow = [];
            this.setState({ rowData: [] }, () => {
              this.getAdminList();
            });
          } else if (rs.data.success === false) {
            this.setState({
              alertMsg: 'Admin not deleted.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
        })
        .catch(error => {
          this.setState({ showLoader: false });
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  };

  // Prototype methods, Bind in Constructor (ES2015)
  handleEvent() {}

  // Class Properties (Stage 3 Proposal)
  handler = () => {
    this.setState();
  };

  tempAdminSelection(evt) {
    //console.log(evt)
    if (!evt.isSelected) {
      selectedRow.push(evt.id);
    } else if (evt.isSelected) {
      if (selectedRow.indexOf(evt.id) > -1) {
        selectedRow.splice(selectedRow.indexOf(evt.id), 1);
      }
    }
    console.log(selectedRow);
  }
  render() {
    const headerData = [
      { header: 'Email', key: 'adminEmail' },
      { header: 'Role', key: 'adminType' },
    ];
    return (
      <div>
        <Grid className="admin-sub-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
              <div style={{ paddingBottom: '32px' }}>
                <h3>List of admins</h3>
              </div>
              <section>
                {this.state.rowData && this.state.rowData.length !== 0 && (
                  <DataTable
                    rows={this.state.rowData}
                    headers={headerData}
                    selectedRows={selectedRow}>
                    {({
                      rows,
                      headers,
                      getHeaderProps,
                      getRowProps,
                      getSelectionProps,
                      getToolbarProps,
                      getBatchActionProps,
                      onInputChange,
                      getTableProps,
                      getTableContainerProps,
                    }) => (
                      <TableContainer {...getTableContainerProps()}>
                        <TableToolbar {...getToolbarProps()}>
                          <TableBatchActions {...getBatchActionProps()}>
                            <TableBatchAction
                              tabIndex={
                                getBatchActionProps().shouldShowBatchActions
                                  ? 0
                                  : -1
                              }
                              renderIcon={Delete}
                              onClick={this.confirmAdminListDelete}>
                              Delete
                            </TableBatchAction>
                          </TableBatchActions>

                          <TableToolbarContent>
                            <TableToolbarSearch onChange={onInputChange} />
                            <Button
                              renderIcon={Add}
                              onClick={this.addPopup}
                              disabled={this.state.activeActionBtn}
                              id="add-btn">
                              Add new
                            </Button>
                          </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()}>
                          <TableHead>
                            <TableRow>
                              <TableSelectAll
                                {...getSelectionProps()}
                                disabled={true}
                              />
                              {headers.map((header, i) => (
                                <TableHeader
                                  key={i}
                                  {...getHeaderProps({ header })}>
                                  {header.header}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row, i) => (
                              <TableRow key={i} {...getRowProps({ row })}>
                                {this.state.currentRole === 'SA' ? (
                                  <TableSelectRow
                                    {...getSelectionProps({
                                      row,
                                      onClick: () =>
                                        this.tempAdminSelection(row),
                                    })}
                                    disabled={
                                      this.state.rowData[i].adminType === 'SA'
                                        ? true
                                        : false
                                    }
                                  />
                                ) : (
                                  <TableSelectRow
                                    {...getSelectionProps({
                                      row,
                                      onClick: () =>
                                        this.tempAdminSelection(row),
                                    })}
                                    disabled={true}
                                  />
                                )}

                                {row.cells.map(cell => (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </DataTable>
                )}
              </section>
              <Loading
                withOverlay={true}
                description="Active loading indicator"
                active={this.state.showLoader}
              />
              <Modal
                hasForm
                open={this.state.isAddAdminOverlayVisible}
                primaryButtonText="Apply"
                secondaryButtonText="Cancel"
                modalHeading="Add new"
                primaryButtonDisabled={this.state.isSubmitDisabled}
                onRequestClose={() => {
                  this.closeAllOverlays();
                  this.resetForm();
                }}
                onRequestSubmit={this.addAdminSubmit}>
                <div style={{ margin: '1em 0' }}>
                  <TextInput
                    id="addAdmin"
                    labelText="Enter email address"
                    value={this.state.addAdminEmail}
                    onChange={this.onTextChange}
                    invalid={this.state.invalidEmailID}
                    invalidText="Please enter valid support email"
                    placeholder="Enter email address"
                  />
                </div>
              </Modal>
              <Modal
                passiveModal={true}
                preventCloseOnClickOutside={false}
                open={this.state.showAlert}
                size="sm"
                onRequestClose={() => {
                  this.setState({ showAlert: false });
                }}>
                <div style={{ padding: '1em 0' }}>
                  {this.state.alertType == 'Success' && (
                    <div>
                      <CheckmarkOutline
                        style={{ width: '7em', height: '7em', color: 'green' }}
                      />
                    </div>
                  )}
                  {this.state.alertType == 'Warning' && (
                    <div>
                      <WarningAlt
                        style={{ width: '7em', height: '7em', color: 'orange' }}
                      />
                    </div>
                  )}
                  {this.state.alertType == 'Error' && (
                    <div>
                      <MisuseOutline
                        style={{ width: '7em', height: '7em', color: 'red' }}
                      />
                    </div>
                  )}
                  <div style={{ paddingLeft: '1em' }}>
                    {this.state.alertMsg}
                  </div>
                </div>
              </Modal>
              <Modal
                hasForm
                open={this.state.isDeleteAdminOverlay}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                primaryButtonDisabled={false}
                onRequestClose={() => {
                  this.closeAllOverlays();
                }}
                onRequestSubmit={this.deleteAdminList}>
                <p>Are you sure for the selected Admin ?</p>
              </Modal>
            </div>
          </Column>
        </Grid>
      </div>
    );
  }
}
