import { Button, TextInput, Loading, Modal, Grid, Column } from '@carbon/react';
import { CheckmarkOutline } from '@carbon/react/icons';
import { Warning_02, DoNot } from '@carbon/pictograms-react';
import React from 'react';
import axios from 'axios';
import utilService from '../../services/util-service';
import Cookies from 'universal-cookie';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import factoryService from '../../services/factory-service';

const cookies = new Cookies();
const validEmailRegex = RegExp(/^([a-zA-Z0-9_\.\-\'])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/);

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

export default class EmailAuth extends React.Component {
  constructor(props) {
    super(props);
    utilService.setBaseURL();
    this.state = {
      baseURL: utilService.hostname,
      email: null,
      dataFaq: null,
      showLoader: false,
      showModal: false,
      showActiveModal: false,
      showResendEmail: false,
      customMsg: '',
      alertType: 'Success',
      programData: null,
      supportEmail: null,
      errors: {
        email: '',
      },
      validEmail: false,
      accessTokn: factoryService.Xaccess, //process.env.REACT_APP_X_ACCESS
    };
  }

  CloseModal = event => {
    this.setState({ showModal: false, showActiveModal: false, showResendEmail: false });
  }

  resendEmail = event => {
    this.CloseModal(); this.setState({ showLoader: true }); let user_details = { universityEmail: this.state.email.toString().toLowerCase() };
    axios.post(this.state.baseURL + '/a2mt/services/user-details/resendEmailtoUser', user_details, {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(res => {
      this.setState({ showLoader: false });
      if (res.data.success == true) {
        this.setState({ customMsg: "<span style='font-size: 1.5em;'>An email has been sent to " + this.state.email.toString().toLowerCase() + ".</span><br></br> Please click on the validation link in that email to complete the recertification process.", alertType: 'Success', showModal: true })
      }
      else { this.setState({ customMsg: 'Something went wrong. Please try again.', alertType: 'Error', showModal: true }); }
    })
      .catch(error => { this.setState({ showLoader: false }); this.setState({ customMsg: 'Something went wrong. Please try again.', alertType: 'Error', showModal: true }); });
  }

  recertifyRedirect = event => {
    this.CloseModal();
    window.location = '/a2mt/register-edit';
    utilService.setOtherPageAccessible(true);
  }

  async componentDidMount() {
    if (typeof cookies.get('Jwtauth') !== 'undefined') {
      cookies.remove('Jwtauth');
      cookies.remove('rfrT');
    }
    sessionStorage.removeItem('isOtherPageAccessible');
    sessionStorage.removeItem('redirectHome');
    utilService.setOtherPageAccessible(false);
    this.setState({ showLoader: true });
    if (typeof cookies.get('AppAccess') !== 'string') {
      setTimeout(() => { this.initStatus(cookies.get('AppAccess')); }, 3000);
    } else {
      this.initStatus(cookies.get('AppAccess'));
    }
  }

  initStatus = (token) => {
    axios.get(this.state.baseURL + '/a2mt/services/user-details/getConfigDet', {
      headers: { "Content-Type": "application/json", "X-access": token }
    }).then(res => {
      this.setState({ showLoader: false });
      if (typeof res.data !== 'undefined' && res.data !== null) {
        console.log('configData', res.data);
        localStorage.setItem('ConfigPortalName', res.data.portalName);
        this.setState({
          programData: res.data,
          supportEmail: res.data.emailId
        });
        axios.get(this.state.baseURL + "/a2mt/services/user-details/getFAQ", {
          headers: { "Content-Type": "application/json", "X-access": token }
        }).then((result) => {
          console.log('faqData', result.data);
          if (result.data.faq != "" || result.data.faq != null) {
            this.setState({ dataFaq: result.data.faq });
            localStorage.setItem('dataFAQ', result.data.faq);
          }
        });
      }
    })
      .catch(error => { this.setState({ showLoader: false }); this.setState({ customMsg: 'Something went wrong. Please try again.', alertType: 'Error', showModal: true }); });
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'email':
        errors.email =
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        this.setState({ validEmail: value.trim() == '' ? false : true });
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(this.state.errors) && this.state.validEmail) {
      console.info('Valid Form');
      this.setState({ showLoader: true });
      if (this.state.email.includes('ibm.com')) {
        localStorage.setItem('university_email', this.state.email.toString().toLowerCase());
        axios.get(this.state.baseURL + '/a2mt/services/user-details/chkDomainExistance/' + this.state.email.toString().toLowerCase(), {
          headers: { "Content-Type": "application/json", "X-access": cookies.get('AppAccess') }
        }).then(res => {
          this.setState({ showLoader: false });
          console.log('res', res.data);
          if (res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == "rejected" || res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == "inprogress" || res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == "in progress") {
            if (res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == "rejected") {
              this.setState({ customMsg: "Your access request is rejected", alertType: 'Warning', showModal: true });
            } else if (res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == "inprogress" || res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == "in progress") {
              this.setState({ customMsg: "Your access request is in progress", alertType: 'Success', showModal: true });
            }
          } else if (res.data.isNewIbmer == true && res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == "blank") {
            localStorage.setItem("userEmail", this.state.email.toString().toLowerCase());
            utilService.setOtherPageAccessible(true);
            window.location = "/a2mt/denied-ibmer";
          } else {
            this.mainCheck(this.state.email.toString().toLowerCase());
          }
        })
          .catch(error => {
            this.setState({ showLoader: false });
            this.setState({ customMsg: 'Something went wrong. Please try again.', alertType: 'Error', showModal: true });
          });
      } else {
        this.mainCheck(this.state.email.toString().toLowerCase());
      }

    } else {
      console.error('Invalid Form');
      if (!this.state.validEmail) { this.setState({ errors: { email: 'Please enter a value!' } }) }
    }
  }

  mainCheck = (university_emailid) => {
    this.setState({ showLoader: true });
    axios.post(this.state.baseURL + '/a2mt/services/user-details/user', university_emailid, {
      headers: { "Content-Type": "application/json", "X-access": cookies.get('AppAccess') }
    }).then(res => {
      this.setState({ showLoader: false }); console.log('result', res.data, res.headers);
      if (typeof (res.data) != 'undefined' && res.data != null) {
        const result = factoryService.decodeData(res,university_emailid);

        if (typeof result.dplPassed !== 'undefined' && result.dplPassed.toLowerCase() === 'fail') {
          this.setState({ customMsg: "<span style='font-size: 1.5em;'>Error completing login.</span><br></br> There has been an error completing your login, please try again later.", alertType: 'Error', showModal: true });
        }
        else if (typeof result.isBlackListedDomain !== 'undefined' && result.isBlackListedDomain === true) {
          let dom = university_emailid.split('@');
          this.setState({ customMsg: "<span style='font-size: 1.5em;'>Thank you for your interest.</span><br></br> At this time we are not accepting registrations from this email domain (<span style='color:blue'>" + dom[1] + "</span>)", alertType: 'Warning', showModal: true });
        }
        else if (typeof result.isBlackListedCountry !== 'undefined' && result.isBlackListedCountry === true) {
          this.setState({ customMsg: "<span style='font-size: 1.5em;'>Thank you for your interest in IBM SkillsBuild Software Downloads.</span><br></br> At this time we are not accepting registrations or logins from your geography.", alertType: 'Warning', showModal: true });
        }
        else if (result.isAdmin === true) {
          localStorage.setItem('ConfigPortalName', result.configDetails["portalName"]);
          localStorage.setItem("userEmail", university_emailid);
          window.location.href = result.redirectURL;
        }
        else if (result.isDomainExists === false) {
          localStorage.setItem('ConfigPortalName', result.configDetails["portalName"]);
          localStorage.setItem("userEmail", university_emailid);
          utilService.setOtherPageAccessible(true);
          window.location = "/a2mt/invalid-university";
        }
        else if (result.isDomainExists === true && result.isUserExists === false) {
          localStorage.setItem('ConfigPortalName', result.configDetails["portalName"]);
          localStorage.setItem("userEmail", university_emailid);
          localStorage.setItem('ConfigInst_id', result.instn_id);
          localStorage.setItem('ConfigMasterDetails', JSON.stringify(result.MasterDetails));
          utilService.setOtherPageAccessible(true);
          window.location = "/a2mt/register";
        }
        else if (result.isDomainExists === true && result.isUserExists === true && result.isIBMIDExists === false) {
          localStorage.setItem('userEmail', university_emailid);
          localStorage.setItem('ConfigPortalName', result.configDetails["portalName"]);
          localStorage.setItem('ConfigInst_id', result.instn_id);
          window.location.href = result.redirectURL;
        }
        else if (result.isDomainExists === true && result.isUserExists === true && result.isIBMIDExists === true) {
          localStorage.setItem('ConfigPortalName', result.configDetails["portalName"]);
          localStorage.setItem("userEmail", university_emailid);
          localStorage.setItem('ConfigInst_id', result.instn_id);
          localStorage.setItem('ConfigUserDet', JSON.stringify(result.userDetails));
          localStorage.setItem('ConfigMasterDetails', JSON.stringify(result.MasterDetails));
          localStorage.setItem('ConfigDays', result.days);
          if (result.reValidate) {
            this.setState({ customMsg: "<span style='font-size: 1.5em;'>Your access needs to be revalidated.</span><br></br> Please click on the validation link in the email sent to you to complete the recertification process. If you did not receive the email please click on resend.", alertType: 'Warning', showResendEmail: true });
          }
          else if (result.recertCountOverFlow) {
            this.setState({ customMsg: "<span style='font-size: 1.5em;'>You have exceeded the limit of recertification.</span>", alertType: 'Warning', showModal: true });
          }
          else if (result.days > 0 && result.days <= 10) {
            window.location.href = result.redirectURL;
          }
          else if (result.days <= 0) {
            window.location.href = result.redirectURL;
          }
        }
      }
    })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({ customMsg: 'Something went wrong. Please try again.', alertType: 'Error', showModal: true });
      });
  }

  render() {
    const { errors } = this.state;
    return (
      <div>
        <NormalHeader></NormalHeader>
        <Grid condensed>
          <Column lg={5} md={8} sm={4}><div width="100%" className="bgImg1"></div></Column>
          <Column lg={{ span: 8, offset: 6 }} md={{ span: 6, offset: 1 }} sm={4} style={{ padding: "6em 3em 0 0" }}>
            <span className="textContent">
              <form onSubmit={this.handleSubmit} noValidate>
                <h3>Enter your academic institution issued email to begin</h3><br></br> <br></br>
                <p className="defaultPara customText">Only the students and faculty of participating academic institutions are eligible to access this website. Please enter your academic institution issued email below to register.</p>
                <br></br> <br></br>
                <TextInput labelText="Your academic institution issued email" id="univ_email" invalid={this.state.errors.email !== '' ? true : false}
                  type='email' name='email' onChange={this.handleChange} noValidate></TextInput>{errors.email.length > 0 && <span className='error'>{errors.email}</span>}
                <br></br> <br></br>
                <p className="defaultPara customText">Find answers in our <a target="_blank" href={this.state.dataFaq} className="bx--link" rel="noopener noreferrer">frequently asked questions</a></p>
                <br></br> <br></br>
                <Button type="submit">Submit</Button><br></br><br></br>
              </form>
            </span>
          </Column>
        </Grid>
        <Loading withOverlay={true} description="Active loading indicator" active={this.state.showLoader}></Loading>

        <Modal size="sm" open={this.state.showModal} passiveModal={true} onRequestClose={this.CloseModal}>
          <div style={{ padding: "1em 0" }}>
            {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
            {this.state.alertType == 'Warning' && (<div><Warning_02 style={{ width: "7em", height: "7em", color: "orange" }}></Warning_02></div>)}
            {this.state.alertType == 'Error' && (<div><DoNot style={{ width: "7em", height: "7em", color: "red" }}></DoNot></div>)}
            <div style={{ paddingLeft: "1em" }}><p dangerouslySetInnerHTML={{ __html: this.state.customMsg }}></p></div>
          </div>
        </Modal>

        <Modal preventCloseOnClickOutside={false} open={this.state.showActiveModal} size="sm" onRequestClose={this.CloseModal} primaryButtonText="Recertify"
          secondaryButtonText="Cancel" onSecondarySubmit={this.CloseModal} onRequestSubmit={this.recertifyRedirect}>
          <div style={{ padding: "1em 0" }}>
            {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
            {this.state.alertType == 'Warning' && (<div><Warning_02 style={{ width: "7em", height: "7em", color: "orange" }}></Warning_02></div>)}
            {this.state.alertType == 'Error' && (<div><DoNot style={{ width: "7em", height: "7em", color: "red" }}></DoNot></div>)}
            <div style={{ paddingLeft: "1em" }}><p dangerouslySetInnerHTML={{ __html: this.state.customMsg }}></p></div>
          </div>
        </Modal>

        <Modal preventCloseOnClickOutside={false} open={this.state.showResendEmail} size="sm" onRequestClose={this.CloseModal} primaryButtonText="Resend"
          secondaryButtonText="Cancel" onSecondarySubmit={this.CloseModal} onRequestSubmit={this.resendEmail}>
          <div style={{ padding: "1em 0" }}>
            {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
            {this.state.alertType == 'Warning' && (<div><Warning_02 style={{ width: "7em", height: "7em", color: "orange" }}></Warning_02></div>)}
            {this.state.alertType == 'Error' && (<div><DoNot style={{ width: "7em", height: "7em", color: "red" }}></DoNot></div>)}
            <div style={{ paddingLeft: "1em" }}><p dangerouslySetInnerHTML={{ __html: this.state.customMsg }}></p></div>
          </div>
        </Modal>
      </div>
    )
  }
}

