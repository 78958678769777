import {
  Theme,
  Grid,
  Column,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  Loading,
  Modal,
} from '@carbon/react';
import { Link } from 'react-router-dom';
import {
  Help,
  CheckmarkOutline,
  WarningAlt,
  MisuseOutline,
  UserAvatar,
  Search,
} from '@carbon/react/icons';
import React, { useState, useEffect } from 'react';
import factoryService from '../../services/factory-service';
import axios from 'axios';
import './register-email-matched.scss';
import utilService from '../../services/util-service';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const RegisterEmailidMatched = () => {
  //utilService.setBaseURL();
  //const [ baseURL, setBaseURL ] = useState('https://'+window.location.host);
  //const [ baseURL, setBaseURL ] = useState('https://a2mt-develop2.968d96a4.public.multi-containers.ibm.com');
  const [showLoader, setShowLoader] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('Test');
  const [alertType, setAlertType] = useState('Success');
  const [university_emailid, setUniversity_emailid] = useState(
    localStorage.getItem('userEmail')
  );
  //const [ university_emailid, setUniversity_emailid ] = useState('a2mtfaculty@mailinator.com');
  const [portalName, setPortalName] = useState(
    localStorage.getItem('ConfigPortalName')
  );
  const [userExists, setUserExists] = useState(false);
  const [notExist, setNotExist] = useState(false);
  const [daysLeft, setDaysLeft] = useState(localStorage.getItem('ConfigDays'));
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [customMsg, setCustomMsg] = useState('');
  //const [ accessTokn, setAccessTokn ] = useState(process.env.REACT_APP_X_ACCESS);

  useEffect(() => {
    init();
  }, []);

  function init() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
    if (localStorage.getItem('ConfigDays') !== null && daysLeft <= 0) {
      setCustomMsg(
        "<span style='font-size: 1.5em;'>Your access to " +
          portalName +
          " has expired.</span><br></br> Please click on recertify to update your profile and reactivate your access. <br></br><span style='font-size: 0.7em;'>(You will be automatically redirected to recertify page in 30 seconds)</span>"
      );
      setAlertType('Warning');
      setShowActiveModal(true);
      startRedirectCountdown();
    } else if (
      localStorage.getItem('ConfigDays') !== null &&
      daysLeft > 0 &&
      daysLeft <= 10
    ) {
      setCustomMsg(
        "<span style='font-size: 1.5em;'>Your access to " +
          portalName +
          ' will expire in ' +
          daysLeft +
          " days.</span><br></br> Please click on the recertify button below to update your profile and extend your access. Click on the cancel button to do this another time. <br></br><span style='font-size: 0.7em;'>(You will be automatically redirected to recertify page in 30 seconds)</span>"
      );
      setAlertType('Warning');
      setShowActiveModal(true);
      startRedirectCountdown();
    } else {
      loginSuccessCheck();
    }
  }

  function loginSuccessCheck() {
    let redirectionURL = factoryService.source_a2mt + 'user-check';
    if (typeof cookies.get('redirectTo') != 'undefined') {
      redirectionURL = cookies.get('redirectTo');
    }
    const updateRegDate = async () => {
      const res = await factoryService.updRegDateAftURX(
        university_emailid,
        redirectionURL
      );
      console.log('res:::: ', res);
      if (typeof res.data !== 'undefined' && res.data !== '') {
        const result = res.data;
        setShowLoader(false);
        if (result.success === true) {
          if (result.isStudentFaculty === true) {
            if (result.redirectURL != '') {
              if (result.userExists) {
                setUserExists(true);
              } else {
                setNotExist(true);
              }
              setTimeout(() => {
                window.location.href = result.redirectURL;
              }, 2000);
            } else {
              setShowLoader(false);
              setShowAlert(true);
              setAlertType('Error');
              setAlertMsg(
                'Redirection failed. Please try logging in after sometime.'
              );
            }
          } else if (
            result.isSuperAdmin === true ||
            result.isAdmin === true ||
            result.isSKAdmin === true ||
            result.isSPSSAdmin === true
          ) {
            localStorage.setItem('adminEmail', university_emailid);
            localStorage.setItem('isSuperAdmin', result.isSuperAdmin);
            localStorage.setItem('isAdmin', result.isAdmin);
            localStorage.setItem('isSKAdmin', result.isSKAdmin);
            localStorage.setItem('isSPSSAdmin', result.isSPSSAdmin);
            localStorage.setItem('userName', result.userName);
            setUserExists(false);
            setNotExist(true);
            utilService.setOtherPageAccessible(true);
            setTimeout(() => {
              window.location = '/a2mt/admin-dashboard';
            }, 2000);
          }
        } else {
          setShowLoader(false);
          setShowAlert(true);
          setAlertType('Error');
          setAlertMsg('Something went wrong. Please try again from the start.');
        }
      } else {
        window.location = '/a2mt/register-email-not-matched';
      }
    };
    updateRegDate();
  }

  const CloseModal = event => {
    setShowActiveModal(false);
  };

  const CancelPrompt = event => {
    CloseModal();
    if (
      localStorage.getItem('ConfigDays') !== null &&
      daysLeft > 0 &&
      daysLeft <= 10
    ) {
      loginSuccessCheck();
    } else {
      sessionStorage.removeItem('isOtherPageAccessible');
      utilService.setOtherPageAccessible(false);
      window.location = '/a2mt/';
    }
  };

  const recertifyRedirect = event => {
    CloseModal();
    utilService.setOtherPageAccessible(true);
    window.location = '/a2mt/register-edit';
  };

  function startRedirectCountdown() {
    setTimeout(() => {
      recertifyRedirect();
    }, 30 * 1000);
  }

  return (
    <div>
      <HeaderContainer
        render={({ isSideNavExpanded, onClickSideNavExpand }) => (
          <Theme theme="g100">
            <Header aria-label="IBM SkillsBuild Software Downloads">
              <SkipToContent />
              <HeaderMenuButton
                aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'}
                onClick={onClickSideNavExpand}
                isActive={isSideNavExpanded}
                aria-expanded={isSideNavExpanded}
              />
              <HeaderName as={Link} to="/" prefix="IBM">
                SkillsBuild Software Downloads
              </HeaderName>
              <HeaderGlobalBar>
                <HeaderGlobalAction aria-label="Search">
                  <Search size={20} />
                </HeaderGlobalAction>
                <HeaderGlobalAction aria-label="Help">
                  <Help size={20} />
                </HeaderGlobalAction>
                <HeaderGlobalAction
                  aria-label="User Profile"
                  tooltipAlignment="end">
                  <UserAvatar size={20} />
                </HeaderGlobalAction>
              </HeaderGlobalBar>
            </Header>
          </Theme>
        )}
      />
      <Theme theme="g10">
        <Grid className="landing-page" style={{ height: 600 + 'px' }}>
          <Column lg={{ span: 12, offset: 2 }} md={8} sm={4}>
            {notExist && (
              <div>
                <br /> <br />
                <br /> <br />
                <h1>
                  Congratulation You have successfully signed in with the
                  registered IBM ID.
                </h1>
                <p style={{ paddingTop: '2em' }}>
                  Your ID will be used for login to {portalName}. You will be
                  redirected to the {portalName} website now.
                </p>
              </div>
            )}

            {userExists && (
              <div>
                <br /> <br />
                <br /> <br />
                <h1>You have successfully signed in with the IBM ID.</h1>
                <p style={{ paddingTop: '2em' }}>
                  Your ID will be used for login to {portalName}. You will be
                  redirected to the {portalName} website now.
                </p>
              </div>
            )}
          </Column>
        </Grid>
      </Theme>

      <Modal
        className="mymodal"
        passiveModal={true}
        preventCloseOnClickOutside={false}
        open={showAlert}
        size="sm"
        onRequestClose={() => {
          setShowAlert(false);
        }}>
        <div style={{ padding: '1em 0' }}>
          {alertType == 'Success' && (
            <div>
              <CheckmarkOutline
                style={{ width: '7em', height: '7em', color: 'green' }}
              />
            </div>
          )}
          {alertType == 'Warning' && (
            <div>
              <WarningAlt
                style={{ width: '7em', height: '7em', color: 'orange' }}
              />
            </div>
          )}
          {alertType == 'Error' && (
            <div>
              <MisuseOutline
                style={{ width: '7em', height: '7em', color: 'red' }}
              />
            </div>
          )}
          <div style={{ paddingLeft: '1em' }}>{alertMsg}</div>
        </div>
      </Modal>

      <Modal
        className="mymodal"
        preventCloseOnClickOutside={false}
        open={showActiveModal}
        size="sm"
        onRequestClose={CancelPrompt}
        primaryButtonText="Recertify"
        secondaryButtonText="Cancel"
        onSecondarySubmit={CancelPrompt}
        onRequestSubmit={recertifyRedirect}>
        <div style={{ padding: '1em 0' }}>
          {alertType == 'Success' && (
            <div>
              <CheckmarkOutline
                style={{ width: '7em', height: '7em', color: 'green' }}
              />
            </div>
          )}
          {alertType == 'Warning' && (
            <div>
              <WarningAlt
                style={{ width: '7em', height: '7em', color: 'orange' }}
              />
            </div>
          )}
          {alertType == 'Error' && (
            <div>
              <MisuseOutline
                style={{ width: '7em', height: '7em', color: 'red' }}
              />
            </div>
          )}
          <div style={{ paddingLeft: '1em' }}>
            <p dangerouslySetInnerHTML={{ __html: customMsg }} />
          </div>
        </div>
      </Modal>

      <Loading
        withOverlay={true}
        description="Active loading indicator"
        active={showLoader}
      />
    </div>
  );
};
export default RegisterEmailidMatched;
