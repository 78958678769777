import React from 'react';
import {
    Grid,
    Column,
    Theme,
    Modal,
    TextInput,
    TextArea,
    Button,
    DatePickerInput,
    DatePicker,
    NumberInput,
    Loading
} from '@carbon/react';
import { ArrowRight } from '@carbon/icons-react';
import queryString from 'query-string';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import AsyncImage from './AsyncImage';
import { CheckmarkOutline } from '@carbon/react/icons';
import { Warning_02, DoNot } from '@carbon/pictograms-react';
import utilService from '../../services/util-service';
import factoryService from '../../services/factory-service';

export class LecturesRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: true,
            usrID: 0,
            userEmail: '',
            userUniversity: '',
            userCntryCode: '',
            showLoader: false,
            showAlert: false,
            alertType: 'Success',
            alertMsg: 'Test',
            questionnaireData: [],
            questionsData: [],
            startDate: '',
            endDate: '',
            lectureRequest: {},
            lectureData: "",
            ambasdrEmail: "",
            glName: "",
            gLTitle: "",
            lectureId: "",
            answerValues: [],
            minStartDate: 'mm/dd/yyyy',
            minEndDate: 'mm/dd/yyyy',
            comment_chars: 0,
            updatedCount: 0,
            userFollowupDate: '',
            showThankyou: false
        };
        this.onDateSelect = this.onDateSelect.bind(this);
        this.onNumberChange = this.onNumberChange.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.onRequestSubmit = this.onRequestSubmit.bind(this);
        this.closeAlert = this.closeAlert.bind(this);
    }

    async componentDidMount() {
       await this.getUserValues();
    }

    async getUserValues() {
        let session_val=await factoryService.getGenSession('academics')
        let params = queryString.parse(window.location.search);
        console.log('paramsGetter', params);
        this.setState({ showLoader: true });
        if (typeof params['lecId'] !== 'undefined') {
            if (sessionStorage.getItem('userEm') !== 'undefined' && sessionStorage.getItem('userEm') !== null) {
                await factoryService.getJWT(sessionStorage.getItem('userEm'));
            }
            const value = await factoryService.getGuestLectureWithId(params['lecId']);
            const indiData = factoryService.decodeData(value, 'gupai@us.ibm.com');
            this.setState({ showLoader: false });
            console.log('individual Data', indiData);
            this.setState({
                lectureRequest: indiData, lectureData: indiData.travelDeliveryOptions, ambasdrEmail: indiData.email,
                glName: indiData.guestLecturerName, lectureId: indiData.guestLectureId, gLTitle: indiData.abstracts[0].guestLectureTitle,
                userEmail: sessionStorage.getItem('userEm')
            });
            this.questionnaireLoad();
        }
        try{
        let v= await factoryService.getUserDetails(session_val)
            if (v != null && v != 'undefined') {
                let details = factoryService.decodeData(v,session_val);
                console.log('details', details);
                if (details.email != null) {
                    this.setState({
                        usrID: String(details.id),
                        userUniversity: details.universityName,
                    });
                  let resp= await factoryService.getInsttnDetails(details.insttnId)
                            console.log(
                                'User Country Details',
                                resp,
                                resp.isoCtrycd
                            );
                            if (typeof resp.isoCtrycd !== 'undefined') {
                                this.setState({ userCntryCode: resp.isoCtrycd });
                            }
                
                }
            }
        }catch(err) {
            this.setState({ showLoader: false });
            console.log('user details error:', err);
            this.setState({
                alertMsg:
                    'Something went wrong! Please try refreshing the page or logging back again.',
                alertType: 'Error',
                showAlert: true,
            });
        };


        /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
        await factoryService.getUserDetails(session_val).then(async(v) => {
            if (v != null && v != 'undefined') {
                let details = factoryService.decodeData(v,session_val);
                console.log('details', details);
                if (details.email != null) {
                    this.setState({
                        usrID: String(details.id),
                        userUniversity: details.universityName,
                    });
                   await factoryService.getInsttnDetails(details.insttnId)
                        .then(res => {
                            console.log(
                                'User Country Details',
                                res,
                                res.isoCtrycd
                            );
                            if (typeof res.isoCtrycd !== 'undefined') {
                                this.setState({ userCntryCode: res.isoCtrycd });
                            }
                        }).catch(err => {
                            this.setState({ showLoader: false });
                            console.log('user details error:', err);
                            this.setState({
                                alertMsg:
                                    'Something went wrong! Please try refreshing the page or logging back again.',
                                alertType: 'Error',
                                showAlert: true,
                            });

                        });
                
                }
            }
        }).catch(err => {
            this.setState({ showLoader: false });
            console.log('user details error:', err);
            this.setState({
                alertMsg:
                    'Something went wrong! Please try refreshing the page or logging back again.',
                alertType: 'Error',
                showAlert: true,
            });
        });;
        ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++  modify above segment */

        // await factoryService.getUserDetails(factoryService.getSession()).then(v => {
        //     if (v != null && v != 'undefined') {
        //         let details = factoryService.decodeData(v,factoryService.getSession());
        //         console.log('details', details);
        //         if (details.email != null) {
        //             this.setState({
        //                 usrID: String(details.id),
        //                 userUniversity: details.universityName,
        //             });
        //             factoryService.getInsttnDetails(details.insttnId)
        //                 .then(res => {
        //                     console.log(
        //                         'User Country Details',
        //                         res,
        //                         res.isoCtrycd
        //                     );
        //                     if (typeof res.isoCtrycd !== 'undefined') {
        //                         this.setState({ userCntryCode: res.isoCtrycd });
        //                     }
        //                 });
        //         }
        //     }
        // }).catch(err => {
        //     this.setState({ showLoader: false });
        //     console.log('user details error:', err);
        //     this.setState({
        //         alertMsg:
        //             'Something went wrong! Please try refreshing the page or logging back again.',
        //         alertType: 'Error',
        //         showAlert: true,
        //     });
        // });;
    }

    async questionnaireLoad() {
        let jwt= await factoryService.getGenSession('jwtAuth_ID')
        console.log('jwt',jwt)
        this.setState({ showLoader: true });
        try{
        let res= await factoryService.fetchLecQuestions(jwt)
            //.then(res=>{
                this.setState({ showLoader: true });
                console.log('fetchLecQuestions', res);
                if (typeof (res) != 'undefined' && res != null && res != "" && res!=" ") {
                    let tempErrors = res.map(val => {
                        return {
                            qId: val.questionOrder,
                            invalid: false,
                            ans: '',
                            que: val.question,
                        };
                    });

                    this.setState({ questionsData: res },async()=>{

                   

                    let defaultAnswers = tempErrors.map(
                        el =>
                            el.qId === 7
                                ? {
                                    ...el,
                                    invalid: false,
                                    ans: 'N/A',
                                }
                                : el.qId === 8
                                    ? {
                                        ...el,
                                        invalid: false,
                                        ans: this.state.userEmail,
                                    }
                                    : el.qId === 11
                                        ? {
                                            ...el,
                                            invalid: false,
                                            ans: this.state.lectureData,
                                        }
                                        : el
                    );
                    console.log('defaut answer valuesssss', defaultAnswers);
                    this.setState({ answerValues: defaultAnswers });
                    console.log('this.state.questionsData',this.state.questionsData)
                    await this.setState({ showLoader: false });
                
                    await this.setFormQuestions(this.state.questionsData);
                });
                }else{
                    this.setState({
                        alertMsg:
                            'Something went wrong! Please reload the page and try again.',
                        alertType: 'Error',
                        showAlert: true,
                        showLoader: false
                    });
                }
                //this.setState({ showLoader: true });
                console.log('this.state.questionsData',this.state.questionsData)
                // await this.setState({ showLoader: false });
            
                // await this.setFormQuestions(this.state.questionsData);
                
            
            // setTimeout(() => {
            //     this.setFormQuestions(this.state.questionsData);
            //     this.setState({ showLoader: false });
            // }, 5000);     
              
           // })

            }catch(err){
                this.setState({ showLoader: false });
                console.log('ques error:', err);
                this.setState({
                    alertMsg:
                        'Something went wrong! Please reload the page and try again.',
                    alertType: 'Error',
                    showAlert: true,
                    showLoader: false
                });
            };
        this.getInitialMinDate();
    }

    getInitialMinDate() {
        //Adding 2 weeks from today to start date initially
        const numWeeks = 2;
        const now = new Date();
        let finalDate = now.setDate(now.getDate() + numWeeks * 7);
        let strDate = new Date(finalDate);
        const dd = String(strDate.getDate()).padStart(2, '0');
        const mm = String(strDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = strDate.getFullYear();
        strDate = mm + '/' + dd + '/' + yyyy;
        console.log('strDate', strDate);
        this.setState({ minStartDate: strDate, minEndDate: strDate });
    }

    setFormQuestions(data) {
        console.log('setFormQuestions',data)
        if (data != null && typeof data != 'undefined') {
            if (data.length > 0) {
                let tempArray = [];
                tempArray = data.map((val, idx) => {
                    if (val.questionType == 'Text' && val.question.toLowerCase().includes('email')) {
                        return (
                            <p key={idx} className="lecture-request__formInput">
                                <TextInput disabled key={idx} id={'id' + idx} labelText={val.question} name={val.id} placeholder="Enter your email" maxLength="200" defaultValue={sessionStorage.getItem('userEm')} />
                            </p>
                        )
                    } else if (val.questionType == 'Date range') {
                        return (
                            <p key={idx} className="lecture-request__formInput">
                                <DatePicker datePickerType="single" minDate={ val.id === 9 ? this.state.minStartDate : val.id === 10 ? this.state.minEndDate : '' } onChange={e => this.onDateSelect(e, val)}>
                                    <DatePickerInput placeholder="mm/dd/yyyy" labelText={val.question} id={'id' + idx} name={val.id} invalidText="Please enter date" invalid={this.state.answerValues[idx].invalid} />
                                </DatePicker>
                            </p>
                        )
                    } else if (val.questionType == 'Numeric') {
                        return (
                            <p key={idx} className="lecture-request__formInput">
                                <NumberInput key={idx} id={'id' + idx} invalidText="Please enter valid response" placeholder="Enter your response" name={val.id} max={99999} min={0} label={val.question} hideSteppers={true} onChange={e => this.onNumberChange(e)} invalid={this.state.answerValues[idx].invalid} />
                            </p>
                        )
                    } else if (val.questionType == 'Text area') {
                        return (
                            <p key={idx} className="lecture-request__formInput">
                                <TextArea cols={60} rows={5} key={idx} id={'id' + idx} invalidText="Please enter your response" labelText={val.question} placeholder="Comments" maxLength="1500" name={val.id} invalid={this.state.answerValues[idx].invalid} onChange={e => this.onTextChange(e)} maxCount={1500} helperText={[this.state.comment_chars,'/1500',]} />
                            </p>
                        )
                    }
                })
                this.setState({ questionnaireData: tempArray });
            }
        }
        console.log('questionnaireData', this.state.questionnaireData);
    }

    async onDateSelect(event, value) {
        console.log('follow up date', this.state.userFollowupDate);

        let curStrDate = event[0];
        let curAnsDate = event[0];
        console.log('cur sel date', event[0]);

        let ansDate = this.convertDate(curAnsDate);
        let answers = this.state.answerValues.map(
            el =>
                '' + el.qId === value.id.toString()
                    ? {
                        ...el,
                        invalid: false,
                        ans: ansDate,
                    }
                    : el
        );
        console.log('answers', answers);
        this.setState({ answerValues: answers }, () =>
            console.log(
                'multiselect answer valuesssss',
                this.state.answerValues
            )
        );
        // setTimeout(() => {
        //     this.setFormQuestions(this.state.questionsData);
        // }, 500);
            await this.setFormQuestions(this.state.questionsData);
        

        if (value.id === 9) {
            //adding 2 weeks to end date from the start date
            const numWeeks = 2;
            const now = curStrDate;
            let finalDate = now.setDate(now.getDate() + numWeeks * 7);
            // console.log('dddddddddd', finalDate);
            let enDate = new Date(finalDate);
            let flDate = new Date(finalDate);
            // console.log('eeeeeeeeee',flDate );
            const dd = String(enDate.getDate()).padStart(2, '0');
            const mm = String(enDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            const yyyy = enDate.getFullYear();
            flDate = yyyy + '-' + mm + '-' + dd;
            enDate = mm + '/' + dd + '/' + yyyy;
            // console.log('ffffffffffffff',flDate );userFollowupDate
            this.setState({ minEndDate: enDate });
            this.setState({ userFollowupDate: flDate });
        }
    }

    convertDate(inputFormat) {
        function pad(s) {
            return s < 10 ? '0' + s : s;
        }
        var d = new Date(inputFormat);
        // return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
        return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join(
            '-'
        );
    }

    convertMinMaxDate(sDate) {
        let prsntDay = sDate;
        const dd = String(prsntDay.getDate()).padStart(2, '0');
        const mm = String(prsntDay.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = prsntDay.getFullYear();
        return (prsntDay = mm + '/' + dd + '/' + yyyy);
    }

    onNumberChange(event) {
        // console.log("event>>>>>>>>>>>>>", event)

        let nm = event.target.name,
            vl = event.target.value;

        // console.log("nm >>>>>>>>>>>>>", nm)
        // console.log("event>>>>>>>>>>>>>", vl)

        // console.log('answer values', this.state.answerValues)

        if (vl !== '' && vl > 0 && vl < 99999) {
            let answers = this.state.answerValues.map(
                el =>
                    '' + el.qId === nm.toString()
                        ? { ...el, invalid: false, ans: vl }
                        : el
            );
            this.setState({ answerValues: answers }, () =>
                console.log(
                    'number input answer valuesssss',
                    nm,
                    vl,
                    this.state.answerValues
                )
            );
        } else {
            let answers = this.state.answerValues.map(
                el =>
                    '' + el.qId === nm.toString()
                        ? { ...el, invalid: true, ans: '' }
                        : el
            );
            this.setState({ answerValues: answers }, () =>
                console.log(
                    'number input answer valuesssss',
                    nm,
                    vl,
                    this.state.answerValues
                )
            );
        }
        setTimeout(() => {
            this.setFormQuestions(this.state.questionsData);
        }, 500);
    }

    onTextChange(event) {
        // console.log("event>>>>>>>>>>>>>", event)
        let nm = event.target.name,
            vl = event.target.value;

        // console.log("nm >>>>>>>>>>>>>", nm)
        // console.log("event>>>>>>>>>>>>>", vl)

        // console.log('answer values', this.state.answerValues)

        const objCharCount = event.target.value.length;
        console.log('objCharCount', objCharCount);
        this.setState({ comment_chars: objCharCount });

        if (vl.trim() !== '') {
            let answers = this.state.answerValues.map(
                el =>
                    '' + el.qId === nm.toString()
                        ? { ...el, invalid: false, ans: vl.trim() }
                        : el
            );
            this.setState({ answerValues: answers }, () =>
                console.log(
                    'txt area answer valuesssss',
                    nm,
                    vl.trim(),
                    this.state.answerValues
                )
            );
        } else {
            let answers = this.state.answerValues.map(
                el =>
                    '' + el.qId === nm.toString()
                        ? { ...el, invalid: true, ans: '' }
                        : el
            );
            this.setState({ answerValues: answers });
        }
        setTimeout(() => {
            this.setFormQuestions(this.state.questionsData);
        }, 500);
    }

    onRequestSubmit(event) {
        event.preventDefault();
        let isFormValid = true;
        let answers = this.state.answerValues.map(el => {
            if (el.ans === '') {
                isFormValid = false;
            }
            return el.ans === '' ? { ...el, invalid: true } : el;
        });

        this.setState({ answerValues: answers }, () =>
            console.log(
                'submit answer valuesssss',
                this.state.answerValues,
                'isFormValid',
                isFormValid
            )
        );

        setTimeout(() => {
            this.setFormQuestions(this.state.questionsData);
        }, 500);

        console.log('user Id', this.state.usrID);

        if (isFormValid && this.state.usrID != 0) {
            this.setState({ showLoader: true });
            let QAset = this.state.answerValues.map(val => {
                return {
                    qId: String(val.qId),
                    question: val.que,
                    ans: val.ans,
                };
            });

            console.log('QAset', QAset);

            let dataToSend = {
                userId: this.state.usrID,
                lectureId: this.state.lectureId,
                ambassadorEmail: this.state.ambasdrEmail,
                status: 'Open',
                requestorName: '',
                requestorEmailId: this.state.userEmail,
                guestLectureName: this.state.glName,
                lectureName: this.state.gLTitle,
                requestorUniversityName: this.state.userUniversity,
                requestorCountryCode: this.state.userCntryCode,
                followupDate: this.state.userFollowupDate,
                lectureQuestionAnswer: QAset,
            };
            console.log('dataToSend', dataToSend);

            factoryService
                .submitLecRequest(
                    dataToSend,
                    sessionStorage.getItem('jwtAuth_ID')
                )
                .then(result => {
                    this.setState({ showLoader: false });
                    console.log('res', result);
                    if (result.success === true) {
                        factoryService
                            .updateTimesGiven(this.state.lectureId)
                            .then(reslt => {
                                this.setState({ showLoader: false });
                                let res = factoryService.decodeData(reslt, 'gupai@us.ibm.com');
                                if (res.message.indexOf('count') > 0) {
                                    let indx = res.message.indexOf('count') + 6;
                                    let val = res.message
                                        .substr(indx, 3)
                                        .trim();
                                    this.setState({ updatedCount: val });
                                }
                                this.setState({ showThankyou: true });
                                setTimeout(()=>{window.location.href = '/a2mt/lectures';},15000)
                            })
                            .catch(error => {
                                this.setState({ showLoader: false });
                            });
                    } else {
                        this.setState({
                            alertMsg:
                                'Error occurred while processing your request! Please try again later.',
                            alertType: 'Error',
                            showAlert: true,
                        });
                    }
                })
                .catch(error => {
                    this.setState({ showLoader: false });
                    console.log(error);
                    this.setState({
                        alertMsg: 'Something went wrong. Please try again.',
                        alertType: 'Error',
                        showAlert: true,
                    });
                });
        }
    }

    closeAlert(event) {
        this.setState({ showAlert: false });
    }

    render() {
        return (
            typeof this.state.lectureRequest.abstracts != 'undefined' ? (
                <Theme theme="white">
                    <NormalHeader></NormalHeader>
                    <Grid className="lecture-request">
                        <Column md={8} lg={4} sm={4} className="lecture-request__verticalLine lecture-request__sidePanel">
                            <AsyncImage email={this.state.lectureRequest.email} guestLecturerName={this.state.lectureRequest.guestLecturerName} type={3} />
                            <p className="lecture-request__filterHeading" style={{ borderBottom: "0px" }} dangerouslySetInnerHTML={{ __html: this.state.lectureRequest.abstracts[0].guestLectureTitle }}></p>
                            <p className="lecture-request__filterHeading" style={{ borderBottom: "0px" }}>By {this.state.lectureRequest.guestLecturerName}</p>
                        </Column>
                        {!this.state.showThankyou && <Column md={8} lg={{ span: 8, offset: 4 }} sm={4} className="lecture-request__formGroup">
                            <h2 className="lecture-request__formHeading">Guest request lecture form</h2>
                            <p className="lecture-request__formText">Please complete the form, once submitted you will be added to our waitlist, registration will be confirmed after the request period has finished.</p>
                            {this.state.questionnaireData != null && (
                                <span>{this.state.questionnaireData}</span>
                            )}
                            <p className="lecture-request__formInput">
                                <Button renderIcon={ArrowRight} iconDescription="Submit lecture request" id="submitButton" onClick={e => this.onRequestSubmit(e)}>Submit</Button>
                            </p>
                        </Column>}
                        {this.state.showThankyou && <Column md={8} lg={{ span: 8, offset: 4 }} sm={4} className="lecture-request__formGroup">
                            <h2 className="lecture-request__formHeading">Thank you</h2>
                            <p>Your waitlist registration is confirmed for IBM SkillsBuild</p>
                            <p>{this.state.lectureRequest.abstracts[0].guestLectureTitle} - {this.state.answerValues[2].ans} to {this.state.answerValues[3].ans}</p>
                            <p>You will receive this information in an email message confirming you registration</p>
                            <p className="lecture-request__formText">If your waitlist status changes, you will get notified in an email message</p>
                            <p className="lecture-request__formText">This page will be automatically redirected in 15 seconds</p>
                        </Column>}
                    </Grid>
                    <Modal
                        className="customModal"
                        passiveModal={true}
                        preventCloseOnClickOutside={false}
                        open={this.state.showAlert}
                        size="sm"
                        onRequestClose={this.closeAlert}>
                        <div style={{ padding: '1em 0' }}>
                            {this.state.alertType == 'Success' && (
                                <div>
                                    <CheckmarkOutline
                                        style={{
                                            width: '7em',
                                            height: '7em',
                                            color: 'green',
                                        }}
                                    />
                                </div>
                            )}
                            {this.state.alertType == 'Warning' && (
                                <div>
                                    <Warning_02
                                        style={{
                                            width: '7em',
                                            height: '7em',
                                            color: 'orange',
                                        }}
                                    />
                                </div>
                            )}
                            {this.state.alertType == 'Error' && (
                                <div>
                                    <DoNot
                                        style={{
                                            width: '7em',
                                            height: '7em',
                                            color: 'red',
                                        }}
                                    />
                                </div>
                            )}
                            <div style={{ paddingLeft: '1em' }}>
                                {this.state.alertMsg}
                            </div>
                        </div>
                    </Modal>
                    <Loading
                        withOverlay={true}
                        description="Active loading indicator"
                        active={this.state.showLoader}
                    />
                </Theme>
            ) : ""
        );
    }
}
export default LecturesRequest;