import React from 'react';
import { useEffect, useState } from 'react';
import {
    Loading,
    Section,
    Grid,
    Column
} from '@carbon/react';
import NormalHeader from '../components/NormalHeader/NormalHeader';
import factoryService from '../services/factory-service';
import queryString from 'query-string';
import utilService from '../services/util-service';

const UserCheck = (props) => {
    const [userDat, setUserDat] = useState(null);

    useEffect(() => {
        const uuID = queryString.parse(props.location.search);
        console.log('uuID', uuID);

        const getUserDet = async () => {
            const v = await factoryService.getUserDetails(uuID.id);
            setUserDat(factoryService.decodeData(v, uuID.id));
        }

        if (typeof uuID.id !== 'undefined') {
            factoryService.setSession(uuID.id);
            getUserDet();
        }
    }, [])

    useEffect(() => {
        if (userDat !== null && typeof userDat.ssoAccessToken !== 'undefined') {
            console.log('userDat', userDat, userDat.ssoAccessToken);
            factoryService.setTechCode(userDat.ssoAccessToken);
            sessionStorage.setItem('userEm', userDat.email);
            sessionStorage.setItem('userRo', userDat.role);
            utilService.setOtherPageAccessible(true);
            if (sessionStorage.getItem('tempLecId') != null) {
                window.location.href = '/a2mt/lectures-request?lecId=' + sessionStorage.getItem('tempLecId');
                sessionStorage.removeItem('tempLecId');
            } else if (sessionStorage.getItem('WorkShopId') !== null) {
                window.location.href = '/a2mt/event-request';
            } else {
                window.location.href = '/a2mt/downloads';
            }
        } else if (factoryService.getTechCode() !== false) {
            utilService.setOtherPageAccessible(true);
            if (sessionStorage.getItem('tempLecId') != null) {
                window.location.href = '/a2mt/lectures-request?lecId=' + sessionStorage.getItem('tempLecId');
                sessionStorage.removeItem('tempLecId');
            } else if (sessionStorage.getItem('WorkShopId') !== null) {
                window.location.href = '/a2mt/event-request';
            } else {
                window.location.href = '/a2mt/downloads';
            }
        }
    }, [userDat]);

    return (
        <Section>
            <NormalHeader></NormalHeader>
            <Grid>
                <Column style={{ innerHeight: "80vh" }}></Column>
            </Grid>
            <Loading></Loading>
        </Section>
    )
}

export default UserCheck;