import {
  Theme,
  Grid,
  Column, 
  TextInput, Button,
  Select, SelectItem, Checkbox, Link, Loading, Modal
} from '@carbon/react';
import { Help, CheckmarkOutline} from '@carbon/icons-react';
import { WarningAlt, MisuseOutline } from '@carbon/react/icons';
import React from 'react';
import './invalid-university.scss';
import Axios from 'axios';
import TermsModal from '../../modals/termsModal';
import PrivacyModal from '../../modals/privacyModal';
import utilService from "../../services/util-service";
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import factoryService from '../../services/factory-service';

export default class InvalidUniversity extends React.Component {
  constructor(props) {
    super(props);
    utilService.setBaseURL();
    this.state = {
      baseURL: utilService.hostname,
      dataFaq: localStorage.getItem('dataFAQ'),
      platfrmName: localStorage.getItem("ConfigPortalName"),
      showLoader: true,
      userEmail: localStorage.getItem('userEmail'),
      showAlert: false, alertMsg: 'Test', alertType: 'Success', showRedirectAlert: false,
      otherTextHidden: true,
      isInstSelectDisabled: true,
      isDomainExisting: false, isDomainRejected: false, mainFormControlVisible: true,
      termsModalShow: false, privacyModalShow: false, checkTerms: false, checkPrivacy: false, termsofservice: null, privacyconsent: null, invalidtermsofservice: false, invalidprivacyconsent: false,
      labelTerms: <span>I agree to the  <Link href="#" onClick={this.termsModalShowClick} style={{ fontWeight: "bold" }}>Software Downloads terms of service</Link></span>,
      labelPrivacy: <span>I agree to the  <Link href="#" onClick={this.privacyModalShowClick} style={{ fontWeight: "bold" }}>privacy consent</Link></span>,
      roles: [], countries: [], jobRoles: [], InstitutionTypes: [], departments: [], schools: [],
      selectedRole: null, selectedJobRole: null, selectedCountry: null, selectedInstitution: null, selectedInstName: null, selectedInstitutionType: null, selectedDept: null,
      enteredFirstName: null, enteredLastName: null, enteredEmail: null, enteredWebsite: null, enteredSchool: null,
      invalidRoleType: false, invalidJobRoleType: false, invalidCountry: false, invalidInstitution: false, invalidInstitutionType: false, invalidDept: false,
      invalidFirstName: false, invalidLastName: false, invalidEmail: false, invalidWebsite: false, invalidSchool: false,
      accessTokn: factoryService.Xaccess,//process.env.REACT_APP_X_ACCESS,
    };
  }

  componentDidMount() {
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getUserRole', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(rol => {
      if (rol.data !== null && rol.data.length > 0) {
        console.log('rol', rol.data); this.setState({ roles: rol.data }); setTimeout(() => {
          this.setState({ showLoader: false });
          this.countryData(); this.InstitutionTypeData(); this.DepartmentData(); this.JobRoleData();
        }, 500);
      }
    })
      .catch(error => { this.setState({ showLoader: false }); this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true }); });
  }

  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  countryData() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getCountry', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(cntry => {
      this.setState({ showLoader: false });
      console.log('cntry', cntry.data);
      if (cntry.data !== null && cntry.data.length > 0) {
        this.setState({ countries: cntry.data });
      } else { this.countryData(); }
    })
  }
  InstitutionTypeData() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getInstitutionType', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(inst => {
      this.setState({ showLoader: false });
      console.log('inst', inst.data);
      if (inst.data !== null && inst.data.length > 0) {
        this.setState({ InstitutionTypes: inst.data });
      } else { this.InstitutionTypeData(); }
    })
  }
  DepartmentData() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getDepartments', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(dept => {
      this.setState({ showLoader: false });
      console.log('dept', dept.data);
      if (dept.data !== null && dept.data.length > 0) {
        this.setState({ departments: dept.data });
      } else { this.DepartmentData(); }
    })
  }
  JobRoleData() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getJobRole', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(job => {
      this.setState({ showLoader: false });
      console.log('job', job.data);
      if (job.data !== null && job.data.length > 0) {
        this.setState({ jobRoles: job.data });
      } else { this.JobRoleData(); }
    })
  }

  SchoolData(data) {
    this.setState({ showLoader: true, isInstSelectDisabled: false });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getSchoolNames/' + data, {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    })
      .then(schl => {
        this.setState({ showLoader: false }); console.log('schl', schl.data);
        if (schl.data.isDataAvailable == false) {
          this.setState({ schools: [] });
        }
        else if (schl.data !== null && schl.data.length > 0) {
          this.setState({ schools: schl.data });
        }
      });
  }

  onSelectTypeChange = (event) => {
    const { options, selectedIndex } = event.target;
    if (event.target.name == 'role') {
      this.setState({ selectedRole: event.target.value, invalidRoleType: false });
    } else if (event.target.name == 'job_role') {
      this.setState({ selectedJobRole: event.target.value, invalidJobRoleType: false });
    } else if (event.target.name == 'country') {
      this.setState({ selectedCountry: event.target.value, invalidCountry: false }); this.SchoolData(event.target.value);
    } else if (event.target.name == 'institution') {
      if (event.target.value == 'Other') { this.setState({ selectedInstName: event.target.value, otherTextHidden: false, invalidInstitution: false }) } else {
        this.setState({ selectedInstName: options[selectedIndex].innerHTML, selectedInstitution: event.target.value, invalidInstitution: false, otherTextHidden: true });
      }
    } else if (event.target.name == 'institution_type') {
      this.setState({ selectedInstitutionType: event.target.value, invalidInstitutionType: false });
    } else if (event.target.name == 'department') {
      this.setState({ selectedDept: event.target.value, invalidDept: false });
    }
  }

  onTextChange = (event) => {
    let nm = event.target.name, vl = event.target.value; console.log('nm', nm, vl.trim());
    if (nm == 'first_name' && vl.trim() !== '') { this.setState({ enteredFirstName: vl.trim(), invalidFirstName: false }) } else if (nm == 'first_name' && vl.trim() == '') { this.setState({ enteredFirstName: null }); }
    if (nm == 'last_name' && vl.trim() !== '') { this.setState({ enteredLastName: vl.trim(), invalidLastName: false }) } else if (nm == 'last_name' && vl.trim() == '') { this.setState({ enteredLastName: null }); }
    if (nm == 'confirm_email' && vl.trim() !== '') {
      if (vl.trim() == document.getElementById("text-3").value) {
        this.setState({ enteredEmail: vl.trim(), invalidEmail: false })
      } else { this.setState({ enteredEmail: null }); }
    } else if (nm == 'confirm_email' && vl.trim() == '') { this.setState({ enteredEmail: null }); }
    if (nm == 'website' && vl.trim() !== '') { this.setState({ enteredWebsite: vl.trim(), invalidWebsite: false }) } else if (nm == 'website' && vl.trim() == '') { this.setState({ enteredWebsite: null }); }
    if (!this.state.otherTextHidden) {
      if (nm == 'school' && vl.trim() !== '') {
        let val = vl.trim();
        if (!/[^a-zA-Z]/.test(val.charAt(0))) { val = (val.charAt(0).toUpperCase() + val.slice(1)); }
        this.setState({ enteredSchool: val, invalidSchool: false })
      } else if (nm == 'school' && vl.trim() == '') {
        this.setState({ enteredSchool: null });
      }
    }
  }

  termsModalShowClick = event => { event.preventDefault(); this.setState({ termsModalShow: true }) };
  modalCloseTerms = event => { event.preventDefault(); this.setState({ termsModalShow: false }) };
  privacyModalShowClick = event => { event.preventDefault(); this.setState({ privacyModalShow: true }) };
  modalClosePrivacy = event => { event.preventDefault(); this.setState({ privacyModalShow: false }) };
  closeAlert = event => { this.setState({ showAlert: false }) }
  closeAlertAndRedirect = event => { this.setState({ showRedirectAlert: false }); window.location = '/a2mt/email-auth'; }

  checkTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: true });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: false })
  };
  uncheckTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: false });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: true })
  };
  checkPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: true });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: false })
  };
  uncheckPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: false });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let isFormValid = true;
    // this.setState({ isFormValid: true }, () => { console.log(this.state.isFormValid, 'isFormValid'); });
    if (this.state.selectedRole == null) { this.setState({ invalidRoleType: true }); isFormValid = false; }
    if (this.state.selectedJobRole == null) { this.setState({ invalidJobRoleType: true }); isFormValid = false; }
    if (this.state.selectedCountry == null) { this.setState({ invalidCountry: true }); isFormValid = false; }
    if (this.state.selectedInstName == null) { this.setState({ invalidInstitution: true }); isFormValid = false; }
    if (this.state.selectedInstitutionType == null) { this.setState({ invalidInstitutionType: true }); isFormValid = false; }
    if (this.state.selectedDept == null) { this.setState({ invalidDept: true }); isFormValid = false; }

    if (this.state.enteredFirstName == null) { this.setState({ invalidFirstName: true }); isFormValid = false; }
    if (this.state.enteredLastName == null) { this.setState({ invalidLastName: true }); isFormValid = false; }
    if (this.state.enteredEmail == null) { this.setState({ invalidEmail: true }); isFormValid = false; }
    if (this.state.enteredWebsite == null) { this.setState({ invalidWebsite: true }); isFormValid = false; }
    if (!this.state.otherTextHidden) {
      if (this.state.enteredSchool == null) { this.setState({ invalidSchool: true }); isFormValid = false; }
    }

    if (!this.state.checkTerms) { this.setState({ invalidtermsofservice: true }); isFormValid = false; }
    if (!this.state.checkPrivacy) { this.setState({ invalidprivacyconsent: true }); isFormValid = false; }

    if (isFormValid == true) {
      this.setState({ showLoader: true });
      Axios.get(this.state.baseURL + '/a2mt/services/user-details/isWhitelistCountry/' + this.state.selectedCountry, {
        headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
      })
        .then(rs => {
          this.setState({ showLoader: true }); let boolData = rs.data; console.log('boolData', boolData);
          if (typeof (boolData.whiteListCountry) != 'undefined' && boolData.whiteListCountry == "true") { // is whiteListCountry
            this.callSubmitService(); // submit Form data
          } else { // not a whiteListCountry
            if (this.state.selectedRole == '1') { // User role is student
              this.setState({ alertMsg: 'Thank you for your interest in ' + this.state.platfrmName + '. We require that an educator or staff member at the academic institution complete this request form.', alertType: 'Warning', showRedirectAlert: true });
            } else { // User role is faculty or staff
              this.checkEmailDomain(); // call service 'user-details/chkDomainExistance/' to know isRejectedDomain or isDomainExists or none
            }
          }
        })
        .catch(error => { this.setState({ showLoader: false }); this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true }); })
    }
  }

  callSubmitService() {
    let insttnNameV = ''; let insttnIdV = ''; let jbRole = '';
    let rl = this.state.selectedRole;
    let sch = this.state.selectedInstName;
    if (sch == 'Other') {
      insttnNameV = this.state.enteredSchool;
      insttnIdV = '100259';
    } else {
      insttnNameV = this.state.selectedInstName;
      insttnIdV = this.state.selectedInstitution;
    }
    if (rl == '1') { jbRole = '21'; } else { jbRole = this.state.selectedJobRole; }

    const formData = {
      emailId: this.state.userEmail,
      emailDomain: this.state.userEmail.split('@')[1],
      userRoleId: rl,
      jobRoleId: jbRole,
      firstName: this.state.enteredFirstName,
      lastName: this.state.enteredLastName,
      insttnName: insttnNameV,
      insttnId: insttnIdV,
      isoCtryCd: this.state.selectedCountry,
      insttnTypeId: this.state.selectedInstitutionType,
      departmentId: this.state.selectedDept,
      insttnWebsite: this.state.enteredWebsite,
      adminApprovalStatus: 'In Progress',
      geoRepApprovalStatus: 'Not Started',
      createdBy: this.state.userEmail,
      modifiedBy: this.state.userEmail,
    }
    console.log('formData', formData);
    this.setState({ showLoader: true });
    Axios.post(this.state.baseURL + '/a2mt/services/user-details/saveInsttnDomainRequest', formData, {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    })
      .then(rs => {
        this.setState({ showLoader: false }); let res = rs.data; console.log('fffffffffffffffff', res);
        if (res.isRejectedDomain == true) {
          this.setState({ alertMsg: 'Unfortunately you have already requested access to ' + this.state.platfrmName + ' program and that request has been denied.', alertType: 'Warning', showRedirectAlert: true });
        } else if (res.isDomainExist == true) {
          this.setState({ alertMsg: 'Someone at your academic institution has already requested access to ' + this.state.platfrmName + ' program and we are evaluating the request. You will be notified via email once the request is processed', alertType: 'Warning', showRedirectAlert: true });
        } else if (typeof (res.emailIdExist) != 'undefined' && res.emailIdExist == "true") {
          this.setState({ alertMsg: 'You have already requested access to ' + this.state.platfrmName + ' program and we are evaluating the request. You will be notified via email once the request is processed.', alertType: 'Warning', showRedirectAlert: true });
        } else if (res.isFormDataSaved == true) {
          this.setState({ alertMsg: 'Thank you, your request has been submitted successfully. We will inform you via email once the request has been processed.', alertType: 'Success', showRedirectAlert: true });
        } else {
          this.setState({ alertMsg: 'Error occurred while saving data! Please try again later.', alertType: 'Error', showAlert: true });
        }
      })
      .catch(error => { this.setState({ showLoader: false }); this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true }); })
  }

  checkEmailDomain() {
    if (this.state.userEmail != '' && this.state.userEmail != null) {
      this.setState({ showLoader: true });
      Axios.get(this.state.baseURL + '/a2mt/services/user-details/chkDomainExistance/' + this.state.userEmail, {
        headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
      })
        .then(rs => {
          this.setState({ showLoader: false }); console.log('chkDomain', rs.data);
          if (rs.data != null && rs.data != '') {
            let res = rs.data;
            if (res.isRejectedDomain == true) { this.setState({ isDomainRejected: true, selectedRole: null, mainFormControlVisible: false }) }
            else if (res.isDomainExist == true) { this.setState({ isDomainExisting: true, selectedRole: null, mainFormControlVisible: false }) }
            else { setTimeout(() => { this.callSubmitService(); }, 250); }
          } else if (rs.data == null) { this.checkEmailDomain(); }
        })
        .catch(error => { this.setState({ showLoader: false }); this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true }); })
    }
  }

  render() {
    return (
      <div>
        <NormalHeader></NormalHeader>
       
          <Theme theme="g10" >
          <Grid className="invalid-university-page" style={{minHeight:600+'px'}} >
            <Column lg={{ span: 9, offset: 3 }} md={8} sm={4}>
                <div className="textContentforInvalidUniv">
                  <p className="defaultPara customText">Find answers in our <a target="_blank" href={this.state.dataFaq} className="bx--link" rel="noopener noreferrer">frequently asked questions</a></p>
                  <form onSubmit={this.handleSubmit} noValidate>
                    <p className="defaultPara customHeader">Thank you for your interest in {this.state.platfrmName}.</p><br></br>
                    {!this.state.isDomainExisting && !this.state.isDomainRejected && (<span>
                      <p className="defaultPara customText">Your academic institution is not currently a part of {this.state.platfrmName} program. <span className="boldFnt">To become a member of {this.state.platfrmName} please have an educator or
                        staff member submit the form below and we will evaluate your request.</span> You will be notified via email once
                        your request is processed.</p>
                      <br></br>
                      <p className="defaultPara customText">While you are waiting for access you will not be able to download
                        software or courses due to licensing restrictions, but you are welcome to leverage other resources
                        available on the site.</p></span>)}

                    {this.state.isDomainRejected && (<span>
                      <p className="defaultPara customText"><span className="boldFnt">Unfortunately your academic institution has already requested
                        access to {this.state.platfrmName} program and that request has been denied.</span></p>
                      <br></br>
                      <p className="defaultPara customText">You are welcome to leverage other resources available on the site.</p></span>)}

                    {this.state.isDomainExisting && (<span>
                      <p className="defaultPara customText"><span className="boldFnt">Someone at your academic institution has already requested access
                        to {this.state.platfrmName} program and we are evaluating the request.</span> You will be notified via email once
                        your request is processed.</p>
                      <br></br>
                      <p className="defaultPara customText">While you are waiting for access you will not be able to download
                        software or courses due to licensing restrictions, but you are welcome to leverage other resources
                        available on the site.</p></span>)}

                    <br></br>
                    <p className="defaultPara customText">We look forward to introducing you to IBM technology in the near
                      future!</p>
                    <br></br>
                    <div className={this.state.mainFormControlVisible ? '' : 'isHidden'}>
                      <p style={{ paddingBottom: "1em" }}><span style={{ color: "red" }}>*</span>All fields provided below are mandatory. Incorrectly or inaccurately completed requests will be rejected.</p>
                      <Select id="select-1" defaultValue="placeholder-item" labelText="Type of User" invalidText="Please choose an option" name="role"
                        invalid={this.state.invalidRoleType} onChange={this.onSelectTypeChange}>
                        <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
                        {this.state.roles.map((val) => <SelectItem value={val.id} text={val.roleName} />)}
                      </Select>
                    </div><br></br>

                    <div className={this.state.selectedRole == null ? 'isHidden' : ''}>
                      <Select labelText="Job Role" defaultValue="placeholder-item" id="select-2" invalidText="Please choose an option" name="job_role"
                        invalid={this.state.invalidJobRoleType} onChange={this.onSelectTypeChange}>
                        <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
                        {this.state.jobRoles.map((val) => <SelectItem value={val.id} text={val.roleName} />)}
                      </Select>
                      <br></br><br></br>

                      <TextInput id="text-1" labelText="First Name" placeholder="Enter first name" invalidText="Please enter first name" name="first_name"
                        invalid={this.state.invalidFirstName} onChange={this.onTextChange}></TextInput>
                      <br></br><br></br>

                      <TextInput id="text-2" labelText="Last Name" placeholder="Enter last name" invalidText="Please enter last name" name="last_name"
                        invalid={this.state.invalidLastName} onChange={this.onTextChange}></TextInput>
                      <br></br><br></br>

                      <TextInput id="text-3" labelText="Email" placeholder="Enter email" disabled={true} defaultValue={this.state.userEmail}></TextInput>
                      <br></br><br></br>

                      <TextInput id="text-4" labelText="Confirm Email" placeholder="Enter email" invalidText="Please enter email. Email should match with registered email."
                        name="confirm_email" invalid={this.state.invalidEmail} onChange={this.onTextChange}></TextInput>
                      <br></br><br></br>

                      <Select labelText="Country" id="select-3" defaultValue="placeholder-item" invalidText="Please choose an option" name="country"
                        invalid={this.state.invalidCountry} onChange={this.onSelectTypeChange}>
                        <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
                        {this.state.countries.map((val) => <SelectItem value={val[0]} text={val[1]} />)}
                      </Select>
                      <br></br><br></br>

                      <Select labelText="Academic Institution (Please select 'Other' if your academic institution is not listed)" id="select-4"
                        defaultValue="placeholder-item" invalidText="Please choose an option" name="institution"
                        invalid={this.state.invalidInstitution} onChange={this.onSelectTypeChange} disabled={this.state.isInstSelectDisabled}>
                        <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
                        {this.state.schools.map((val) => <SelectItem value={val.id} text={val.instituteName} />)}
                        <SelectItem value="Other" text="Other" />
                      </Select>
                      <br></br><br></br>

                      <span className={this.state.otherTextHidden ? 'isHidden' : ''}>
                        <TextInput id="text-5" labelText="Enter Academic Institution Name" placeholder="Enter institution" invalidText="Please enter institution name"
                          name="school" invalid={this.state.invalidSchool} onChange={this.onTextChange}></TextInput>
                        <br></br><br></br>
                      </span>

                      <Select labelText="Type of Institution" id="select-5" defaultValue="placeholder-item" invalidText="Please choose an option"
                        name="institution_type" invalid={this.state.invalidInstitutionType} onChange={this.onSelectTypeChange}>
                        <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
                        {this.state.InstitutionTypes.map((val) => <SelectItem value={val.id} text={val.insttnTypName} />)}
                      </Select>
                      <br></br><br></br>

                      <Select labelText="Department" id="select-6" defaultValue="placeholder-item" invalidText="Please choose an option" name="department"
                        invalid={this.state.invalidDept} onChange={this.onSelectTypeChange}>
                        <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
                        {this.state.departments.map((val) => <SelectItem value={val.id} text={val.department} />)}
                      </Select>
                      <br></br><br></br>

                      <TextInput id="text-6" labelText="Academic Institution Website" placeholder="Enter website" invalidText="Please enter website"
                        name="website" invalid={this.state.invalidWebsite} onChange={this.onTextChange}></TextInput>
                      <br></br><br></br>

                      <fieldset className="bx--fieldset">
                        <legend className="bx--label">Please click on the links below to read and accept the program agreement and privacy statement</legend>
                        <Checkbox labelText={this.state.labelTerms} id="checked-2" disabled checked={this.state.checkTerms} />{this.state.termsModalShow && (
                          <div><TermsModal hidden={this.modalCloseTerms} check={this.checkTermsModalClick} uncheck={this.uncheckTermsModalClick} /></div>
                        )}
                        <Checkbox labelText={this.state.labelPrivacy} id="checked-3" disabled checked={this.state.checkPrivacy} />{this.state.privacyModalShow && (
                          <div><PrivacyModal hidden={this.modalClosePrivacy} check={this.checkPrivacyModalClick} uncheck={this.uncheckPrivacyModalClick} /></div>
                        )}
                        {(this.state.invalidtermsofservice ||
                          this.state.invalidprivacyconsent) &&
                          <span className='error' style={{ color: '#da1e28' }}>Please agree on Terms of Service and Privacy Consent</span>}
                      </fieldset>
                      <br></br>

                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </Column>
            </Grid>
            <br/><br/><br/>
          </Theme>
        
        <Loading withOverlay={true} description="Active loading indicator" active={this.state.showLoader}></Loading>

        <Modal passiveModal={true} preventCloseOnClickOutside={false} open={this.state.showAlert} size="sm" onRequestClose={this.closeAlert}>
          <div style={{ padding: "1em 0" }}>
            {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
            {this.state.alertType == 'Warning' && (<div><WarningAlt style={{ width: "7em", height: "7em", color: "orange" }}></WarningAlt></div>)}
            {this.state.alertType == 'Error' && (<div><MisuseOutline style={{ width: "7em", height: "7em", color: "red" }}></MisuseOutline></div>)}
            <div style={{ paddingLeft: "1em" }}>{this.state.alertMsg}</div>
          </div>
        </Modal>

        <Modal passiveModal={true} preventCloseOnClickOutside={false} open={this.state.showRedirectAlert} size="sm" onRequestClose={this.closeAlertAndRedirect}>
          <div style={{ padding: "1em 0" }}>
            {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
            {this.state.alertType == 'Warning' && (<div><WarningAlt style={{ width: "7em", height: "7em", color: "orange" }}></WarningAlt></div>)}
            {this.state.alertType == 'Error' && (<div><MisuseOutline style={{ width: "7em", height: "7em", color: "red" }}></MisuseOutline></div>)}
            <div style={{ paddingLeft: "1em" }}>{this.state.alertMsg}</div>
          </div>
        </Modal>
      </div>
    )
  }
}