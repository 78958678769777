import React, { useEffect, useState } from 'react';
import {
  Theme,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavLink,
  SideNavItems,
  SideNavDivider,
  Loading,
  HeaderPanel,
  Modal,
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from '@carbon/react';
import { Help, UserAvatar, CheckmarkOutline } from '@carbon/react/icons';
import { Warning_02, DoNot } from '@carbon/pictograms-react';
import { Link } from 'react-router-dom';
import { decodeToken } from 'react-jwt';
import moment from 'moment';
import factoryService from '../../services/factory-service';
import utilService from '../../services/util-service';
import { IdleSessionTimeout } from 'idle-session-timeout';

var session = new IdleSessionTimeout(20 * 60 * 1000, 'click');

const SbsdHeader = () => {
  const [technology, setTechnology] = useState(null);
  const [nontechnology, setNontechnology] = useState(null);
  const [path, setPath] = useState('');
  const [showLoader, setLoader] = useState(false);
  const [isUserProfileVisible, setUserProfileVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  const [showAlertLogout, setShowAlertLogout] = useState(false);
  const [alertMsgLogout, setAlertMsgLogout] = useState("Test");
  const [alertTypeLogout, setAlertTypeLogout] = useState("Success");

  useEffect(() => {
    setPath(window.location.pathname);

    const getTech = async () => {
      setLoader(true);
      const val = await factoryService.getAllTechnology();
      if (typeof val.data !== 'undefined' && val.data !== null) {
        let tch = val.data.filter(t => t.VISIBILITY === true && t.GROUP_ITEMS === "All_technologies");
        setTechnology(tch);
        let nontch = val.data.filter(t => t.VISIBILITY === true && t.GROUP_ITEMS === "Static_menu");
        setNontechnology(nontch);
      };
      if (sessionStorage.getItem('userEm') !== 'undefined' && sessionStorage.getItem('userEm') !== null) {
        setEmail(sessionStorage.getItem('userEm'));
      }
      session.onTimeOut = () => {
        setAlertMsgLogout('Session expired. Please login back again.'); setAlertTypeLogout('Error'); setShowAlertLogout(true);
      };
      session.onTimeLeftChange = (timeLeft) => {
        console.log('Session time left-', millisToMinutesAndSeconds(timeLeft));
      };
      session.start();
      setSession(); setTokenExpiry(sessionStorage.getItem('jwtAuth_ID'));
    };

    getTech();
  }, []);

  useEffect(() => {
    console.log('technology1', technology);
    if (technology !== null) {
      setLoader(false);
    }
  }, [technology]);

  const handleRedirect = (event, data) => {
    utilService.setOtherPageAccessible(true);
    console.log('redirect', event, data);
    window.location =
      '/a2mt/downloads/' +
      data
        .split(' ')
        .join('_')
        .toLowerCase();
  };

  const userProfileClick = (event) => {
    console.log('userProfile', event);
    setUserProfileVisible(prevState => !prevState);
  };

  const userLogout = (event) => {
    console.log('userLogout', event);
    sessionStorage.removeItem('userRo');
    sessionStorage.removeItem('jwtAuth_ID');
    sessionStorage.removeItem('userEm');
    sessionStorage.removeItem('tCode');
    sessionStorage.removeItem('academics');
    sessionStorage.removeItem('refreshT_ID');
    sessionStorage.removeItem('techList');
    window.location = '/a2mt/email-auth';
  }

  const setSession = () => {
    let duration = 20 * 60 * 1000;

    session.dispose();
    session = new IdleSessionTimeout(duration, "click");

    session.onTimeOut = () => {
      setAlertMsgLogout('Session expired. Please login back again.'); setAlertTypeLogout('Error'); setShowAlertLogout(true);
    };
    session.onTimeLeftChange = (timeLeft) => {
      console.log('Session time left-', millisToMinutesAndSeconds(timeLeft));
    };
    session.start();
  }

  const setTokenExpiry = (token) => {
    const decodedToken = decodeToken(token);
    const tokenExpiration = moment(decodedToken.exp, 'X');
    let currentTime = moment();
    let duration = moment.duration(tokenExpiration.diff(currentTime));
    let dur = duration.asMilliseconds() - 180000;

    setTimeout(() => {
      checkTokenExpiry();
    }, duration);
    console.log('milli', millisToMinutesAndSeconds(duration));
  }

  const checkTokenExpiry = async () => {
    await factoryService.getNewToken(sessionStorage.getItem('refreshT_ID')).then(res => {
      console.log('Header Data2', res);
      if (typeof res.headers['jwt-auth'] != 'undefined') {
        let token = res.headers['jwt-auth'];
        sessionStorage.setItem('jwtAuth_ID', token);
        setTokenExpiry(token);
      } else {
        setAlertMsgLogout('Session expired. Please login back again.'); setAlertTypeLogout('Error'); setShowAlertLogout(true);
      }
    }).catch((error) => {
      setAlertMsgLogout('Session expired. Please login back again.'); setAlertTypeLogout('Error'); setShowAlertLogout(true);
    })
  }

  const millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Theme theme="g100">
          <Header role='presentation'>
            <SkipToContent />
            <HeaderMenuButton
              aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'}
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
              aria-expanded={isSideNavExpanded}
            />
            <HeaderName as={Link} to="/a2mt/downloads" prefix="IBM">
              SkillsBuild Software Downloads
            </HeaderName>
            <HeaderGlobalBar>
              <HeaderGlobalAction aria-label="Help">
                <Help size={20} />
              </HeaderGlobalAction>
              <HeaderGlobalAction
                aria-label="User Profile"
                tooltipAlignment="end"
                onClick={e => userProfileClick(e)}>
                <UserAvatar size={20} />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
            <HeaderPanel role='button' expanded={isUserProfileVisible}>
              <p className="sbsdHeader__user">{email}</p>
              <p className="sbsdHeader__user-logout"><Button kind="danger--ghost" size='lg' onClick={e => setLogoutConfirmation(true)}>Logout</Button></p>
            </HeaderPanel>

            <Theme theme="white">
              <SideNav
                aria-label="Side navigation"
                expanded={isSideNavExpanded}
                onSideNavBlur={onClickSideNavExpand}
                href="#main-content">
                <SideNavItems>
                  <SideNavLink
                    as={Link}
                    to="/a2mt/downloads"
                    isActive={path === '/a2mt/downloads' ? true : false}>
                    All technologies
                  </SideNavLink>
                  {technology !== null &&
                    technology.map(v => {
                      return (
                        <SideNavLink
                          as={Link}
                          onClick={e => handleRedirect(e, v.NAME)}
                          isActive={
                            path ===
                              '/a2mt/downloads/' +
                              v.NAME.split(' ')
                                .join('_')
                                .toLowerCase()
                              ? true
                              : false
                          }>
                          {v.NAME}
                        </SideNavLink>
                      );
                    })}
                  {nontechnology !== null && <SideNavDivider />}
                  {nontechnology !== null &&
                    nontechnology.map(v => {
                      return (
                        <SideNavLink
                          as={Link}
                          onClick={e => window.open(v.IMG_URL, "_blank")}>
                          {v.NAME}
                        </SideNavLink>
                      );
                    })}
                </SideNavItems>
              </SideNav>
            </Theme>
          </Header>
          <Loading
            withOverlay={true}
            description="Active loading indicator"
            active={showLoader}
          />
          <Theme theme="white">
            <Modal
              danger
              size="sm"
              open={logoutConfirmation}
              primaryButtonText="Logout"
              secondaryButtonText="Cancel"
              onRequestClose={() => {
                setLogoutConfirmation(false);
              }}
              onSecondarySubmit={() => {
                setLogoutConfirmation(false);
              }}
              onRequestSubmit={e => userLogout()}
              modalHeading="Confirm logout"
            >
              Are you sure you want to logout?
            </Modal>

            <ComposedModal size='sm' open={showAlertLogout} onRequestClose={() => setShowAlertLogout(false)} icondescription="Close the modal" onClose={e => userLogout()} preventCloseOnClickOutside={true} style={{ zIndex: "9999" }}>
              <ModalHeader>Session Expired!</ModalHeader>
              <ModalBody>
                {alertTypeLogout == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
                {alertTypeLogout == 'Warning' && (<div><Warning_02 style={{ width: "7em", height: "7em", color: "orange" }}></Warning_02></div>)}
                {alertTypeLogout == 'Error' && (<div><DoNot style={{ width: "7em", height: "7em", color: "red" }}></DoNot></div>)}
                <div style={{ paddingLeft: "1em" }}>{alertMsgLogout}</div>
              </ModalBody>
              <ModalFooter>
                <Button
                  kind="primary"
                  onClick={e => userLogout()}>Ok</Button>
              </ModalFooter>
            </ComposedModal>
          </Theme>
        </Theme>
      )}
    />
  );
};

export default SbsdHeader;
