import React, { useEffect, useState } from 'react';
import { Column } from '@carbon/react';
import factoryService from '../../services/factory-service';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const AsyncImage = (props) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const apiUrl = factoryService.source_a2mt + 'services/user-details/image/' + props.email;
                const headers = {
                    'X-access': cookies.get('AppAccess'),
                };

                const response = await fetch(apiUrl, { headers });
                const blob = await response.blob();
                const objectURL = URL.createObjectURL(blob);

                setImageSrc(objectURL);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        };

        fetchImage();
    }, [props]);

    return (
        props.type == 1 ? (
            <Column lg={2} md={1} sm={4}>
                {imageSrc !== null && <img src={imageSrc} className="guest-lecture__lectureImg" alt={ 'Display picture of ' + props.guestLecturerName } aria-label={ 'Display picture of ' + props.guestLecturerName } />}
                {imageSrc === null && <img src={`${process.env.PUBLIC_URL}/unknownuser.png`} className="guest-lecture__lectureImg" alt={ 'Display picture of ' + props.guestLecturerName } aria-label={ 'Display picture of ' + props.guestLecturerName } />}
                {/* {props.email} */}
            </Column>
        ) : props.type == 2 ? (
            <p>
                {imageSrc !== null && <img src={imageSrc} className="guest-lecture__abstractImg" alt={ 'Display picture of ' + props.guestLecturerName } aria-label={ 'Display picture of ' + props.guestLecturerName } />}
                {imageSrc === null && <img src={`${process.env.PUBLIC_URL}/unknownuser.png`} className="guest-lecture__abstractImg" alt={ 'Display picture of ' + props.guestLecturerName } aria-label={ 'Display picture of ' + props.guestLecturerName } />}
            </p>
        ) : (
            <p>
                {imageSrc !== null && <img src={imageSrc} alt={ 'Display picture of ' + props.guestLecturerName } aria-label={ 'Display picture of ' + props.guestLecturerName } style={{maxWidth:"50%"}} />}
                {imageSrc === null && <img src={`${process.env.PUBLIC_URL}/unknownuser.png`} alt={ 'Display picture of ' + props.guestLecturerName } aria-label={ 'Display picture of ' + props.guestLecturerName } style={{maxWidth:"50%"}} />}
            </p>
        )
    );
};

export default AsyncImage;