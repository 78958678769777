//import './cssfile.scss';
import React, { Component } from 'react';
import './modals.scss';
import { Modal } from '@carbon/react';
class PrivacyModal extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    className='mymodal'
                    hasScrollingContent={false}
                    iconDescription='Close the modal'
                    modalAriaLabel=''
                    modalLabel=''
                    onRequestClose={this.props.hidden}
                    onRequestSubmit={this.props.check}
                    onSecondarySubmit={this.props.uncheck}
                    open
                    passiveModal={false}
                    primaryButtonDisabled={false}
                    primaryButtonText='Accept'
                    secondaryButtonText='Cancel'
                    selectorPrimaryFocus='[data-modal-primary-focus]'
                    size='lg'>
                    {/* <div className='bx--modal-content__text' style={{ paddingTop:'2%' }}> */}
                    {/* <div style={{ padding:'2%', width:'', fontSize:'20px', textAlign : 'center' }}> */}
                    <div className='bx--modal-content__text'>
                    <div className="modalBody">
			
                    <p className="defaultPara customHeader">IBM SkillsBuild Software Downloads Privacy Consent</p>
                    <p className="customBold">Scope</p>
			<p>The IBM SkillsBuild Software Downloads Privacy Statement applies to use of the IBM SkillsBuild Software Downloads website. The
				below statements should be considered in addition to the IBM Online Privacy Statement.</p><br />
                <p className="customBold">Personal Information</p>
			<p>We collect your personal information for the purpose of granting access, processing, or communications as
				it pertains to IBM SkillsBuild Software Downloads.</p><br />
			<ol>
				<li>1. Participants will be contacted to revalidate their access to the program every six (6) months.</li>
				<li>2. Participants may also be contacted with reminders of when services they have signed up for are about
					to expire.</li><br />
			</ol>
			<p className="customBold">Uses</p>
			<ol>
				<li>1. To evaluate, review and process your request for access to the program.</li>
				<li>2. To contact you in connection with revalidating your access to the program.</li>
				<li>3. To contact you to promote projects and services related to those you have previously subscribed to.
				</li>
				<li>4. To remind you of expiring services.</li>
                <li>5. To connect you with other IBM academic offerings, projects, and services related to those you have previously subscribed to.
                </li><br />
			</ol>
			<p className="customBold">Retention</p>
			<ol class="ibm-bullet-list">
				<li>1. Data is retained for as long as the user remains active and reauthorizes. Once the user no longer
					reauthorizes, we retain their data for only 6 months.</li><br />
			</ol>
			<p className="customBold">Your Choices</p>
			<ol>
				<li>1. When we collect information from you, you may tell us that you do not want it used for further
					contact.</li>
				<li>2. You may also request that your nomination be withdrawn.</li><br />
			</ol>
            <p>IBM may share the personal information collected with IBM subsidiaries and third parties globally.</p><br />
			<p>To find out more about IBM privacy practices, see the <a href="http://www.ibm.com/privacy/details/us/en/"
					tabindex="2" target="_blank">IBM Online Privacy Statement</a>.</p>
		</div>
        </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default PrivacyModal;