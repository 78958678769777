import {
  Button, 
  Theme,
  Grid,
  Column, 
  Loading,
  Select, SelectItem, RadioButtonGroup, RadioButton, FormGroup, Checkbox, Modal, Link, ComposedModal,
  ModalHeader, ModalBody, ModalFooter, TextInput
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/react/icons';
import { Help, CheckmarkOutline } from '@carbon/react/icons';
import React from 'react';
import './register-edit.scss';
import TermsModal from '../../modals/termsModal';
import PrivacyModal from '../../modals/privacyModal';
import SuccessModal from '../../modals/successModal';
import SuccessRecertifyModal from '../../modals/successRecertifyModal';
import RecertifyModal from '../../modals/recertify';
import utilService from "../../services/util-service";
import Axios from 'axios';
import { IdleSessionTimeout } from "idle-session-timeout";
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import factoryService from '../../services/factory-service';

let arayMonth = [];
let arayPopulateMonth = [];
let yearArray = [];
let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
let lsEditValue = JSON.parse(localStorage.getItem('ConfigUserDet'));
let arrMonthYearSplit = [];
var session = new IdleSessionTimeout(15 * 60 * 1000, "click");

export default class RegisterEdit extends React.Component {
  constructor(props) {
    super(props);
    utilService.setBaseURL();
    let localStorageValue = JSON.parse(localStorage.getItem('ConfigMasterDetails'));
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    for (let year = currentYear; year < currentYear + 10; year++) {
      yearArray.push(year);
    }

    arayMonth = [
      {
        name: 'Jan',
        index: '0'
      },
      {
        name: 'Feb',
        index: '1'
      },
      {
        name: 'Mar',
        index: '2'
      },
      {
        name: 'Apr',
        index: '3'
      },
      {
        name: 'May',
        index: '4'
      },
      {
        name: 'Jun',
        index: '5'
      },
      {
        name: 'Jul',
        index: '6'
      },
      {
        name: 'Aug',
        index: '7'
      },
      {
        name: 'Sep',
        index: '8'
      },
      {
        name: 'Oct',
        index: '9'
      },
      {
        name: 'Nov',
        index: '10'
      },
      {
        name: 'Dec',
        index: '11'
      },
    ];
    arayPopulateMonth = arayMonth;

    /////////////Fetch edit data/////////////////


    let editRole = "";
    let isSelectRole = false;
    if (lsEditValue.student_Faculty == 2) {
      editRole = "Faculty";
      isSelectRole = false;
    }
    else if (lsEditValue.student_Faculty == 3) {
      editRole = "Staff";
      isSelectRole = false;
    }
    else if (lsEditValue.student_Faculty == 1) {
      editRole = "Student";
      isSelectRole = true;
    }
    arrMonthYearSplit = lsEditValue.graduationDate.split('-');

    this.state = {
      baseURL: utilService.hostname,
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      termsModalShow: false,
      privacyModalShow: false,
      successModalShow: false,
      successRecertifyModalShow: false,
      recertifyModalShow: false,
      labelTerms: <span>I agree to the  <Link href="#" onClick={this.termsModalShowClick}>Software Downloads terms of service</Link></span>,
      labelPrivacy: <span>I agree to the  <Link href="#" onClick={this.privacyModalShowClick}>privacy consent</Link></span>,
      loader: false,
      checkTerms: false,
      checkPrivacy: false,
      termsofservice: null,
      privacyconsent: null,
      invalidfirstName: false,
      selectRole: isSelectRole,
      datFaq: [],
      dataRole: [],
      dataDegree: localStorageValue.CurrentDegree,
      dataDepartment: localStorageValue.UniversityDepartment,
      dataSoughtDegree: localStorageValue.SoughtDegree,
      dataYear: yearArray,
      dataMonth: arayMonth,
      role: editRole,
      student_faculty: lsEditValue.student_Faculty,
      degree: lsEditValue.currentDegree,
      sought_degree: lsEditValue.soughtDegree,
      graduation_date: null,
      graduation_month: null,
      department: lsEditValue.department,
      badgeProgramEnrolled: lsEditValue.badgeProgramEnrolled.toString(),
      invalidrole: false,
      invalidstudent_faculty: false,
      invaliddegree: false,
      invalidsought_degree: false,
      invalidgraduation_date: false,
      invalidgraduation_month: false,
      invaliddepartment: false,
      invalidtermsofservice: false,
      invalidprivacyconsent: false,
      universityEmail: localStorageValue.UniversityEmail,
      universityName: localStorageValue.UniversityName,
      lableBadge: <span>Enroll me in the IBM badge program. <Link href="https://ibm.com/badging" target="_blank" >What is this?</Link></span>,
      btnDisabled: false,
      showAlertLogout: false,
      alertMsgLogout: "Test",
      alertTypeLogout: "Success",
      accessTokn: factoryService.Xaccess, //process.env.REACT_APP_X_ACCESS,
      jwtTokn: '',
      refrTokn: '',
      userTitle: '',
      userId: lsEditValue.id,
    };

    session.onTimeOut = () => {
      this.setState({ alertMsgLogout: 'Session expired. Please login back again.', alertTypeLogout: 'Error', showAlertLogout: true });
    };
    session.onTimeLeftChange = (timeLeft) => {
      console.log('Session time left-', this.millisToMinutesAndSeconds(timeLeft));
    };
    session.start();
  }

  async getJWT() {
    this.setState({ showLoader: true });
    await Axios.get(this.state.baseURL + '/a2mt/services/user-details/getJwt', { headers: { 'emailId': this.state.universityEmail } }).then(res => {
      console.log('Header Data', res);
      if (typeof res.headers['jwt-auth'] != 'undefined') {
        this.setState({ jwtTokn: res.headers['jwt-auth'] });
        this.setState({ refrTokn: res.headers['refresh'] });
        this.setSession(res.headers['jwt-auth']);
        this.setTokenExpiry(res.headers['jwt-auth']);
        this.getUserRole();
        this.loadSelectedData();
      } else {
        this.setState({ showLoader: false });
        this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
      }
    }).catch((error) => {
      this.setState({ showLoader: false });
      this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
    });
  }

  setSession(token) {
    let duration = 15 * 60 * 1000;

    session.dispose();
    session = new IdleSessionTimeout(duration, "click");

    session.onTimeOut = () => {
      this.setState({ alertMsgLogout: 'Session expired. Please login back again.', alertTypeLogout: 'Error', showAlertLogout: true });
    };
    session.onTimeLeftChange = (timeLeft) => {
      console.log('Session time left-', this.millisToMinutesAndSeconds(timeLeft));
    };
    session.start();
  }

  setTokenExpiry(token) {
    let duration = 2 * 60 * 1000;

    setTimeout(() => {
      this.checkTokenExpiry();
    }, duration);
    console.log('milli', this.millisToMinutesAndSeconds(duration));
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  async checkTokenExpiry() {
    await Axios.get(this.state.baseURL + '/a2mt/services/user-details/checkExpiration',
      { headers: { 'JwtExpiration': this.state.refrTokn } }).then(res => {
        console.log('Header Data2', res);
        if (typeof res.headers['jwt-auth'] != 'undefined') {
          let token = res.headers['jwt-auth'];
          this.setState({ jwtTokn: token });
          this.setTokenExpiry(token);
        } else {
          this.setState({ alertMsgLogout: 'Session expired. Please login back again.', alertTypeLogout: 'Error', showAlertLogout: true });
        }
      }).catch((error) => {
        this.setState({ alertMsgLogout: 'Session expired. Please login back again.', alertTypeLogout: 'Error', showAlertLogout: true });
      })
  }

  userLogout() {
    utilService.setOtherPageAccessible(false);
    window.location = "/a2mt/email-auth";
  }

  onChangeHandlerRadio = (event) => {
    //console.log("event----->", event);

    this.setState({ badgeProgramEnrolled: event });
  }
  onChangeHandlerSelect = (event) => {
    const { options, selectedIndex } = event.target;

    //console.log(options[selectedIndex].innerHTML);
    if (event.target.name == "role") {
      this.setState({ role: options[selectedIndex].innerHTML });
      this.setState({ student_faculty: event.target.value });
      this.setState({ invalidrole: false });

      if (options[selectedIndex].innerHTML == "Student") {
        this.setState({ selectRole: true });

      }
      else {
        this.setState({ selectRole: false });
      }
    }
    else if (event.target.name == "graduation_month") {
      this.setState({ graduation_month: event.target.value });
      this.setState({ invalidgraduation_month: false });
    }
  }

  onChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    let namconcat = "invalid" + nam;
    this.setState({ [namconcat]: false });
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();
    if (nam == "graduation_date") {
      if (currentYear == event.target.value) {
        arayMonth = [];
        let count = 0;
        for (let i = currentMonth; i < 12; i++) {
          let obj = {
            name: months[i],
            index: i,
            countIndex: count
          }
          arayMonth.push(obj);
          count++;
        }
        this.setState({ dataMonth: arayMonth });
        let e = document.getElementById("select-graduation-month");
        let selectedMonth = e.options[e.selectedIndex].text;
        let obj = arayMonth.find(o => o.name === selectedMonth);
        if (obj != undefined) {
          document.getElementById('select-graduation-month').value = obj.countIndex;
        }
        else {
          document.getElementById('select-graduation-month').value = "placeholder-item";
        }
      }
      else {
        arayMonth = [];
        for (let i = 0; i < 12; i++) {
          let obj = {
            name: months[i],
            index: i
          }
          arayMonth.push(obj);
        }
        this.setState({ dataMonth: arayMonth });
        let e = document.getElementById("select-graduation-month");
        let selectedMonth = e.options[e.selectedIndex].text;
        if (selectedMonth != 'Choose graduation month') {
          let obj = arayMonth.find(o => o.name === selectedMonth);
          if (obj != undefined) {
            document.getElementById('select-graduation-month').value = obj.index;
          }
        }
        else {
          document.getElementById('select-graduation-month').value = "placeholder-item";
        }
      }
    }

  }

  loadSelectedData() {
    if (this.state.role == "Student") {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth();

      if (yearArray.indexOf(parseInt(arrMonthYearSplit[0])) !== -1) {
        for (let i = 0; i < yearArray.length; i++) {
          if (yearArray[i].toString().trim() == arrMonthYearSplit[0].toString().trim()) {
            document.getElementById('select-graduation-date').value = yearArray[i];
          }

        }
      }
      if (arrMonthYearSplit[0].toString().trim() == currentYear.toString()) {
        arayMonth = [];
        let count = 0;
        for (let i = currentMonth; i < 12; i++) {
          let obj = {
            name: months[i],
            index: i,
            countIndex: count
          }
          arayMonth.push(obj);
          count++;
        }
        this.setState({ dataMonth: arayMonth });
        let monthIndex = (parseInt(arrMonthYearSplit[1]) - 1);
        let obj = arayMonth.find(o => o.index === monthIndex);
        if (obj != undefined) {
          document.getElementById('select-graduation-month').value = obj.countIndex;
        }
        else {
          let obj = arayMonth.find(o => o.index === currentMonth);
          if (obj != undefined) {
            document.getElementById('select-graduation-month').value = obj.countIndex;
          }
        }
      }
      else {
        arayMonth = [];
        for (let i = 0; i < 12; i++) {
          let obj = {
            name: months[i],
            index: i
          }
          arayMonth.push(obj);
        }
        this.setState({ dataMonth: arayMonth });
        if (yearArray.indexOf(parseInt(arrMonthYearSplit[0])) !== -1) {
          let monthIndex = (parseInt(arrMonthYearSplit[1]) - 1);
          let obj = arayMonth.find(o => o.index === monthIndex);
          if (obj != undefined) {
            document.getElementById('select-graduation-month').value = obj.index;
          }
        }
        else {
          document.getElementById('select-graduation-month').value = 0;
        }
      }
    }
  }

  handleClick = (event) => {
    event.preventDefault();
  }
  handleSubmit = (event) => {
    event.preventDefault();
    let valid = true;

    if (document.getElementById('select-degree').value == "placeholder-item") {
      this.setState({ invaliddegree: true });
      valid = false;
    }
    if (this.state.role == "Student") {
      if (document.getElementById('select-soughtdegree').value == "placeholder-item") {
        this.setState({ invalidsought_degree: true });
        valid = false;
      }
      if (document.getElementById('select-graduation-date').value == "placeholder-item") {
        this.setState({ invalidgraduation_date: true });
        valid = false;
      }
      if (document.getElementById('select-graduation-month').value == "placeholder-item") {
        this.setState({ invalidgraduation_month: true });
        valid = false;
      }
    }
    if (document.getElementById('select-department').value == "placeholder-item") {
      this.setState({ invaliddepartment: true });
      valid = false;
    }

    if (!this.state.checkTerms) {
      this.setState({ invalidtermsofservice: true });
      valid = false;
    }
    if (!this.state.checkPrivacy) {
      this.setState({ invalidprivacyconsent: true });
      valid = false;
    }

    if (valid) {
      this.setState({ recertifyModalShow: true });
      this.setState({ btnDisabled: true });
    }

  }

  modalCloseSuccess = event => {
    event.preventDefault();
    this.setState({ successModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  successModalClick = event => {
    event.preventDefault();
    this.setState({ successModalShow: false });
    window.location = "/a2mt/email-auth";
  };
  modalCloseSuccessRecertify = event => {
    event.preventDefault();
    this.setState({ successRecertifyModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  successRecertifyModalClick = event => {
    event.preventDefault();
    this.setState({ successRecertifyModalShow: false });
    window.location = "/a2mt/email-auth";
  };

  modalCloseRecertify = event => {
    event.preventDefault();
    this.setState({ recertifyModalShow: false });
    this.setState({ btnDisabled: false });
  };

  recertifyModalClick = event => {
    event.preventDefault();
    this.setState({ recertifyModalShow: false });
    this.recertifyDataSave();
    this.setState({ btnDisabled: false });
  };

  recertifyDataSave() {
    let inputData = {};
    let saveData = {
      universityEmail: this.state.universityEmail,
      universityName: this.state.universityName,
      currentDegree: document.getElementById('select-degree').value,
      department: document.getElementById('select-department').value,
      badgeProgramEnrolled: this.state.badgeProgramEnrolled,
      student_Faculty: parseInt(this.state.student_faculty),
      termsAndCondition: this.state.checkTerms,
      privacyConsent: this.state.checkPrivacy,
      regWhiteListRedID: lsEditValue.regWhiteListRedID,
      insttnID: lsEditValue.insttnID,
      id: this.state.userId
    };
    if (this.state.role == "Student") {
      let graduation_date = document.getElementById('select-graduation-date').value;
      let graduation_month = (parseInt(document.getElementById('select-graduation-month').value) + 1).toString();
      saveData["graduationDate"] = graduation_date + "-" + graduation_month;
      saveData["soughtDegree"] = document.getElementById('select-soughtdegree').value;
      inputData = { "userData": saveData, "title": "" };
    }
    else {
      saveData["graduationDate"] = "";
      saveData["soughtDegree"] = "";
      inputData = { "userData": saveData, "title": this.state.userTitle }
    }
    console.log("postData", saveData);
    // return false;
    this.setState({ showLoader: true });
    Axios.post(this.state.baseURL + '/a2mt/services/user-details/updateUser', inputData, {
      headers: { "Content-Type": "application/json", "Jwtauth": this.state.jwtTokn }
    })
      .then(rs => {
        console.log(rs.data); this.setState({ showLoader: false });
        if (rs.data.success === true) { this.setState({ successRecertifyModalShow: true }); }
        else { this.setState({ alertMsg: 'Update failed. Please try again later.', alertType: 'Warning', showAlert: true }); }
      })
      .catch((error) => {
        this.setState({ showLoader: false });
        this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
      });
  }

  checkTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: true });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: false })
  };
  uncheckTermsModalClick = event => {
    event.preventDefault();
    this.setState({ checkTerms: false });
    this.setState({ termsModalShow: false });
    this.setState({ invalidtermsofservice: true })
  };


  termsModalShowClick = event => {
    event.preventDefault();
    this.setState({ termsModalShow: true })
  }
  modalCloseTerms = event => {
    event.preventDefault();
    this.setState({ termsModalShow: false })
  };
  privacyModalShowClick = event => {
    event.preventDefault();
    this.setState({ privacyModalShow: true })
  }
  modalClosePrivacy = event => {
    event.preventDefault();
    this.setState({ privacyModalShow: false })
  };

  checkPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: true });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: false })
  };
  uncheckPrivacyModalClick = event => {
    event.preventDefault();
    this.setState({ checkPrivacy: false });
    this.setState({ privacyModalShow: false });
    this.setState({ invalidprivacyconsent: true });
  };

  componentDidMount() {
    if (this.state.jwtTokn == '') {
      this.getJWT();
    } else {
      this.getUserRole();
      this.loadSelectedData();
    }
    this.getUserTitle();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  getFAQ() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getFAQ', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(res => {
      this.setState({ showLoader: false });
      console.log(res.data);
      if (res.data != null) {
        this.setState({ dataFaq: res.data.faq });
      } else {
        this.setState({ dataFaq: [] })
      }
    }).catch((error) => {
      this.setState({ showLoader: false });
      this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
    });
  }

  getUserRole() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getUserRole', {
      headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
    }).then(res => {
      this.setState({ showLoader: false });
      console.log(res.data); this.getFAQ();
      if (res.data != null) {
        this.setState({ dataRole: res.data });
      } else {
        this.setState({ dataRole: [] })
      }
    }).catch((error) => {
      this.setState({ showLoader: false });
      this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
    });
  }

  getUserTitle() {
    // alert("getUserTitle", this.state.accessTokn)
    let data = { "userId": this.state.userId, "emailId": this.state.universityEmail };
    // let data = {"userId":6554,"emailId":"a2mtfaculty10@mailinator.com"}
    Axios.post(this.state.baseURL + '/a2mt/services/user-details/getUserTitle', data, {
      headers: { "X-access": this.state.accessTokn }
    }).then(res => {

      console.log("a2mtfaculty@mailinator.com====", res.data.title);
      this.setState({ userTitle: res.data.title });

    })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({ customMsg: 'Something went wrong. Please try again.', alertType: 'Error', showModal: true });
      });

  }

  onChangeInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    return (
      <div>
       <NormalHeader></NormalHeader>
        <section>
        <Theme theme="g10" >
          <Grid className="invalid-university-page" style={{minHeight:600+'px'}} >
            <Column lg={{ span: 9, offset: 3 }} md={8} sm={4}>
                <div className="textContentforInvalidRegis">
                  <p className="defaultPara customText">Find answers in our <a href={this.state.dataFaq} target="_blank" className="bx--link" rel="noopener noreferrer">frequently asked questions</a></p>
                  <p className="defaultPara customHeader">Please update the information below</p><br></br>
                  <p className="defaultPara customText">Please review the information below update if necessary to complete the recertification process</p>
                  <br></br> <br></br>
                  <p className="defaultPara customText">Academic institution issued email: <Link href="#" onClick={this.handleClick}>{this.state.universityEmail}</Link></p>
                  <br></br> <br></br>
                  <p className="defaultPara customText">Academic institution name: {this.state.universityName}</p>
                  <br></br> <br></br>
                  <form onSubmit={this.handleSubmit} noValidate>
                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        defaultValue="placeholder-item"
                        value={this.state.student_faculty}
                        id="select-role"
                        invalidText="Please select a Role"
                        name='role'
                        invalid={this.state.invalidrole}
                        labelText="Role"
                        disabled="true"
                        onChange={this.onChangeHandlerSelect}
                      >
                        {this.state.dataRole.map((value) => <SelectItem text={value.roleName} value={value.id}>{value.roleName}</SelectItem>)}
                      </Select>
                    </div>
                    {!this.state.selectRole && (
                      <div style={{ marginBottom: '2rem' }}>
                        <TextInput
                          type="text"
                          name="userTitle"
                          labelText="Title"
                          aria-label="Title"
                          value={this.state.userTitle}
                          onChange={this.onChangeInput}
                          required
                        />
                      </div>
                    )}
                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        defaultValue="placeholder-item"
                        value={this.state.degree}
                        id="select-degree"
                        invalidText="Please select a Degree"
                        name='degree'
                        invalid={this.state.invaliddegree}
                        labelText="Current Degree"
                        onChange={this.onChangeHandler}
                      >

                        <SelectItem
                          value="placeholder-item"
                          text="Choose degree"
                        />
                        {this.state.dataDegree.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}

                      </Select>
                    </div>
                    {this.state.selectRole && (
                      <div>
                        <div style={{ marginBottom: '2rem' }}>
                          <Select
                            defaultValue={this.state.sought_degree}
                            id="select-soughtdegree"
                            invalidText="Please select a Sought Degree"
                            name='sought_degree'
                            invalid={this.state.invalidsought_degree}
                            labelText="Sought Degree"
                            onChange={this.onChangeHandler}
                          >

                            <SelectItem
                              value="placeholder-item"
                              text="Choose sought degree"
                            />
                            {this.state.dataSoughtDegree.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}
                          </Select>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <div className="bx--grid--full-width" style={{ paddingLeft: '0.9rem' }}>
                            <div className="bx--row">
                              <div className="bx--col">
                                <Select
                                  id="select-graduation-date"
                                  invalidText="Please select a Graduation Date"
                                  name='graduation_date'
                                  invalid={this.state.invalidgraduation_date}
                                  labelText="Expected graduation Date"
                                  onChange={this.onChangeHandler}
                                >

                                  <SelectItem
                                    value="placeholder-item"
                                    text="Choose graduation date"
                                  />
                                  {this.state.dataYear.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}
                                </Select>
                              </div>
                              <div className="bx--col">
                                <Select
                                  id="select-graduation-month"
                                  invalidText="Please select a Month"
                                  name='graduation_month'
                                  invalid={this.state.invalidgraduation_month}
                                  labelText="Month"
                                  onChange={this.onChangeHandlerSelect}
                                >

                                  <SelectItem
                                    value="placeholder-item"
                                    text="Choose graduation month"
                                  />
                                  {this.state.dataMonth.map((value) => <SelectItem text={value.name} value={value.index}>{value.name}</SelectItem>)}
                                </Select>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    )}


                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        defaultValue={this.state.department}
                        id="select-department"
                        invalidText="Please select a Department"
                        name='department'
                        invalid={this.state.invaliddepartment}
                        labelText="Department"
                        onChange={this.onChangeHandler}
                      >

                        <SelectItem
                          value="placeholder-item"
                          text="Choose department"
                        />
                        {this.state.dataDepartment.map((value) => <SelectItem text={value} value={value}>{value}</SelectItem>)}
                      </Select>
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <FormGroup
                        legendText={this.state.lableBadge}
                      >
                        <RadioButtonGroup orientation='vertical'
                          defaultSelected="default-selected"
                          legend="Group Legend"
                          role="radiogroup"
                          aria-label="Enrollment in IBM badge program"
                          name="badgeProgramEnrolled"
                          valueSelected={this.state.badgeProgramEnrolled}
                          onChange={this.onChangeHandlerRadio}
                        >
                          <RadioButton
                            id="radio-1"
                            labelText="Yes"
                            value="true"
                          />
                          <RadioButton
                            id="radio-2"
                            labelText="No"
                            value="false"
                          />

                        </RadioButtonGroup>
                      </FormGroup>
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <fieldset className="bx--fieldset">
                        <legend className="bx--label">Please click on the links below to read and accept the program agreement and privacy statement</legend>

                        <Checkbox labelText={this.state.labelTerms} id="checked-2" disabled checked={this.state.checkTerms} />{this.state.termsModalShow && (
                          <div>
                            <TermsModal hidden={this.modalCloseTerms} check={this.checkTermsModalClick} uncheck={this.uncheckTermsModalClick} />
                          </div>
                        )}

                        <Checkbox labelText={this.state.labelPrivacy} id="checked-3" disabled checked={this.state.checkPrivacy} />{this.state.privacyModalShow && (
                          <div>
                            <PrivacyModal hidden={this.modalClosePrivacy} check={this.checkPrivacyModalClick} uncheck={this.uncheckPrivacyModalClick} />
                          </div>
                        )}
                        {(this.state.invalidtermsofservice ||
                          this.state.invalidprivacyconsent) &&
                          <span className='error' style={{ color: '#da1e28' }}>Please agree on Terms of Service and Privacy Consent</span>}

                      </fieldset>
                      {this.state.successModalShow && (
                        <div>
                          <SuccessModal hidden={this.modalCloseSuccess} successok={this.successModalClick} />
                        </div>
                      )}
                      {this.state.recertifyModalShow && (
                        <div>
                          <RecertifyModal hidden={this.modalCloseRecertify} successok={this.recertifyModalClick}
                            recertify={this.recertifyModalClick} unrecertify={this.unrecertifyModalClick} />
                        </div>
                      )}
                      {this.state.successRecertifyModalShow && (
                        <div>
                          <SuccessRecertifyModal hidden={this.modalCloseSuccessRecertify} successok={this.successRecertifyModalClick} />
                        </div>
                      )}

                      {this.state.loader && (
                        <div>
                          <Loading
                            description="Active loading indicator" withOverlay={true} active={true}
                          />
                        </div>
                      )}
                      <Button
                        kind="primary"
                        tabIndex={0}
                        type="submit"
                        disabled={this.state.btnDisabled}
                      >
                        Recertify
                      </Button>

                    </div>
                  </form>
                </div>
              </Column>
            </Grid>
            <Loading withOverlay={true} description="Active loading indicator" active={this.state.showLoader}></Loading>
            <Modal passiveModal={true} preventCloseOnClickOutside={false} open={this.state.showAlert} size="sm" onRequestClose={() => { this.setState({ showAlert: false }) }}>
              <div style={{ padding: "1em 0" }}>
                {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
                {this.state.alertType == 'Warning' && (<div><WarningAlt style={{ width: "7em", height: "7em", color: "orange" }}></WarningAlt></div>)}
                {this.state.alertType == 'Error' && (<div><MisuseOutline style={{ width: "7em", height: "7em", color: "red" }}></MisuseOutline></div>)}
                <div style={{ paddingLeft: "1em" }}>{this.state.alertMsg}</div>
              </div>
            </Modal>
            <ComposedModal size='sm' open={this.state.showAlertLogout} onRequestClose={this.props.hidden} icondescription="Close the modal" onClose={this.userLogout} preventCloseOnClickOutside={true}>
              <ModalHeader>Session Expired!</ModalHeader>
              <ModalBody>
                {this.state.alertTypeLogout == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
                {this.state.alertTypeLogout == 'Warning' && (<div><WarningAlt style={{ width: "7em", height: "7em", color: "orange" }}></WarningAlt></div>)}
                {this.state.alertTypeLogout == 'Error' && (<div><MisuseOutline style={{ width: "7em", height: "7em", color: "red" }}></MisuseOutline></div>)}
                <div style={{ paddingLeft: "1em" }}>{this.state.alertMsgLogout}</div>
              </ModalBody>
              <ModalFooter>
                <Button
                  kind="primary"
                  onClick={this.userLogout}>Ok</Button>
              </ModalFooter>
            </ComposedModal>
          </Theme>
        </section>
      </div>
    )
  }
}

