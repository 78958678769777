import {
  Grid,
  Column,
  Button,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Loading,
  TableContainer,
  TableSelectRow,
  TextInput,
  Modal,
  Select,
  SelectItem,
  ButtonSet,
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import { Add, CheckmarkOutline } from '@carbon/icons-react';
import React from 'react';
import Axios from 'axios';
import './white-list.scss';
import utilService from '../../../services/util-service';
import factoryService from '../../../services/factory-service'
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

let selectedRow = [];
let emailID = null;
let emailName = null;

export default class WhiteList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: utilService.hostname,
      emailId: localStorage.getItem('adminEmail'),
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      labelAdd: (
        <div>
          Enter Academic Institution Domain:<span className="error">*</span>{' '}
          (Example: ibm.com)
        </div>
      ),
      isEditOverlayVisible: false,
      isAddOverlayVisible: false,
      isDeleteOverlayVisible: false,
      headerData: [{ header: 'Email', key: 'domainName' }],
      selectedRowData: null,
      invalidDomainName: false,
      domainName: null,
      domainListPrimary: [],
      regionsList: [],
      instituteList: [],
      domainList: [],
      newUserData: [],
      isDatatableShow: false,
      errors: {
        email: '',
      },
      validEmail: false,
      errorMsg: 'Email is required',
      accessTokn: factoryService.Xaccess,
    };
  }

  componentDidMount() {
    document.getElementById('regionList').disabled = true;
    document.getElementById('universityList').disabled = true;
    this.getAllRegionsList();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  getAllRegionsList() {
    this.setState({ showLoader: true });
    Axios.get(this.state.baseURL + '/a2mt/services/user-details/getCountry', {
      headers: {
        'Content-Type': 'application/json',
        'X-access': this.state.accessTokn,
      },
    })
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        if (res.data != null) {
          this.setState({ regionsList: res.data });
        } else {
          this.setState({ regionsList: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  getDomainList() {
    let sel = document.getElementById('regionList');
    let regionListId = sel.value;
    console.log(regionListId);
    let data = {
      isoCtrycd: regionListId,
    };
    this.setState({ showLoader: true });
    utilService
      .postData('/a2mt/services/user-details/getDomain', data)
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        if (res.data != null) {
          this.setState({ instituteList: res.data });
        } else {
          this.setState({ instituteList: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  onTextChange = event => {
    let id = event.target.id,
      vl = event.target.value;
    // console.log('onTextChange', id, vl);
    if (id == 'userEmail' && vl.trim() !== '') {
      validEmailRegex.test(vl)
        ? this.setState({
            domainName: vl.trim(),
            invalidDomainName: false,
            errorMsg: '',
          })
        : this.setState({
            domainName: null,
            invalidDomainName: true,
            errorMsg: 'Email must be a valid email address',
          });
    } else if (id == 'userEmail' && vl.trim() === '') {
      this.setState({
        domainName: null,
        invalidDomainName: true,
        errorMsg: 'Email is required',
      });
    }
  };

  addUser = event => {
    let addFormValid = true;
    if (this.state.domainName == null) {
      this.setState({ invalidDomainName: true });
      addFormValid = false;
    }
    if (addFormValid) {
      this.setState(
        (prevState, props) => {
          const row = {
            id: this.guidGenerator(),
            domainName: document.getElementById('userEmail').value,
          };
          return { newUserData: [...prevState.newUserData, row] };
        },
        () => {
          this.resetForm();
        }
      );
    }
  };
  guidGenerator() {
    let S4 = function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }

  tempSelectedRow = event => {
    console.log(event);
    if (!event.isSelected) {
      //selectedRow.push(event.cells[0].value);
      let obj = {
        id: event.id,
        domainName: event.cells[0].value,
      };
      selectedRow.push(obj);
    } else if (event.isSelected) {
      selectedRow.filter((e, i) => {
        if (e.id === event.id) {
          selectedRow.splice(i, 1);
        }
      });
    }
    if (selectedRow.length > 0) {
      document.getElementById('regionList').disabled = false;
    }
    console.log('selectedRow', selectedRow);
  };

  onSelectRegDomainChange = event => {
    const { options, selectedIndex } = event.target;
    if (event.target.name == 'regionList') {
      this.getDomainList();
      document.getElementById('universityList').disabled = false;
      document.getElementById('universityList').value = 'placeholder-item';
    } else if (event.target.name == 'universityList') {
      //this.setState({ domainId: event.target.value, domain: options[selectedIndex].innerHTML });
      emailID = event.target.value;
      emailName = options[selectedIndex].innerHTML;
    }
  };

  addWhiteListData = event => {
    if (selectedRow.length > 0) {
      let sel_regionList = document.getElementById('regionList');
      let regionID = sel_regionList.value;
      let sel_universityList = document.getElementById('universityList');
      let domainID = sel_universityList.value;
      let domain =
        sel_universityList.options[sel_universityList.selectedIndex].text;
      if (regionID != 'placeholder-item' && domainID != 'placeholder-item') {
        if (domain != 'null') {
          this.setState({ isAddOverlayVisible: true });
        } else {
          this.setState({
            alertMsg: 'Domain can not be null',
            alertType: 'Error',
            showAlert: true,
          });
        }
      } else {
        this.setState({
          alertMsg: 'Please select a region and domain name',
          alertType: 'Error',
          showAlert: true,
        });
      }
    } else {
      this.setState({
        alertMsg: 'Please select a record',
        alertType: 'Error',
        showAlert: true,
      });
    }
  };

  admin_addWhiteLstUser = event => {
    this.removeSelectedListData();
    let emailIDs = [];
    for (let i = 0; i < selectedRow.length; i++) {
      emailIDs.push(selectedRow[i].domainName);
    }
    let data = {
      //createdBy: 'saambig1@in.ibm.com',
      createdBy: localStorage.getItem('adminEmail'),
      domain: emailName,
      emailIDs: emailIDs,
      id: emailID,
    };
    console.log('postData', data);

    this.setState({ showLoader: true });

    utilService
      .postData('/a2mt/services/user-details/addWhiteLstUser', data)
      .then(rs => {
        console.log(rs.data);
        this.setState({ showLoader: false });
        this.resetForm();
        this.resetSelect();
        if (rs.data.success === true) {
          this.setState({
            alertMsg: 'User is created',
            alertType: 'Success',
            showAlert: true,
          });
        } else if (rs.data.success === false) {
          this.setState({
            alertMsg:
              'Save failed. User is already exist. Duplicate records not allowed.',
            alertType: 'Warning',
            showAlert: true,
          });
        } else {
          this.setState({
            alertMsg: 'Save failed. Please try again later.',
            alertType: 'Warning',
            showAlert: true,
          });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.resetForm();
        this.resetSelect();
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  };
  removeSelectedListData = prevState => {
    this.closeAllOverlays();
    let newUserList = [];
    this.setState((prevState, props) => {
      if (prevState.newUserData.length > 1) {
        for (let i = 0; i < prevState.newUserData.length; i++) {
          for (let j = 0; j < selectedRow.length; j++) {
            if (prevState.newUserData[i].id == selectedRow[j].id) {
              prevState.newUserData.splice(i, 1);
              selectedRow.splice(j, 1);
            }
          }
        }

        prevState.newUserData.filter((e, i) => {
          selectedRow.filter((event, j) => {
            if (e.id === event.id) {
              prevState.newUserData.splice(i, 1);
              selectedRow.splice(j, 1);
            }
          });
        });

        newUserList = prevState.newUserData;
      } else {
        prevState.newUserData = [];
        newUserList = [];
        selectedRow = [];
      }
      console.log('newUserData', prevState.newUserData);
      //return { newUserData: prevState.newUserData };
    });
    this.setState(
      {
        newUserData: [],
      },
      () => {
        this.setState({
          newUserData: newUserList,
        });
      }
    );
  };

  removeWhiteListData = event => {
    if (selectedRow.length > 0) {
      this.setState({ isDeleteOverlayVisible: true });
    } else {
      this.setState({
        alertMsg: 'Please select a record',
        alertType: 'Error',
        showAlert: true,
      });
    }
  };

  closeAllOverlays = event => {
    this.setState({
      isAddOverlayVisible: false,
      isEditOverlayVisible: false,
      isDeleteOverlayVisible: false,
    });
  };

  resetSelect() {
    document.getElementById('regionList').value = 'placeholder-item';
    document.getElementById('universityList').value = 'placeholder-item';
    document.getElementById('universityList').disabled = true;
  }
  resetForm() {
    this.setState({
      invalidDomainName: false,
      domainName: null,
      errorMsg: 'Email is required',
    });
    document.getElementById('userEmail').value = '';
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="body-height">
        <Grid className="admin-sub-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <div>
              <div style={{ paddingBottom: '32px' }}>
                <h3>Exception List</h3>
              </div>
              <section>
                <div style={{ marginBottom: '2rem' }}>
                  <div className="bx--grid--full-width">
                    <div className="bx--row">
                      <div className="bx--col">
                        {' '}
                        <TextInput
                          helperText=""
                          id="userEmail"
                          onChange={this.onTextChange}
                          invalid={this.state.invalidDomainName}
                          invalidText={this.state.errorMsg}
                          labelText={
                            <div>
                              <span className="error">*</span>Enter email
                            </div>
                          }
                          placeholder="Placeholder text"
                        />
                        {/* <Button renderIcon={Add}  id="add-btn" size='small' style={{ marginLeft: '8px'}}>Add email</Button> */}
                      </div>
                      <div className="bx--col" style={{ marginTop: '1.5rem' }}>
                        <Button
                          size="field"
                          renderIcon={Add}
                          id="add-btn"
                          onClick={this.addUser}>
                          Add email
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <DataTable
                  rows={this.state.newUserData}
                  headers={this.state.headerData}
                  isSortable>
                  {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getSelectionProps,
                    getTableProps,
                    getTableContainerProps,
                    onInputChange,
                    selectedRows,
                  }) => (
                    <TableContainer {...getTableContainerProps()}>
                      <Table {...getTableProps()}>
                        <TableHead>
                          <TableRow>
                            <TableHeader />
                            {headers.map((header, i) => (
                              <TableHeader
                                key={i}
                                {...getHeaderProps({ header })}>
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {rows.map((row, i) => (
                            <TableRow key={i} {...getRowProps({ row })}>
                              <TableSelectRow
                                {...getSelectionProps({
                                  row,
                                  onClick: () => this.tempSelectedRow(row),
                                })}
                              />
                              {row.cells.map(cell => (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </DataTable>
                <div style={{ marginBottom: '2rem', marginTop: '2rem' }}>
                  <div className="bx--grid--full-width">
                    <div className="bx--row">
                      <div className="bx--col">
                        <Select
                          id="regionList"
                          defaultValue="placeholder-item"
                          labelText="Select Country / Region"
                          invalidText="Please choose an option"
                          name="regionList"
                          onChange={this.onSelectRegDomainChange}>
                          <SelectItem
                            disabled
                            hidden
                            value="placeholder-item"
                            text="Choose an option"
                          />
                          {this.state.regionsList.map(val => (
                            <SelectItem value={val[0]} text={val[1]} />
                          ))}
                        </Select>
                      </div>
                      <div className="bx--col">
                        <Select
                          id="universityList"
                          defaultValue="placeholder-item"
                          labelText="Select Domain"
                          invalidText="Please choose an option"
                          name="universityList"
                          onChange={this.onSelectRegDomainChange}>
                          <SelectItem
                            value="placeholder-item"
                            text="Choose an option"
                          />
                          {this.state.instituteList.map(val => (
                            <SelectItem
                              value={val.id}
                              text={val.universityDomain}
                            />
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginBottom: '2rem' }}>
                  <div className="bx--grid--full-width">
                    <div className="bx--row">
                      <div className="bx--col">
                        <ButtonSet>
                          <Button
                            kind="secondary"
                            onClick={this.removeWhiteListData}>
                            Remove
                          </Button>
                          <Button
                            kind="primary"
                            onClick={this.addWhiteListData}>
                            Apply
                          </Button>
                        </ButtonSet>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <Loading
                withOverlay={true}
                description="Active loading indicator"
                active={this.state.showLoader}
              />

              <Modal
                passiveModal={true}
                preventCloseOnClickOutside={false}
                open={this.state.showAlert}
                size="sm"
                onRequestClose={() => {
                  this.setState({ showAlert: false });
                }}>
                <div style={{ padding: '1em 0' }}>
                  {this.state.alertType == 'Success' && (
                    <div>
                      <CheckmarkOutline
                        style={{ width: '7em', height: '7em', color: 'green' }}
                      />
                    </div>
                  )}
                  {this.state.alertType == 'Warning' && (
                    <div>
                      <WarningAlt
                        style={{ width: '7em', height: '7em', color: 'orange' }}
                      />
                    </div>
                  )}
                  {this.state.alertType == 'Error' && (
                    <div>
                      <MisuseOutline
                        style={{ width: '7em', height: '7em', color: 'red' }}
                      />
                    </div>
                  )}
                  <div style={{ paddingLeft: '1em' }}>
                    {this.state.alertMsg}
                  </div>
                </div>
              </Modal>

              <Modal
                hasForm
                open={this.state.isAddOverlayVisible}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                modalHeading="Apply"
                onRequestClose={() => {
                  this.closeAllOverlays();
                  this.resetForm();
                }}
                onRequestSubmit={this.admin_addWhiteLstUser}>
                <div style={{ margin: '1em 0' }}>
                  <p>Are you sure for the selected value?</p>
                </div>
              </Modal>

              <Modal
                hasForm
                open={this.state.isDeleteOverlayVisible}
                primaryButtonText="Yes"
                secondaryButtonText="No"
                modalHeading="Remove"
                onRequestClose={() => {
                  this.closeAllOverlays();
                  this.resetForm();
                }}
                onRequestSubmit={this.removeSelectedListData}>
                <div style={{ margin: '1em 0' }}>
                  <p>Are you sure for the selected value?</p>
                </div>
              </Modal>
            </div>
          </Column>
        </Grid>
      </div>
    );
  }
}
