//import './cssfile.scss';
import React, { Component } from 'react';
import './modals.scss';
import { Modal } from '@carbon/react';
class TermsModal extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    className='mymodal'
                    hasScrollingContent={false}
                    iconDescription='Close the modal'
                    modalAriaLabel=''
                    modalLabel=''
                    onRequestClose={this.props.hidden}
                    onRequestSubmit={this.props.check}
                    onSecondarySubmit={this.props.uncheck}
                    open
                    passiveModal={false}
                    primaryButtonDisabled={false}
                    primaryButtonText='Accept'
                    secondaryButtonText='Cancel'
                    selectorPrimaryFocus='[data-modal-primary-focus]'
                    size='lg'
                    >
                    {/* <div className='bx--modal-content__text' style={{ paddingTop:'2%' }}> */}
                    {/* <div style={{ padding:'2%', width:'', fontSize:'20px', textAlign : 'center' }}> */}
                    <div className='bx--modal-content__text'>
                        <div className="modalBody">

                            <p className="defaultPara customHeader">IBM SkillsBuild Software Downloads Agreement </p>
                            <p>The purpose of this IBM SkillsBuild Software Downloads agreement ("Agreement") is to make available certain software, resources, cloud services for educational and non-commercial research only to qualifying students, employees or contractors with educational institutions. As an authorized representative, you accept the terms of this Agreement by completing the registration process at <a href="https://ibm.biz/academic" target="_blank">ibm.biz/academic</a></p>

                            <p>This Agreement and any IBM license agreements or any other agreements under which Eligible Resources are made available are the complete agreement between you and IBM regarding the use of those Eligible Resources. This Agreement replaces any prior oral or written communications between you and IBM regarding this offering. If there is a conflict between any of the terms of other applicable licenses and agreements and those of this Agreement, the terms of this Agreement prevail to the extent that the terms conflict.</p><br />
                            <ul class="overlay-ul-contanier">
                                <li>
                                    <p className="defaultPara customHeader">Definitions</p>
                                    <strong>Institution:</strong> an accredited publicly- or privately-funded educational institution approved by IBM to participate in this offering. Qualifications for approval are listed at the following web site: <a href="https://ibm.biz/academic-initiative-faq"
                                        target="_blank">ibm.com/academic/faqs/faqs</a><br />
                                    <br /><strong>Registered Educator:</strong> a member of an Institution's teaching or research staff who has registered, with the authorization of their Institution, to participate in this offering.<br />
                                    <br /><strong>Registered Student:</strong> a student of an Institution who has the authorization of their Institution to participate in this offering.  To be a “Registered Student”, student must be: (1) at least sixteen (16) years old if physically located in the United States, Canada, or the European Union; (2) at least 19 years old if physically located in Algeria or South Korea; (3) at least 20 years old if physically located in Japan, New Zealand, Taiwan and Thailand; and (4) at least 18 years old if physically located in a country not listed above.<br />
                                    <br /><strong>Educational Materials:</strong> The term "Educational Materials" means educational material that IBM or a third party on behalf of IBM may make commercially available. Educational Materials may be tutorials, instructor guides, student guides, lab guides, lab exercises, slide decks, sample tests, syllabi, workbooks, charts, white papers, data sets and IBM Redbooks. Educational Materials may be delivered in the form of software or written material, computer-based training (CBT) or Web-based training (WBT) courses, Webcasts, or Adobe Acrobat ("PDF") or csv files or revisable formats.<br />
                                    <br /><strong>Eligible Resources:</strong> Eligible Resources include software, cloud services, Educational Materials, and other resources that are listed in the IBM SkillsBuild Software Downloads website at <a href="https://ibm.biz/academic" target="_blank">ibm.biz/academic.</a><br />
                                </li>
                                <li><br />
                                    <p className="defaultPara customHeader">License</p> IBM grants you a nonexclusive, nontransferable license to use Eligible Resources solely for instruction and learning, as well as noncommercial research at the Institution. This use includes the design, development and testing of applications, hardware or virtual environments created by you. Eligible Resources cannot be used in production environments. Only Registered Educators may make copies, including backup copies, to support the level of use authorized, provided that the copyright notices and any other legends of ownership are reproduced on each copy or partial copy of the Eligible Resources. Registered Educators may use Eligible Resources and distribute them only to Registered Students enrolled in specific courses/programs requiring access.<br /> <br />
                                Eligible Resources may not be 1) used, copied, modified, or distributed except as provided in this Agreement; 2) reverse assembled, reverse compiled, or otherwise translated, except as specifically permitted by law without the possibility of contractual waiver; 3) sublicensed, rented, or leased; 4) have Internet "links" to or from the offering for third parties to access. No Eligible Resource may be used for commercial or administrative purposes. In addition, Eligible Resources may not be used for noncredit courses which do not lead to nationally recognized qualifications or levels of academic achievement unless indicated to the contrary by IBM at <a
                                        href="https://ibm.biz/academic" target="_blank">ibm.biz/academic.</a> IBM shall have no responsibility for any costs required for certification examinations and training for any Registered Educator and Registered Students.<br />
                                </li>
                                <li><br />
                                    <p className="defaultPara customHeader">Derivative Works</p> Only Registered Educators may modify, translate, or take excerpts from select Educational Materials that IBM provides ("Derivative Works") subject to two exceptions specified in the Catalogue: (a) those Educational Materials from which you may not create a Derivative Work and (b) those from which you are required to create a Derivative Work from part, or all, of such Educational Materials.<br /> <br />
                                    Derivative Works may not be created from any course where the Registered Student or Registered Educator is eligible for a digital credential upon successful completion of that course.<br /> <br />
                                Derivative Works are subject to the same license terms as the Education Materials. Upon request, Registered Educators will provide IBM with a copy of any such Derivative Work. IBM has 1) an irrevocable, nonexclusive, worldwide, paid-up license to use, reproduce, display, distribute (internally and externally) copies of, and prepare derivative works based on, any such Derivative Work and 2) the right to authorize others to do any of the former.<br /> <br />
                                The terms of this Agreement apply to each copy a Registered Educator may make. Registered Students may not modify, translate, or take excerpts from the Educational Materials that IBM provides.
				</li>

                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">Cloud Services</p> Some Eligible Resources may be made available to you remotely through the Internet providing access to (i) functionality of programs, services and (ii) infrastructure and includes related offering materials as part of the remote offering. Access to and use of the IBM cloud based offerings may be subject to additional terms and conditions. IBM cloud offers may not be programs but may require you to download other software in order to use. You agree that IBM is not providing you or your Institution with access to the Internet in order to use IBM cloud offer(s) and that you remain responsible for Internet access. You also acknowledge that IBM and its subsidiaries (1) do not control the transfer of data over telecommunications facilities, including the Internet, and (2) in a public Internet environment cannot commit to particular confidentiality obligations.
					<br /><br /> Eligible Resources available through the Internet may not be "framed" or "mirrored" forming part of an IBM cloud offering, other than on your own intranets in connection with your authorized use of the IBM cloud offering.
				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">Charges and Taxes</p> IBM provides Eligible Resources under this Agreement at no charge. IBM makes programs, versions, releases, and updates of such Eligible Resources available to you as downloads, promotional codes or other distribution methods. However, if you require programs, versions, releases, or updates of such Eligible Resources or that are available in digital media (including public, private or hybrid clouds), IBM may provide it to you for a charge.
					<br /><br /> If any authority imposes a duty, tax, levy or fee, excluding those based on IBM's net income, upon Eligible Resources supplied by IBM under this Agreement, then you agree to pay that amount as IBM or IBM authorized partner/agency specifies in an invoice or equivalent document or supply exemption documentation.
				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">IBM Support and Maintenance</p> IBM does NOT provide support or maintenance for any Educational Resources provided through this Agreement. IBM provides administrative assistance only to you for access to each Eligible Resource available under this Agreement. IBM provides no charge assistance for most resources via web-based self-service forums and portals which permits access to a knowledge base of documentation, frequently asked questions, hints and tips, technical notes, readme files, programming samples, newsgroups, product fixes and refreshes, and product evaluations. Links to most IBM support resources can be found at: <a
                                        href="https://www.ibm.com/support/" target="_blank">www.ibm.com/support/</a>
                                </li>

                                <li><br />
                                    <p className="defaultPara customHeader">Your Responsibilities</p> You represent that you are either a Registered Educator or a Registered Student.
					<br /><br /><b>You agree to:</b><br /> <br /> 1. Read, understand, and agree to be bound by the terms of this Agreement.<br /><br /> 2. Ensure, as a Registered Educator, that any Registered Student to whom you provide access to any Eligible Resources has read, understands, and agrees to be bound by the terms of this Agreement. Except for Cloud services, you also agree to maintain records of all copies made of Eligible Resources, including the names and email addresses of the users to whom they were distributed.
					<br /><br /> 3. Allow IBM to monitor your utilization of resources available thru this offering and publicly report on overall IBM SkillsBuild Software Downloads participation. At no time will your individual participation be publicly shared without your consent.
					<br /><br /> 4. Request Cloud access by each qualifying Registered Educator and Registered Student.
					<br /><br /> 5. Comply with all applicable export and import laws and associated embargo and economic sanction regulations, including those of the United States, that prohibit or restrict the export, re-export, or transfer of Products, technology, Services or data, directly or indirectly, to certain countries, or for certain end uses or end users.
                    <br /><br /> 6. Never to undertake any activity connected with the use of Eligible Resources that may directly or indirectly support terrorists or acts of terrorism, or for any other unlawful purpose.
				</li>
                                <p></p><br />
                                <p>By accessing this free offering, You, as User, acknowledge that You are not violating any applicable government laws or regulations, or any Academic Institution rules or policies.</p>
                                <p></p>
                                <li>
                                    <p className="defaultPara customHeader"><br />No Warranty</p>
                                    <p className="customBold"><b>SUBJECT TO ANY STATUTORY WARRANTIES WHICH CAN NOT BE EXCLUDED, IBM MAKES NO WARRANTIES OR CONDITIONS EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT REGARDING THE ELIGIBLE RESOURCES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION MAY NOT APPLY TO YOU. The exclusion also applies to any of IBM's subcontractors, suppliers, or program developers (collectively called "Suppliers").</b></p>
                                    <br />
                                    <p>Manufacturers, suppliers, or publishers of non-IBM programs may provide their own warranties.</p>
                                </li>


                                <li><br />
                                    <p className="defaultPara customHeader">Business Contact Information</p> You agree to allow IBM and entities within International Business Machines Corporation and the subsidiaries it owns by more than fifty (50) percent ("Enterprise") to store and use your business contact information, including names, business telephone numbers, and business e-mail addresses, anywhere they do business. Such information will be processed and used in connection with our business relationship, and may be provided to contractors acting on IBM's behalf, IBM Business Partners who promote, market, and support certain IBM resources, products and services, and assignees for uses consistent with our business relationship.
				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">Limitation of Liability</p>Circumstances may arise where, because of a default on IBM's part or other liability, you are entitled to recover damages from IBM. In each such instance, regardless of the basis on which you are entitled to claim damages from IBM (including fundamental breach, negligence, misrepresentation, or other contract or tort claim), IBM is liable for no more than:<br />
                                                                                                    1. damages for bodily injury (including death) and damage to real property and tangible personal property; and<br /><br /> 2. the amount of any other actual direct damages up to the charges you paid for the Eligible Resources that is the subject of the claim. If you receive the Eligible Resources that is the subject of the claim at no charge, then IBM is not liable for any actual direct damages other than those specified in item 1 above in this section.<br />
                                    <br /> This limit also applies to any of IBM's subcontractors and Program developers. It is the maximum for which IBM and its subcontractors and Program developers are collectively responsible.
					<br />
                                    <strong><br />Items for Which IBM is Not Liable<br /></strong><br /> Under no circumstances is IBM, its subcontractors, suppliers or Program developers liable for any of the following even if informed of their possibility:<br />
                                    <br />1. loss of, or damage to, data;<br /> 2. special, incidental, or indirect damages or for any economic consequential damages; or<br /> 3. lost profits, business, revenue, goodwill, or anticipated savings.
				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">Change in Terms</p>IBM may change the terms of this Agreement by giving you one month's notice via mail or e-mail. The revised terms will be posted at the IBM SkillsBuild Software Downloads website. Otherwise, for any other change to be valid, both of us must agree through a digital signature or signed writing. Changes are not retroactive. Additional or different terms in any written communication from you are void.
				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">Term and Termination</p>Unless otherwise stated in the offer description, the offering has no term end date for as long as you qualify for participation as outlined in this Agreement.
If IBM determines that you or your Institution no longer qualify for this offering, IBM will so notify you or your Institution, as applicable, in writing via email. You agree that IBM may immediately terminate your access to and use of the Eligible Resources including Derivative Works you created. If you fail to comply with the terms of this Agreement, IBM may immediately and without prior notice terminate your access to and use of the Eligible Resources including Derivative Works you created. You may terminate this Agreement on written notice to IBM at gupai@us.ibm.com following the expiration or termination of your obligations or status. With your termination you forfeit your rights to use any resources you may have acquired under this Agreement. IBM may terminate this Agreement on one month's written notice (via email) to you. In this instance you may continue using materials which you have developed with IBM cited in your Derivative Works, however you must discontinue use of all Education Resources by the date(s) stated in the termination notice. Any terms of this Agreement which by their nature extend beyond the Agreement termination remain in effect until fulfilled, and apply to both of our respective successors and assignees.

				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">General</p> 1. You may not assign this Agreement, in whole or in part, without the prior written consent of IBM. Any attempt to do so is void.<br />
                                    <br />2. You agree to use Eligible Resources received under this Agreement  in accordance with all laws, rules, policies and regulations applicable to You, including without limitation anti-bribery laws. You agree that no-charge access to Eligible Resources is not intended to circumvent Your  established or local procurement or ethics laws or rules. In addition, such access is not intended to and will not influence, Your procurement decisions (or any organization with which You may be affiliated), . 
You agree your No-charge access to the Eligible Resources will not preclude or restrict IBM from proposing or bidding on any business opportunities that might exist now or in the future with Your institution.  No-charge access to the Eligible Resources  is not made for the purpose of lobbying.  You and IBM will each take appropriate action to mitigate or eliminate any conflicts of interest that might interfere with no-charge access to the Eligible Resources  or any future agreements between the parties.
<br /> <br />
                                    <br />3. Neither of us grants the other the right to use its trademarks, trade names, or other designations in any promotion or publication without prior written consent.<br />
                                    <br />4. All information exchanged is nonconfidential. If either of us requires the exchange of confidential information, it will be made under a signed confidentiality agreement.
					<br /><br /> 5. Each of us is free to enter into similar agreements with others.
					<br /><br /> 6. Each of us grants the other only the licenses and rights specified. No other licenses or rights (including licenses or rights under patents) are granted.
					<br /><br /> 7. Each of us may communicate with the other by electronic means and such communication is acceptable as a signed writing. An identification code (called a "user ID") contained in an electronic document is sufficient to verify the sender's identity and the document's authenticity.
					<br /><br /> 8. You agree that this Agreement will not create any right or cause of action for any third party, nor will IBM be responsible for any third party claims against you except as permitted by the Limitation of Liability section above for bodily injury (including death) or damage to real or tangible personal property for which IBM is legally liable.
					<br /><br /> 9. Neither you nor IBM will bring a legal action under this Agreement more than two years after the cause of action arose unless otherwise provided by local law without the possibility of contractual waiver or limitation.<br />
                                    <br />10. Neither you nor IBM is responsible for failure to fulfill any obligations due to causes beyond its control.
					<br /><br /> 11. In the event that any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions of this Agreement remain in full force and effect.
					<br /><br /> 12. You agree also that this agreement can be executed using an electronic signature and that the binding nature of an electronic signature is the legal equivalent of a manual signature. You also agree no certification authority or other third party verification is necessary to validate our e-signature and that the lack of such certification authority or third party verification will not in any way affect the enforceability of these English language terms and conditions regardless of local language restrictions or otherwise. If you do not agree to these terms, do not use the Eligible Resources.
				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">Geographic Scope</p> The rights, duties, and obligations of each of us are valid only in [insert name of country the offering is to be made available].
				</li>
                                <p></p>
                                <li><br />
                                    <p className="defaultPara customHeader">Governing Law</p> Both parties agree to the application of the laws of the country where the transaction is performed (or for Cloud Services, the laws of the country of Client's business address) to the Agreement, without regard to conflict of law principles. [USA Only: U.S. Government Users Restricted Rights - Use, duplication, or disclosure restricted by the GSA ADP Schedule Contract with the IBM Corporation.]
				</li>
                            </ul>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default TermsModal;