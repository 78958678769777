import { Grid, Column } from '@carbon/react';
import React from 'react';
import './_sbsd-agreement.scss';
import NormalHeader from '../../components/NormalHeader/NormalHeader';

export default class SBSDAgreement extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <NormalHeader />
        <Grid condensed>
          <Column lg={5} md={8} sm={4}>
            <div width="100%" className="bgImg1" />
          </Column>
          <Column
            lg={{ span: 8, offset: 6 }}
            md={{ span: 6, offset: 1 }}
            sm={4}>
            <span className="textContent">
              <br /> <br />
              <br /> <br />
              <h2 class="contentTitle">
                IBM SkillsBuild Software Downloads Agreement
              </h2>
              <div />
              <div>
                <div>
                  <div>
                    <div class="content_descp">
                      <p>
                        The purpose of this IBM SkillsBuild Software Downloads
                        agreement ("Agreement") is to make available certain
                        software, resources, cloud services for educational and
                        non-commercial research only to qualifying students,
                        employees or contractors with educational institutions.
                        As an authorized representative, you accept the terms of
                        this Agreement by completing the registration process
                        at&nbsp;
                        <a href="https://www.ibm.com/academic" target="_blank">
                          ibm.com/academic
                        </a>
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        This Agreement and any IBM license agreements or any
                        other agreements under which Eligible Resources are made
                        available are the complete agreement between you and IBM
                        regarding the use of those Eligible Resources. This
                        Agreement replaces any prior oral or written
                        communications between you and IBM regarding this
                        offering. If there is a conflict between any of the
                        terms of other applicable licenses and agreements and
                        those of this Agreement, the terms of this Agreement
                        prevail to the extent that the terms conflict.
                      </p>
                      <p>&nbsp;</p>

                      <h4>Definitions</h4>

                      <p>
                        Institution:&nbsp;an accredited publicly- or
                        privately-funded educational institution approved by IBM
                        to participate in this offering. Qualifications for
                        approval are listed at the following web site:&nbsp;
                        <a
                          href="https://www.ibm.com/academic/faqs/faqs"
                          rel="noopener noreferrer noopener noreferrer noopener noreferrer"
                          target="_self">
                          ibm.com/academic/faqs/faqs
                        </a>
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>Registered Educator:</strong>&nbsp;a member of
                        an Institution's teaching or research staff who has
                        registered, with the authorization of their Institution,
                        to participate in this offering.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>Registered Student:</strong>&nbsp;a student of
                        an Institution who has the authorization of their
                        Institution to participate in this offering.&nbsp;To be
                        a “Registered Student”, student must be: (1) at least
                        sixteen (16) years old if physically located in the
                        United States, Canada, or the European Union; (2) at
                        least 19 years old if physically located in Algeria or
                        South Korea; (3) at least 20 years old if physically
                        located in Japan, New Zealand, Taiwan and Thailand; and
                        (4) at least 18 years old if physically located in a
                        country not listed above.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>Educational Materials:</strong>&nbsp;The term
                        "Educational Materials"&nbsp;means educational material
                        that IBM or a third party on behalf of IBM may make
                        commercially available. Educational Materials may be
                        tutorials, instructor guides, student guides, lab
                        guides, lab exercises, slide decks, sample tests,
                        syllabi, workbooks, charts, white papers, data sets and
                        IBM Redbooks. Educational Materials may be delivered in
                        the form of software or written material, computer-based
                        training (CBT) or Web-based training (WBT) courses,
                        Webcasts, or Adobe Acrobat ("PDF") or csv files or
                        revisable formats.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>Eligible Resources:&nbsp;</strong>Eligible
                        Resources include software, cloud services, Educational
                        Materials, and other resources that are listed in the
                        IBM SkillsBuild Software Downloads website at&nbsp;
                        <a
                          href="https://www.ibm.com/academic"
                          rel="noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer"
                          target="_blank">
                          ibm.com/academic
                        </a>
                        .
                      </p>
                      <p>&nbsp;</p>

                      <h4>License</h4>

                      <p>
                        IBM grants you a nonexclusive, nontransferable license
                        to use Eligible Resources solely for instruction and
                        learning, as well as noncommercial research at the
                        Institution. This use includes the design, development
                        and testing of applications, hardware or virtual
                        environments created by you. Eligible Resources cannot
                        be used in production environments. Only Registered
                        Educators may make copies, including backup copies, to
                        support the level of use authorized, provided that the
                        copyright notices and any other legends of ownership are
                        reproduced on each copy or partial copy of the Eligible
                        Resources. Registered Educators may use Eligible
                        Resources and distribute them only to Registered
                        Students enrolled in specific courses/programs requiring
                        access.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        Eligible Resources may not be 1) used, copied, modified,
                        or distributed except as provided in this Agreement; 2)
                        reverse assembled, reverse compiled, or otherwise
                        translated, except as specifically permitted by law
                        without the possibility of contractual waiver; 3)
                        sublicensed, rented, or leased; 4) have Internet
                        "links"&nbsp;to or from the offering for third parties
                        to access. No Eligible Resource may be used for
                        commercial or administrative purposes. In addition,
                        Eligible Resources may not be used for noncredit courses
                        which do not lead to nationally recognized
                        qualifications or levels of academic achievement unless
                        indicated to the contrary by IBM on the hub catalog
                        at&nbsp;
                        <a href="https://www.ibm.com/academic" target="_blank">
                          ibm.com/academic
                        </a>
                        . IBM shall have no responsibility for any costs
                        required for certification examinations and training for
                        any Registered Educator and Registered Students.
                      </p>
                      <p>&nbsp;</p>

                      <h4>Derivative Works</h4>

                      <p>
                        Only Registered Educators may modify, translate, or take
                        excerpts from select Educational Materials that IBM
                        provides ("Derivative Works") subject to two exceptions
                        specified in the Catalogue: (a) those Educational
                        Materials from which you may not create a Derivative
                        Work and (b) those from which you are required to create
                        a Derivative Work from part, or all, of such Educational
                        Materials.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        Derivative Works may not be created from any course
                        where the Registered Student or Registered Educator is
                        eligible for a digital credential upon successful
                        completion of that course.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        Derivative Works are subject to the same license terms
                        as the Education Materials. Upon request, Registered
                        Educators will provide IBM with a copy of any such
                        Derivative Work. IBM has 1) an irrevocable,
                        nonexclusive, worldwide, paid-up license to use,
                        reproduce, display, distribute (internally and
                        externally) copies of, and prepare derivative works
                        based on, any such Derivative Work and 2) the right to
                        authorize others to do any of the former.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        The terms of this Agreement apply to each copy a
                        Registered Educator may make. Registered Students may
                        not modify, translate, or take excerpts from the
                        Educational Materials that IBM provides.
                      </p>
                      <p>&nbsp;</p>

                      <h4>Cloud Services</h4>

                      <p>
                        Some Eligible Resources may be made available to you
                        remotely through the Internet providing access to (i)
                        functionality of programs, services and (ii)
                        infrastructure and includes related offering materials
                        as part of the remote offering. Access to and use of the
                        IBM cloud based offerings may be subject to additional
                        terms and conditions. IBM cloud offers may not be
                        programs but may require you to download other software
                        in order to use. You agree that IBM is not providing you
                        or your Institution with access to the Internet in order
                        to use IBM cloud offer(s) and that you remain
                        responsible for Internet access. You also acknowledge
                        that IBM and its subsidiaries (1) do not control the
                        transfer of data over telecommunications facilities,
                        including the Internet, and (2) in a public Internet
                        environment cannot commit to particular confidentiality
                        obligations.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        Eligible Resources available through the Internet may
                        not be "framed"&nbsp;or "mirrored"&nbsp;forming part of
                        an IBM cloud offering, other than on your own intranets
                        in connection with your authorized use of the IBM cloud
                        offering.
                      </p>
                      <p>&nbsp;</p>

                      <h4>Charges and Taxes</h4>

                      <p>
                        IBM provides Eligible Resources under this Agreement at
                        no charge. IBM makes programs, versions, releases, and
                        updates of such Eligible Resources available to you as
                        downloads, promotional codes or other distribution
                        methods. However, if you require programs, versions,
                        releases, or updates of such Eligible Resources or that
                        are available in digital media (including public,
                        private or hybrid clouds), IBM may provide it to you for
                        a charge.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        If any authority imposes a duty, tax, levy or fee,
                        excluding those based on IBM's net income, upon Eligible
                        Resources supplied by IBM under this Agreement, then you
                        agree to pay that amount as IBM or IBM authorized
                        partner/agency specifies in an invoice or equivalent
                        document or supply exemption documentation.
                      </p>
                      <p>&nbsp;</p>

                      <h4>IBM Support and Maintenance</h4>

                      <p>
                        IBM does NOT provide support or maintenance for any
                        Educational Resources provided through this Agreement.
                        IBM provides administrative assistance only to you for
                        access to each Eligible Resource available under this
                        Agreement. IBM provides no charge assistance for most
                        resources via web-based self-service forums and portals
                        which permits access to a knowledge base of
                        documentation, frequently asked questions, hints and
                        tips, technical notes, readme files, programming
                        samples, newsgroups, product fixes and refreshes, and
                        product evaluations. Links to most IBM support resources
                        can be found at:&nbsp;
                        <a
                          href="https://www.ibm.com/mysupport/s/?language=en_US"
                          target="_blank">
                          www.ibm.com/support/
                        </a>
                      </p>
                      <p>&nbsp;</p>

                      <h4>Your Responsibilities</h4>

                      <p>
                        You represent that you are either a Registered Educator
                        or a Registered Student.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>You agree to:</strong>
                      </p>

                      <p>&nbsp;</p>

                      <ol>
                        <li>
                          Read, understand, and agree to be bound by the terms
                          of this Agreement.
                        </li>
                        <li>
                          Ensure, as a Registered Educator, that any Registered
                          Student to whom you provide access to any Eligible
                          Resources has read, understands, and agrees to be
                          bound by the terms of this Agreement. Except for Cloud
                          services, you also agree to maintain records of all
                          copies made of Eligible Resources, including the names
                          and email addresses of the users to whom they were
                          distributed.
                        </li>
                        <li>
                          Allow IBM to monitor your utilization of resources
                          available thru this offering and publicly report on
                          overall IBM SkillsBuild Software Downloads
                          participation. At no time will your individual
                          participation be publicly shared without your consent.
                        </li>
                        <li>
                          Request Cloud access by each qualifying Registered
                          Educator and Registered Student.
                        </li>
                        <li>
                          Comply with all applicable export and import laws and
                          associated embargo and economic sanction regulations,
                          including those of the United States, that prohibit or
                          restrict the export, re-export, or transfer of
                          Products, technology, Services or data, directly or
                          indirectly, to certain countries, or for certain end
                          uses or end users.
                        </li>
                        <li>
                          Never to undertake any activity connected with the use
                          of Eligible Resources that may directly or indirectly
                          support terrorists or acts of terrorism, or for any
                          other unlawful purpose.
                        </li>
                      </ol>
                      <p>&nbsp;</p>

                      <p>
                        By accessing this free offering, You, as User,
                        acknowledge that You are not violating any applicable
                        government laws or regulations, or any Academic
                        Institution rules or policies.
                      </p>

                      <p>&nbsp;</p>

                      <h4>No Warranty</h4>

                      <p>
                        <strong>
                          SUBJECT TO ANY STATUTORY WARRANTIES WHICH CAN NOT BE
                          EXCLUDED, IBM MAKES NO WARRANTIES OR CONDITIONS EITHER
                          EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, THE
                          IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
                          FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT
                          REGARDING THE ELIGIBLE RESOURCES. SOME JURISDICTIONS
                          DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
                          OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR
                          LIMITATION MAY NOT APPLY TO YOU. The exclusion also
                          applies to any of IBM's subcontractors, suppliers, or
                          program developers (collectively called "Suppliers").
                        </strong>
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        Manufacturers, suppliers, or publishers of non-IBM
                        programs may provide their own warranties.
                      </p>
                      <p>&nbsp;</p>

                      <h4>Business Contact Information</h4>

                      <p>
                        You agree to allow IBM and entities within International
                        Business Machines Corporation and the subsidiaries it
                        owns by more than fifty (50) percent ("Enterprise") to
                        store and use your business contact information,
                        including names, business telephone numbers, and
                        business e-mail addresses, anywhere they do business.
                        Such information will be processed and used in
                        connection with our business relationship, and may be
                        provided to contractors acting on IBM's behalf, IBM
                        Business Partners who promote, market, and support
                        certain IBM resources, products and services, and
                        assignees for uses consistent with our business
                        relationship.
                      </p>
                      <p>&nbsp;</p>

                      <h4>Limitation of Liability</h4>

                      <p>
                        Circumstances may arise where, because of a default on
                        IBM's part or other liability, you are entitled to
                        recover damages from IBM. In each such instance,
                        regardless of the basis on which you are entitled to
                        claim damages from IBM (including fundamental breach,
                        negligence, misrepresentation, or other contract or tort
                        claim), IBM is liable for no more than:
                      </p>

                      <ol>
                        <li>
                          damages for bodily injury (including death) and damage
                          to real property and tangible personal property; and
                        </li>
                        <li>
                          the amount of any other actual direct damages up to
                          the charges you paid for the Eligible Resources that
                          is the subject of the claim. If you receive the
                          Eligible Resources that is the subject of the claim at
                          no charge, then IBM is not liable for any actual
                          direct damages other than those specified in item 1
                          above in this section.
                        </li>
                      </ol>

                      <p>
                        This limit also applies to any of IBM's subcontractors
                        and Program developers. It is the maximum for which IBM
                        and its subcontractors and Program developers are
                        collectively responsible.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        <strong>Items for Which IBM is Not Liable</strong>
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        Under no circumstances is IBM, its subcontractors,
                        suppliers or Program developers liable for any of the
                        following even if informed of their possibility:
                      </p>

                      <ol>
                        <li>loss of, or damage to, data;</li>
                        <li>
                          special, incidental, or indirect damages or for any
                          economic consequential damages; or
                        </li>
                        <li>
                          lost profits, business, revenue, goodwill, or
                          anticipated savings.
                        </li>
                      </ol>
                      <p>&nbsp;</p>

                      <h4>Change in Terms</h4>

                      <p>
                        IBM may change the terms of this Agreement by giving you
                        one month's notice via mail or e-mail. The revised terms
                        will be posted at the IBM SkillsBuild Software
                        Downloads&nbsp;website. Otherwise, for any other change
                        to be valid, both of us must agree through a digital
                        signature or signed writing. Changes are not
                        retroactive. Additional or different terms in any
                        written communication from you are void.
                      </p>
                      <p>&nbsp;</p>

                      <h4>Term and Termination</h4>

                      <p>
                        Unless otherwise stated in the offer description, the
                        offering has no term end date for as long as you qualify
                        for participation as outlined in this Agreement.
                      </p>

                      <p>&nbsp;</p>

                      <p>
                        If IBM determines that you or your Institution no longer
                        qualify for this offering, IBM will so notify you or
                        your Institution, as applicable, in writing via email.
                        You agree that IBM may immediately terminate your access
                        to and use of the Eligible Resources including
                        Derivative Works you created. If you fail to comply with
                        the terms of this Agreement, IBM may immediately and
                        without prior notice terminate your access to and use of
                        the Eligible Resources including Derivative Works you
                        created. You may terminate this Agreement on written
                        notice to IBM at gupai@us.ibm.com&nbsp;following the
                        expiration or termination of your obligations or status.
                        With your termination you forfeit your rights to use any
                        resources you may have acquired under this Agreement.
                        IBM may terminate this Agreement on one month's written
                        notice (via email) to you. In this instance you may
                        continue using materials which you have developed with
                        IBM cited in your Derivative Works, however you must
                        discontinue use of all Education Resources by the
                        date(s) stated in the termination notice. Any terms of
                        this Agreement which by their nature extend beyond the
                        Agreement termination remain in effect until fulfilled,
                        and apply to both of our respective successors and
                        assignees.
                      </p>
                      <p>&nbsp;</p>

                      <h4>General</h4>

                      <ol>
                        <li>
                          You may not assign this Agreement, in whole or in
                          part, without the prior written consent of IBM. Any
                          attempt to do so is void.
                        </li>
                        <li>
                          You agree to use Eligible Resources received under
                          this Agreement &nbsp;in accordance with all laws,
                          rules, policies and regulations applicable to You,
                          including without limitation anti-bribery laws. You
                          agree that no-charge access to Eligible Resources is
                          not intended to circumvent Your &nbsp;established or
                          local procurement or ethics laws or rules. In
                          addition, such access is not intended to and will not
                          influence, Your procurement decisions (or any
                          organization with which You may be affiliated).
                          <ul>
                            <li>
                              You agree your No-charge access to the Eligible
                              Resources will not preclude or restrict IBM from
                              proposing or bidding on any business opportunities
                              that might exist now or in the future with Your
                              institution.&nbsp; No-charge access to the
                              Eligible Resources&nbsp; is not made for the
                              purpose of lobbying.&nbsp; You and IBM will each
                              take appropriate action to mitigate or eliminate
                              any conflicts of interest that might interfere
                              with no-charge access to the Eligible Resources
                              &nbsp;or any future agreements between the
                              parties.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Neither of us grants the other the right to use its
                          trademarks, trade names, or other designations in any
                          promotion or publication without prior written
                          consent.
                        </li>
                        <li>
                          All information exchanged is nonconfidential. If
                          either of us requires the exchange of confidential
                          information, it will be made under a signed
                          confidentiality agreement.
                        </li>
                        <li>
                          Each of us is free to enter into similar agreements
                          with others.
                        </li>
                        <li>
                          Each of us grants the other only the licenses and
                          rights specified. No other licenses or rights
                          (including licenses or rights under patents) are
                          granted.
                        </li>
                        <li>
                          Each of us may communicate with the other by
                          electronic means and such communication is acceptable
                          as a signed writing. An identification code (called a
                          "user ID") contained in an electronic document is
                          sufficient to verify the sender's identity and the
                          document's authenticity.
                        </li>
                        <li>
                          You agree that this Agreement will not create any
                          right or cause of action for any third party, nor will
                          IBM be responsible for any third party claims against
                          you except as permitted by the Limitation of Liability
                          section above for bodily injury (including death) or
                          damage to real or tangible personal property for which
                          IBM is legally liable.
                        </li>
                        <li>
                          Neither you nor IBM will bring a legal action under
                          this Agreement more than two years after the cause of
                          action arose unless otherwise provided by local law
                          without the possibility of contractual waiver or
                          limitation.
                        </li>
                        <li>
                          Neither you nor IBM is responsible for failure to
                          fulfill any obligations due to causes beyond its
                          control.
                        </li>
                        <li>
                          In the event that any provision of this Agreement is
                          held to be invalid or unenforceable, the remaining
                          provisions of this Agreement remain in full force and
                          effect.
                        </li>
                        <li>
                          You agree also that this agreement can be executed
                          using an electronic signature and that the binding
                          nature of an electronic signature is the legal
                          equivalent of a manual signature. You also agree no
                          certification authority or other third party
                          verification is necessary to validate our e-signature
                          and that the lack of such certification authority or
                          third party verification will not in any way affect
                          the enforceability of these English language terms and
                          conditions regardless of local language restrictions
                          or otherwise. If you do not agree to these terms, do
                          not use the Eligible Resources.
                        </li>
                      </ol>
                      <p>&nbsp;</p>

                      <h4>Geographic Scope</h4>

                      <p>
                        The rights, duties, and obligations of each of us are
                        valid only in [insert name of country the offering is to
                        be made available].
                      </p>
                      <p>&nbsp;</p>

                      <h4>Governing Law</h4>

                      <p>
                        Both parties agree to the application of the laws of the
                        country where the transaction is performed (or for Cloud
                        Services, the laws of the country of Client's business
                        address) to the Agreement, without regard to conflict of
                        law principles. [USA Only: U.S. Government Users
                        Restricted Rights -&nbsp;Use, duplication, or disclosure
                        restricted by the GSA ADP Schedule Contract with the IBM
                        Corporation.]
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
              <br /> <br />
              <br /> <br />
            </span>
          </Column>
        </Grid>
      </div>
    );
  }
}
