import {Link } from '@carbon/react';
import React from 'react';
import './validate.scss';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
export default class ValidateEmail extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            dataFaq: localStorage.getItem('dataFAQ') != undefined ? localStorage.getItem('dataFAQ') : "https://www.ibm.com/academic/faqs/faqs"
        }
        if (localStorage.getItem('ConfigDays') !== null) { localStorage.removeItem('ConfigDays'); }
      }
      handleRedirect= (event) => {
        window.location = '/a2mt/email-auth';
      }
      render() {
       
        return (
            <div>
                <NormalHeader></NormalHeader>
               
                <div className="bx--grid--full-width">
                    <div className="bx--row">
                        
                        <div className="bx--col" style={{ padding: "5em" }}>
                            <div className="textContent">
                                <h3>Your recertification process has completed now.</h3><br></br> <br></br>
                                <p className="defaultPara customText">Please <Link href="#" onClick={this.handleRedirect}>click here</Link> to login the application.</p>
                                </div>
                        </div>
                    </div>
                </div>
               
            </div>
        )
    }
    }