import React from 'react';
import {
    Grid,
    Column,
    Theme,
    Section,
    Button,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Loading
} from '@carbon/react';
import { Launch, Calendar, WatsonHealthStatusAcknowledge, Person, Workspace } from '@carbon/icons-react';
import factoryService from '../../services/factory-service';
import utilService from '../../services/util-service';
import queryString from 'query-string';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Workshop = () => {
    const [wsID, setWsID] = useState('');
    const [eventDetails, setEventDetails] = useState(null);
    const [eventDateValue, setEventDateValue] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    
    useEffect(() => {
        let params = queryString.parse(window.location.search);
        console.log('paramsGetter', params);
        setShowLoader(true);

        const loadEvent = async (id) => {
            const det = await factoryService.getEventDetails(id);
            console.log('det', det); setShowLoader(false);
            if (det !== null) {
                setEventDetails(det);
                let newDate = eventDateFormat(det);
                setEventDateValue(newDate);
            }
        };

        if (typeof params['ws'] !== 'undefined') {
            sessionStorage.setItem('WorkShopId', params['ws']);
            if (typeof cookies.get('AppAccess') !== 'string') {
                setTimeout(() => { window.location.href = factoryService.removeParams(window.location.href); }, 3000);
            } else {
                window.location.href = factoryService.removeParams(window.location.href);
            }
            
            
        } else if (sessionStorage.getItem('WorkShopId') !== 'undefined' && sessionStorage.getItem('WorkShopId') !== null) {
            setWsID(sessionStorage.getItem('WorkShopId'));
            loadEvent(sessionStorage.getItem('WorkShopId'));
            // sessionStorage.removeItem('WorkShopId');
        }
    }, []);

    const eventDateFormat = (data) => {
        let sDate = data.START_DATE;
        let eDate = data.END_DATE.split(',')[0];
        if (sDate === eDate) {
            return data.END_DATE.split(',')[0] + ', ' + data.END_DATE.split(',')[1];;
        } else {
            let date = data.START_DATE.split(',')[0] + ' - ' + data.END_DATE.split(',')[0] + ', ' + data.END_DATE.split(',')[1];;
            return date;
        }
    };

    const requestWorkshopCTA = (event) => {
        event.stopPropagation();
        if (sessionStorage.getItem('userEm') !== 'undefined' && sessionStorage.getItem('userEm') !== null) {
            utilService.setOtherPageAccessible(true);
            window.location.href = "/a2mt/event-request";
        } else {
            sessionStorage.setItem('WorkShopId', wsID);
            window.location.href = "/a2mt/email-auth";
        }
    }

    return (
        eventDetails !== null && (
            <Section>
                <NormalHeader></NormalHeader>
                <Theme theme="g10">
                    <Grid className="workshop">
                        <Column md={6} lg={{ span: 8, offset: 4 }} sm={4} className="workshop__banner">
                            <h1 className="workshop__heading" style={{ whiteSpace: "pre-wrap" }}
                                dangerouslySetInnerHTML={{ __html: eventDetails.TITLE }}></h1>
                            <p><Workspace size={20} className="workshop__attributeIcons"></Workspace> <span className="workshop__attributeText">Topic domain: {eventDetails.TOPIC}</span></p>
                            <p><Calendar size={20} className="workshop__attributeIcons"></Calendar> <span className="workshop__attributeText">Date: {eventDateValue}, {eventDetails.START_TIME} - {eventDetails.END_TIME}, {eventDetails.timeZone} {'(' + eventDetails.relative_to_gmt + ')'}</span></p>
                            <p><WatsonHealthStatusAcknowledge size={20} className="workshop__attributeIcons"></WatsonHealthStatusAcknowledge> <span className="workshop__attributeText">Status: {eventDetails.STATUS} for registration</span></p>
                            <p><Person size={20} className="workshop__attributeIcons"></Person> <span className="workshop__attributeText">Capacity: {eventDetails.CAPACITY} Participants</span></p>
                            <p className="workshop__requestButton"><Button renderIcon={Launch} iconDescription="Register for workshop" id="signup-button" onClick={e=>requestWorkshopCTA(e)}>Sign up</Button></p>
                        </Column>
                        <Column md={2} lg={{ span: 3, offset: 13 }} sm={2} className="workshop__verticalLine">
                            <p><img alt="Badge Image" aria-label="Badge Image" src={eventDetails.PHOTO_LINK} className="workshop__badgeImg" /></p>
                        </Column>
                    </Grid>
                </Theme>
                <Theme theme="white">
                    <Grid className="workshop">
                        <Column md={8} lg={{ span: 8, offset: 4 }} sm={4} className="workshop__tabs">
                            <Tabs>
                                <TabList>
                                    <Tab>Overview</Tab>
                                    <Tab>Objectives</Tab>
                                </TabList>
                                <TabPanels className="workshop__details">
                                    <TabPanel><p className="workshop__content" dangerouslySetInnerHTML={{ __html: eventDetails.DESCRIPTION }}></p></TabPanel>
                                    <TabPanel><p className="workshop__content" dangerouslySetInnerHTML={{ __html: eventDetails.OBJECTIVE }}></p></TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Column>
                    </Grid>
                </Theme>
                <Loading withOverlay={true} description="Active loading indicator" active={showLoader}></Loading>
            </Section>
        )
    )
};

export default Workshop;