//import './cssfile.scss';
import React, { Component } from 'react';
import './modals.scss';
import { Modal } from '@carbon/react';
class RecertifyModal extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    className='mymodal'
                    hasScrollingContent={false}
                    iconDescription='Close the modal'
                    modalAriaLabel=''
                    modalLabel=''
                    onRequestClose={this.props.hidden}
                    onRequestSubmit={this.props.recertify}
                    onSecondarySubmit={this.props.unrecertify}
                    open
                    passiveModal={false}
                    primaryButtonDisabled={false}
                    primaryButtonText='Proceed'
                    secondaryButtonText='Cancel'
                    selectorPrimaryFocus='[data-modal-primary-focus]'
                    size='lg'>
                    {/* <div className='bx--modal-content__text' style={{ paddingTop:'2%' }}> */}
                    {/* <div style={{ padding:'2%', width:'', fontSize:'20px', textAlign : 'center' }}> */}
                    <div className='bx--modal-content__text'>
                    <div className="modalBody">
			
                    
			<p>Please confirm your registration details are correct and click Proceed. Click on Cancel should you need to edit the information.</p>
           
              
		</div>
        </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default RecertifyModal;