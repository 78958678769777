import React, { Component } from 'react';
import moment from 'moment';
import './recertification.scss';
import { Save } from '@carbon/icons-react';
import Axios from 'axios';
import utilService from '../../../services/util-service';
import {
  Grid,
  Column,
  NumberInput,
  TableSelectRow,
  DataTable,
  TableSelectAll,
  TableBatchAction,
  TableToolbar,
  TableBatchActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Loading,
} from '@carbon/react';
export default class Recertification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: utilService.hostname,
      rowData: [],
      emailId: localStorage.getItem('adminEmail'),
      showLoader: false,
      counterVal: [],
      keyRownum: [],
      selectedRow: [],
    };
    this.handleEvent = this.handleEvent.bind(this);
  }
  admin_getRecertificationList() {
    utilService
      .getData('/a2mt/services/user-details/getRecertList')
      .then(res => {
        console.log('getRecertificationList>>>', res);
        if (res.status === 200) {
          this.setState({
            rowData: res.data,
          });
        }
      })
      .catch(error => {
        throw error;
      });
  }
  admin_updateRecertificationCount() {
    //console.log(counterVal);
    //console.log(selectedRow);
    this.setState({
      showLoader: true,
    });
    let finalArray = [];
    for (let i = 0; i < this.state.counterVal.length; i++) {
      for (let j = 0; j < this.state.selectedRow.length; j++) {
        let obj = {
          id: '',
          defaultRecertCount: '',
          updatedBy: '',
          universityEmail: '',
        };
        if (
          this.state.counterVal[i]['idx'] ==
          this.state.selectedRow[j]['idx'].toString()
        ) {
          for (let k = 0; k < this.state.selectedRow[j]['cells'].length; k++) {
            if (
              this.state.selectedRow[j]['cells'][k]['info']['header'] ===
              'universityEmail'
            ) {
              obj['id'] = this.state.selectedRow[j]['id'];
              obj['updatedBy'] = this.state.emailId;
              obj['defaultRecertCount'] = this.state.counterVal[i]['val'];
              obj['universityEmail'] = this.state.selectedRow[j]['cells'][k][
                'value'
              ];
              finalArray.push(obj);
            }
          }
        }
      }
    }
    console.log(finalArray);
    utilService
      .postData('/a2mt/services/user-details/updRecertCount', finalArray)
      .then(res => {
        console.log('updateRecertificationCount>>>', res);
        if (res.status === 200) {
          this.setState({
            showLoader: false,
            counterVal: [],
            keyRownum: [],
            selectedRow: [],
          });
          this.admin_getRecertificationList();
        }
      })
      .catch(error => {
        throw error;
      });
  }
  componentDidMount() {
    this.admin_getRecertificationList();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
    if (prevState.name !== this.state.name) {
      this.handler();
    }
  }
  componentWillUnmount() {}
  recertificationSelection(evt, index) {
    console.log(evt);
    evt.idx = index;
    if (evt.isSelected === true) {
      for (let i = 0; i < this.state.selectedRow.length; i++) {
        if (evt.id === this.state.selectedRow[i]['id']) {
          this.state.selectedRow.splice(i, 1);
        }
      }
    } else {
      for (let i = 0; i < this.state.counterVal.length; i++) {
        if (this.state.counterVal[i]['idx'] == index) {
          this.state.selectedRow.push(evt);
        }
      }
    }
    console.log(this.state.selectedRow);
  }
  numberChange = (event, name) => {
    console.log('+++++++>>>', event.target.id.split('_')[1], name);
    if (event.target.value > 0) {
      this.setState({
        [name]: true,
      });
    } else {
      this.setState({
        [name]: false,
      });
    }
    let indexPostion = this.state.keyRownum.indexOf(
      event.target.id.split('_')[1]
    );
    let obj = {
      idx: event.target.id.split('_')[1],
      val: event.target.value,
    };
    if (indexPostion == -1) {
      this.state.keyRownum.push(event.target.id.split('_')[1]);
      this.state.counterVal.push(obj);
    } else if (indexPostion > -1) {
      if (event.target.value == 0) {
        this.state.keyRownum.splice(indexPostion, 1);
        this.state.counterVal.splice(indexPostion, 1);
        console.log('ZERO');
      } else {
        this.state.counterVal[indexPostion]['val'] = event.target.value;
      }
    }
    console.log(this.state.counterVal);
  };
  isActive_Row(value, name) {
    return this.state[name] === true ? 'activeRow' : 'defaultRow';
  }
  // Prototype methods, Bind in Constructor (ES2015)
  handleEvent() {}
  // Class Properties (Stage 3 Proposal)
  handler = () => {
    this.setState();
  };
  render() {
    const headerData = [
      { header: 'Email', key: 'universityEmail' },
      { header: 'Domain', key: 'domainName' },
      { header: 'Date', key: 'recertDate' },
      { header: 'Type', key: 'student_Faculty' },
      {
        header: 'Recert Count',
        key: 'recertCount',
      },
    ];
    return (
      <div className="body-height">
        <Grid className="admin-sub-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <div>
              <div style={{ paddingBottom: '32px' }}>
                <h3>Recertification</h3>
              </div>
              <Loading
                withOverlay={true}
                description="Active loading indicator"
                active={this.state.showLoader}
              />
              <section>
                <DataTable rows={this.state.rowData} headers={headerData}>
                  {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getSelectionProps,
                    getToolbarProps,
                    getBatchActionProps,
                    onInputChange,
                    selectedRows,
                    getTableProps,
                    getTableContainerProps,
                  }) => (
                    <TableContainer {...getTableContainerProps()}>
                      <TableToolbar {...getToolbarProps()}>
                        <TableBatchActions {...getBatchActionProps()}>
                          <TableBatchAction
                            tabIndex={
                              getBatchActionProps().shouldShowBatchActions
                                ? 0
                                : -1
                            }
                            renderIcon={Save}
                            onClick={() =>
                              this.admin_updateRecertificationCount()
                            }>
                            Save
                          </TableBatchAction>
                        </TableBatchActions>
                      </TableToolbar>
                      <Table {...getTableProps()}>
                        <TableHead>
                          <TableRow>
                            <TableSelectAll
                              {...getSelectionProps()}
                              disabled={true}
                            />
                            {headers.map((header, i) => (
                              <TableHeader
                                key={i}
                                {...getHeaderProps({ header })}>
                                {header.header}
                              </TableHeader>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, i) => (
                            <TableRow key={i} {...getRowProps({ row })}>
                              <TableSelectRow
                                className={this.isActive_Row(
                                  i,
                                  'active_row' + i
                                )}
                                {...getSelectionProps({
                                  row,
                                  onClick: () =>
                                    this.recertificationSelection(row, i),
                                })}
                                // disabled ={
                                //   this.state.rowData[i]
                                // }
                              />
                              {row.cells.map(cell => (
                                <TableCell key={cell.id}>
                                  {cell.id.indexOf('recertCount') !== -1 ? (
                                    <NumberInput
                                      onChange={e =>
                                        this.numberChange(e, 'active_row' + i)
                                      }
                                      id={'counterVal_' + i}
                                      invalidText="Please enter valid duration"
                                      min={0}
                                      max={10}
                                    />
                                  ) : cell.id.indexOf('student_Faculty') !==
                                    -1 ? (
                                    cell.value == 2 ? (
                                      'Faculty'
                                    ) : (
                                      'Student'
                                    )
                                  ) : cell.id.indexOf('recertDate') !== -1 ? (
                                    moment(cell.value).format('LL')
                                  ) : (
                                    cell.value
                                  )}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </DataTable>
              </section>
            </div>
          </Column>
        </Grid>
      </div>
    );
  }
}
