import { Theme,
  Grid,
  Column,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenuButton,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent } from '@carbon/react';
import { Help, UserAvatar, Search } from '@carbon/react/icons';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import './register-email-not-matched.scss';
//import utilService from '../../services/util-service';

const RegisterEmailidNotmatched = () => {
  useEffect(() => {
    init();
  }, []);

  function init() {
    sessionStorage.removeItem('isOtherPageAccessible');
    //utilService.setOtherPageAccessible(false);
  }


    return (
      <div>
        <HeaderContainer
          render={({ isSideNavExpanded, onClickSideNavExpand }) => (
            <Theme theme='g100'>
              <Header aria-label="IBM SkillsBuild Software Downloads">
                <SkipToContent />
                <HeaderMenuButton aria-label={isSideNavExpanded ? 'Close menu' : 'Open menu'} onClick={onClickSideNavExpand} isActive={isSideNavExpanded} aria-expanded={isSideNavExpanded} />
                <HeaderName as={Link} to="/" prefix="IBM">
                  SkillsBuild Software Downloads
                </HeaderName>
                <HeaderGlobalBar>
                  <HeaderGlobalAction aria-label="Search">
                    <Search size={20} />
                  </HeaderGlobalAction>
                  <HeaderGlobalAction aria-label="Help">
                    <Help size={20} />
                  </HeaderGlobalAction>
                  <HeaderGlobalAction aria-label="User Profile" tooltipAlignment="end">
                    <UserAvatar size={20} />
                  </HeaderGlobalAction>
                </HeaderGlobalBar>

                
              </Header>
            </Theme>
          )}
        />
        <Theme theme="g10">
        <Grid className="landing-page" style={{height:600+'px'}}>
          <Column lg={{ span: 12, offset: 2 }} md={8} sm={4}>
              <h1 className="invalid-text" style={{paddingTop:"3em"}}>
                Another IBMid has already been used with this application. Please clear your browser's cache prior to logging in with a new IBMid.
              </h1>
              </Column>
          </Grid>
        </Theme>
      </div>
    )
}
export default RegisterEmailidNotmatched;
