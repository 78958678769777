import {
  Modal,
  Loading,
  ComposedModal,
  ModalFooter,
  ModalBody,
  Button,
  ModalHeader,
  Grid,
  Column,
  Theme,
  Tile,
  Link,
} from '@carbon/react';
import {
  Help,
  UserAvatar,
  CheckmarkOutline,
  ArrowRight,
} from '@carbon/icons-react';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import {
  AddDocument,
  Multitask,
  ListBullet,
  University,
  Advocate,
  Tools,
  Gear,
  Marketplace,
  ManagingItems,
  Digital,
} from '@carbon/pictograms-react';
import React from 'react';
import './admin-dashboard.scss';
import AdminSettings from '../admin-settings';
import NewDomainList from '../new-domain-list';
import AdminList from '../admin-list';
import EditProgram from '../edit-program';
//import AdminHome from "../admin-home/admin-home";
import InstituteManagement from '../institute-management';
import Recertification from '../recertification-list';
import WhiteList from '../white-list';
import Axios from 'axios';
import utilService from '../../../services/util-service';
import WorkshopEvent from '../create-workshop';
import WorkshopEventForm from '../workshop-event-form';
import ManageWorkshop from '../manage-workshop/manage-workshop';
import SPSS from '../spss';
// import { Footer } from "@carbon/ibmdotcom-react";
// import '@carbon/ibmdotcom-styles';
import Cookies from 'universal-cookie';
import { IdleSessionTimeout } from 'idle-session-timeout';
import SbsdHeader from '../SBSDHeader/SBSDHeader';
const cookies = new Cookies();
var session = new IdleSessionTimeout(15 * 60 * 1000, 'click');

export default class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    utilService.setBaseURL();
    console.log('URL_Params', this.props.match.params.id);
    this.state = {
      baseURL: utilService.hostname,
      emailId: localStorage.getItem('adminEmail'),
      //emailId: 'saambig1@in.ibm.com',
      dataFaq:
        localStorage.getItem('dataFAQ') != undefined
          ? localStorage.getItem('dataFAQ')
          : 'https://www.ibm.com/academic/faqs/faqs',
      platfrmName:
        localStorage.getItem('ConfigPortalName') != undefined
          ? localStorage.getItem('ConfigPortalName')
          : 'IBM SkillsBuild Software Downloads',
      currentRole: '',
      isSideNavExpanded: false,
      user_name: localStorage.getItem('userName'),
      isUserprofile: false,
      isLoggedin: false,
      showPage:
        typeof this.props.match.params.id == 'undefined'
          ? ''
          : this.props.match.params.id,
      logoutConfirmation: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      showAlertLogout: false,
      alertMsgLogout: 'Test',
      alertTypeLogout: 'Success',
      showLoader: false,
      cardData: [
        {
          ID: 1,
          NAME: 'New Domain requests',
          LNAME: 'New Domain requests',
          IMG_URL: '',
          VISIBILITY: 'A',
          ICON: 'Domain',
        },
        {
          ID: 2,
          NAME: 'Institution Management',
          LNAME: 'Institution Management',
          IMG_URL: '',
          VISIBILITY: 'A',
          ICON: 'Education',
        },
        {
          ID: 3,
          NAME: 'Exception list',
          LNAME: 'Exception list',
          IMG_URL: '',
          VISIBILITY: 'A',
          ICON: 'Exception',
        },
        {
          ID: 4,
          NAME: 'Recertification',
          LNAME: 'Recertification',
          IMG_URL: '',
          VISIBILITY: 'A',
          ICON: 'Recertification',
        },
        {
          ID: 5,
          NAME: 'Admin Access',
          LNAME: 'Admin Access',
          IMG_URL: '',
          VISIBILITY: 'SA',
          ICON: 'Admin',
        },
        {
          ID: 6,
          NAME: 'Program Configuration',
          LNAME: 'Program Configuration',
          IMG_URL: '',
          VISIBILITY: 'SA',
          ICON: 'Program',
        },
        {
          ID: 7,
          NAME: 'Program Settings',
          LNAME: 'Settings',
          IMG_URL: '',
          VISIBILITY: 'SA',
          ICON: 'Program',
        },
        {
          ID: 8,
          NAME: 'Create event/workshop',
          LNAME: 'Create workshop',
          IMG_URL: '',
          VISIBILITY: 'SK',
          ICON: 'Workshop',
        },
        {
          ID: 9,
          NAME: 'Manage Workshop Registrations',
          LNAME: 'Manage Registrations',
          IMG_URL: '',
          VISIBILITY: 'A',
          ICON: 'Registrations',
        },
        {
          ID: 10,
          NAME: 'Manage SPSS',
          LNAME: 'SPSS',
          IMG_URL: '',
          VISIBILITY: 'SP',
          ICON: 'SPSS',
        },
      ],
    };
    session.onTimeOut = () => {
      this.setState({
        alertMsgLogout: 'Session expired. Please login back again.',
        alertTypeLogout: 'Error',
        showAlertLogout: true,
      });
    };
    session.onTimeLeftChange = timeLeft => {
      /*console.log(
        'Session time left-',
        this.millisToMinutesAndSeconds(timeLeft)
      );*/
    };
    session.start();
  }

  onClickSideNavExpand = event => {
    console.log(event.target);
    if (this.state.isSideNavExpanded === false) {
      this.setState({ isSideNavExpanded: true });
    } else {
      this.setState({ isSideNavExpanded: false });
    }
  };

  UserProfileLoad = () => {
    if (this.state.user_name != null && this.state.user_name != '') {
      console.log('if', typeof this.state.user_name);
      this.setState({
        isLoggedin: true,
      });
    } else {
      this.setState({
        isLoggedin: false,
      });
    }
  };

  userProfileToggle = () => {
    if (this.state.isUserprofile === true) {
      this.setState({
        isUserprofile: false,
      });
    } else {
      this.setState({
        isUserprofile: true,
      });
    }
  };

  handleRedirect(event, data) {
    // utilService.setOtherPageAccessible(true);
    // window.location = '/a2mt/admin-dashboard/' + path;

    utilService.setOtherPageAccessible(true);
    window.location =
      '/a2mt/admin-dashboard/' +
      data
        .split(' ')
        .join('-')
        .toLowerCase();
  }

  componentDidMount() {
    if (typeof cookies.get('Jwtauth') != 'string') {
      this.getJWT();
    } else {
      this.checkUserRole();
    }
    this.UserProfileLoad();
    document.body.addEventListener('click', function(evt) {
      //console.log(evt.target.id)
      if (evt.target.id !== 'user_wrap' && evt.target.id === undefined) {
        if (this.state.isUserprofile === true) {
          this.setState({
            isUserprofile: false,
          });
        }
      }
    });
  }

  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');

    if (sessionStorage.getItem('redirectHome') == undefined) {
      utilService.setOtherPageAccessible(false);
      sessionStorage.removeItem('redirectHome');
    } else {
      utilService.setOtherPageAccessible(true);
      sessionStorage.removeItem('redirectHome');
    }
  }

  async getJWT() {
    this.setState({ showLoader: true });
    await Axios.get(this.state.baseURL + '/a2mt/services/user-details/getJwt', {
      headers: { emailId: this.state.emailId },
    })
      .then(res => {
        console.log('Header Data', res);
        if (typeof res.headers['jwt-auth'] != 'undefined') {
          let token = res.headers['jwt-auth'];
          cookies.set('Jwtauth', token);
          cookies.set('rfrT', res.headers['refresh']);
          this.setSession(token);
          this.setTokenExpiry(token);
          this.checkUserRole();
        } else {
          this.setState({ showLoader: false });
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  setSession(token) {
    // const decodedToken = decodeToken(token);
    // let tokenExpiration = moment(decodedToken.exp, 'X');
    // let currentTime = moment();
    // console.log('TokenExpiration is after currentTime', tokenExpiration.isAfter(currentTime));
    // let duration = moment.duration(tokenExpiration.diff(currentTime));
    // console.log('Time difference from currentTime', this.millisToMinutesAndSeconds(duration));
    let duration = 15 * 60 * 1000;

    session.dispose();
    session = new IdleSessionTimeout(duration, 'click');

    session.onTimeOut = () => {
      this.setState({
        alertMsgLogout: 'Session expired. Please login back again.',
        alertTypeLogout: 'Error',
        showAlertLogout: true,
      });
    };
    session.onTimeLeftChange = timeLeft => {
      /*console.log(
        'Session time left-',
        this.millisToMinutesAndSeconds(timeLeft)
      );*/
    };
    session.start();
  }

  setTokenExpiry(token) {
    // const decodedToken = decodeToken(token);
    // const tokenExpiration = moment(decodedToken.exp, 'X');
    // let currentTime = moment();
    // let duration = moment.duration(tokenExpiration.diff(currentTime));
    let duration = 27 * 60 * 1000;
    // console.log('sesTime', process.env.REACT_APP_SESSION_TIME);
    // let duration = (process.env.REACT_APP_SESSION_TIME) * 60 * 1000;

    setTimeout(() => {
      this.checkTokenExpiry();
    }, duration);
    //console.log('milli', this.millisToMinutesAndSeconds(duration));
  }

  async checkTokenExpiry() {
    await Axios.get(
      this.state.baseURL + '/a2mt/services/user-details/checkExpiration',
      { headers: { JwtExpiration: cookies.get('rfrT') } }
    )
      .then(res => {
        console.log('Header Data2', res);
        if (typeof res.headers['jwt-auth'] != 'undefined') {
          let token = res.headers['jwt-auth'];
          cookies.set('Jwtauth', token);
          // this.setSession(token);
          this.setTokenExpiry(token);
        } else {
          this.setState({
            alertMsgLogout: 'Session expired. Please login back again.',
            alertTypeLogout: 'Error',
            showAlertLogout: true,
          });
        }
      })
      .catch(error => {
        this.setState({
          alertMsgLogout: 'Session expired. Please login back again.',
          alertTypeLogout: 'Error',
          showAlertLogout: true,
        });
      });
  }

  checkUserRole() {
    Axios.get(
      this.state.baseURL +
        '/a2mt/services/user-details/chkAdminData/' +
        this.state.emailId,
      { headers: { jwtToken: cookies.get('Jwtauth') } }
    )
      .then(res => {
        this.setState({ showLoader: false });
        console.log('User Data', res.data);
        if (res.data != null && typeof res.data.result == 'undefined') {
          this.setState({ currentRole: res.data[0].adminType });
          let role = res.data[0].adminType;
          let data = [];
          for (var i = 0; i < this.state.cardData.length; i++) {
            if (role === 'SK' && this.state.cardData[i].VISIBILITY === 'SK') {
              data.push(this.state.cardData[i]);
              this.setState({ cardData: data });
            }
            if (role === 'SP' && this.state.cardData[i].VISIBILITY === 'SP') {
              data.push(this.state.cardData[i]);
              this.setState({ cardData: data });
            }
            if (role === 'A' && this.state.cardData[i].VISIBILITY === 'A') {
              data.push(this.state.cardData[i]);
              this.setState({ cardData: data });
            }
          }
        } else if (typeof res.data.result != 'undefined') {
          this.setState({ currentRole: '' });
          this.setState({
            alertMsgLogout: 'Session expired. Please login back again.',
            alertTypeLogout: 'Error',
            showAlertLogout: true,
          });
        } else {
          this.setState({ currentRole: '' });
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  userLogout() {
    if (typeof cookies.get('Jwtauth') != 'undefined') {
      cookies.remove('Jwtauth');
      cookies.remove('rfrT');
    }
    localStorage.removeItem('adminEmail');
    localStorage.removeItem('userName');
    localStorage.removeItem('isSuperAdmin');
    localStorage.removeItem('isSKAdmin');
    localStorage.removeItem('isSPSSAdmin');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('university_email');
    sessionStorage.removeItem('isOtherPageAccessible');
    sessionStorage.removeItem('redirectHome');
    utilService.setOtherPageAccessible(false);
    window.location = '/a2mt/email-auth';
  }

  millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  }

  render() {
    return (
      <div>
        <SbsdHeader cardData={this.state.cardData} />
        <Theme theme="g10">
          {this.state.showPage == '' && (
            <Grid className="admin-home-page">
              <Column lg={{ span: 12, offset: 4 }} md={8} sm={4} />
              <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
                <h1 className="landing-page__subheading">
                  Explore IBM SkillsBuild Software Downloads
                </h1>
              </Column>
              {this.state.cardData !== null &&
                this.state.cardData.map((v, idx) => {
                  return (
                    <Column
                      sm={4}
                      md={4}
                      lg={idx % 3 === 0 ? { span: 4, offset: 4 } : { span: 4 }}
                      className="admin-page__gapping"
                      onClick={e => this.handleRedirect(e, v.LNAME)}>
                      <Tile id="tile-1">
                        {v.ICON === 'Education' && (
                          <University className="iconSize" />
                        )}
                        {v.ICON === 'Domain' && (
                          <AddDocument className="iconSize" />
                        )}
                        {v.ICON === 'Exception' && (
                          <ListBullet className="iconSize" />
                        )}
                        {v.ICON === 'Recertification' && (
                          <Multitask className="iconSize" />
                        )}
                        {v.ICON === 'Admin' && (
                          <Advocate className="iconSize" />
                        )}
                        {v.ICON === 'Program' && <Tools className="iconSize" />}
                        {v.ICON === 'Settings' && <Gear className="iconSize" />}
                        {v.ICON === 'Workshop' && (
                          <Marketplace className="iconSize" />
                        )}
                        {v.ICON === 'Registrations' && (
                          <ManagingItems className="iconSize" />
                        )}
                        {v.ICON === 'SPSS' && <Digital className="iconSize" />}
                        <div className="tileHead">{v.NAME}</div>
                        <div className="tileDesc">{v.DESCRIPTION}</div>

                        <Link
                          style={{ float: 'right' }}
                          renderIcon={() => <ArrowRight aria-label="Link" />}
                        />
                        <br />
                      </Tile>
                    </Column>
                  );
                })}
            </Grid>
          )}

          {this.state.showPage == 'new-domain-requests' && (
            <div>
              {' '}
              <NewDomainList />
            </div>
          )}
          {this.state.showPage == 'program-configuration' && (
            <div>
              {' '}
              <EditProgram />
            </div>
          )}
          {this.state.showPage == 'institution-management' && (
            <div>
              {' '}
              <InstituteManagement />
            </div>
          )}
          {this.state.showPage == 'exception-list' && (
            <div>
              <WhiteList />
            </div>
          )}
          {this.state.showPage == 'recertification' && (
            <div>
              <Recertification />
            </div>
          )}
          {this.state.showPage == 'admin-access' && (
            <div>
              <AdminList userRole={this.state.currentRole} />
            </div>
          )}
          {this.state.showPage == 'settings' && (
            <div>
              <AdminSettings />
            </div>
          )}
          {this.state.showPage == 'spss' && (
            <div>
              <SPSS />
            </div>
          )}

          {this.state.showPage == 'manage-registrations' &&
            (this.state.currentRole == 'SK' ||
              this.state.currentRole == 'SA') && (
              <div>
                <ManageWorkshop />
              </div>
            )}
          {this.state.showPage == 'create-workshop' &&
            (this.state.currentRole == 'SK' ||
              this.state.currentRole == 'SA') && (
              <div>
                <WorkshopEvent />
              </div>
            )}
          {this.state.showPage == 'workshop-event-form' &&
            (this.state.currentRole == 'SK' ||
              this.state.currentRole == 'SA') && (
              <div>
                <WorkshopEventForm />
              </div>
            )}
        </Theme>
        {/* <Footer type="short" /> */}

        <Modal
          danger
          size="sm"
          open={this.state.logoutConfirmation}
          primaryButtonText="Logout"
          secondaryButtonText="Cancel"
          onRequestClose={() => {
            this.setState({ logoutConfirmation: false });
          }}
          onSecondarySubmit={() => {
            this.setState({ logoutConfirmation: false });
          }}
          onRequestSubmit={this.userLogout}
          modalHeading="Confirm logout">
          Are you sure you want to logout?
        </Modal>
        <Modal
          passiveModal={true}
          preventCloseOnClickOutside={false}
          open={this.state.showAlert}
          size="sm"
          onRequestClose={() => {
            this.setState({ showAlert: false });
          }}>
          <div style={{ padding: '1em 0' }}>
            {this.state.alertType == 'Success' && (
              <div>
                <CheckmarkOutline
                  style={{ width: '7em', height: '7em', color: 'green' }}
                />
              </div>
            )}
            {this.state.alertType == 'Warning' && (
              <div>
                <WarningAlt
                  style={{ width: '7em', height: '7em', color: 'orange' }}
                />
              </div>
            )}
            {this.state.alertType == 'Error' && (
              <div>
                <MisuseOutline
                  style={{ width: '7em', height: '7em', color: 'red' }}
                />
              </div>
            )}
            <div style={{ paddingLeft: '1em' }}>{this.state.alertMsg}</div>
          </div>
        </Modal>
        <ComposedModal
          size="sm"
          open={this.state.showAlertLogout}
          onRequestClose={this.props.hidden}
          icondescription="Close the modal"
          onClose={this.userLogout}
          preventCloseOnClickOutside={true}>
          <ModalHeader>Session Expired!</ModalHeader>
          <ModalBody>
            {this.state.alertTypeLogout == 'Success' && (
              <div>
                <CheckmarkOutline
                  style={{ width: '7em', height: '7em', color: 'green' }}
                />
              </div>
            )}
            {this.state.alertTypeLogout == 'Warning' && (
              <div>
                <WarningAlt
                  style={{ width: '7em', height: '7em', color: 'orange' }}
                />
              </div>
            )}
            {this.state.alertTypeLogout == 'Error' && (
              <div>
                <MisuseOutline
                  style={{ width: '7em', height: '7em', color: 'red' }}
                />
              </div>
            )}
            <div style={{ paddingLeft: '1em' }}>
              {this.state.alertMsgLogout}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button kind="primary" onClick={this.userLogout}>
              Ok
            </Button>
          </ModalFooter>
        </ComposedModal>
        <Loading
          withOverlay={true}
          description="Active loading indicator"
          active={this.state.showLoader}
        />
      </div>
    );
  }
}
