import { Button, Loading, Modal, Select, SelectItem, Grid, Column } from '@carbon/react';
import { CheckmarkOutline} from '@carbon/icons-react';
import { WarningAlt, MisuseOutline } from '@carbon/react/icons';
import React from 'react';
import './approval-status.scss';
import queryString from 'query-string';
import { Base64 } from 'js-base64';
import Axios from 'axios';
import utilService from '../../services/util-service';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import factoryService from '../../services/factory-service';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
const cookies = new Cookies();

// let masterCount = 0;
// let objJSON = null;

export default class ApprovalStatus extends React.Component {
    constructor(props) {
        super(props);
        utilService.setBaseURL();
        this.state = {
            appAccess:'',
            showLoader: false,
            showAlert: false,
            alertMsg: 'Test',
            alertType: 'Success',
            showModal: false,
            showActiveModal: false,
            commentsList: [],
            commentDropDown: null,
            invalidcommentDropDown: false,
            baseURL: utilService.hostname,
            case1: false,
            case2: false,
            case3: false,
            case4: false,
            case5: false,
            case6: false,
            case7: false,
            debugVisible: false,
            institutionName: null,
            lastModifiedBy: null,
            JSONtext: null,
            masterCount: 0,
            objJSON: null,
            formVisible: true,
            accessTokn: factoryService.Xaccess,//process.env.REACT_APP_X_ACCESS
        };
    }
    
    
    

    CloseModal = event => {
        this.setState({ showModal: false, showActiveModal: false });
    }
   
    componentDidMount() {
        (async()=>{
        this.setState({ showLoader: true });
        
        console.log('in async set coockie');
        let acc= await factoryService.getAppID();

        console.log('in async data', acc);
        //this.setState({accessTokn:acc});
        
        this.setState({
            accessTokn:acc
        },() => {
            console.log(this.state.accessTokn);
            this.setState({ showLoader: false});
        console.log('in didmount API call for ID',this.state.accessTokn, 'API-direct-----acc', acc)
        cookies.set('AppAccess', this.state.accessTokn);
     
   // })()
        // (async()=>{
        // console.log('in async set coockie')    
        // this.setState({appAccess:await factoryService.getAppID()})

        // console.log('in didmount API call for ID',this.state.appAccess, 'API-direct', await factoryService.getAppID())
        // cookies.set('AppAccess', this.state.appAccess);
        // })()
        let params = queryString.parse(this.props.location.search);
        console.log('paramsforPreview', params);
        let count = 0; for (let p in params) { count = count + 1; } console.log(count); this.masterCount = count;
        console.log('brfore if',this.state.accessTokn, 'coockie',cookies.get('AppAccess'))
        if (count == 6 && typeof params['userEmailId'] !== 'undefined' &&
            typeof params['geoRepEmailId'] !== 'undefined' &&
            typeof params['status'] !== 'undefined' &&
            typeof params['instName'] !== 'undefined' &&
            typeof params['instDomain'] !== 'undefined' &&
            typeof params['ccode'] !== 'undefined') {
               
            //  (async()=>{ await cookies.get('AppAccess')
               
            //     console.log('in async state',this.state.appAccess)
            //     let acc=await factoryService.getAppID()
            //     console.log('in async data', acc)
            if(typeof(this.state.accessTokn)=='string')
                this.dropdownInit(params['status']);
            
            
            console.log('paramEmail:', Base64.decode(params['geoRepEmailId']),
            '| paramStatus:', params['status'],
            '| paramExtra:', Base64.decode(params['userEmailId']),
            '| paramIttn:', Base64.decode(params['instName']),
            '| paramInstDomain:', Base64.decode(params['instDomain']),
            '| paramCcode:', Base64.decode(params['ccode']));

        this.objJSON = {
            'modifiedBy': Base64.decode(params['geoRepEmailId']),
            'emailId': Base64.decode(params['userEmailId']),
            'geoRepApprovalStatus': params['status'],
            'insttnName': Base64.decode(params['instName']),
            'isoCtryCd': Base64.decode(params['ccode']),
            'emailDomain': Base64.decode(params['instDomain'])
        }
        console.log("objJSON", this.objJSON);
                 // })()

                    // this.dropdownInit(params['status']);
            //let x=this.check_cookie();
            //while(true){
                //this.setState({showLoader:true})
                //if (typeof cookies.get('AppAccess') == 'string') {
               // if(typeof this.state.accessTokn=='string'){
                    // this.dropdownInit(params['status']);
                    //this.setState({showLoader:false})
                    //break;
               // }
            //}
           
                // (async()=>{ let data = await cookies.get('AppAccess') 
                //     this.setState({appaccess:data})
                //     if(data){
                //         console.log("in if await  ",params)
                //         this.dropdownInit(params['status']);
                //     }
                    
    
                // })()
        //     if (await (cookies.get('AppAccess') )) {
        //         console.log("in if await  ",params)
        //         //setTimeout(() => { this.dropdownInit(params['status']); }, 3000);
        //    // } else {
        //         this.dropdownInit(params['status']);
        //     }

        } else if (count == 3 && typeof params['userEmailId'] !== 'undefined' &&
            typeof params['geoRepEmailId'] !== 'undefined' &&
            typeof params['status'] !== 'undefined') {
            
            if (typeof cookies.get('AppAccess') !== 'string') {
                setTimeout(() => { this.dropdownInit(params['status']); }, 3000);
            } else {
                this.dropdownInit(params['status']);
            }

            console.log('paramEmail:', Base64.decode(params['geoRepEmailId']),
                '| paramStatus:', params['status'],
                '| paramExtra:', Base64.decode(params['userEmailId']));

            this.objJSON = {
                'modifiedBy': Base64.decode(params['geoRepEmailId']),
                'emailId': Base64.decode(params['userEmailId']),
                'geoRepApprovalStatus': params['status']
            }
        } else {
            this.setState({ JSONtext: '{invalid-parameters}' });
            this.changeCase(5);
        }
    });

    })()

    }
    
    componentDidUpdate() {
        sessionStorage.removeItem('isOtherPageAccessible');
        utilService.setOtherPageAccessible(true);
    }

    dropdownInit(value) {
        this.setState({ showLoader: true });
        if (value == 'Approved') {
            Axios.get(this.state.baseURL + '/a2mt/services/user-details/getstatusmessage/approved', {
                headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
            }).then(res => {
                this.setState({ showLoader: false });
                if (res.data != null && res.data != '') {
                    console.log(res.data);
                    let commentsList = [];
                    res.data.map(elm => {
                        if (elm.isEnable) {
                            commentsList.push(elm);
                        }
                    });
                    this.setState({ commentsList: commentsList });
                }

            }).catch((error) => {
                this.setState({ showLoader: false });
                this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
            });
        } else if (value == 'Rejected') {
            Axios.get(this.state.baseURL + '/a2mt/services/user-details/getstatusmessage/rejected', {
                headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
            }).then(res => {
                this.setState({ showLoader: false });
                if (res.data != null && res.data != '') {
                    console.log(res.data);
                    let commentsList = [];
                    res.data.map(elm => {
                        if (elm.isEnable) {
                            commentsList.push(elm);
                        }
                    });
                    this.setState({ commentsList: commentsList });
                }
            }).catch((error) => {
                this.setState({ showLoader: false });
                this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
            });
        }
    }

    changeCase(num) {
        switch (num) {
            case 1:
                this.setState({ case1: true });
                this.setState({ case2: false });
                this.setState({ case3: false });
                this.setState({ case4: false });
                this.setState({ case5: false });
                this.setState({ case6: false });
                this.setState({ case7: false });
                break;
            case 2:
                this.setState({ case1: false });
                this.setState({ case2: true });
                this.setState({ case3: false });
                this.setState({ case4: false });
                this.setState({ case5: false });
                this.setState({ case6: false });
                this.setState({ case7: false });
                break;
            case 3:
                this.setState({ case1: false });
                this.setState({ case2: false });
                this.setState({ case3: true });
                this.setState({ case4: false });
                this.setState({ case5: false });
                this.setState({ case6: false });
                this.setState({ case7: false });
                break;
            case 4:
                this.setState({ case1: false });
                this.setState({ case2: false });
                this.setState({ case3: false });
                this.setState({ case4: true });
                this.setState({ case5: false });
                this.setState({ case6: false });
                this.setState({ case7: false });
                break;
            case 5:
                this.setState({ case1: false });
                this.setState({ case2: false });
                this.setState({ case3: false });
                this.setState({ case4: false });
                this.setState({ case5: true });
                this.setState({ case6: false });
                this.setState({ case7: false });
                break;
            case 6:
                this.setState({ case1: false });
                this.setState({ case2: false });
                this.setState({ case3: false });
                this.setState({ case4: false });
                this.setState({ case5: false });
                this.setState({ case6: true });
                this.setState({ case7: false });
                break;
            case 7:
                this.setState({ case1: false });
                this.setState({ case2: false });
                this.setState({ case3: false });
                this.setState({ case4: false });
                this.setState({ case5: false });
                this.setState({ case6: false });
                this.setState({ case7: true });
                break;
        }
    }
    onChangeHandlerSelect = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        let namconcat = "invalid" + nam;
        this.setState({ [namconcat]: false });
    }

    onSubmit = (event) => {
        event.preventDefault();
        let isFormValid = true;
        let select = document.getElementById('commentDropDown').value.trim();
        console.log("select", select);
        if (this.state.commentDropDown == null) { this.setState({ invalidcommentDropDown: true }); isFormValid = false; }

        if (isFormValid) {
            this.setState({ formVisible: false });
            this.objJSON['comments'] = select; console.log('data0', this.objJSON);

            if (this.masterCount == 6 && this.objJSON != null) {
                this.setState({ showLoader: true });

                Axios.post(this.state.baseURL + '/a2mt/services/user-details/updateGeoRepStatus', this.objJSON, {
                    headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
                })
                    .then(res => {
                        this.setState({ showLoader: false });
                        console.log('res', res.data);

                        // alert('updateGeoRepStatus'); this.JSONtext = JSON.stringify(res);
                        if (res.data != null && res.data != '') {
                            if (res.data.isGeoRepUpdated == true) {
                                this.setState({ institutionName: this.objJSON.insttnName });
                                if (this.objJSON.geoRepApprovalStatus == 'Approved') {
                                    this.changeCase(6);
                                } else if (this.objJSON.geoRepApprovalStatus == 'Rejected') {
                                    this.changeCase(7);
                                }
                            } else if (res.data.isGeoRepUpdated == false) {
                                if (typeof res.data.approvalStatus !== 'undefined') {
                                    let em = res.data.approvedBy;
                                    this.setState({ lastModifiedBy: em.replace(/^(.{2})[^@]+/, "$1****") });
                                    if (res.data.approvalStatus.toLowerCase() == 'approved') {
                                        this.changeCase(3);
                                    } else if (res.data.approvalStatus.toLowerCase() == 'rejected') {
                                        this.changeCase(4);
                                    }
                                } else {
                                    this.changeCase(5);
                                }
                            } else {
                                this.changeCase(5);
                            }
                        } else {
                            this.changeCase(5);
                        }
                    }).catch((error) => {
                        this.setState({ showLoader: false });
                        this.changeCase(5);
                        this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
                    });
            } else if (this.masterCount == 3 && this.objJSON != null) {
                this.setState({ showLoader: true });
                Axios.post(this.state.baseURL + '/a2mt/services/user-details/updateIbmerGeoRepStatus', this.objJSON, {
                    headers: { "Content-Type": "application/json", "X-access": this.state.accessTokn }
                })
                    .then(res => {
                        this.setState({ showLoader: false });
                        console.log('res', res.data);
                        // alert('updateIbmerGeoRepStatus'); this.JSONtext = JSON.stringify(res);
                        if (res.data != null && res.data != '') {
                            if (res.data.isGeoRepApprovalStatusUpdated == true) {
                                this.setState({ institutionName: '' });
                                if (this.objJSON.geoRepApprovalStatus == 'Approved') {
                                    this.changeCase(1);
                                } else if (this.objJSON.geoRepApprovalStatus == 'Rejected') {
                                    this.changeCase(2);
                                }
                            } else if (res.data.isGeoRepApprovalStatusUpdated == false) {
                                if (typeof res.data.newIbmerGeoRepApprovalStatus !== 'undefined') {
                                    let em = res.data.approvedBy;
                                    this.setState({ lastModifiedBy: em.replace(/^(.{2})[^@]+/, "$1****") });
                                    if (res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == 'approved') {
                                        this.changeCase(3);
                                    } else if (res.data.newIbmerGeoRepApprovalStatus.toLowerCase() == 'rejected') {
                                        this.changeCase(4);
                                    }
                                } else {
                                    this.changeCase(5);
                                }
                            } else {
                                this.changeCase(5);
                            }
                        } else {
                            this.changeCase(5);
                        }
                    })
                    .catch((error) => {
                        this.setState({ showLoader: false });
                        this.changeCase(5);
                        this.setState({ alertMsg: 'Something went wrong. Please try again.', alertType: 'Error', showAlert: true });
                    });
            }
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <div>
                <NormalHeader></NormalHeader>
                <Grid condensed>
                    <Column lg={5} md={8} sm={4}><div width="100%" className="bgImg1"></div></Column>
                    <Column lg={{ span: 8, offset: 6 }} md={{ span: 6, offset: 1 }} sm={4} style={{ padding: "6em 3em 0 0" }}>
                            <div className="textContent">
                                <h3>IBM SkillsBuild Software Downloads</h3><br></br> <br></br>

                                {this.state.case1 && (<p className="defaultPara customText">Thank you. The request has been approved. You may now close this tab/window.</p>)}
                                {this.state.case2 && (<p className="defaultPara customText">Thank you. The request has been rejected. You may now close this tab/window.</p>)}
                                {this.state.case3 && (<p className="defaultPara customText">Thank you. This request has already been approved by {this.state.lastModifiedBy}. You may now close this tab/window.</p>)}
                                {this.state.case4 && (<p className="defaultPara customText">Thank you. This request has already been rejected by {this.state.lastModifiedBy}. You may now close this tab/window.</p>)}
                                {this.state.case5 && (<p className="defaultPara customText">Something went wrong. Please close this tab/window and try again.</p>)}
                                {this.state.case6 && (<p className="defaultPara customText">Thank you. The request for {this.state.institutionName} has been approved. You may now close this tab/window.</p>)}
                                {this.state.case7 && (<p className="defaultPara customText">Thank you. The request for {this.state.institutionName} has been rejected. You may now close this tab/window.</p>)}

                                {this.state.debugVisible && (<p className="defaultPara customText">{this.state.JSONtext}</p>)}
                                {this.state.formVisible && (
                                    <form onSubmit={this.onSubmit} noValidate>
                                        <p className="defaultPara customText">Please select an appropriate justification below:</p>
                                        <br></br> <br></br>
                                        <Select id="commentDropDown" defaultValue="placeholder-item" labelText="Select justification" invalidText="Justification selection is required" name="commentDropDown"
                                            invalid={this.state.invalidcommentDropDown}
                                            onChange={this.onChangeHandlerSelect}>
                                            <SelectItem disabled hidden value="placeholder-item" text="Choose an option" />
                                            {this.state.commentsList.map((val) => <SelectItem value={val.id} text={val.message} />)}
                                        </Select>
                                        <br></br> <br></br>
                                        <Button type="submit">Submit</Button><br></br><br></br>
                                    </form>
                                )}
                            </div>
                        </Column>
                    
                </Grid>
                <Loading withOverlay={true} description="Active loading indicator" active={this.state.showLoader}></Loading>
                <Modal passiveModal={true} preventCloseOnClickOutside={false} open={this.state.showAlert} size="sm" onRequestClose={() => { this.setState({ showAlert: false }) }}>
                    <div style={{ padding: "1em 0" }}>
                        {this.state.alertType == 'Success' && (<div><CheckmarkOutline style={{ width: "7em", height: "7em", color: "green" }}></CheckmarkOutline></div>)}
                        {this.state.alertType == 'Warning' && (<div><WarningAlt style={{ width: "7em", height: "7em", color: "orange" }}></WarningAlt></div>)}
                        {this.state.alertType == 'Error' && (<div><MisuseOutline style={{ width: "7em", height: "7em", color: "red" }}></MisuseOutline></div>)}
                        <div style={{ paddingLeft: "1em" }}>{this.state.alertMsg}</div>
                    </div>
                </Modal>
            </div>
        )
    }
}

