import React, { useEffect, useState } from 'react';
import {
    Grid,
    Column,
    Button,
    Breadcrumb,
    BreadcrumbItem,
    Select,
    SelectItem,
    Theme
} from '@carbon/react';
import { Education, Translate, Time, Launch, PlanePrivate, List, Earth } from '@carbon/icons-react';
import factoryService from '../../services/factory-service';
import AsyncImage from './AsyncImage';
import utilService from '../../services/util-service';
import Cookies from 'universal-cookie';

const cookies =  new Cookies();

const LectureAbstract = ({lecID}) => {
    const [lectureData, setLectureData] = useState([]);
    const [currentLanguage, setCurrentLanguage] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const value = await factoryService.getGuestLectureWithId(lecID);
            const indiData = await factoryService.decodeData(value, 'gupai@us.ibm.com');
            indiData.abstracts.map((i) => {
                if (i.abstract.includes('* ')) {
                    i.abstract = i.abstract.replaceAll('* ', ' \u2022 ')
                }
                return i;
            });
            console.log('individual Data', indiData);
            setLectureData(indiData);
        }

        if (typeof cookies.get('AppAccess') !== 'string') {
            setTimeout(() => { fetchData().catch(console.error); }, 3000);
        } else {
            fetchData().catch(console.error);
        }
    }, [lecID]);

    const requestLectureCTA = (event) => {
        event.stopPropagation();
        if(sessionStorage.getItem('userEm') !== 'undefined' && sessionStorage.getItem('userEm') !== null) {
            utilService.setOtherPageAccessible(true);
            let url = factoryService.removeParams(window.location.href); 
            window.location.href = url + '-request?lecId=' + lectureData.guestLectureId;
        } else {
            sessionStorage.setItem('tempLecId', lecID);
            window.location.href = "/a2mt/email-auth";
        }
    };

    return (
        typeof lectureData.abstracts != 'undefined' && (
            <Grid className="guest-lecture">
                <Column md={8} lg={4} sm={4} className="guest-lecture__languageSelect">
                    <label for='LanguageSelector' className="guest-lecture__attributeText">Language:</label><br/><br/>
                    <Theme theme='g100'>
                        <Select id="LanguageSelector" noLabel onChange={(event)=>{setCurrentLanguage(event.target.value)}}>
                            {lectureData.abstracts.map((val, idx) => {
                                return (
                                    <SelectItem value={idx} text={val.lectureLanguage} />
                                )
                            })}
                        </Select>
                    </Theme>
                </Column>
                <Column md={8} lg={{ span: 8, offset: 4 }} sm={4}>
                    <Breadcrumb className="guest-lecture__breadCrumb">
                        <BreadcrumbItem onClick={() => { window.location.href = factoryService.removeParams(window.location.href); }}>Lectures</BreadcrumbItem>
                    </Breadcrumb>
                    <h1 className="guest-lecture__heading" style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{ __html: lectureData.abstracts[currentLanguage].guestLectureTitle, }}></h1>
                    <p style={{ whiteSpace: "pre-wrap" }} className="guest-lecture__abstractDescription"
                        dangerouslySetInnerHTML={{ __html: lectureData.abstracts[currentLanguage].abstract, }}
                    />
                    <p className="guest-lecture__abstractButton"><Button renderIcon={Launch} iconDescription="Register for lecture" id={lectureData.guestLectureId} onClick={e=>requestLectureCTA(e)}>Request lecture</Button></p>
                    <p className="guest-lecture__filterHeading" style={{borderBottom:"0px"}}>{lectureData.guestLecturerName}</p>
                    <AsyncImage email={lectureData.email} guestLecturerName={lectureData.guestLecturerName} type={2} />
                    {lectureData.ambassadorBio !== 'undefined' && (<p className="guest-lecture__abstractBio" style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{__html: lectureData.ambassadorBio}}></p>)}
                </Column>
                <Column md={8} lg={{ span: 4, offset: 12 }} sm={4} className="guest-lecture__verticalLine">
                    <p><Education size={20} className="guest-lecture__attributeIcons"></Education> <span className="guest-lecture__attributeText">Topic: {lectureData.topics}</span></p>
                    <p><Translate size={20} className="guest-lecture__attributeIcons"></Translate> <span className="guest-lecture__attributeText">Languages: {lectureData.abstracts.map((l,i)=>{return (i?", ":"") + l.lectureLanguage})}</span></p>
                    <p><Time size={20} className="guest-lecture__attributeIcons"></Time> <span className="guest-lecture__attributeText">Duration: {lectureData.duration}</span></p>
                    <p><PlanePrivate size={20} className="guest-lecture__attributeIcons"></PlanePrivate> <span className="guest-lecture__attributeText">Travel/delivery: {lectureData.travelDeliveryOptions}</span></p>
                    {typeof lectureData.timesGiven != 'undefined' && (<p><List size={20} className="guest-lecture__attributeIcons"></List> <span className="guest-lecture__attributeText">Times requested: {lectureData.timesGiven} times</span></p>)}
                    <p><Earth size={20} className="guest-lecture__attributeIcons"></Earth> <span className="guest-lecture__attributeText">Speaker country: {lectureData.country}</span></p>
                </Column>
            </Grid>
        )
    )
}

export default LectureAbstract;