import { Grid, Column } from '@carbon/react';
import React from 'react';
import './_sbsd-guidelines.scss';
import NormalHeader from '../../components/NormalHeader/NormalHeader';
import { Row } from '@carbon/icons-react';

export default class SBSDGuidelines extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <NormalHeader />
        <Grid condensed>
          <Column lg={5} md={8} sm={4}>
            <div width="100%" className="bgImg1" />
          </Column>
          <Column
            lg={{ span: 8, offset: 6 }}
            md={{ span: 6, offset: 1 }}
            sm={4}>
            <br /> <br />
            <br /> <br />
            <h2 class="contentTitle">Software Usage Guidelines</h2>
            <div />
            <div>
              <div>
                <div>
                  <div
                    data-wch-inline-edit="elements.desc.value"
                    class="content_descp">
                    <p>
                      <strong>
                        THERE WAS A PROGRAM UPDATE IN AUGUST 2022. PLEASE
                        REFERENCE LATEST{' '}
                        <a
                          data-key="undefined"
                          href="https://www.ibm.com/academic/faqs/agreement"
                          rel="noopener noreferrer noopener noreferrer noopener noreferrer"
                          target="_blank">
                          TERMS&nbsp;OF&nbsp;USE
                        </a>
                        .
                      </strong>
                    </p>

                    <p>&nbsp;</p>

                    <p>
                      The below is available as reference for those users
                      participating in the prior version of our annually renewed
                      program.
                    </p>

                    <p>&nbsp;</p>

                    <p>
                      These Software Usage Guidelines supplement the terms of
                      the{' '}
                      <a
                        data-key="undefined"
                        href="https://www.ibm.com/academic/faqs/agreement"
                        rel="noopener noreferrer noopener noreferrer noopener noreferrer"
                        target="_self">
                        IBM SkillsBuild Software Downloads&nbsp;Agreement
                      </a>{' '}
                      (the "Agreement"). In the event of a conflict between the
                      Software Usage Guidelines and the Agreement, the Agreement
                      shall prevail.
                    </p>

                    <p>&nbsp;</p>

                    <h4>General guidelines</h4>

                    <p>
                      The guidelines in this table apply to all IBM software
                      products and tools. Be sure to also read{' '}
                      <strong>
                        Additional details by IBM product and brand
                      </strong>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <hr class="columnDivider" />

                <div class="bx--row">
                  <div class="bx--col leftColumnDesc">
                    <h4 class="columnTitle">What users ARE allowed to do:</h4>
                  </div>
                  <div class="bx--col rightColumnDesc">
                    <h4 class="columnTitle">
                      What users are NOT allowed to do:
                    </h4>
                  </div>
                </div>
                <div>
                  <div class="bx--row">
                    <div class="bx--col bx--col-sm-2 leftColumnDesc">
                      <div>
                        <p>
                          The software that is obtained from IBM SkillsBuild
                          Software Downloads&nbsp;is restricted to educational
                          and noncommercial research purposes ONLY by&nbsp;
                          <strong>Qualified Academic Institutions</strong>.
                          Educational purposes are defined as conducting
                          education classes, labs, or related programs for
                          teaching and/or learning the Products, or concepts
                          related to the Products, that are part of IBM
                          SkillsBuild Software Downloads. For example, classes
                          on software development, introduction to programming
                          languages, systems design and analysis, relevant
                          mathematics courses, relevant business process
                          courses, relevant science courses, or relevant
                          engineering courses, to name a few. Noncommercial
                          research purposes are defined as conducting
                          not-for-profit research projects whose results would
                          be considered to be in the public domain and suitable
                          for submission to a peer-reviewed journal or
                          conference for publication. IBM Products may be used
                          in noncommercial research that is focused on the
                          business concepts, science, math, or technology upon
                          which the product is based.
                        </p>

                        <p>&nbsp;</p>

                        <p>
                          A Qualified Academic Institution is any publicly- or
                          privately-funded body that is defined as one the
                          following:
                        </p>

                        <ul>
                          <li>
                            University or college offering education that leads
                            to nationally-recognized&nbsp;degrees,
                            qualifications, or levels of academic achievement
                            -&nbsp;accredited by a regional or national
                            accrediting council, commission, appropriate
                            government agency, or board of education of the
                            state or country in which the educational
                            institution is located.
                          </li>
                          <li>
                            Research institutions comprised of degree-granting
                            accredited academic institutions or consortia
                            comprised of degree-granting accredited academic
                            institutions.
                          </li>
                          <li>
                            Primary, elementary, or secondary school, either
                            publicly-or privately-funded, where education is the
                            principal objective leading to nationally recognized
                            degrees, qualifications, or levels of academic
                            achievement, and accredited to provide education by
                            national or regional councils or agencies.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="bx--col bx--col-sm-2 rightColumnDesc">
                      <div>
                        <p>
                          IBM SkillsBuild Software Downloads is NOT a broad
                          commercial software-licensing program with
                          unrestricted use. As such, the software that is
                          obtained as part of IBM SkillsBuild Software Downloads
                          has some restrictions: such as, it may NOT be used for
                          any infrastructure purposes whatsoever either in the
                          classroom, the lab, or for administration purposes.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div>
                  <div class="bx--row">
                    <div class="bx--col bx--col-sm-2 leftColumnDesc">
                      <div>
                        <p>
                          Educators who are registered with IBM SkillsBuild
                          Software Downloads may distribute the Products to
                          learners enrolled in their courses or degree-granting
                          programs by downloading the Products to a secure
                          server and allowing learners access to the server,
                          and/or by downloading the Products onto a personal
                          machine and copying to a storage medium with
                          subsequent copying to lab machines or learner
                          machines. Access to the Products may only be provided
                          to learners enrolled in classes or degree-granting
                          programs being taught or advised by the educator.
                        </p>
                      </div>
                    </div>
                    <div class="bx--col bx--col-sm-2 rightColumnDesc">
                      <div>
                        <p>
                          If the Institution has only one server that is shared
                          between the administrative staff and
                          educators/learners, the Products may not be installed
                          on that server. There must be separate servers for
                          administrative staff and educators/learners. IBM
                          SkillsBuild Software Downloads provides server license
                          agreements, and it is not possible to split server
                          licenses.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div>
                  <div class="bx--row">
                    <div class="bx--col bx--col-sm-2 leftColumnDesc">
                      <div>
                        <p>
                          Individual educators/learners of a qualified academic
                          institution may register with IBM SkillsBuild Software
                          Downloads to gain access to IBM assets made available
                          by IBM SkillsBuild Software Downloads.
                        </p>
                      </div>
                    </div>
                    <div class="bx--col bx--col-sm-2 rightColumnDesc">
                      <div>
                        <p>
                          Registrations with IBM SkillsBuild Software Downloads
                          may not be shared among educators or departments. Each
                          user must register with IBM SkillsBuild Software
                          Downloads on their own. This includes a prohibition on
                          sharing membership IDs and passwords with non-members,
                          including learners.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div>
                  <div class="bx--row">
                    <div class="bx--col bx--col-sm-2 leftColumnDesc">
                      <div>
                        <p>
                          Each user will administer, manage, and enforce the
                          distribution guidelines for the Products according to
                          the IBM SkillsBuild Software Downloads Agreement and
                          these Software Usage Guidelines.
                        </p>
                      </div>
                    </div>
                    <div class="bx--col bx--col-sm-2 rightColumnDesc">
                      <div>
                        <p>
                          IBM assets may not be delivered to, or shared with,
                          the institution business operations or administrative
                          departments.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div>
                  <div class="bx--row">
                    <div class="bx--col bx--col-sm-2 leftColumnDesc">
                      <div>
                        <p>
                          Each educator may make copies of the IBM Products for
                          backup purposes, or to provide a single copy to each
                          learner enrolled in their class. The educator is
                          responsible for semi-annual reporting of the number of
                          students who have used the Products, and other
                          information requested periodically by IBM.
                        </p>
                      </div>
                    </div>
                    <div class="bx--col bx--col-sm-2 rightColumnDesc">
                      <div>
                        <p>
                          Copies of the Products may not be provided to learners
                          who are not enrolled in an educator member’s class,
                          nor may copies be provided to other educators who are
                          not also registered with IBM SkillsBuild Software
                          Downloads. Learners who withdraw from a course being
                          taught by an educator member must delete the Products
                          from their personal workstation, and/or be denied
                          access to the Products via a server or lab machine.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div>
                  <div class="bx--row">
                    <div class="bx--col bx--col-sm-2 leftColumnDesc">
                      <div>
                        <p>
                          Every copy of the Products shall be a true and
                          complete copy and include all copyright and trademark
                          notices.
                        </p>
                      </div>
                    </div>
                    <div class="bx--col bx--col-sm-2 rightColumnDesc">
                      <div>
                        <p>
                          The Products may only be provided to learners who are
                          taking at least one credit course from a registered
                          educator member of IBM SkillsBuild Software Downloads.
                        </p>

                        <p>&nbsp;</p>

                        <p>
                          The Products available via IBM SkillsBuild Software
                          Downloads may not be sold, rented, leased or
                          transferred to any third-party including contractors,
                          other department's personnel, other companies, or
                          consultants performing services for your department or
                          institution, or learners not enrolled in an educator
                          member's class. This includes entities working on
                          joint research projects.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div>
                  <div class="bx--row">
                    <div class="bx--col bx--col-sm-2 leftColumnDesc">
                      <div>
                        <p>
                          The Education Materials (all courseware, lab
                          exercises, tests, and other related material) may only
                          be used for teaching purposes. You can make copies of
                          the Education Materials and provide them to learners
                          in your classes.
                        </p>

                        <p>&nbsp;</p>

                        <p>
                          You can extract portions of the Education Materials
                          and incorporate them into other teaching materials you
                          may be using, as long as all IBM trademarks and trade
                          names remain within the extracted portion of the
                          materials.
                        </p>

                        <p>&nbsp;</p>

                        <p>
                          You may translate the Education Materials to other
                          languages, so long as all IBM trademarks and trade
                          names remain within the translated materials. All
                          translated materials will be considered "Derivative
                          Works" under the terms of the Agreement.
                        </p>
                      </div>
                    </div>
                    <div class="bx--col bx--col-sm-2 rightColumnDesc">
                      <div>
                        <p>
                          The Education Materials may not be used for personal
                          profit.
                        </p>

                        <p>&nbsp;</p>

                        <p>
                          The Education Materials may not be provided to
                          learners who are not enrolled in an educator
                          members'&nbsp;class, nor may copies be provided to
                          other educators who are not registered with IBM
                          SkillsBuild Software Downloads.
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <div
                    data-wch-inline-edit="elements.desc.value"
                    class="content_descp">
                    <h4>Additional details by IBM product and brand</h4>

                    <p>&nbsp;</p>

                    <ul>
                      <li>
                        Tivoli software may be used for non-commercial research
                        and teaching concepts supporting the Tivoli pillars of
                        performance, availability, configuration, and
                        operations; storage; and security. Tivoli products MAY
                        NOT be used in commercial research for configuration
                        management, software distribution/inventory, network
                        monitoring, storage or security systems. They may not be
                        used in classes formally addressing these concepts. The
                        use of Tivoli products to maintain the infrastructure
                        within the institution, or for the performance of other
                        administrative functions, is prohibited under IBM
                        SkillsBuild Software Downloads. Tivoli products may NOT
                        be used for commercial research in any capacity. Tivoli
                        products may be used to as aids in teaching concepts
                        that support all Tivoli "pillars"&nbsp;(performance,
                        availability configuration and operations, storage,
                        security, and asset management).
                      </li>
                      <li>
                        DB2 and Informix Dynamic Server may be used to teach the
                        concepts, theory or procedures of database structures,
                        database administration, or similar database-related
                        courses. DB2 may also be used in noncommercial research
                        to store, organize and query data.
                      </li>
                      <li>
                        WebSphere Application Server may be used to teach
                        concepts, theory or procedures in web application
                        development.
                      </li>
                      <li>
                        AIX products may not be used as the operating system in
                        a lab that is not used to facilitate the teaching and/or
                        learning of development concepts, theories and
                        procedure.
                      </li>
                      <li>
                        Cluster/scalable parallel (SP) software (CSM, PSSP, and
                        so forth) may not be used to support clusters in a lab
                        that is not used to facilitate the teaching, learning
                        and/or research of clustering and parallelization
                        development concepts, theories, and procedures.
                      </li>
                    </ul>

                    <p>&nbsp;</p>

                    <p>
                      Please refer to the current{' '}
                      <a
                        data-key="undefined"
                        href="https://www.ibm.com/academic/faqs/agreement"
                        rel="noopener noreferrer noopener noreferrer noopener noreferrer noopener noreferrer"
                        target="_self">
                        IBM SkillsBuild Software Downloads Agreement
                      </a>{' '}
                      for specific license rights.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <br /> <br />
            <br /> <br />
          </Column>
        </Grid>
      </div>
    );
  }
}
