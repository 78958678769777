import {
  Grid,
  Column,
  Button,
  TextInput,
  Loading,
  Modal,
  NumberInput,
  Tooltip,
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import { CheckmarkOutline } from '@carbon/icons-react';
import React from 'react';
import Axios from 'axios';
import utilService from '../../../services/util-service';

const validURLRegex = RegExp(
  /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/
);

export default class AdminSettings extends React.Component {
  constructor(props) {
    super(props);
    let triggerEmail = (
      <div>
        <span style={{ color: 'red' }}>*</span>Recert Email Notification
      </div>
    );
    let triggerFreq = (
      <div>
        <span style={{ color: 'red' }}>*</span>Recert Frequency
      </div>
    );
    let triggerCount = (
      <div>
        <span style={{ color: 'red' }}>*</span>Recert Count
      </div>
    );
    let triggerFAQ = (
      <div>
        <span style={{ color: 'red' }}>*</span>FAQ URL
      </div>
    );

    this.state = {
      tooltip_notification: {
        recert_email: (
          <Tooltip direction="bottom" tabIndex={0} triggerText={triggerEmail}>
            <p>
              Set number of days for sending out recertification email to the
              users.
            </p>
          </Tooltip>
        ),
        recert_frequency: (
          <Tooltip direction="bottom" tabIndex={0} triggerText={triggerFreq}>
            <p>Set number of months for recertification.</p>
          </Tooltip>
        ),
        recert_count: (
          <Tooltip direction="bottom" tabIndex={0} triggerText={triggerCount}>
            <p>Set the count of recertification.</p>
          </Tooltip>
        ),
        faq_url: (
          <Tooltip direction="bottom" tabIndex={0} triggerText={triggerFAQ}>
            <p>Set the FAQ URL.</p>
          </Tooltip>
        ),
      },
      baseURL: utilService.hostname,
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      dataRecertDet: [],
      recert_id: null,
      recert_email: null,
      recert_frequency: null,
      recert_count: null,
      faq_url: null,
      errors_recert_email: false,
      errors_recert_frequency: false,
      errors_recert_count: false,
      errors_faq_url: false,
    };
  }

  onChangeHandler = event => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });

    let namconcat = 'errors_' + nam;
    this.setState({ [namconcat]: false });
  };

  handleKeypress(e) {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }
  onTextChange = event => {
    let id = event.target.id,
      vl = event.target.value;
    console.log('onTextChange', id, vl);
    if (id == 'faq-url' && vl.trim() !== '') {
      validURLRegex.test(vl)
        ? this.setState({ faq_url: vl.trim(), errors_faq_url: false })
        : this.setState({ faq_url: null, errors_faq_url: true });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    let valid = true;
    if (document.getElementById('recert-email').value == '') {
      valid = false;
    }
    if (document.getElementById('recert-frequency').value == '') {
      valid = false;
    }
    if (document.getElementById('recert-count').value == '') {
      valid = false;
    }
    if (document.getElementById('faq-url').value == '') {
      this.setState({ errors_faq_url: true });
      valid = false;
    }
    if (document.getElementById('faq-url').value != '') {
      if (!validURLRegex.test(document.getElementById('faq-url').value)) {
        this.setState({ errors_faq_url: true });
        valid = false;
      }
    }

    if (valid) {
      let data = {
        emailRecertDays: document.getElementById('recert-email').value,
        faq: document.getElementById('faq-url').value,
        id: this.state.recert_id,
        recertCount: document.getElementById('recert-count').value,
        recertMonthsReq: document.getElementById('recert-frequency').value,
        createdBy: localStorage.getItem('adminEmail'),
      };
      console.log('postData', data);
      //return false;
      this.setState({ showLoader: true });
      utilService
        .postData('/a2mt/services/user-details/updateRecertDet', data)
        .then(rs => {
          console.log(rs.data);
          this.setState({ showLoader: false });
          this.getRecertDetails();
          if (rs.data.success === true) {
            this.setState({
              alertMsg: 'Data is successfully updated',
              alertType: 'Success',
              showAlert: true,
            });
          } else {
            this.setState({
              alertMsg: 'Update failed. Please try again later.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
        })
        .catch(error => {
          this.setState({ showLoader: false });
          this.setState({
            alertMsg: 'Something went wrong. Please try again.',
            alertType: 'Error',
            showAlert: true,
          });
        });
    }
  };

  getRecertDetails() {
    this.setState({ showLoader: true });
    utilService
      .getData('/a2mt/services/user-details/getRecertDet')
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        if (res.data != null) {
          this.setState({
            dataRecertDet: res.data,
            recert_id: res.data.id,
            recert_email: res.data.emailRecertDays,
            recert_frequency: res.data.recertMonthsReq,
            recert_count: res.data.recertCount,
            faq_url: res.data.faq,
          });
        } else {
          this.setState({ dataRecertDet: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  componentDidMount() {
    this.getRecertDetails();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  render() {
    return (
      <div>
        <Grid className="admin-sub-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <div>
              <div className="bx--grid">
                <div className="bx--row">
                  <div className="bx--col">
                    <div style={{ paddingBottom: '32px' }}>
                      <h3>Program Settings</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={this.handleSubmit} noValidate>
              <div style={{ marginBottom: '2rem' }}>
                <div className="bx--grid">
                  <div className="bx--row">
                    <div className="bx--col">
                      <NumberInput
                        helperText=""
                        id="recert-email"
                        name="recert_email"
                        invalidText="Please enter valid number"
                        placeholder="Recert Email"
                        label={this.state.tooltip_notification.recert_email}
                        //max={100}
                        min={0}
                        // step={10}
                        value={this.state.dataRecertDet.emailRecertDays}
                        onChange={this.onChangeHandler}
                        onKeyDown={this.handleKeypress}
                      />
                    </div>
                    <div className="bx--col">
                      <NumberInput
                        helperText=""
                        id="recert-frequency"
                        name="recert_frequency"
                        invalidText="Please enter valid number"
                        placeholder="Recert Frequency"
                        label={this.state.tooltip_notification.recert_frequency}
                        //max={100}
                        min={0}
                        // step={10}
                        value={this.state.dataRecertDet.recertMonthsReq}
                        onChange={this.onChangeHandler}
                        onKeyDown={this.handleKeypress}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '2rem' }}>
                <div className="bx--grid">
                  <div className="bx--row">
                    <div className="bx--col">
                      <NumberInput
                        helperText=""
                        id="recert-count"
                        name="recert_count"
                        invalidText="Please enter valid number"
                        label={this.state.tooltip_notification.recert_count}
                        placeholder="Recert Count"
                        // max={100}
                        min={0}
                        //step={10}
                        value={this.state.dataRecertDet.recertCount}
                        onChange={this.onChangeHandler}
                        onKeyDown={this.handleKeypress}
                      />
                    </div>
                    <div className="bx--col">
                      <TextInput
                        helperText=""
                        id="faq-url"
                        name="faq_url"
                        invalidText="FAQ URL is required"
                        invalid={this.state.errors_faq_url}
                        labelText={this.state.tooltip_notification.faq_url}
                        defaultValue={this.state.dataRecertDet.faq}
                        placeholder="Enter FAQ"
                        onChange={event => this.onTextChange(event)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: '2rem' }}>
                <div className="bx--grid">
                  <div className="bx--row">
                    <div className="bx--col">
                      <Button kind="primary" tabIndex={0} type="submit">
                        Update
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Column>
          <Loading
            withOverlay={true}
            description="Active loading indicator"
            active={this.state.showLoader}
          />
          <Modal
            passiveModal={true}
            preventCloseOnClickOutside={false}
            open={this.state.showAlert}
            size="sm"
            onRequestClose={() => {
              this.setState({ showAlert: false });
            }}>
            <div style={{ padding: '1em 0' }}>
              {this.state.alertType == 'Success' && (
                <div>
                  <CheckmarkOutline
                    style={{ width: '7em', height: '7em', color: 'green' }}
                  />
                </div>
              )}
              {this.state.alertType == 'Warning' && (
                <div>
                  <WarningAlt
                    style={{ width: '7em', height: '7em', color: 'orange' }}
                  />
                </div>
              )}
              {this.state.alertType == 'Error' && (
                <div>
                  <MisuseOutline
                    style={{ width: '7em', height: '7em', color: 'red' }}
                  />
                </div>
              )}
              <div style={{ paddingLeft: '1em' }}>{this.state.alertMsg}</div>
            </div>
          </Modal>
        </Grid>
      </div>
    );
  }
}
