import {
  Button,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Loading,
  TableContainer,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Modal,
  TextInput,
  Select,
  SelectItem,
  ComposedModal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Pagination,
  Grid,
  Column,
} from '@carbon/react';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import { CheckmarkOutline } from '@carbon/icons-react';
import React from 'react';
import Axios from 'axios';
import './new-domain-list.scss';
import utilService from '../../../services/util-service';

const validDomainRegex = RegExp('([\\da-z.-]+)\\.([a-z.]{2,6})[\\w.-]*?');

export default class NewDomainList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: utilService.hostname,
      emailId: localStorage.getItem('adminEmail'),
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      isEditOverlayVisible: false,
      isViewOverlayVisible: false,
      editDataset: {},
      resendDisabled: false,
      page: 1,
      pageSize: 10,
      rowData: [],
      dplStatusValues: ['Pass', 'Fail', 'Passed by Admin'],
      headerData: [
        { header: 'Institution Name', key: 'insttnName' },
        { header: 'Institution Type', key: 'insttnTypeName' },
        { header: 'Email Domain', key: 'emailDomain' },
        { header: 'Country', key: 'countryName' },
        { header: 'DPL Status', key: 'dplStatus' },
        { header: 'DPL Code', key: 'dplCode' },
        { header: 'Admin Approval Status', key: 'adminApprovalStatus' },
        { header: 'Geo Rep Approval Status', key: 'geoRepApprovalStatus' },
      ],
      selectedRowData: null,
      invalidDplType: false,
      invalidEmailDomain: false,
      invalidInstitution: false,
    };
  }

  componentDidMount() {
    this.initGrid();
  }
  componentDidUpdate() {
    sessionStorage.removeItem('isOtherPageAccessible');
    utilService.setOtherPageAccessible(false);
  }

  initGrid() {
    this.setState({ showLoader: true });
    utilService
      .getData('/a2mt/services/user-details/getDomainRequest')
      .then(res => {
        this.setState({ showLoader: false });
        console.log(res.data);
        if (res.data != null) {
          this.setState({ rowData: res.data });
          this.initDPLstatus();
        } else {
          this.setState({ rowData: [] });
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  initDPLstatus() {
    this.setState({ showLoader: true });
    utilService
      .getData('/a2mt/services/user-details/getDplStatus')
      .then(res => {
        this.setState({ showLoader: false });
        if (res.data != null && res.data.length > 0) {
          let tempData = [];
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].isEnable == true) {
              tempData.push(res.data[i].statusName);
            }
          }
          this.setState({ dplStatusValues: tempData });
          console.log(tempData);
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  onSelectTypeChange = event => {
    const { options, selectedIndex } = event.target;
    if (event.target.name == 'dplStatus') {
      this.setState(prevState => ({
        editDataset: {
          ...prevState.editDataset,
          dplStatus: options[selectedIndex].innerHTML,
        },
      }));
      this.setState({ invalidDplType: false });
    }
    setTimeout(() => {
      console.log(this.state.editDataset);
    }, 1000);
  };

  onInputChange = event => {
    let nm = event.target.name;
    let vl = event.target.value;
    console.log('vl', nm, vl.trim());
    if (nm == 'domain' && vl.trim() !== '') {
      if (validDomainRegex.test(vl.trim())) {
        this.setState(prevState => ({
          editDataset: { ...prevState.editDataset, emailDomain: vl.trim() },
        }));
        this.setState({ invalidEmailDomain: false });
      } else {
        this.setState({ invalidEmailDomain: true });
      }
    } else if (nm == 'domain' && vl.trim() == '') {
      this.setState({ invalidEmailDomain: true });
    }
    if (nm == 'insttn' && vl.trim() !== '') {
      this.setState(prevState => ({
        editDataset: { ...prevState.editDataset, insttnName: vl.trim() },
      }));
      this.setState({ invalidInstitution: false });
    } else if (nm == 'insttn' && vl.trim() == '') {
      this.setState({ invalidInstitution: true });
    }
    setTimeout(() => {
      console.log(this.state.editDataset);
    }, 1000);
  };

  getSelectedRow = event => {
    this.setState({ invalidDplType: false });
    let sel = this.state.selectedRowData;
    console.log(sel);
    if (sel !== null) {
      let record = this.state.rowData.filter(e => e.id === sel.id);
      if (record.length > 0) {
        if (!record[0].dplStatus) {
          setTimeout(() => {
            document.getElementById('dplStatusDropdown').selectedIndex = 0;
          }, 1000);
        }
        this.setState({ editDataset: record[0] });

        console.log(
          'record :::::',
          record[0].dplStatus,
          record[0],
          record[0].adminApprovalStatus,
          record[0].adminApprovalStatus
        );
        if (
          record[0].adminApprovalStatus == 'Approved' ||
          record[0].adminApprovalStatus == 'Rejected'
        ) {
          if (record[0].adminApprovalStatus == 'Approved') {
            this.setState({ resendDisabled: false });
          } else {
            this.setState({ resendDisabled: true });
          }
          this.setState({ isViewOverlayVisible: true });
        } else {
          this.setState({ isEditOverlayVisible: true });
        }
      } else {
        this.setState({ editDataset: {} });
      }
    } else {
      this.setState({
        alertMsg: 'Please select a row to review!',
        alertType: 'Warning',
        showAlert: true,
      });
    }
  };

  closeOverlay = event => {
    this.setState({ isEditOverlayVisible: false, isViewOverlayVisible: false });
  };
  closeAlert = event => {
    this.setState({ showAlert: false });
  };
  validateForm() {
    if (document.getElementById('dplStatusDropdown').selectedIndex === 0) {
      this.setState({ invalidDplType: true });
    } else {
      if (
        !this.state.invalidDplType &&
        !this.state.invalidEmailDomain &&
        !this.state.invalidInstitution
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  handlePagination(event) {
    const { page, pageSize } = event;
    if (page && pageSize) {
      this.setState({ page, pageSize });
    }
  }

  onSave = event => {
    console.log('form valid', this.validateForm());
    if (this.validateForm()) {
      this.submitStatusData(false, '');
    } else {
      return false;
    }
  };

  onApprove = event => {
    console.log('form valid', this.validateForm());
    if (this.validateForm()) {
      this.submitStatusData(true, 'Approved');
    } else {
      return false;
    }
  };

  onReject = event => {
    console.log('form valid', this.validateForm());
    if (this.validateForm()) {
      this.submitStatusData(true, 'Rejected');
    } else {
      return false;
    }
  };

  onResendEmail = event => {
    this.setState({ showLoader: true });
    console.log('formData', this.state.editDataset);
    this.closeOverlay();
    utilService
      .postData(
        '/a2mt/services/user-details/resendEmailToGeoRep',
        this.state.editDataset
      )
      .then(res => {
        this.setState({ showLoader: false });
        if (typeof res.data !== 'undefined' && res.data !== null) {
          const result = res.data;
          if (result.emailSent == true) {
            this.setState({
              alertMsg: 'Email re-sent successfully.',
              alertType: 'Success',
              showAlert: true,
            });
          } else {
            this.setState({
              alertMsg: 'Email not sent. Please try again later.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
          this.initGrid();
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.closeOverlay();
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  };

  submitStatusData(apprRej, status) {
    this.setState({ showLoader: true });
    let formData = this.state.editDataset;
    formData['modifiedBy'] = this.state.emailId;
    console.log('formData', formData);
    this.closeOverlay();
    utilService
      .postData(
        '/a2mt/services/user-details/updateInsttnDomainRequest',
        formData
      )
      .then(res => {
        this.setState({ showLoader: false });
        if (typeof res.data !== 'undefined' && res.data !== null) {
          const result = res.data;
          if (result.isDomainExist == true) {
            this.setState({
              alertMsg: 'Save updates failed. Email domain already exists.',
              alertType: 'Warning',
              showAlert: true,
            });
          } else if (result.isAdminDataUpdated == true) {
            if (apprRej) {
              formData['adminApprovalStatus'] = status;
              if (status == 'Approved') {
                formData['geoRepApprovalStatus'] = 'In Progress';
              }
              this.approveRejectStatus(formData);
            } else {
              this.setState({
                alertMsg: 'Updates saved successfully.',
                alertType: 'Success',
                showAlert: true,
              });
              setTimeout(() => {
                this.initGrid();
              }, 1000);
            }
          } else if (result.isBlackListedDomain == true) {
            this.setState({
              alertMsg: 'Save updates failed. This is a blacklisted domain.',
              alertType: 'Warning',
              showAlert: true,
            });
          } else {
            this.setState({
              alertMsg: 'Save updates failed. Please try again later.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.closeOverlay();
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  approveRejectStatus(formData) {
    this.setState({ showLoader: true });
    utilService
      .postData('/a2mt/services/user-details/updateAdminStatus', formData)
      .then(res => {
        this.setState({ showLoader: false });
        this.closeOverlay();
        if (typeof res.data !== 'undefined' && res.data !== null) {
          const result = res.data;
          console.log('result', result);
          if (result.isAdminApproved == true) {
            if (formData.adminApprovalStatus == 'Approved') {
              this.setState({
                alertMsg:
                  'Request Approved. Email sent to Geo Leader for Review',
                alertType: 'Success',
                showAlert: true,
              });
            } else {
              this.setState({
                alertMsg: 'Request Rejected. Email sent to Requester.',
                alertType: 'Success',
                showAlert: true,
              });
            }
          } else {
            this.setState({
              alertMsg:
                'Request approval/rejection failed. Please try again later.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
          setTimeout(() => {
            this.initGrid();
          }, 1000);
        }
      })
      .catch(error => {
        this.setState({ showLoader: false });
        this.closeOverlay();
        this.setState({
          alertMsg: 'Something went wrong. Please try again.',
          alertType: 'Error',
          showAlert: true,
        });
      });
  }

  render() {
    const { page, pageSize } = this.state;
    return (
      <div className="body-height">
        <Grid className="admin-sub-page">
          <Column lg={{ span: 12, offset: 4 }} md={8} sm={4}>
            <div style={{ paddingBottom: '32px' }}>
              <h3>New Domain Requests</h3>
            </div>
            <section>
              <DataTable
                rows={this.state.rowData}
                headers={this.state.headerData}
                radio
                isSortable>
                {({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getSelectionProps,
                  getTableProps,
                  getTableContainerProps,
                  onInputChange,
                }) => (
                  <TableContainer {...getTableContainerProps()}>
                    <TableToolbar>
                      <TableToolbarContent>
                        <TableToolbarSearch onChange={onInputChange} />
                        <Button onClick={this.getSelectedRow}>
                          Review Request
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <th scope="col" />
                          {headers.map((header, i) => (
                            <TableHeader
                              key={i}
                              {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows
                          .splice((page - 1) * pageSize, pageSize)
                          .map((row, i) => (
                            <TableRow key={i} {...getRowProps({ row })}>
                              <TableSelectRow
                                {...getSelectionProps({
                                  row,
                                  onClick: () =>
                                    this.setState({ selectedRowData: row }),
                                })}
                              />
                              {row.cells.map(cell => (
                                <TableCell key={cell.id}>
                                  {cell.value}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </DataTable>
              <Pagination
                backwardText="Previous page"
                disabled={false}
                forwardText="Next page"
                isLastPage={false}
                itemsPerPageText="Items per page:"
                onChange={e => this.handlePagination(e)}
                pageInputDisabled={false}
                pageNumberText="Page Number"
                pageSize={10}
                pageSizes={[10, 15, 20, 25]}
                pagesUnknown={false}
                totalItems={this.state.rowData.length}
              />
            </section>

            <Loading
              withOverlay={true}
              description="Active loading indicator"
              active={this.state.showLoader}
            />

            <Modal
              passiveModal={true}
              preventCloseOnClickOutside={false}
              open={this.state.showAlert}
              size="sm"
              className="mymodal1"
              onRequestClose={this.closeAlert}>
              <div style={{ padding: '1em 0' }}>
                {this.state.alertType == 'Success' && (
                  <div>
                    <CheckmarkOutline
                      style={{ width: '7em', height: '7em', color: 'green' }}
                    />
                  </div>
                )}
                {this.state.alertType == 'Warning' && (
                  <div>
                    <WarningAlt
                      style={{ width: '7em', height: '7em', color: 'orange' }}
                    />
                  </div>
                )}
                {this.state.alertType == 'Error' && (
                  <div>
                    <MisuseOutline
                      style={{ width: '7em', height: '7em', color: 'red' }}
                    />
                  </div>
                )}
                <div style={{ paddingLeft: '1em' }}>{this.state.alertMsg}</div>
              </div>
            </Modal>

            <ComposedModal
              size="lg"
              className="mymodal"
              selectorPrimaryFocus="#text-input-1"
              hasForm
              open={this.state.isEditOverlayVisible}
              hasScrollingContent
              onRequestClose={this.props.hidden}
              iconDescription="Close the modal"
              onClose={this.closeOverlay}>
              <ModalHeader>
                <h3 className="bx--modal-header__heading">
                  Review Domain Request
                </h3>
              </ModalHeader>
              <ModalBody className="removePadding">
                <div className="bx--grid">
                  <div className="bx--row" style={{ padding: '1em 0 1em 0' }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>Email ID</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.emailId}
                    </div>
                  </div>
                  <div className="bx--row" style={{ paddingBottom: '1em' }}>
                    <div
                      className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                      style={{ padding: '1em 0 0 1em' }}>
                      <span style={{ fontWeight: 'bold' }}>Email Domain</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <TextInput
                        id="text-input-1"
                        name="domain"
                        value={this.state.editDataset.emailDomain}
                        onChange={this.onInputChange}
                        invalid={this.state.invalidEmailDomain}
                        invalidText="Please enter valid domain"
                        noLabel
                      />
                    </div>
                  </div>
                  <div className="bx--row" style={{ paddingBottom: '1em' }}>
                    <div
                      className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                      style={{ padding: '1em 0 0 1em' }}>
                      <span style={{ fontWeight: 'bold' }}>
                        Institution Name
                      </span>{' '}
                      :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <TextInput
                        id="text-input-2"
                        name="insttn"
                        value={this.state.editDataset.insttnName}
                        onChange={this.onInputChange}
                        invalid={this.state.invalidInstitution}
                        invalidText="Please enter institution"
                        noLabel
                      />
                    </div>
                  </div>
                  <div className="bx--row" style={{ paddingBottom: '1em' }}>
                    <div
                      className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                      style={{ padding: '1em 0 0 1em' }}>
                      <span style={{ fontWeight: 'bold' }}>DPL Status</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <Select
                        id="dplStatusDropdown"
                        defaultValue="placeholder-item"
                        noLabel
                        invalidText="Please choose an option"
                        name="dplStatus"
                        invalid={this.state.invalidDplType}
                        onChange={this.onSelectTypeChange}
                        value={this.state.editDataset.dplStatus}>
                        <SelectItem
                          disabled
                          value="placeholder-item"
                          text="Choose an option"
                        />
                        {this.state.dplStatusValues.map(val => (
                          <SelectItem value={val} text={val} />
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>DPL Code</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.dplCode}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>User Role</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.userRoleName}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>Job Role</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.jobRoleName}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>First Name</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.firstName}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>Last Name</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.lastName}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>Country</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.countryName}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>
                        Institution Type
                      </span>{' '}
                      :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.insttnTypeName}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>Website</span> :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.insttnWebsite}
                    </div>
                  </div>
                  <div
                    className="bx--row"
                    style={{
                      borderBottom: '1px solid #c6c6c6',
                      padding: '1em 0 1em 0',
                    }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>
                        Admin Approval Status
                      </span>{' '}
                      :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.adminApprovalStatus}
                    </div>
                  </div>
                  <div className="bx--row" style={{ padding: '1em 0 1em 0' }}>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      <span style={{ fontWeight: 'bold' }}>
                        Geo Rep Approval Status
                      </span>{' '}
                      :
                    </div>
                    <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                      {this.state.editDataset.geoRepApprovalStatus}
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button kind="danger" onClick={this.onReject}>
                  Reject
                </Button>
                <Button kind="secondary" onClick={this.onSave}>
                  Save
                </Button>
                <Button kind="primary" onClick={this.onApprove}>
                  Approve
                </Button>
              </ModalFooter>
            </ComposedModal>

            <Modal
              hasForm
              size="lg"
              hasScrollingContent
              selectorPrimaryFocus="#text-input-2"
              open={this.state.isViewOverlayVisible}
              primaryButtonText="Re-send Email"
              secondaryButtonText="Cancel"
              primaryButtonDisabled={this.state.resendDisabled}
              onRequestClose={this.closeOverlay}
              onSecondarySubmit={this.closeOverlay}
              onRequestSubmit={this.onResendEmail}
              modalHeading="Review Domain Request">
              <div className="bx--grid">
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Email ID</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.emailId}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Email Domain</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.emailDomain}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Institution Name</span>{' '}
                    :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.insttnName}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>DPL Status</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.dplStatus}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>DPL Code</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.dplCode}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>User Role</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.userRoleName}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Job Role</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.jobRoleName}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>First Name</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.firstName}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Last Name</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.lastName}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Country</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.countryName}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Institution Type</span>{' '}
                    :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.insttnTypeName}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>Website</span> :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.insttnWebsite}
                  </div>
                </div>
                <div
                  className="bx--row"
                  style={{
                    borderBottom: '1px solid #c6c6c6',
                    padding: '1em 0 1em 0',
                  }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>
                      Admin Approval Status
                    </span>{' '}
                    :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.adminApprovalStatus}
                  </div>
                </div>
                <div className="bx--row" style={{ padding: '1em 0 1em 0' }}>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    <span style={{ fontWeight: 'bold' }}>
                      Geo Rep Approval Status
                    </span>{' '}
                    :
                  </div>
                  <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                    {this.state.editDataset.geoRepApprovalStatus}
                  </div>
                </div>
              </div>
            </Modal>
          </Column>
        </Grid>
      </div>
    );
  }
}
