//import './cssfile.scss';
import React, { Component } from 'react';
import './modals.scss';
import { Modal } from '@carbon/react';
class AgreementModal extends Component {
    render() {
        return (
            <React.Fragment>
                <Modal
                    className='some-class'
                    hasScrollingContent={false}
                    iconDescription='Close the modal'
                    modalAriaLabel=''
                    modalLabel=''
                    onRequestClose={this.props.hidden}
                    onRequestSubmit={this.props.check}
                    onSecondarySubmit={this.props.uncheck}
                    open
                    passiveModal={false}
                    primaryButtonDisabled={false}
                    primaryButtonText='Accept'
                    secondaryButtonText='Cancel'
                    selectorPrimaryFocus='[data-modal-primary-focus]'
                    size='lg'>
                    {/* <div className='bx--modal-content__text' style={{ paddingTop:'2%' }}> */}
                    {/* <div style={{ padding:'2%', width:'', fontSize:'20px', textAlign : 'center' }}> */}
                    <div className='bx--modal-content__text'>
                        <div className="modalBody" >

                            <p className="defaultPara customHeader">Additional Download Agreement</p>
                            <p>I agree to avoid performing software downloads through the IBM SkillsBuild Software Downloads website unless previously agreed upon
                            with the site owner. The IBM SkillsBuild Software Downloads website is not intended to serve as a
                            software delivery vehicle for IBMers to download products for PoCs, self-education, client engagements or
                            fulfilment. Access is granted on the condition that all use is related to University / Academic Relations so
                            that you may properly demonstrate the capabilities of the program and assist learners and educators with
        questions.</p>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default AgreementModal;