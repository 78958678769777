import {
  Grid,
  Column,
  Button,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Loading,
  TableContainer,
  Tab,
  TabPanel,
  TabPanels,
  TabList,
  Tabs,
  TableSelectRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Modal,
  TextArea,
  TextInput,
  Select,
  SelectItem,
  ComposedModal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Pagination,
  DatePickerInput,
  DatePicker,
  OrderedList,
  UnorderedList,
  ListItem,
} from '@carbon/react';

import React from 'react';
import { WarningAlt, MisuseOutline } from '@carbon/icons-react';
import { CheckmarkOutline } from '@carbon/icons-react';
import './spss.scss';
import utilService from '../../../services/util-service';

export default class SPSS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseURL: utilService.hostname,
      emailId: localStorage.getItem('adminEmail'),
      activeTab: 'Pending',
      Rejected: 0,
      Approved: 0,
      Pending: 0,
      emailId: localStorage.getItem('adminEmail'),
      showLoader: false,
      showAlert: false,
      alertMsg: 'Test',
      alertType: 'Success',
      isEditOverlayVisible: false,
      isViewOverlayVisible: false,
      editDataset: {},
      resendDisabled: false,
      statusUpdated: false,
      page: 1,
      pageSize: 10,
      rowData: [],
      spssVersionList: [],
      questionAnswerData: [],
      spssExpiryDat: '',
      minDat: 'mm/dd/yyyy',
      status: ['Rejected', 'Pending', 'Approved'],
      dplStatusValues: [],
      headerData: [
        // { header: 'Id', key: 'id' },
        { header: 'User Id', key: 'userId' },
        { header: 'Institution Name', key: 'institutionName' },
        { header: 'DPL Status', key: 'dplStatus' },
        // { header: 'DPL Denial Code', key: 'dplCode' },
        { header: 'Email', key: 'userEmail' },
        { header: 'First Name', key: 'firstName' },
        { header: 'Last Name', key: 'lastName' },
        //  { header: "Registraion Date & Time", key: "registeredDate" },
      ],
      selectedRowData: null,
      // invalidDplDenialCode: false,
      invalidexpiryDate: false,
      invalidSpssLicCd: false,
      invalidEmailDomain: false,
      invalidInstitution: false,
      invalidVersion: false,
      initialLoad: true,
      curr_Page: 1,
      curr_PageSize: 10,
      disableAllFormFields: false,
      disableSubmitButt: false,
    };
  }

  componentDidMount() {
    this.initGrid();

    let currDate = new Date();
    let currYear = currDate.getFullYear();
    let currDat = currDate.getDate();
    let currMonth = currDate.getMonth() + 1;

    let currMinDate = `${currMonth}/${currDat}/${currYear}`;

    this.state.minDat = currMinDate;
  }

  async initGrid() {
    this.state.showLoader = true;
    this.state.rowData = [];

    try {
      const res = await utilService.getData(
        '/a2mt/services/user-details/getspssRequest'
      );
      console.log('getspssRequest=', res);

      console.log('res.data=', res);
      const dataArray = res.data; // dynamic

      if (dataArray === null || dataArray === '') {
        this.state.showLoader = false;

        this.state.rowData = ['Data not available'];
      } else {
        this.state.showLoader = false;

        let finalDataArray = [];
        dataArray.map((data, i) => {
          let tab = this.state.activeTab.search(dataArray[i].status);

          if (tab != -1) {
            finalDataArray.push(dataArray[i]);
          }
        });

        let calculatedPageNumber =
          finalDataArray.length / this.state.pageSize +
          (finalDataArray.length % this.state.pageSize > 0 ? 1 : 0);

        if (this.state.initialLoad === false) {
          if (parseInt(calculatedPageNumber) < this.state.curr_Page) {
            this.setState({
              page: parseInt(calculatedPageNumber),
              pageSize: this.state.pageSize,
            });
          }
        }

        this.setState({
          rowData: finalDataArray,
        });

        this.getWorkshopcount();
      }
    } catch (error) {
      this.state.showLoader = false;
    }
  }

  handleSelect = label => {
    // this is how you update your state

    this.setState({
      activeTab: label,
      page: 1,
      pageSize: 10,
    });
    this.initGrid();
  };

  async initVersion() {
    try {
      const res = await utilService.getData(
        '/a2mt/services/user-details/getspssVersion'
      );

      if (res.data != null && res.data.length > 0) {
        let tempData = [];
        for (let i = 0; i < res.data.length; i++) {
          tempData.push(res.data[i].version);
        }
        this.setState({
          spssVersionList: tempData,
        });
        this.initDPLstatus();
      }
    } catch (err) {}
  }

  async initDPLstatus() {
    try {
      const res = await utilService.getData(
        '/a2mt/services/user-details/getDplStatus'
      );

      if (res.data != null && res.data.length > 0) {
        let dplStatusTempData = [];
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].isEnable == true) {
            dplStatusTempData.push(res.data[i].statusName);
          }
        }
        this.setState({
          dplStatusValues: dplStatusTempData,
        });
        console.log('tempData==', dplStatusTempData);
      }
    } catch (error) {}
  }

  async getQuestionAnswer(userId, requestId) {
    this.state.showLoader = true;

    try {
      const res = await utilService.getData(
        `/a2mt/services/user-details/spssQuestionAnswer/${userId}/${requestId}`
      );
      console.log('questionAnswerData=====', res.data);

      this.state.showLoader = false;

      this.setState({
        questionAnswerData: res.data,
      });

      console.log('questionAnswerData=====', this.state.questionAnswerData);
    } catch (error) {
      this.setState({
        alertMsg: 'Please try again later.',
        alertType: 'Warning',
        showAlert: true,
      });
    }
  }

  changeDateFormat(dateValue) {
    let newdateFormat = new Date(dateValue);
    let year = newdateFormat.getFullYear(); // 2021
    let month = newdateFormat.getMonth() + 1; // 0 (zero-based index)
    let date = newdateFormat.getDate();
    return (month + '/' + date + '/' + year).toString();
  }
  getSelectedRow = event => {
    this.state.editDataset = {};

    let sel = this.state.selectedRowData;

    if (sel !== null) {
      let record = this.state.rowData.filter(e => e.id === sel.id);
      let requestId = this.state.selectedRowData.id;

      let userId = sel.cells[0].value;

      this.getQuestionAnswer(userId, requestId);
      if (record.length > 0) {
        record.map((data, i) => {
          if (!('expiryDate' in data)) {
            data.expiryDate = null;
          }
          if (!('version' in data)) {
            data.version = '';
          }
          if (!('SpssLicCd' in data)) {
            data.SpssLicCd = '';
          }

          if (typeof data.denialCode == 'undefined') {
            data.denialCode = '';
          }
          if (typeof data.SpssLicCd == 'undefined') {
            data.SpssLicCd = '';
          }
          if (data.status === 'Rejected' || data.status === 'Approved') {
            this.state.disableAllFormFields = true;
            this.state.disableSubmitButt = true;
          }

          if (
            data.version == 'null' ||
            typeof record[0].version == 'undefined'
          ) {
            data.version = '';
          }
        });

        this.setState({
          editDataset: record[0],
        });

        this.setState({
          isViewOverlayVisible: true,
        });
      } else {
        this.state.editDataset = {};
      }
    } else {
      this.setState({
        alertMsg: 'Please select a row to review!',
        alertType: 'Warning',
        showAlert: true,
      });
    }
  };

  onChangeInput = event => {
    console.log('onChangeInput=', this.state.editDataset);
    this.setState({
      editDataset: Object.assign({}, this.state.editDataset, {
        [event.target.name]: event.target.value,
      }),
    });

    // if (event.target.name === "denialCode") {
    //   this.setState({
    //     invalidDplDenialCode: false,
    //   });
    // }
    if (event.target.name === 'SpssLicCd') {
      this.setState({
        invalidSpssLicCd: false,
      });
    }
  };

  onSelectTypeChange = event => {
    console.log('event >>>> ', event);
    const { options, selectedIndex } = event.target;
    console.log('event.target >>>> ', event.target);
    if (event.target.name == 'dplStatus') {
      this.setState(prevState => ({
        editDataset: {
          ...prevState.editDataset,
          dplStatus: options[selectedIndex].innerHTML,
        },
      }));
    }

    if (event.target.name == 'status') {
      this.setState(prevState => ({
        editDataset: {
          ...prevState.editDataset,
          status: options[selectedIndex].innerHTML,
        },
      }));
    }

    if (event.target.name == 'version') {
      //Select Version
      if (options[selectedIndex].innerHTML === 'Select Version') {
        this.setState(prevState => ({
          editDataset: {
            ...prevState.editDataset,
            version: '',
          },
        }));
      } else {
        this.setState(prevState => ({
          editDataset: {
            ...prevState.editDataset,
            version: options[selectedIndex].innerHTML,
          },
        }));
        this.setState({
          invalidVersion: false,
        });
      }
    }
  };

  handleChangeDate = value => {
    console.log('value' + value);
    this.setState(prevState => ({
      editDataset: {
        ...prevState.editDataset,
        expiryDate: value,
      },
    }));
    if (value != '') {
      let expiryDatFull = new Date(value);

      let expiryDatFullDay = expiryDatFull.toString().substr(4, 6);

      let expiryDatFullYear = expiryDatFull.toString().substr(11, 13);

      this.setState({
        spssExpiryDat: expiryDatFullDay + ', ' + expiryDatFullYear + ' AM',
        invalidexpiryDate: false,
      });
    }
  };

  handlePagination(event) {
    const { page, pageSize } = event;

    if (page && pageSize) {
      this.setState({
        page,
        pageSize,
      });
    }
  }

  onSubmit = event => {
    if (this.state.editDataset.status === 'Approved') {
      let formValidation = false;
      formValidation = this.validateForm();

      if (formValidation) {
        event.preventDefault();
        this.submitStatusData();
      } else {
        return false;
      }
    } else {
      event.preventDefault();
      this.submitStatusData();
    }
  };

  submitStatusData() {
    let expiryDate;
    console.log('this.state.spssExpiryDat', this.state.spssExpiryDat, '---');
    if (this.state.spssExpiryDat !== '') {
      expiryDate = this.state.spssExpiryDat;
    } else {
      console.log('date ===', this.state.editDataset.expiryDate);
      expiryDate = this.state.editDataset.expiryDate;
      if (expiryDate === '' || expiryDate === null) {
        expiryDate = null;
      } else if (expiryDate.length === 0) {
        expiryDate = null;
      }
    }
    if (this.state.editDataset.status === 'Rejected') {
      expiryDate = null;
      this.state.editDataset.version = '';
      this.state.editDataset.SpssLicCd = '';
    }

    let formData = {
      id: Number(this.state.editDataset.id),
      dplStatus: this.state.editDataset.dplStatus,
      denialCode: this.state.editDataset.denialCode, //
      updatedBy: localStorage.getItem('userEmail'),
      status: this.state.editDataset.status,
      expiryDate: expiryDate, //Dec 20, 2021 12:00:00 AM",
      version: this.state.editDataset.version, //
      SpssLicCd: this.state.editDataset.SpssLicCd, //
    };
    console.log('formData', formData);

    this.updateStatus(formData);
  }

  updateStatus(formData) {
    this.setState({
      showLoader: true,
    });

    utilService
      .postData('/a2mt/services/user-details/updateSpssRequest', formData)
      .then(res => {
        this.setState({
          showLoader: false,
        });

        if (typeof res.data !== 'undefined' && res.data !== null) {
          if (res.status == 200) {
            this.setState({
              alertMsg: 'Updates saved successfully.',
              alertType: 'Success',
              showAlert: true,
            });
          } else {
            this.setState({
              alertMsg: 'Save updates failed. Please try again later.',
              alertType: 'Warning',
              showAlert: true,
            });
          }
          this.closeOverlay();
        }
      })
      .catch(error => {
        this.setState({
          showLoader: false,
        });
        this.closeOverlay();
      });
  }

  closeOverlay = event => {
    this.setState({
      isEditOverlayVisible: false,
      isViewOverlayVisible: false,
      spssExpiryDat: '',
    });
    // this.initGrid();
    this.resetValidationfields();
  };
  closeAlert = event => {
    this.setState({
      showAlert: false,
    });
    this.initGrid();
  };

  resetValidationfields() {
    this.state.invalidexpiryDate = false;
    // this.state.invalidDplDenialCode  = false;
    this.state.invalidSpssLicCd = false;
    this.state.invalidVersion = false;
    this.state.initialLoad = false;
    this.state.disableAllFormFields = false;
    this.state.disableSubmitButt = false;
    this.state.questionAnswerData = [];
  }

  validateForm = () => {
    if (
      document.getElementById('expiry-date-picker-single').value.trim() === ''
    ) {
      this.setState({
        invalidexpiryDate: true,
      });
    } else {
      this.setState({
        invalidexpiryDate: false,
      });
    }

    // if (this.state.editDataset.denialCode.trim() === "") {
    //   this.setState({
    //     invalidDplDenialCode: true,
    //   });
    // } else {
    //   this.setState({
    //     invalidDplDenialCode: false,
    //   });
    // }

    if (this.state.editDataset.SpssLicCd.trim() === '') {
      this.setState({
        invalidSpssLicCd: true,
      });
    } else {
      this.setState({
        invalidSpssLicCd: false,
      });
    }

    if (this.state.editDataset.version.trim() === '') {
      this.setState({
        invalidVersion: true,
      });
    } else {
      this.setState({
        invalidVersion: false,
      });
    }

    if (
      document.getElementById('expiry-date-picker-single').value.trim() !==
        '' &&
      this.state.editDataset.SpssLicCd.trim() !== '' &&
      this.state.editDataset.version.trim() !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  async getWorkshopcount() {
    const res = await utilService.getData(
      '/a2mt/services/user-details/getSPSScountStatus'
    );

    this.setState({
      Pending: res.data.Pending,
      Approved: res.data.Approved,
      Rejected: res.data.Rejected,
    });
    this.initVersion();
  }

  render() {
    let uiExpiryDate = this.changeDateFormat(this.state.editDataset.expiryDate);

    const { page, pageSize } = this.state;
    this.state.curr_Page = page;
    this.state.curr_PageSize = pageSize;

    return (
      <Grid>
        <Column
          lg={{ span: 12, offset: 4 }}
          md={8}
          sm={4}
          style={{ padding: '3em 0' }}>
          <header className="header">
            <Tabs className="tabs" style={{ marginLeft: '2rem' }}>
              <TabList>
                {[
                  'Pending' + ' (' + this.state.Pending + ')',
                  'Approved' + ' (' + this.state.Approved + ')',
                  'Rejected' + ' (' + this.state.Rejected + ')',
                ].map((label, index) => (
                  <Tab key={label} onClick={() => this.handleSelect(label)}>
                    {label}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {[
                  'Pending' + ' (' + this.state.Pending + ')',
                  'Approved' + ' (' + this.state.Approved + ')',
                  'Rejected' + ' (' + this.state.Rejected + ')',
                ].map((label, index) => (
                  <TabPanel label={label} key={label}>
                    <div>
                      <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4 section-height">
                        <section>
                          <DataTable
                            rows={this.state.rowData}
                            headers={this.state.headerData}
                            radio
                            isSortable>
                            {({
                              rows,
                              headers,
                              getHeaderProps,
                              getRowProps,
                              getSelectionProps,
                              getTableProps,
                              getTableContainerProps,
                              onInputChange,
                            }) => (
                              <TableContainer {...getTableContainerProps()}>
                                <TableToolbar>
                                  <TableToolbarContent>
                                    <TableToolbarSearch
                                      onChange={onInputChange}
                                    />
                                    <Button onClick={this.getSelectedRow}>
                                      Review
                                    </Button>
                                  </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()}>
                                  <TableHead>
                                    <TableRow>
                                      <th scope="col" />
                                      {headers.map((header, i) => (
                                        <TableHeader
                                          key={i}
                                          {...getHeaderProps({
                                            header,
                                          })}>
                                          {header.header}
                                        </TableHeader>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows
                                      .splice((page - 1) * pageSize, pageSize)
                                      .map((row, i) => (
                                        <TableRow
                                          key={i}
                                          {...getRowProps({ row })}>
                                          <TableSelectRow
                                            {...getSelectionProps({
                                              row,
                                              onClick: () =>
                                                this.setState({
                                                  selectedRowData: row,
                                                }),
                                            })}
                                          />
                                          {row.cells.map(cell => (
                                            <TableCell key={cell.id}>
                                              {cell.value}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </DataTable>
                          <Pagination
                            page={page}
                            backwardText="Previous page"
                            // disabled={false}
                            forwardText="Next page"
                            isLastPage={false}
                            itemsPerPageText="Items per page:"
                            onChange={e => this.handlePagination(e)}
                            disabled={false}
                            pageNumberText="Page Number"
                            pageSizes={[10, 15, 20, 25]}
                            pagesUnknown={false}
                            totalItems={this.state.rowData.length}
                          />
                        </section>
                        <Loading
                          withOverlay={true}
                          description="Active loading indicator"
                          active={this.state.showLoader}
                        />
                        <Modal
                          passiveModal={true}
                          preventCloseOnClickOutside={false}
                          open={this.state.showAlert}
                          size="sm"
                          onRequestClose={this.closeAlert}>
                          <div style={{ padding: '1em 0' }}>
                            {this.state.alertType == 'Success' && (
                              <div>
                                <CheckmarkOutline
                                  style={{
                                    width: '7em',
                                    height: '7em',
                                    color: 'green',
                                  }}
                                />
                              </div>
                            )}
                            {this.state.alertType == 'Warning' && (
                              <div>
                                <WarningAlt
                                  style={{
                                    width: '7em',
                                    height: '7em',
                                    color: 'orange',
                                  }}
                                />
                              </div>
                            )}
                            {this.state.alertType == 'Error' && (
                              <div>
                                <MisuseOutline
                                  style={{
                                    width: '7em',
                                    height: '7em',
                                    color: 'red',
                                  }}
                                />
                              </div>
                            )}
                            <div style={{ paddingLeft: '1em' }}>
                              {this.state.alertMsg}
                            </div>
                          </div>
                        </Modal>
                        <ComposedModal
                          className="spssOverlay"
                          size="lg"
                          selectorPrimaryFocus="#text-input-1"
                          hasform
                          open={this.state.isViewOverlayVisible}
                          hasScrollingContent
                          onRequestClose={this.props.hidden}
                          icondescription="Close the modal"
                          onClose={this.closeOverlay}>
                          <ModalHeader>
                            <h3 className="bx--modal-header__heading">
                              Review SPSS Request
                            </h3>
                          </ModalHeader>
                          <ModalBody className="removePadding">
                            <div className="bx--grid">
                              <div
                                className="bx--row"
                                style={{ paddingBottom: '1em' }}>
                                <div
                                  className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                  // style={{ padding: "1em 0 0 1em" }}
                                >
                                  <span>ID</span>
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  {this.state.editDataset.userId}
                                </div>
                              </div>
                              <div
                                className="bx--row"
                                style={{ paddingBottom: '1em' }}>
                                <div
                                  className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4"
                                  // style={{ padding: "1em 0 0 1em" }}
                                >
                                  <span>Institution Name</span>
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  {this.state.editDataset.institutionName}
                                </div>
                              </div>

                              <div
                                className="bx--row"
                                style={{
                                  paddingBottom: '1em',
                                }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>Email</span>{' '}
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  {this.state.editDataset.userEmail}
                                </div>
                              </div>
                              <div
                                className="bx--row"
                                style={{
                                  paddingBottom: '1em',
                                }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>First Name</span>{' '}
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  {this.state.editDataset.firstName}
                                </div>
                              </div>
                              <div
                                className="bx--row"
                                style={{
                                  borderBottom: '1px solid #c6c6c6',
                                  paddingBottom: '1em',
                                }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>Last Name</span>{' '}
                                </div>

                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  {this.state.editDataset.lastName}
                                </div>
                              </div>
                              {this.state.questionAnswerData.length !== 0 && (
                                <div
                                  className="bx--row"
                                  style={{
                                    borderBottom: '1px solid #c6c6c6',
                                    padding: '1em 0 1em 1em',
                                  }}>
                                  <div className="bx--col-lg-16 bx--col-md-8 bx--col-sm-4">
                                    {this.state.questionAnswerData.map(val => (
                                      <UnorderedList>
                                        <ListItem>
                                          <span
                                            style={{
                                              fontWeight: '600',
                                            }}>
                                            Question:{' '}
                                          </span>
                                          {val.question}
                                          <UnorderedList
                                            style={{
                                              marginBottom: '1em',
                                            }}>
                                            <ListItem>
                                              <span
                                                style={{
                                                  fontWeight: '600',
                                                }}>
                                                Answer:{' '}
                                              </span>
                                              {val.answer}
                                            </ListItem>
                                          </UnorderedList>
                                        </ListItem>
                                      </UnorderedList>
                                    ))}
                                  </div>
                                </div>
                              )}
                              <div
                                className="bx--row"
                                style={{ padding: '1em 0 0 0' }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>DPL Status</span>{' '}
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <Select
                                    required
                                    id="select-option-1"
                                    noLabel
                                    name="dplStatus"
                                    onChange={this.onSelectTypeChange}
                                    value={this.state.editDataset.dplStatus}
                                    disabled={this.state.disableAllFormFields}>
                                    <SelectItem
                                      disabled
                                      text="Choose an option"
                                    />
                                    {this.state.dplStatusValues.map(val => (
                                      <SelectItem value={val} text={val} />
                                    ))}
                                  </Select>
                                </div>
                              </div>
                              <div
                                className="bx--row"
                                style={{ padding: '1em 0 0 0' }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>DPL Denial Code</span>
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <TextInput
                                    disabled={this.state.disableAllFormFields}
                                    type="text"
                                    name="denialCode"
                                    value={this.state.editDataset.denialCode}
                                    // invalid={this.state.invalidDplDenialCode}
                                    onChange={this.onChangeInput}
                                    required
                                  />
                                </div>
                              </div>
                              <div
                                className="bx--row"
                                style={{ padding: '1em 0 0 0' }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>Status</span>{' '}
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <Select
                                    required
                                    disabled={this.state.disableAllFormFields}
                                    id="select-option-1"
                                    noLabel
                                    name="status"
                                    onChange={this.onSelectTypeChange}
                                    value={this.state.editDataset.status}>
                                    <SelectItem
                                      disabled
                                      text="Choose an option"
                                    />
                                    {this.state.status.map(val => (
                                      <SelectItem value={val} text={val} />
                                    ))}
                                  </Select>
                                </div>
                              </div>

                              <div
                                className="bx--row"
                                style={{ padding: '1em 0 0 0' }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>SPSS Licence Code</span>
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <TextInput
                                    disabled={this.state.disableAllFormFields}
                                    type="text"
                                    name="SpssLicCd"
                                    value={this.state.editDataset.SpssLicCd}
                                    onChange={this.onChangeInput}
                                    invalid={this.state.invalidSpssLicCd}
                                    required
                                  />
                                </div>
                              </div>
                              <div
                                className="bx--row"
                                style={{ padding: '1em 0 0 0' }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>SPSS Version</span>
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <Select
                                    disabled={this.state.disableAllFormFields}
                                    id="select-option-1"
                                    noLabel
                                    value={this.state.editDataset.version}
                                    onChange={this.onSelectTypeChange}
                                    name="version"
                                    invalid={this.state.invalidVersion}>
                                    <SelectItem text="Select Version" />
                                    {this.state.spssVersionList.map(val => (
                                      <SelectItem value={val} text={val} />
                                    ))}
                                  </Select>
                                </div>
                              </div>
                              <div
                                className="bx--row"
                                style={{
                                  borderBottom: '1px solid #c6c6c6',
                                  padding: '1em 0 1em 0',
                                }}>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <span>Expiry date</span>{' '}
                                </div>
                                <div className="bx--col-lg-8 bx--col-md-4 bx--col-sm-4">
                                  <DatePicker
                                    value={uiExpiryDate}
                                    // value={"06/25/2022"}
                                    datePickerType="single"
                                    minDate={this.state.minDat}
                                    invalid={this.state.invalidexpiryDate}
                                    // dateFormat="m/d/Y"
                                    onChange={this.handleChangeDate.bind(this)}>
                                    <DatePickerInput
                                      style={
                                        this.state.invalidexpiryDate
                                          ? {
                                              border: '2px solid red',
                                            }
                                          : {
                                              border: '2px solid white',
                                            }
                                      }
                                      placeholder="mm/dd/yyyy"
                                      id="expiry-date-picker-single"
                                      name="expiryDate"
                                      disabled={this.state.disableAllFormFields}
                                    />
                                  </DatePicker>
                                </div>
                              </div>
                            </div>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              kind="secondary"
                              onClick={this.closeOverlay}>
                              Close
                            </Button>
                            <Button
                              kind="primary"
                              disabled={this.state.disableSubmitButt}
                              onClick={this.onSubmit}>
                              Submit
                            </Button>
                          </ModalFooter>
                        </ComposedModal>
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </header>
        </Column>
      </Grid>
    );
  }
}
