//import './cssfile.scss';
import React, { Component } from 'react';
import './modals.scss';
import { Button, ComposedModal, ModalHeader, ModalFooter } from '@carbon/react';
class SuccessModal extends Component {
    render() {
        return (
            <React.Fragment>
               <ComposedModal
                    className='some-class'
                    hasScrollingContent={false}
                    iconDescription='Close the modal'
                    modalAriaLabel=''
                    modalLabel=''
                    onRequestClose={this.props.hidden}
                    onRequestSubmit={this.props.successok}
                    open
                    primaryButtonDisabled={false}
                    primaryButtonText='Submit'
                    secondaryButtonText=''
                    alert={true}
                    hasForm={true}
                    selectorPrimaryFocus='[data-modal-primary-focus]'
                    size='sm'
                    onClose={this.props.hidden}>
                    <ModalHeader>
                    <div style={{ marginTop: '1rem' }}>
                        <p>Thank you, your request has been submitted successfully. We will inform you via email once the request has been processed.</p><br></br>
                        </div>
                    </ModalHeader>
                     <ModalFooter>
                        <Button
                            kind="primary"
                            type="button"
                            onClick={this.props.successok}
                        >
                            OK
  </Button>

                    </ModalFooter>
                </ComposedModal>
            </React.Fragment>
        )
    }
}
export default SuccessModal;